import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import DashboardLocalFooter from '../../userDashboardLocalFooter';

const ActiveInvestment = (props) => {
  return (
    <div>
      <div className=" is-2_5em--white-wrap is-1_27-grid  is-txt--centered">
        <div>
          <div className="">
            <img
              src="/images/user-guide/approval-investment_1366x575.png"
              alt="Investment pending confirmation"
            ></img>
          </div>
          <div className="c-rich-text">
            <h3>Investment confirmed</h3>
          </div>
        </div>
        <div className="is-flex flex-c">
          <div className="c-rich-text is-flex__80-jc-c">
            <p>
              Congratulation! Your investment has been confirmed and the state
              is now
              <span className="credit-sum__state active--solid glue-c-capcel">
                active
              </span>
            </p>

            <p>
              We have sent you a confirmation email with further details as
              regards your investment.
            </p>

            <p>Thank you for choosing Skyewise</p>
          </div>
        </div>
      </div>
      <DashboardLocalFooter />
    </div>
  );
};

export default ActiveInvestment;
