import http from './httpService';
import configData from '../config.json';

const apiUrl = configData.blogUpdateUrl;
const apiEndpoint = apiUrl + '/posts';
const mediaEndpoint = apiUrl + '/media/';

function blogUrl(query) {
  return `${apiEndpoint}/${query}`;
}

export function createBlog(newBlog) {
  return http.post(apiEndpoint, {
    blog: newBlog.blog,
    amount: newBlog.amount,
  });
}

export function getBlogs() {
  return http.get(apiEndpoint);
}

export function getActiveBlog(blogId) {
  return http.get(blogUrl(blogId));
}

export function getBlog(blogId) {
  return http.get(blogUrl(blogId));
}

export function getBlogQuery(query) {
  let queryStr = '';

  if (query) {
    Object.keys(query).map((prop, k) => {
      queryStr +=
        k == 0
          ? '?' + prop + '=' + query[prop]
          : '&' + prop + '=' + query[prop];
    });
  }
  return http.get(apiEndpoint + queryStr);
}

export async function setBlogFeaturedMedia(blogPosts) {
  // Initialize post data
  localStorage.removeItem('recentBlogPosts');

  let postWithMediaURL = await blogPosts.map(async (post, i) => {
    const postMediaId = post.featured_media;

    // try {
    let { data: postMedia } = await http.get(mediaEndpoint + postMediaId);
    post.featured_media_url = postMedia.guid.rendered;

    let prevLocalPost;

    if (localStorage.getItem('recentBlogPosts')) {
      prevLocalPost = JSON.parse(localStorage.getItem('recentBlogPosts'));
    } else {
      prevLocalPost = [];
    }

    prevLocalPost.push(post);

    localStorage.setItem('recentBlogPosts', JSON.stringify(prevLocalPost));
    // } catch (e) {}

    // localPostData.push(post);

    // }

    // console.log('Post with Featured Meddia', localPostData);

    return post;
  });

  // localStorage.setItem('tempRecentBlogPosts', JSON.stringify(postWithMediaURL));
  return postWithMediaURL;
}

export function saveBlog(blog) {
  if (blog._id) {
    const body = { ...blog };
    delete body._id;
    return http.put(blogUrl(blog._id), body);
  }

  return http.post(apiEndpoint, blog);
}

export function updateBlog(blogId, body) {
  return http.patch(blogUrl(blogId), body);
}

export function deleteBlog(blogId) {
  return http.delete(blogUrl(blogId));
}

export default {
  getActiveBlog,
  createBlog,
  getBlogQuery,
};
