import React from 'react';
import { Link } from 'react-router-dom';

const AdminNav = (props) => {
  return (
    <div className="gray-bg-01  wf-section">
      <div className=""></div>
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text ">
              <div class="c-rich-text">
                <h2>Management portal</h2>
                <p>Manage clients, loans, and investment requests</p>
              </div>
            </div>
          </div>
        </div>

        <div className="_3-col-st-grid is--t-mg-2vh">
          <div
            id="w-node-d32d7ecc-132a-3c09-e382-4dfad903fa9f-f14bd371"
            data-w-id="d32d7ecc-132a-3c09-e382-4dfad903fa9f"
            className="s-cards-slider admin-nav__item"
          >
            <div className="div-block-129 ">
              <Link to="/admin/investments">
                <div className="s-cards-slider__wheel ">
                  <div className="admin-nav__item__icon">
                    <span class="is-fa-icon--300"></span>
                  </div>
                  <h3 className="s-cards-slider__title">Investments</h3>
                  <p className="s-cards-slider__p">Manage investment</p>
                  <div className="content-rw__cta s-cards-slider__cta"></div>
                </div>
              </Link>
            </div>
          </div>
          <div
            id="w-node-d32d7ecc-132a-3c09-e382-4dfad903fa9f-f14bd371"
            data-w-id="d32d7ecc-132a-3c09-e382-4dfad903fa9f"
            className="s-cards-slider admin-nav__item"
          >
            <div className="div-block-129 ">
              <Link to="/admin/loans">
                <div className="s-cards-slider__wheel ">
                  <div className="admin-nav__item__icon">
                    <span class="is-fa-icon--300"></span>
                  </div>
                  <h3 className="s-cards-slider__title">Loans</h3>
                  <p className="s-cards-slider__p">Manage loans</p>
                  <div className="content-rw__cta s-cards-slider__cta"></div>
                </div>
              </Link>
            </div>
          </div>

          <div
            id="w-node-d32d7ecc-132a-3c09-e382-4dfad903fa9f-f14bd371"
            data-w-id="d32d7ecc-132a-3c09-e382-4dfad903fa9f"
            className="s-cards-slider admin-nav__item"
          >
            <div className="div-block-129 ">
              <Link to="/admin/clients">
                <div className="s-cards-slider__wheel ">
                  <div className="admin-nav__item__icon">
                    <span class="is-fa-icon--300"></span>
                  </div>
                  <h3 className="s-cards-slider__title">Clients</h3>
                  <p className="s-cards-slider__p">Manage users </p>
                  <div className="content-rw__cta s-cards-slider__cta"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNav;
