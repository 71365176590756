import React, { Fragment, Component } from 'react';
import KYCPageHeader from './kycPageHeader';
import Navbar from '../../common/navbar/navbar';
import Footer from '../../common/footer/footer';
import KYCFormSection from './kycFormSection';
import KycProgressHint from './kycStepsHint';
import restartWebflowScriptClassComponents from '../../common/restartWebflowScriptClassConponents';
import GlueTips from '../../common/UiLayoutsAndComponents/glueTips';
import WhyKycVerification from './kycVerificationWhy';

class KYCVerificationOverview extends Component {
  componentDidMount() {
    restartWebflowScriptClassComponents();
  }

  render() {
    return (
      <Fragment>
        <Navbar />
        <KYCPageHeader />
        <WhyKycVerification />
        <Footer />
      </Fragment>
    );
  }
}

export default KYCVerificationOverview;
