import React, { Fragment, useEffect, Component, useLayoutEffect } from "react";
import restartWebflowScript from "../common/restartWebflowScript";

import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import PageExitCTA from "../common/pageExitCTA";

import TravelAndTourHero from "./travelAndTourHeroOne";
import TravelAndTourIntoText from "./travelAndTourIntroText";
import BookTicket from "./travelAndTicketBookingSection";

import TravelAndTourButtonGridCta from "./travelAndTourButtonGridCta";
import TravelAndTourServices from "./travelAndTourServices";
import TravelAndTourFeaturedService from "./travelAndTourFeaturedService";
import TravelAndTourServicesBooking from "./travelAndTourServicesBooking";
import TravelTourServicesSummary from "./travelAndTourServicesSummary";
import BookingScenario from "./travelTourBookingProcess";

const TravelAndTour = (props) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f460034bd35f");
  });

  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <TravelAndTourHero />

      <TravelAndTourServices />
      <TravelAndTourFeaturedService />
      <TravelAndTourServicesBooking />
      {/* <BookingScenario /> */}
      {/* <TravelTourServicesSummary /> */}

      <TravelAndTourButtonGridCta />
      {/* <BookTicket /> */}
      <TravelAndTourIntoText />
      <PageExitCTA />
      <Footer />
    </Fragment>
  );
};

export default TravelAndTour;
