import React, { Fragment } from "react";
import Progress from "./common/progress";

class ProgressRender extends Progress {
  render() {
    return (
      <Fragment>
        {this.renderLoading(
          this.props.elData.loading.name,
          "message-placehoder",
          this.props.elData.loading.size,
          this.props.elData.loading.transparency
        )}
        {this.renderSuccess(
          this.props.elData.success.name,
          "message-placehoder",
          this.props.elData.loading.size,
          this.props.elData.loading.transparency
        )}
        {this.renderError(
          this.props.elData.error.name,
          "message-placehoder",
          this.props.elData.loading.size,
          this.props.elData.loading.transparency
        )}
      </Fragment>
    );
  }
}

export default ProgressRender;
