import React from 'react';
import { Link } from 'react-router-dom';

const SWRealEstate = (props) => {
  return (
    <div
      data-w-id="da700eed-4ca2-9a0f-1f5f-910d3f1d96b2"
      className="hero-slider__slide w-slide hero-slider__slide--sw-real-estate"
    >
      <div className="overlay--dark overlay--dark--65deg"></div>
      <div className="container hero-slider__container">
        <div className="slide__content">
          <h1 className="slide__content__title">
            <span style={{ fontWeight: 100 }}>Skyewise </span>
            {/* <br /> */}
            <span style={{ display: 'inline-block' }}> Real Estate </span>
          </h1>
          <p className="slide__content__desc">
            Smart homes with excellent modern facilities.
          </p>
          <div className="slide__cta">
            <Link
              to="/real-estate"
              className="s-btn is-btn--light w-inline-block"
            >
              <p>Learn more</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SWRealEstate;
