import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const RealEstateProperties = () => {
  return (
    <Fragment>
      <div className="_1-col-c-center gray-bg-01 wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow st-r-mb ">
              <div className="c-rich-text">
                <h2>5 Bedroom Terrace</h2>
              </div>
            </div>
          </div>

          <div className="_1-col-c-center__wrp">
            {/* <div className="_1-col-c-center__c-narrow"> */}
            <div className="_2-col-st-grid">
              <div className="grid__item">
                {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
                <img
                  className="img--st-b-radius"
                  src="/content/images/real-estate/properties/rs-03.jpg"
                />
              </div>

              <div className="grid__item is-txt-align--left ">
                <div className="property-table">
                  <div>
                    <div className="c-rich-text">
                      {/* <h2>5 Bedroom Terrace</h2> */}
                    </div>
                    <ul role="list" className="w-list-unstyled">
                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">BQ Attached </h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            The 5 Bedroom Terrace comes with a Boys Quarter (BQ)
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">260SQM</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            Unit area of 260 Square meters
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">Pool</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            The 5 Bedroom Terrace comes with a swimming pool
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">24/7 Electricity</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            24 hours stable power supply
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">Prices</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <ul style={{ opacity: 0 }} className="expand__text">
                            <li>
                              <h5>Land</h5>
                              <p>8.5 Million Naira</p>
                            </li>
                            <li>
                              <h5>Completed</h5>
                              <p>75 Million Naira</p>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>

      <div className="_1-col-c-center gray-bg-01 wf-section">
        <div className="container container--no-space-top">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow st-r-mb ">
              <div className="c-rich-text">
                <h2>5 Bedroom Detached</h2>
              </div>
            </div>
          </div>

          <div className="_1-col-c-center__wrp">
            {/* <div className="_1-col-c-center__c-narrow"> */}

            <div className="_2-col-st-grid">
              <div className="grid__item is-txt-align--left ">
                <div className="property-table">
                  <div>
                    <div className="c-rich-text">
                      {/* <h2>5 Bedroom Detached</h2> */}
                    </div>
                    <ul role="list" className="w-list-unstyled">
                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">BQ Attached </h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            The 5 Bedroom Detached comes with a Boys Quarter
                            (BQ)
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">600SQM</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            Unit area of 600 Square meters
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">Pool</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            The 5 Bedroom Detached comes with a swimming pool
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">24/7 Electricity</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            24 hours stable power supply
                          </p>
                        </div>
                      </li>

                      <li className="list-item">
                        <div
                          data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                          className="expand__trigger"
                        >
                          <h4 className="expand__title ">Prices</h4>
                          <div className="is-icon is-icon--square">
                            <p></p>
                          </div>
                        </div>
                        <div
                          style={{ height: 0 + 'px' }}
                          className="expand__details"
                        >
                          <p style={{ opacity: 0 }} className="expand__text">
                            <ul style={{ opacity: 0 }} className="expand__text">
                              <li>
                                <h5>Land</h5>
                                <p>12.5 Million Naira</p>
                              </li>
                              <li>
                                <h5>Completed</h5>
                                <p>90 Million Naira</p>
                              </li>
                            </ul>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="grid__item">
                <img
                  className="img--st-b-radius"
                  src="/content/images/real-estate/properties/rs-04.jpg"
                />

                {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RealEstateProperties;
