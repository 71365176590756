import React from 'react';

const Select = ({ name, label, options, inputIcon, error, ...rest }) => {
  return (
    <div className="s-form__input">
      <div className="s-form__input__con is-wt-100">
        <label className="s-form__input-label" htmlFor={name}></label>
        <div className="is-icon s-form__icon">
          <p>{inputIcon}</p>
        </div>
        <select
          name={name}
          id={name}
          {...rest}
          className="s-form__input__input input--select w-input"
        >
          <option className="" value="">
            {label}
          </option>

          {/* Pass username as value to split later for user selected option confirmation window */}
          {options.map((option, idx) => (
            <option
              className=""
              key={option._id ? option._id : idx}
              value={option._id}
              data--name={option.name}
            >
              <div className="">{option.name}</div>
            </option>
          ))}
        </select>
        {error && (
          <div className="s-form-alert s-form-alert--is-error">{error}</div>
        )}
      </div>
    </div>
  );
};

export default Select;
