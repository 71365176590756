import React, { Fragment } from 'react';
import Navbar from '../../common/navbar/navbar';
import Footer from '../../common/footer/footer';
import { Link } from 'react-router-dom';
import restartWebflowScript from '../../common/restartWebflowScript';

const DirectorEngrChinyereNnennaIgwegbe = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="internal-nav w-nav"
        >
          <div className="header-static static-header--leadership wf-section">
            <div className="container">
              <div className="header-statoc--flex">
                <div className="header-static__container">
                  <h1>Engr. Chinyere Nnenna Igwegbe</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow is--left-align">
                <div className="c-rich-text">
                  <div className="div-block-96 ">
                    <div className="div-block-97">
                      <img
                        src="/images/content/our-people/directors/engr_chinyere_nnenna_igwegbe.webp"
                        loading="lazy"
                        alt="Engr. Chinyere Nnenna Igwegbe"
                      />
                    </div>
                    <div className="div-block-97">
                      {/* <a className="st-link w-inline-block" href="/"> */}
                      <h4 className="c-h4">Engr. Chinyere Nnenna Igwegbe</h4>
                      {/* </a> */}
                      <p>Director </p>
                      <p className="p-medium">
                        Water Resources and Environmental Engineer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="_1-col-c-center__c-narrow is--left-align st-r-mg">
                <div className="c-rich-text">
                  <p className="p-small">
                    A team player with initiative, grit, drive, resilience, and
                    integrity. As an environmental management expert and
                    consultant in the private sector, donor, and the CSO spaces
                    in Nigeria, her experience cuts across the provision of
                    technical guidance on WASH and water security interventions,
                    including providing guidance in the design, implementation,
                    monitoring, and evaluation of WASH and water security
                    programs. Engr. Chinyere Nnenna Igwegbe also has skills in
                    applying evidence-based guidelines, approaches, and tools to
                    support water security programs. Her project management
                    skills gained over the years after graduation with a private
                    firm in the power sector (Distribution, Generation,
                    Transmission, and Procurement) exposed her to a world of
                    resilience to engage and manage Clients, contractors,
                    vendors, and colleagues.
                  </p>

                  <p className="p-small">
                    She can excellently and promptly provide technical guidance
                    where necessary. She is a certified management trainer from
                    the Centre for Management Development(CMD), a Climate
                    Reality Corp Leader under the Al Gore Foundation, and a
                    United Nations Climate Change advocate. She is also a member
                    of the Centre for Strategic Leadership and Development,
                    Africa.
                  </p>

                  <p className="p-small">
                    Her Executive Masters in Business Administration; a diploma
                    in conflict resolution and management, empower her with the
                    skills in the management of community, industry, and civic
                    conflicts.
                  </p>

                  <p className="p-small">
                    Engr. Chinyere Nnenna Igwegbe possesses measurably good
                    leadership skills, these have been proven over the years in
                    her ability to climb the ladder of leadership, attaining
                    some positions in society. She was the first female Class
                    representative in the faculty of Engineering, Ahmadu Bello
                    University, Zaria for from 100L to 500L. She served in
                    different positions with the departmental association and
                    NUESA. Her quest for change in society prompted her to join
                    and serve with different institutions within her profession.
                  </p>

                  <p className="p-small">
                    In 2019, she contested for the Imo State house of Assembly
                    representing Oru West Constituency under the Young
                    Progressives Party (YPP) She served in different capacities
                    of the Nigerian Society of Engineers, Nigerian Institution
                    of Water Engineers, and Nigerian Institution of
                    Environmental Engineers at the local and National levels
                    some of which are:
                  </p>

                  <ul>
                    <li>
                      National Chairman, Nigerian Institution of Water Engineers
                    </li>
                    <li>
                      Secretary-General, Association of Professional Women
                      Engineers of Nigeria (APWEN)
                    </li>

                    <li>
                      4 term National Executive Member (January 2017 to December
                      2020) of the Nigerian Society of Engineers.
                    </li>
                    <li>Chairman membership Board, NSE- 2018</li>
                    <li>
                      Chairman Engineering Manpower, Occupation, and Practice,
                      NSE- 2019
                    </li>

                    <li>
                      Immediate Past Chairman, Nigerian Institution of Water
                      Engineers,
                    </li>

                    <li>
                      Past Publicity Secretary, Past Financial Secretary, the
                      Nigerian Society of Engineers Abuja Branch
                    </li>

                    <li>
                      Nigerian Institution of Environmental Engineers at the
                      local and national levels
                    </li>
                  </ul>

                  <p className="p-small">
                    She belongs to the following associations{' '}
                  </p>

                  <ul>
                    <li>Nigerian Society of Engineers</li>
                    <li>Nigerian Institution of Water Engineers</li>

                    <li>
                      Association of Professional Women Engineers of Nigeria
                    </li>
                    <li>Nigerian Institution of Environmental Engineers</li>
                    <li>
                      Association of Environmental Impact Assessment of Nigeria
                    </li>

                    <li>Society of Women Engineers</li>

                    <li>
                      Society of Women Engineers, Technologists and Scientists
                    </li>

                    <li>Institute of Management Consultant</li>

                    <li>Nigerian Institute of Management</li>
                  </ul>

                  <p className="p-small">
                    Engr. Chinyere Nnenna Igwegbe has attended conferences
                    locally, nationally, and International as well as presented
                    papers in some of them and has received awards and
                    Fellowship from the Nigerian Society of Engineers, the
                    Nigerian Institution of Environmental Engineers, Nigerian
                    Institution of Water Engineers, and Institute of Management
                    Consultant
                  </p>

                  <p className="p-small">
                    Part of her hubby is to lend her voice in bridging the gap
                    spaces between academia and Industry via STEM-based advocacy
                    as an SWE Global Ambassador.
                  </p>

                  <p className="p-small">
                    Engr. Chinyere Nnenna Igwegbe loves the youth and this
                    propelled her to initiate the Bridge the Gap (BTG) program
                    between Academia and the Industry.
                  </p>

                  <p className="p-small">
                    She is the Managing Partner of Krumberg Engineering Limited.
                    Co-founder BTG, Member of Board of Trustees, Benedict
                    Ekejiuba Foundation (BEF).
                  </p>

                  <p className="p-small">
                    Her creative prowess has led to so many insights in Business
                    development and initiatives for start-ups. She loves
                    adventure, nature, travel, writing, and researching.
                  </p>

                  <p className="p-small">She upholds justice and fairness</p>
                </div>
              </div>

              <div className="_1-col-c-center__c-narrow st-r-mg">
                <div className="c-rich-text">
                  <h3>Directors</h3>
                </div>
                <Link
                  to="/about-us/people-and-leadership/directors"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Go back to all directors</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DirectorEngrChinyereNnennaIgwegbe;
