import React, { Component, Fragment } from 'react';

import progressHandler from '../../../utils/progressHandler';
import uploadPlaceholder from '../../../images/co/prcses/l/user-id-2.svg';
import loanService from '../../../services/loanService';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import Form from '../../common/formElements/form';
import {
  getMe,
  updateMe,
  updateKYC,
  uploadKYCDocuments,
} from '../../../services/userService';
import auth from '../../../services/authService';
import ProgressRender from '../../progressRender';
import KYCGlueTips from './kycGlueTips';
import validateFileExt from '../../../utils/validateFileExt';
import errorFactory from '../../../utils/errorFactory';

class LoanCollateral extends Form {
  state = {
    selectedFile: null,
    loan: null,
  };

  componentDidMount() {
    auth.setUVDALocal();
    const currentLoan = auth.getUVDALocal().currentLoan;

    this.setState({ loan: currentLoan });
  }

  fileSelecteMultipleHandler = (event) => {
    initProgressLotties();

    const uploadFiles = event.target.files,
      uploadFile = event.target.files[0];

    try {
      Object.entries(uploadFiles).map((cFile) => {
        // Check for valid file upload
        if (
          !validateFileExt(cFile[1], [
            'image/jpeg',
            'image/png',
            'image/gif',
            'image/webp',
            'image/heic',
          ])
        ) {
          // Throw upload error
          const uploadError = new errorFactory(
            'Invalid Upload',
            'One or more of the selected files is not an image. Please upload only images.'
          );
          // var e = new errorFactory("NotImplementedError message");
          throw uploadError;
          // alert('The file selected is not an image. Please upload a valid image format i.e: jpg, png, gif, webp, heic');
        }
      });
    } catch (e) {
      // Handle file upload error
      progressHandler.showProgress('kyc-document', 'error', e.message);

      setTimeout(() => {
        progressHandler.hideProgress('kyc-document', 'error');
      }, 3500);

      return;
    }

    const imagePreview = window.URL.createObjectURL(uploadFile);

    event.target.parentNode.childNodes[1].children[0].src = imagePreview;

    this.setState({
      selectedFile: event.target.files,
    });
  };

  fileUploadHandler = async (field) => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('kyc-document', 'loading');

    try {
      // Create form Data Object
      const fd = new FormData();

      Object.keys(this.state.selectedFile).map((key) => {
        fd.append(
          field,
          this.state.selectedFile[key],
          this.state.selectedFile.name
        );
      });

      // console.log(fd);

      await uploadKYCDocuments(fd);

      await updateMe({ verificationState: 'data-submission' });

      await auth.setUVDALocal();

      // Hide UI loadin, show success
      progressHandler.hideProgress('kyc-document', 'loading');
      progressHandler.showProgress('kyc-document', 'success');

      setTimeout(() => {
        window.location.reload(false);
      }, 1500);
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        console.error('error');
        // hide UI Loading, show Error
        progressHandler.hideProgress('kyc-document', 'loading');
        progressHandler.showProgress('kyc-document', 'error', msg);
      } else {
        progressHandler.hideProgress('kyc-document', 'loading');
        progressHandler.showProgress(
          'loan',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('kyc-document', 'error');
      }, 3500);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="c-file-input__container">
          {/* <KYCGlueTips /> */}
          <div className="c-file-input" onClick={() => this.fileInput.click()}>
            <input
              className="c-file-input__input-default"
              type="file"
              onChange={this.fileSelecteMultipleHandler}
              ref={(fileInput) => (this.fileInput = fileInput)}
              multiple
            />

            <div className="c-file-input__input-preview input-preview--landscape">
              <img
                id="upload-preview"
                src={uploadPlaceholder}
                className="c-file-input__input-preview__img preview-landscape"
              />

              <ProgressRender
                elData={{
                  loading: {
                    name: 'kyc-document-progress__loading',
                    size: 'small',
                    // transparency: true,
                  },
                  success: {
                    name: 'kyc-document-progress__success',
                    size: 'small',
                    transparency: true,
                  },
                  error: {
                    name: 'kyc-document-progress__error',
                    size: 'small',
                    transparency: true,
                  },
                }}
              />

              <div className="file-upload">
                <div className="file-upload--container">
                  <div className="file-upload--file">
                    <span className="is-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-file-input__cta ">
            <button
              onClick={() => {
                this.fileUploadHandler('kycDocuments');
              }}
              className="s-btn s-btn--blue w-button"
              disabled={this.state.selectedFile ? false : true}
            >
              Verify
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LoanCollateral;
