import React from 'react';
import displayHoverElement from '../../../utils/hoverElementDisplay';

const AdminClientVerification = (props) => {
  const { tip } = props;

  return (
    <div className="ud-main__user__verify ">
      <div id="udm__uv__capcel" className="udm__uv__capcel capcel--disabled">
        <h6>
          {tip.tipTitle}{' '}
          <span className="is-fa-icon is-fa-icon--400 verified">
            {tip.tipIcon}
          </span>
        </h6>
        <p>{tip.tipText}</p>
      </div>
      <div
        className="udm__uv__checkbox"
        onMouseEnter={() => {
          displayHoverElement('udm__uv__capcel', 'capcel--disabled');
        }}
        onMouseLeave={() => {
          displayHoverElement('udm__uv__capcel', 'capcel--disabled');
        }}
      >
        <span className="is-fa-icon is-fa-icon--400 "> {tip.tipIcon}</span>
      </div>
    </div>
  );
};

export default AdminClientVerification;
