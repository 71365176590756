import React from 'react';

const SonarPulse = (props) => {
  return (
    <div className="sonar-pulse">
      <span className="sonar-pulse__ring sonar-pulse__ring--1"></span>
      <span className="sonar-pulse__ring sonar-pulse__ring--2"></span>
      <span className="sonar-pulse__ring sonar-pulse__ring--3"></span>
      <span className="sonar-pulse__ring sonar-pulse__ring--4"></span>
      <span className="sonar-pulse__point"></span>
    </div>
  );
};

export default SonarPulse;
