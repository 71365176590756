import React, { Fragment } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import Navbar from "../common/navbar/navbar";
import ChatAndNavHelperBtn from "../common/chatAndNavHelperOpen";
import LeadershipHeader from "./leadershipHeader";
import BoardOfDirectors from "./boardOfDirectors";
import Footer from "../common/footer/footer";
import BoardOfDirectorsQuote from "./boardOfDirectorsQuote";
import { Helmet } from "react-helmet-async";

const Leadership = () => {
  restartWebflowScript();
  return (
    <Fragment>
      <Navbar />
      <Helmet>
        <title>Board of Directors - About Us - Skyewise Group</title>
        <meta
          name="description"
          content="Skyewise Board of Directors - We have a diverse but complementary team"
        />
        <link rel="canonical" href="/about-us/people-and-leadership" />
      </Helmet>
      <LeadershipHeader />
      <BoardOfDirectors />
      <BoardOfDirectorsQuote />
      <Footer />
    </Fragment>
  );
};

export default Leadership;
