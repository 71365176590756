const Partners = [
  {
    name: "Audi",
    logo: "audi-logo-mini.png",
  },
  {
    name: "BMW",
    logo: "bmw-logo-mini.png",
  },
  {
    name: "Buick",
    logo: "buick-logo-mini.png",
  },
  {
    name: "GMC",
    logo: "gmc-logo-mini.png",
  },

  {
    name: "Toyota",
    logo: "toyota-logo-mini.png",
  },
  {
    name: "Volkswagen",
    logo: "volkswagen-logo-mini.png",
  },
];

function getPartners() {
  return Partners;
}
export default getPartners;
