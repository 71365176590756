import React, { Fragment, Component } from "react";
import PartnershipsGrid from "./partners/partnersLogoGrid";
import getPartners from "./partners/partnersData";

const Partners = getPartners();

const CultureAndInnvationPartnerships = (props) => {
  return (
    <Fragment>
      <section className="section is-bg--gray-02">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h3>Strategic Partnerships</h3>
                  <p>The strength of the team is each individual member</p>
                </div>

                <PartnershipsGrid partners={Partners} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default CultureAndInnvationPartnerships;
