import React, { Component } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

// Services
import auth from './services/authService';

// Context
import UserContext from './context/userContext';

import ProtectedRoute from './components/protectedRoute';

// Pages
import Products from './components/products';
import Posts from './components/posts';
import Home from './components/home/baseHome';
import AdminDashboard from './components/admin/adminCategoryDashboard';
import ProductDetails from './components/productDetails';
import NotFound from './components/notFound';
import ContactPage from './components/contact/contactBase';
import AboutUsOverview from './components/about/aboutUsOverview';
import PurposeAndValues from './components/about/purposeAndValues';
import OurPeople from './components/about/ourPeople';
import PeopleAndLeadership from './components/about/leadership';

// What We Do
import WhatWeDoOverview from './components/about/whatWeDoOverview';
import ProductsAndServices from './components/about/whatWeDoProductsAndServicesBase';

import TheCooperative from './components/theCooperative/theCooperativeBase';

import RealEstate from './components/realEstate/realEstateBase';
import TravelAndTour from './components/travelAndTour/travelAndTourBase';

import Foundation from './components/foundation/foundationBase';
import Automobile from './components/automobile/automobileHome';
import VehiclesShoppingCentre from './components/automobile/vehiclesShoppingCentre';

import LoginPage from './components/loginPage';
import SignupPage from './components/signupPage';

import UserDashbaord from './components/user/userDashboard';
import KYCVerification from './components/user/userKYCVerification/kycVerificationPage';

import './App.css';
import FAQ from './components/about/FAQ';
// import { ToastContainer } from 'react-toastify';
import TestComponent from './components/testComponent';
import SWPLPage from './components/swProducts/loan/loanPage';
import SWPIPage from './components/swProducts/investment/investmentPage';
import WIWS from './components/swProducts/investment/whyInvest';
import CIB from './components/swProducts/investment/clientBenefits';
import PLR from './components/swProducts/loan/personalLoanRequirements';
import DirectorUsmanSarki from './components/about/directors/directorAmbUsmanSarki';
import DirectorAifuwaIgbinoba from './components/about/directors/directorAifuwaIgbinoba';
import DirectorDrNosiruGbadamosi from './components/about/directors/directorNosiruGbadamosi';
import DirectorDrElisAbuyere from './components/about/directors/directorDrElvisAbuyere';
import DirectorEngrChinyereNnennaIgwegbe from './components/about/directors/directorEngrChinyereNnennaIgwegbe';
import DirectorBarrObiageliOnyekwuluje from './components/about/directors/directorBarrObiageliOnyekwuluje';
import TicketBookingPage from './components/travelAndTour/ticketBookingBase';
import AppointmentSchedulePage from './components/appointmentSchedule/appointmentScheduleBase';
import CultureAndInnovation from './components/about/cultureAndInnovationBase';
import OfficeLocations from './components/location/officeLocations';
import Recorgnitions from './components/awardsAndRecorgnitions/awardsBase';
import SEAFEngagements from './components/foundation/communityEngagements/communityEngagementsPage';
import Sustainability from './components/sustainability/sustainabilityPractices';
import BLR from './components/swProducts/loan/businessLoanRequirements';
import Investments from './components/investments';
import MovieForm from './components/movieForm';
import AdminInvestmentDashboard from './components/admin/adminInvestmentsDashboard';
import AdminLoansDashboard from './components/admin/adminLoansDashboard';
import AdminClientDashboard from './components/admin/adminClientsDashboard';
import ManagementTeam from './components/about/managementTeamBase';

import AdminInvestmentSelected from './components/admin/adminInvestmentSelected';
import AdminLoanSelected from './components/admin/adminLoanSelected';
import ToursPage from './components/travelAndTour/toursPage';
import ProtectedRouteAdmin from './components/protectedRouteAdmin';
import UserRestricted from './components/userRestricted';
import AdminClientsSelected from './components/admin/adminClientSelected';
import { initKycProcess } from './utils/initUserOperationDependencies';
import KYCVerificationOverview from './components/user/userKYCVerification/kycVerificationOverview';
import TheCEOsSummit from './components/foundation/theCEOsSummit/mtceosBase';
import MaintenanceMode from './appMaint';
import MeetTheCEOs2022 from './components/foundation/theCEOsSummit2022/MTCEOs2022';
import { getBlogQuery, setBlogFeaturedMedia } from './services/blogService';
import ServerError from './components/tmp/serverError';
import CarListingData from './components/automobile/carListingData';
import { Provider, defaultTheme } from '@adobe/react-spectrum';
import CarListingImages from './components/automobile/carListingImages';
import ShoppingCentreCarPage from './components/automobile/shoppingCentreCarPage';
import ManageCarListing from './components/automobile/manageCarListing';
import { ToastContainer } from '@react-spectrum/toast';
import ProtectedRouteAutoManager from './components/protectedRouteAutoManager';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

class App extends Component {
  state = {};

  async componentDidMount() {
    if (
      localStorage.getItem('UVDA') &&
      localStorage.getItem('c_user') &&
      localStorage.getItem('UATE')
    ) {
      auth.setUVDALocal();
    }
    const userData = auth.getUVDALocal();

    if (userData) {
      const user = {
        userID: userData._id,
        name: userData.name,
        isVerified: userData.isVerified,
        profilePhoto: userData.photo,
        role: userData.role,
        currentLoan: userData.currentLoan,
        currentInvestment: userData.currentInvestment,
        loan: userData.currentInvestment,
        verificationState: userData.verificationState,
        investmentState: userData.investmentState,
        loanState: userData.loanState,
      };
      this.setState({ user });
    }

    const blogSettings = {
      page: 1,
      postLimit: 8,
      postOffset: 0,
    };

    // Fetch Recent Blog Posts
    const fetchBlogPostAsync = async () => {
      let { data: recentBlogUpdate } = await getBlogQuery({
        page: blogSettings.page,
        per_page: blogSettings.postLimit,
        offset: blogSettings.postOffset,
      });

      await setBlogFeaturedMedia(recentBlogUpdate);
    };

    fetchBlogPostAsync();
    // });
  }

  undateUserContext() {
    console.log('User Context Updated');
  }

  render() {
    return (
      <SkeletonTheme baseColor="#f5f5f5" highlightColor="#fefefe">
        <Provider theme={defaultTheme} colorScheme="light">
          <UserContext.Provider value={this.state.user}>
            {/* <ToastContainer /> */}
            <Routes>
              {/* Implementation of protectedRoute v6 */}
              <Route path="/test" element={<TestComponent />} />
              {/* <Route path="/products/:id" element={<ProductDetails />} /> */}
              {/* <Route
            path="/products"
            render={(props) => <Products sortBy="newest" {...props} />}
          /> */}
              {/* <Route path="/posts/:year?/:month?" element={<Posts />} /> */}
              {/* ADMIN ACCESS ROUTES */}
              <Route
                path="/admin/investments/:id"
                element={
                  <ProtectedRouteAdmin>
                    <AdminInvestmentSelected />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/investments"
                element={
                  <ProtectedRouteAdmin>
                    <AdminInvestmentDashboard />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/loans/:id"
                element={
                  <ProtectedRouteAdmin>
                    <AdminLoanSelected />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/loans"
                element={
                  <ProtectedRouteAdmin>
                    <AdminLoansDashboard />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/clients/:id"
                element={
                  <ProtectedRouteAdmin>
                    <AdminClientsSelected />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin/clients"
                element={
                  <ProtectedRouteAdmin>
                    <AdminClientDashboard />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRouteAdmin>
                    <AdminDashboard />
                  </ProtectedRouteAdmin>
                }
              />
              {/* <Navigate from="/messages" to="/posts" /> */}
              {/* Contact */}
              <Route
                path="/contact/appointment"
                exact
                element={<AppointmentSchedulePage />}
              />
              <Route
                path="/contact/locations"
                exact
                element={<OfficeLocations />}
              />
              <Route path="/contact" exact element={<ContactPage />} />
              {/* About */}
              {/* Directors */}
              <Route
                path="/about-us/people-and-leadership/directors/ambassador-usman-sarki"
                exact
                element={<DirectorUsmanSarki />}
              />
              <Route
                path="/about-us/people-and-leadership/directors/aifuwa-igbinoba"
                exact
                element={<DirectorAifuwaIgbinoba />}
              />
              <Route
                path="/about-us/people-and-leadership/directors/dr-nosiru-gbadamosi"
                exact
                element={<DirectorDrNosiruGbadamosi />}
              />
              <Route
                path="/about-us/people-and-leadership/directors/dr-elvis-abuyere"
                exact
                element={<DirectorDrElisAbuyere />}
              />
              <Route
                path="/about-us/people-and-leadership/directors/engr-chinyere-nnenna-igwegbe"
                exact
                element={<DirectorEngrChinyereNnennaIgwegbe />}
              />
              <Route
                path="/about-us/people-and-leadership/directors/barr-obiageli-onyekwuluje"
                exact
                element={<DirectorBarrObiageliOnyekwuluje />}
              />
              <Route
                path="/about-us/people-and-leadership/directors/"
                exact
                element={<PeopleAndLeadership />}
              />
              <Route
                path="/about-us/people-and-leadership/management/"
                exact
                element={<ManagementTeam />}
              />
              <Route
                path="/about-us/people-and-leadership/awards-and-recorgnitions"
                exact
                element={<Recorgnitions />}
              />
              <Route
                path="/about-us/people-and-leadership"
                exact
                element={<PeopleAndLeadership />}
              />
              <Route
                path="/about-us/purpose-and-values"
                exact
                element={<PurposeAndValues />}
              />
              <Route
                path="/about-us/our-people"
                exact
                element={<OurPeople />}
              />
              <Route path="/about-us/faq" exact element={<FAQ />} />
              <Route
                path="/about-us/culture-and-innovation"
                exact
                element={<CultureAndInnovation />}
              />
              <Route
                path="/about-us/overview"
                exact
                element={<AboutUsOverview />}
              />
              {/* Services */}
              {/* Investment */}
              <Route
                path="/services/investment/benefits"
                exact
                element={<CIB />}
              />
              <Route
                path="/services/investment/why-invest"
                exact
                element={<WIWS />}
              />
              <Route path="/services/investment" exact element={<SWPIPage />} />
              <Route path="/services" exact element={<ProductsAndServices />} />
              {/* Loan */}
              <Route
                path="/services/loan/personal-loan/requirements"
                exact
                element={<PLR />}
              />
              <Route
                path="/services/loan/business-loan/requirements"
                exact
                element={<BLR />}
              />
              <Route path="/services/loan/" exact element={<SWPLPage />} />
              {/* What We DO */}
              <Route
                path="/what-we-do/products-and-services/investment"
                exact
                element={<SWPIPage />}
              />
              <Route
                path="/what-we-do/products-and-services/loan"
                exact
                element={<SWPLPage />}
              />
              <Route
                path="/what-we-do/products-and-services"
                exact
                element={<ProductsAndServices />}
              />
              <Route
                path="/what-we-do"
                exact
                element={<ProductsAndServices />}
              />
              <Route
                path="/what-we-do/overview"
                exact
                element={<WhatWeDoOverview />}
              />
              {/* The Cooperative */}
              <Route
                path="/the-cooperative"
                exact
                element={<TheCooperative />}
              />
              <Route path="/real-estate" exact element={<RealEstate />} />
              {/* Tours */}
              <Route
                path="/travel-and-tourism/tours"
                exact
                element={<ToursPage />}
              />
              <Route
                path="/travel-and-tourism/ticket-booking"
                exact
                element={<TicketBookingPage />}
              />
              <Route
                path="/travel-and-tourism"
                exact
                element={<TravelAndTour />}
              />
              {/* Sustainability */}
              <Route
                path="/sustainability"
                exact
                element={<Sustainability />}
              />
              {/* Foundation */}
              <Route path="/seaf-foundation" exact element={<Foundation />} />
              <Route
                path="/seaf-foundation/community-engagements"
                exact
                element={<SEAFEngagements />}
              />
              <Route
                path="/seaf-foundation/the-ceos-summit-2021"
                exact
                element={<TheCEOsSummit />}
              />
              <Route
                path="/meet-the-ceos-22"
                exact
                element={<MeetTheCEOs2022 />}
              />
              {/* Automobile */}
              <Route path="/automobile" exact element={<Automobile />} />
              <Route
                path="/automobile/listing"
                exact
                element={
                  <ProtectedRouteAutoManager>
                    <CarListingData />
                  </ProtectedRouteAutoManager>
                }
              />
              <Route
                path="/automobile/listing/:id"
                exact
                element={<CarListingImages />}
              />

              <Route
                path="/automobile/manage-listing/:id"
                exact
                element={
                  <ProtectedRouteAutoManager>
                    <ManageCarListing />
                  </ProtectedRouteAutoManager>
                }
              />

              <Route path="/cars" exact element={<VehiclesShoppingCentre />} />

              <Route
                path="/automobile/cars"
                exact
                element={<VehiclesShoppingCentre />}
              />
              <Route
                path="/automobile/cars/:id"
                exact
                element={<ShoppingCentreCarPage />}
              />

              {/* User & Authentication */}
              <Route path="/login" exact element={<LoginPage />} />
              <Route path="/sign-up" exact element={<SignupPage />} />
              <Route
                path="/access-restricted"
                exact
                element={<UserRestricted />}
              />
              <Route
                path="/user-dashboard"
                element={
                  <ProtectedRoute>
                    <UserDashbaord props user={this.state.user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account/kyc-verification"
                element={<KYCVerificationOverview />}
              />
              <Route
                path="/account/kyc-verification/start"
                element={
                  <ProtectedRoute>
                    <KYCVerification props user={this.state.user} />
                  </ProtectedRoute>
                }
              />
              <Route path="/not-found" element={<NotFound />} />
              {/* <Route path="/" element={<MaintenanceMode />} /> */}
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} />
              {/* <Route path="*" element={<ServerError />} /> */}
            </Routes>
          </UserContext.Provider>

          <ToastContainer />
        </Provider>
      </SkeletonTheme>
    );
  }
}

export default App;
