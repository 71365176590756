import React from 'react';
import { Link } from 'react-router-dom';

const SWFoundation = (props) => {
  return (
    <div
      data-w-id="da700eed-4ca2-9a0f-1f5f-910d3f1d96b2"
      className="hero-slider__slide w-slide hero-slider__slide--sw-foundation"
    >
      <div className="overlay--dark overlay--dark--65deg"></div>
      <div className="container hero-slider__container">
        <div className="slide__content">
          <h1 className="slide__content__title">
            Building the future{' '}
            <span style={{ fontWeight: 100 }}>we hope to see</span>
          </h1>
          <p className="slide__content__desc">
            SEAF Foundation – raising young people to be self-reliant by
            equipping them with entrepreneurial skills
          </p>
          <div className="slide__cta">
            <Link
              to="/seaf-foundation"
              className="s-btn is-btn--light w-inline-block"
            >
              <p>Learn more</p>
            </Link>
            <a
              data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
              href="#"
              className="alt-btn is-btn--alt w-inline-block"
            >
              <div className="alt-btn__wrp">
                <p>Projects</p>
              </div>
              <div className="alt-btn__underline is-white__underline"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SWFoundation;
