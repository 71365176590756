import React from "react";

const AboutSkyewiseAutos = (props) => {
  return (
    <div className="is-bg--gray-02 wf-section">
      <div className="container container--small-top-btoom">
        <div>
          <p className="p-special">Welcome To Skyewise Autos</p>
          <div className="c-rich-text">
            <h3>A Trusted Auto Dealer In Nigeria</h3>
            <p>
              Skyewise Autos was established in 2017, building a foundation
              based on customer and community service. Over the years, Skyewise
              Autos has been serving Nigerians with quality cars.
            </p>

            <p>
              We pride ourselves on being the best car dealer in Nigeria. Deeply
              rooted within our community, our goals are to hold ourselves to
              the highest standards, ensure that our customers are satisfied,
              and always do the right thing. We intend to provide our customers
              with upfront and transparent buying experience based on trust and
              integrity. Buying with ease and peace of mind are not just things
              we say we offer, but instead, we've made it a large part of our
              company culture since our inception.
            </p>

            <p>
              We know that shopping and buying an automobile can be a daunting
              task, especially in a market the size of Nigeria. We've committed
              to being different than the average automobile dealership and
              avoiding the status quo. A commitment to transparency where the
              price is never a secret. A commitment to honesty where straight
              talk is the norm and fancy sales pitches are not. A commitment to
              integrity means we always do what we say we'll do. At Skyewise
              Autos, it's not about selling cars. It's about making the car
              buying easy.
            </p>

            <p>
              Whether you're a new customer or a longtime patron, we value and
              appreciate you and your business. We'll continue to work hard
              every day to get better in the attempt to maintain your trust and
              provide a best-in-class ownership experience.
            </p>

            <p>
              If you've never purchased a vehicle from Skyewise Autos before or
              it's been a while since you've done business with us, we
              understand. You have many choices. But we're getting better every
              day and have something special here we'd like for you to be a part
              of. We hope you'll consider us in the future, and we look forward
              to meeting you here at our Showroom.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSkyewiseAutos;
