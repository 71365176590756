import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  ActionGroup,
  Badge,
  Button,
  Flex,
  Item,
  StatusLight,
  Text,
} from '@adobe/react-spectrum';
import Settings from '@spectrum-icons/workflow/Settings';
import Add from '@spectrum-icons/workflow/Add';
import Checkmark from '@spectrum-icons/workflow/CheckmarkCircleOutline';
import { ButtonGroup } from 'react-bootstrap';

const CarListingActionDone = (props) => {
  const navigate = useNavigate();
  const manageCarsUrl = '/automobile/cars';
  const listNewCarUrl = '/automobile/listing';

  const { car } = props;

  return (
    <div>
      <div className="">
        <Flex justifyContent={'center'}>
          <div className="">
            <Checkmark size="XL" color="informative" />
            {/* <StatusLight variant="info">Done!</StatusLight> */}
          </div>
        </Flex>
        <div className="c-rich-text">
          {/* <h2>Car listed successfully</h2> */}
          <h3 className="font-bold">Car listed successfully</h3>
          Car id:{' '}
          <span className="">
            <Link to={`/automobile/manage-listing/${car}`}>
              <Badge variant="positive">{car}</Badge>
            </Link>
          </span>{' '}
          Would you like to update the details of this car?{' '}
          <Link to={`/automobile/manage-listing/${car}`}>
            <span className="font-semibold hover:text-[#2398ff]">
              Manage this car
            </span>
          </Link>
        </div>
        <div className="mb-6 mt-12">
          <ButtonGroup>
            <Flex alignItems={'center'} justifyContent={'center'} gap={20}>
              <Button onPress={() => navigate(listNewCarUrl)} variant="accent">
                <Add />
                <Text>Add car</Text>
              </Button>

              <Button
                onPress={() => navigate(manageCarsUrl)}
                variant="secondary"
              >
                <Settings />
                <Text>Manage cars</Text>
              </Button>
            </Flex>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default CarListingActionDone;
