import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SeafProject from './foundationProjectsSliderSlide';

// Add new projects to the SEAFProjects json file
import SEAFProjects from './SEAFProjects';

const ProjectsParallaxSlide = (props) => {
  return (
    <div
      className="section parallax-scroll wf-section"
      id="seaf-foundation-projects"
    >
      <div className="container">
        <div className="parallax-scroll__c-layout">
          <div className="parallax-scroll__c-layout__grid">
            <div
              data-w-id="9eda6efb-983e-a639-f734-1cae2e89e20e"
              // style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
              className="parallax-scroll__heading c-rich-text"
            >
              <h2>Projects</h2>
              <p>
                We have made it our commitment to raise the young people to be
                self reliant {'&'} financially independent.{' '}
              </p>
              <Link
                to="/seaf-foundation/community-engagements"
                className="alt-btn w-inline-block"
              >
                <p>See all Projects</p>
                <p className="is-icon"></p>
              </Link>
            </div>
            <div className="parallax-scroll__content">
              <div className="slide-exit__overlay"></div>
              <div
                data-delay="4000"
                data-animation="slide"
                className="parallax-slider w-slider"
                data-autoplay="false"
                data-easing="ease"
                // style="opacity:0"
                data-hide-arrows="true"
                data-disable-swipe="false"
                data-w-id="9eda6efb-983e-a639-f734-1cae2e89e21a"
                data-autoplay-limit="0"
                data-nav-spacing="3"
                data-duration="500"
                data-infinite="false"
              >
                <div className="parallax-mask w-slider-mask">
                  {SEAFProjects.map((project) => (
                    <SeafProject key={project.title} projectData={project} />
                  ))}
                </div>
                <div className="slide-arrow--style-02 slide-arrow--style-02--center is-btn-left w-slider-arrow-left">
                  <div className="trend__nav__icon w-icon-slider-left"></div>
                </div>
                <div className="slide-arrow--style-02 slide-arrow--style-02--center w-slider-arrow-right">
                  <div className="trend__nav__icon w-icon-slider-right"></div>
                </div>
                <div className="display__none w-slider-nav w-round"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsParallaxSlide;
