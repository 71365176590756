import React from "react";

const TCBA = (props) => {
  return (
    <section className="section wf-section  is-bg--gray-01">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h3>MEMBERSHIP BENEFITS</h3>
            </div>
          </div>
        </div>

        <div className="_5-col-st-grid is--t-mg-2vh">
          <div className="grid__item ">
            <div className="s-card-mini ">
              <div className="s-card-mini__cont interest">
                <p className="fa-icon is-fa-icon--100 fa-icon--x-large"></p>
                <h4 className="s-card-mini__title">Monthly interest</h4>
                <p>
                  You are entitled to monthly interest on your redeemable
                  contribution/special deposit.
                </p>
              </div>
            </div>
          </div>
          <div className="grid__item">
            <div className="s-card-mini">
              <div className="s-card-mini__cont loan">
                <p className="fa-icon is-fa-icon--100 fa-icon--x-large"></p>

                <h4 className="s-card-mini__title">Loan</h4>
                <p>You have access to Cooperative loans on request</p>
              </div>
            </div>
          </div>

          <div className="grid__item">
            <div className="s-card-mini">
              <div className="s-card-mini__cont security">
                <p className="fa-icon is-fa-icon--100 fa-icon--x-large"></p>

                <h4 className="s-card-mini__title">Gurantee amongst members</h4>
                <p>
                  You can use your contribution as security for new loaning
                  members
                </p>
              </div>
            </div>
          </div>

          <div className="grid__item">
            <div className="s-card-mini">
              <div className="s-card-mini__cont auto-loan">
                <p className="fa-icon is-fa-icon--100 fa-icon--x-large"></p>

                <h4 className="s-card-mini__title">Access to auto Loans</h4>
                <p>
                  You can get a car of your choice from Skyewise Autos and
                  monthly repayment through our partner Wema Bank.
                </p>
              </div>
            </div>
          </div>

          <div className="grid__item">
            <div className="s-card-mini">
              <div className="s-card-mini__cont training">
                <p className="fa-icon is-fa-icon--100 fa-icon--x-large"></p>

                <h4 className="s-card-mini__title">
                  Training & Empowerment Programs
                </h4>
                <p>
                  You have access to the Cooperative weekly training and
                  empowerment programs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TCBA;
