import _ from 'lodash';
import http from './httpService';
import configData from '../config.json';

const apiUrl = configData.apiUrl;
const apiEndpoint = apiUrl;
const registerEndpoint = apiEndpoint + '/users/signup';
const activeUserEndpoint = apiEndpoint + '/users/me';
const updateCurrentUserEndpoint = apiEndpoint + '/users/updateMe';
const KYCEndpoint = apiEndpoint + '/users/verifyMe';
const UsersEndpoint = apiEndpoint + '/users/';

export function register(user) {
  return http.post(
    registerEndpoint,
    {
      email: user.username,
      password: user.password,
      passwordConfirm: user.passwordconfirm,
      name: user.name,
      address: user.address,
      phoneNumber: user.phone,
    },
    { withCredentials: true }
  );
}

export function updateMe(user) {
  let fieldsUpdate = {};

  // if (user.country) fieldsUpdate.country = user.country;
  // if (user.region) fieldsUpdate.region = user.region;
  // if (user.zone) fieldsUpdate.zone = user.zone;
  // if (user.church) fieldsUpdate.church = user.church;
  // if (user.contactAddress) fieldsUpdate.contactAddress = user.contactAddress;
  // if (user.email) fieldsUpdate.email = user.email;
  // if (user.phoneNumber) fieldsUpdate.phoneNumber = user.phoneNumber;

  if (user.photo) fieldsUpdate.photo = user.photo;
  if (user.investmentState) fieldsUpdate.investmentState = user.investmentState;
  if (user.loanState) fieldsUpdate.loanState = user.loanState;
  if (user.verificationState)
    fieldsUpdate.verificationState = user.verificationState;

  if (_.isEmpty(fieldsUpdate)) {
    throw {
      name: 'Empty fields',
      level: '',
      message: 'No data provided! Please provide some value.',
      htmlMessage: 'No data provided!',
      toString: function () {
        return this.name + ': ' + this.message;
      },
    };
  }

  return http.patch(updateCurrentUserEndpoint, {
    ...fieldsUpdate,
  });
}

export function updateProfilePhoto(photo) {
  if (photo) {
    return http.patch(updateCurrentUserEndpoint, photo);
  }
}

export function updateKYC(user) {
  return http.patch(
    KYCEndpoint,
    {
      name: user.name,
      gender: user.gender,
      nationality: user.nationality,
      stateOfOrigin: user.stateOfOrigin,
      city: user.city,
      permanentAddress: user.permanentAddress,
      residenceAddress: user.residenceAddress,
      secondaryPhoneNumber: user.secondaryPhoneNumber,
      bankVerificationNumber: user.bankVerificationNumber,
      accountNumber: user.accountNumber,
      nameOfBank: user.nameOfBank,
      verificationState: user.verificationState,
    },
    { withCredentials: true }
  );
}

export function uploadKYCDocuments(documents) {
  if (documents) {
    return http.patch(KYCEndpoint, documents);
  }
}

export function getMe() {
  return http.get(activeUserEndpoint, { withCredentials: true });
}

export function getUsers() {
  return http.get(UsersEndpoint);
}

export function getUser(id) {
  return http.get(UsersEndpoint + '/' + id);
}

export function updateUser(user, data) {
  return http.patch(UsersEndpoint + user, data);
}

export default {
  getUser,
  getUsers,
  register,
  updateMe,
  updateKYC,
  getMe,
  updateProfilePhoto,
  uploadKYCDocuments,
};
