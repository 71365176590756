import React from 'react';
import SubsectionTitleWIthFlip from '../../common/UiLayoutsAndComponents/subsectionTItleWithFlip';

import speakers from './mtceosSpeakersData';

const MTCEOsSpeakers = (props) => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <SubsectionTitleWIthFlip
          props={{
            title: 'Speakers',
            text: 'Meet the speakers',
          }}
        />
        <div className="mtceos-speakers">
          <div className="_3-col-st-grid ">
            {speakers.map((speaker, k) => (
              <div className="grid-item" key={k}>
                <div className="mtceos-speaker">
                  <div className="mtceos-speaker__photo">
                    <img alt={speaker.name} src={speaker.photo} />
                  </div>

                  <div className="mtceos-speaker__detail">
                    <h5 className="mtceos-speaker__detail__name">
                      {' '}
                      {speaker.name}{' '}
                      <span className="mtceos-speaker__detail__desg">
                        {'('}
                        {speaker.designition}
                        {')'}
                      </span>{' '}
                    </h5>
                    <p className="mtceos-speaker__detail__title">
                      {speaker.title}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MTCEOsSpeakers;
