import React, { Fragment } from "react";
import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import restartWebflowScript from "../common/restartWebflowScript";
import StaticHeaderOne from "../common/UiLayoutsAndComponents/staticHeaderOne";
import SustainabilityPractices from "./sustainability";

const Sustainability = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <StaticHeaderOne
        pageData={{
          titleText: "Sustainability",
          headerLocalClass: "sustainability",
          overlay: true,
        }}
      />
      <SustainabilityPractices />
      <Footer />
    </Fragment>
  );
};

export default Sustainability;
