export function copyText(e) {
  /* Get the text field */
  const copyText = e.currentTarget;

  /* Select the text field */
  copyText.focus();
  // copyText.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  navigator.clipboard.writeText(copyText.innerText);

  /* Alert the copied text */
  copyText.classList.add('text-copied');
  setTimeout(() => {
    copyText.classList.remove('text-copied');
  }, 1500);
}

export default copyText;
