import React from 'react';

const AdminClientNotice = (props) => {
  return (
    <div className="ud-main__bio-info">
      <p>{props.notice}</p>
    </div>
  );
};

export default AdminClientNotice;
