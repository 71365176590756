import React, { useState } from 'react';
import NavItem from './userDashboardNavItem';
import auth from '../../services/authService';

const DashboardNav = (props) => {
  return (
    <aside
      id="w-node-_4217e38b-4f10-cd14-e0a3-0c6874739bb2-39382180"
      className="user-credit-dash__col-left"
    >
      <div className="user-credit-dash__nav">
        <div className="user-credit-dash__nav-cont _100-w">
          <ul
            role="list"
            className="user-credit-dash__nav__nav is-flex w-list-unstyled"
          >
            <NavItem
              navProps={{
                navLabel: 'Credit Summary',
                navIcon: '',
                navDashboard: 'credit-summary',
                onNavClick: props.onDashbordChange,
                currentDasboard: props.currentDasboard,
              }}
            />

            <NavItem
              navProps={{
                navLabel: 'Investment',
                navIcon: '',
                navDashboard: 'investment',
                onNavClick: props.onDashbordChange,
                currentDasboard: props.currentDasboard,
              }}
            />

            <NavItem
              navProps={{
                navLabel: 'Loan',
                navIcon: '',
                navDashboard: 'loan',
                onNavClick: props.onDashbordChange,
                currentDasboard: props.currentDasboard,
              }}
            />

            <NavItem
              navProps={{
                navLabel: 'My Account',
                navIcon: '',
                navDashboard: 'my-profile',
                onNavClick: props.onDashbordChange,
                currentDasboard: props.currentDasboard,
              }}
            />

            <NavItem
              navProps={{
                navLabel: 'Help',
                navIcon: '',
                navDashboard: 'support',
                onNavClick: props.onDashbordChange,
                currentDasboard: props.currentDasboard,
              }}
            />

            <NavItem
              navProps={{
                navLabel: 'Logout',
                navIcon: '',
                navDashboard: 'logout',
                onNavClick: props.onDashbordChange,
                currentDasboard: props.currentDasboard,
              }}
            />

            <li className="is-flex--mt-auto user-credit-dash__nav__c-logout">
              <button
                className="s-btn is-btn--outline is-btn--logout"
                onClick={() => auth.logout()}
              >
                <span className="is-fa-icon--300 is-icon--logout"></span>
                <span className="btn-outline--txt-alt">Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default DashboardNav;
