import jwtDecode from 'jwt-decode';
import http from './httpService';
import configData from '../config.json';
import CryptoJS from 'crypto-js';

const storageUrl = configData.storageUrl;
const cryptoKey = configData.CRYPTO_KEY;
const apiUrl = configData.apiUrl;
const apiEndpoint = apiUrl + '/users';
const loginEndpoint = apiEndpoint + '/login';
const logoutEndpoint = apiEndpoint + '/logout';
const cUserEndpoint = apiEndpoint + '/me';

// User Authentication Token Encrypted
const tokenKey = 'UATE';

// http.setJwt(getJwt());

export async function login(email, password) {
  const {
    token: jwt,
    status,
    data,
  } = await (
    await http.post(
      loginEndpoint,
      { email, password },
      { withCredentials: true }
    )
  ).data;
  const { user } = data;

  localStorage.setItem(tokenKey, jwt);

  // Encrypt User Object
  var currentUser = CryptoJS.AES.encrypt(
    JSON.stringify(user),
    cryptoKey
  ).toString();

  localStorage.setItem('c_user', currentUser);
}

export function loginWithJwt(jwt, c_user) {
  var currentUser = CryptoJS.AES.encrypt(
    JSON.stringify(c_user.user),
    cryptoKey
  ).toString();
  localStorage.setItem(tokenKey, jwt);
  localStorage.setItem('c_user', currentUser);
}

export async function logout() {
  await http.get(logoutEndpoint);
  localStorage.removeItem('dashboard');
  localStorage.removeItem('member__view');
  localStorage.removeItem('UATE');
  localStorage.removeItem('c_user');
  localStorage.removeItem('UVDA');
  localStorage.removeItem('current-page');
  localStorage.removeItem('member-in-view');
  localStorage.removeItem('member_add');
  localStorage.removeItem('u-dashboard');
  localStorage.removeItem('startKyc');
  window.location.reload();
}

export function getCurrentUser() {
  try {
    const jwt = getJwt();
    const currentUserEnc = localStorage.getItem('c_user');

    // Decrypt
    const currentUserDec = CryptoJS.AES.decrypt(currentUserEnc, cryptoKey);
    const currentUser = JSON.parse(currentUserDec.toString(CryptoJS.enc.Utf8));

    return {
      jwt: jwtDecode(jwt),
      currentUser,
    };
  } catch (ex) {
    // return ex.message;
    return null;
  }
}

export async function setUVDALocal() {
  try {
    const user = await (
      await http.get(cUserEndpoint, { withCredentials: true })
    ).data.data;

    const { data: currentUser } = user;

    var currentUserEncrypt = CryptoJS.AES.encrypt(
      JSON.stringify(currentUser),
      cryptoKey
    ).toString();

    localStorage.setItem('UVDA', currentUserEncrypt);
  } catch (ex) {
    return null;
  }
}

export function getUVDALocal() {
  try {
    const currentUserEnc = localStorage.getItem('UVDA');

    const currentUserDec = CryptoJS.AES.decrypt(currentUserEnc, cryptoKey);
    const currentUser = JSON.parse(currentUserDec.toString(CryptoJS.enc.Utf8));

    return currentUser;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getUVDALocal,
  setUVDALocal,
  getJwt,
};
