import React, { Component, Fragment } from 'react';
import carImagesIcon from '../../images/user-engagement/car-collateral-placeholder.svg';
import carImageCoverIcon from '../../images/user-engagement/car-listing-cover.svg';

import validateFileExt from '../../utils/validateFileExt';
import errorFactory from '../../utils/errorFactory';
import { updateCar } from '../../services/carService';
import FileUploadPreviewThumbnail from '../common/formElements/fileUploadThubnailPreview';
import Form from '../common/formElements/form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Content,
  ContextualHelp,
  Heading,
  ProgressCircle,
  Text,
} from '@adobe/react-spectrum';
import { ToastQueue } from '@react-spectrum/toast';
import { ProgressBar } from '@adobe/react-spectrum';

class CarImageUploadHandler extends Form {
  state = {
    selectedFile: null,
    isBusy: false,
  };
  componentDidMount() {
    // auth.setUVDALocal();
    // const currentLoan = auth.getUVDALocal().currentLoan;
    // this.setState({ loan: currentLoan });
    // console.log(this.uploadConfig);
  }

  fileSelecteMultipleHandler = (event) => {
    const uploadFiles = event.target.files,
      uploadFile = event.target.files[0];

    try {
      Object.entries(uploadFiles).map((cFile) => {
        // Check for valid file upload
        if (
          !validateFileExt(cFile[1], [
            'image/jpeg',
            'image/png',
            'image/gif',
            'image/webp',
            'image/heic',
          ])
        ) {
          // Throw upload error
          const uploadError = new errorFactory(
            'Invalid Upload',
            'One or more of the selected files is not an image. Please upload only images.'
          );
          // var e = new errorFactory("NotImplementedError message");
          throw uploadError;
          // alert('The file selected is not an image. Please upload a valid image format i.e: jpg, png, gif, webp, heic');
        }
      });
    } catch (e) {
      // Handle file upload error
      ToastQueue.negative(e.message, { timeout: 2500 });

      this.setState({
        selectedFile: null,
      });

      return;
    }

    const imagePreview = window.URL.createObjectURL(uploadFile);

    event.target.parentNode.childNodes[1].children[0].src = imagePreview;

    this.setState({
      selectedFile: event.target.files,
    });
  };

  fileUploadHandler = async (field) => {
    this.setState({
      isBusy: true,
    });

    const { uploadConfig } = this.props;

    try {
      // Create form Data Object
      const fd = new FormData();

      Object.keys(this.state.selectedFile).map((key) => {
        fd.append(
          field,
          this.state.selectedFile[key],
          this.state.selectedFile.name
        );
      });

      await updateCar(uploadConfig.endpoint, fd);
      ToastQueue.info('Upload successful!', { timeout: 1500 });

      this.setState({
        isBusy: false,
      });

      setTimeout(() => {
        uploadConfig.handleParamUpdate(uploadConfig.nextAction);
      }, 500);

      setTimeout(() => {
        // window.location.reload(false);
      }, 500);
    } catch (ex) {
      // if (ex.response) {
      //   let msg = '';

      //   if (typeof ex.response.data === 'string') {
      //     msg += msg + ex.response.data;
      //   }

      //   if (typeof ex.response.data === 'object') {
      //     msg += msg + ex.response.data.message;
      //   }

      //   ToastQueue.negative(msg, { timeout: 2500 });
      // }

      ToastQueue.negative(ex.message, {
        timeout: 2500,
      });

      this.setState({
        isBusy: false,
      });

      // setTimeout(() => {
      //   //Action
      // }, 3500);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="flex items-center mb-6">
          <h3>{this.props.uploadConfig.fieldTitle}</h3>
          <div className="ml-2">
            <ContextualHelp variant="info">
              <Heading>{this.props.uploadConfig.fieldTitle}</Heading>
              <Content>
                <Text>{this.props.uploadConfig.fieldNote}</Text>
              </Content>
            </ContextualHelp>
          </div>
        </div>{' '}
        {/* Progress bar */}
        {/* <div>
          <ProgressBar
            staticColor="black"
            isIndeterminate
            minWidth={500}
            showValueLabel={false}
          /> 
        </div> */}
        <div className="c-file-input__container">
          <div className="c-file-input" onClick={() => this.fileInput.click()}>
            <input
              className="c-file-input__input-default"
              type="file"
              onChange={this.fileSelecteMultipleHandler}
              ref={(fileInput) => (this.fileInput = fileInput)}
              multiple={this.props.multiple}
            />

            <div className="c-file-input__input-preview input-preview--landscape">
              <img
                id="upload-preview"
                src={this.props.multiple ? carImagesIcon : carImageCoverIcon}
                className="c-file-input__input-preview__img preview-landscape"
              />

              <div className="file-upload">
                <div className="file-upload--container">
                  <div className="file-upload--file">
                    <span className="is-icon"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="c-file-input__cta">
            {/* Multile uploads preview */}
            {this.state.selectedFile &&
              this.props.multiple &&
              Object.keys(this.state.selectedFile).length > 1 && (
                <FileUploadPreviewThumbnail files={this.state.selectedFile} />
              )}
            {/* <p className="c-file-input__note">
              Please make sure the photos capture crucial details about your
              collateral. The approved loan amount will be determined by the
              valuation of your collateral.
            </p> */}
            <button
              onClick={() => {
                this.fileUploadHandler(this.props.uploadConfig.fieldName);
              }}
              className="s-btn s-btn--blue w-button"
              disabled={this.state.selectedFile ? false : true}
            >
              <span
                className={`${this.state.isBusy ? 'opacity-0' : 'opacity-100'}`}
              >
                Upload
              </span>

              <span
                className={`absolute ${
                  this.state.isBusy ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <ProgressCircle
                  size="S"
                  aria-label="Loading…"
                  isIndeterminate
                  staticColor="white"
                />
              </span>
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CarImageUploadHandler;
