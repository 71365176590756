import React, { Fragment } from 'react';
import Navbar from '../../common/navbar/navbar';
import Footer from '../../common/footer/footer';
import { Link } from 'react-router-dom';
import restartWebflowScript from '../../common/restartWebflowScript';

const DirectorBarrObiageliOnyekwuluje = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="internal-nav w-nav"
        >
          <div className="header-static static-header--leadership wf-section">
            <div className="container">
              <div className="header-statoc--flex">
                <div className="header-static__container">
                  <h1>Barr. Obiageli Onyekwuluje</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow is--left-align ">
                <div className="c-rich-text">
                  <div className="div-block-96 ">
                    <div className="div-block-97">
                      <img
                        src="/images/content/our-people/directors/barr_obiageli_onyekwuluje.webp"
                        loading="lazy"
                        alt="Barr. Obiageli Onyekwuluje"
                      />
                    </div>
                    <div className="div-block-97">
                      {/* <a className="st-link w-inline-block" href="/"> */}
                      <h4 className="c-h4">Barr. Obiageli Onyekwuluje</h4>
                      {/* </a> */}
                      <p>Director </p>
                      <p className="p-medium">
                        Partner at Padua Legal Consult.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="_1-col-c-center__c-narrow is--left-align st-r-mg ">
                <div className="c-rich-text">
                  <p className="p-small">
                    Barr. Obiageli Onyekwuluje is The Principal Partner of the
                    reputable Padua Legal Consult, a legal chamber situated at
                    the heart of The Federal Capital Territory. She is a
                    seasoned expert in her field, with over 15 years of active
                    legal practice.
                  </p>

                  <p className="p-small">
                    She is a specialist in Company Law and with her chambers
                    affiliates with over 4,000 registered companies in which she
                    currently consults across Nigeria.
                  </p>

                  <p className="p-small">
                    My work is my pride is a phrase common to Barr. Obiageli and
                    to fortress her invincibility in the courtroom she uses the
                    Ethos, Logos, Pathos, and Kairos style of Litigation which
                    has gained her fame in the Legal discipline.
                  </p>

                  <p className="p-small">
                    Considering her years of experience in the profession, she
                    became a Certified Notary Public in February 2021 and with
                    the growing demands and Legal needs of her clients, she
                    decided to advance her qualifications and is currently a
                    student at the University of Abuja, pursuing her master.
                  </p>
                </div>
              </div>

              <div className="_1-col-c-center__c-narrow st-r-mg">
                <div className="c-rich-text">
                  <h3>Directors</h3>
                </div>
                <Link
                  to="/about-us/people-and-leadership/directors"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Go back to all directors</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DirectorBarrObiageliOnyekwuluje;
