import React from 'react';
const PlayerModalPlayBtn = (props) => {
  const btnProps = props.props;
  const { playerSkin } = btnProps;

  let currentSkin = playerSkin === 'light' ? 'light' : 'dark';

  return (
    <button
      onClick={() => {
        btnProps.handleClick(btnProps.playerId);
      }}
      className={'player-modal__play-btn' + ' skin--' + currentSkin}
    >
      <span className="is-fa-icon--300"></span>
    </button>
  );
};

export default PlayerModalPlayBtn;
