import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import configData from "../../config.json";


const storage =  configData.storageUrl;


// const this.props.images = this.props.this.props.images;

export default class ImageLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const { images } = this.props;

    return (
      <div id="c-lightbox">
        <div className="car-in-view-photos flex_order--02">
          <div className="car-in-view-block__grid">
            {images.map((image, i) => (
              <div className="car-in-view__car-card">
                <a
                  href="#"
                  className="car-in-view__image-lightbox w-inline-block w-lightbox"
                >
                  <div className="car-in-view__car-image-wrap st-b-radius">
                    <img
                      src={storage + image}
                      onClick={() =>
                        this.setState({ isOpen: true, photoIndex: i })
                      }
                      className="car-in-view__car-image"
                    />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={storage + images[photoIndex]}
            nextSrc={storage + images[(photoIndex + 1) % images.length]}
            prevSrc={
              storage +
              images[(photoIndex + images.length - 1) % images.length]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
