import React, { Component } from 'react';

const UserProfileForm = () => {
  return (
    <div>
      <h4>Find Support</h4>
    </div>
  );
};

export default UserProfileForm;
