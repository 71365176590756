import React from 'react';
import { Link } from 'react-router-dom';

const RealEstateIntoText = () => {
  return (
    <div className="_1-col-c-center gray-bg-01 wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>Skyewise Real Estate</h2>
              <p>
                We are committed to achieving new standards of excellence by
                providing smart homes services and maximizing the value chains
                in real estate and giving stakeholders, clients, candidates, and
                employees the best affordable smart homes in Africa.
              </p>
            </div>
            <Link
              to="/contact"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Learn more about our real-estate investments</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateIntoText;
