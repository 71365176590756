import React, { Fragment, useContext } from 'react';
import Joi from 'joi-browser';
import Form from '../../common/formElements/form';
import * as userService from '../../../services/userService';
import auth from '../../../services/authService';
import GetUserData from '../../../hooks/GetUserData';
import progressHandler from '../../../utils/progressHandler';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import ProgressRender from '../../progressRender';
import CheckBox from '../../common/formElements/checkBox';
import KYCGlueTips from './kycGlueTips';

class KYCForm extends Form {
  state = {
    data: {
      name: '',
      gender: '',
      nationality: '',
      stateOfOrigin: '',
      city: '',
      permanentAddress: '',
      residenceAddress: '',
      secondaryPhoneNumber: '',
      bankVerificationNumber: '',
      accountNumber: '',
      nameOfBank: '',

      // kycDocument: [""],
      userIDFront: '',
      consent: '',
    },
    errors: {},
  };

  schema = {
    name: Joi.string().required().label('Legal Name'),
    gender: Joi.string().required().label('Gender'),
    permanentAddress: Joi.string().required().label('Permanent Home Address'),
    residenceAddress: Joi.string().required().label('Residence Address'),
    city: Joi.string().required().label('City'),
    secondaryEmail: Joi.label('Secondary Email'),
    secondaryPhoneNumber: Joi.string().label('Secondary Phone Number'),
    nationality: Joi.string().required().label('Nationality'),
    stateOfOrigin: Joi.string().required().label('State Of Origin'),
    bankVerificationNumber: Joi.number()
      .required()
      .label('Bank Verification Number'),
    accountNumber: Joi.number().required().label('Account Number'),
    nameOfBank: Joi.string().required().label('Name of Bank'),
    userIDFront: Joi.binary().required().label('User ID Front'),
    consent: Joi.string().required().label('consent'),
  };

  validateCountryInput = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleCountryInput = (input) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateCountryInput(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  doSubmit = async () => {
    // Initialize Lottie
    initProgressLotties();

    progressHandler.showProgress('kyc-verification', 'loading');

    try {
      const { data } = this.state;

      const response = await userService.updateKYC(data);
      // auth.loginWithJwt(response.data.token, response.data.data);
      // const [VPST, changeVPST] = useState(localStorage.getItem("u-dashboard"));

      if (await userService.updateMe({ verificationState: 'p-photo' })) {
        const setCUser = await auth.setUVDALocal();

        // Hide UI loadin, show success
        progressHandler.hideProgress('kyc-verification', 'loading');
        progressHandler.showProgress('kyc-verification', 'success');
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      }

      // const logedInUser = await userService.getMe();

      // console.log(logedInUser);
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }
        // hide UI Loading, show Error
        progressHandler.hideProgress('kyc-verification', 'loading');
        progressHandler.showProgress('kyc-verification', 'error', msg);
      } else {
        progressHandler.hideProgress('kyc-verification', 'loading');
        progressHandler.showProgress(
          'kyc-verification',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('kyc-verification', 'error');
      }, 1500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        // this.setState({ errors });
      }
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <Fragment>
        <div className="st-form-blk">
          <ProgressRender
            elData={{
              loading: { name: 'kyc-verification-progress__loading' },
              success: { name: 'kyc-verification-progress__success' },
              error: { name: 'kyc-verification-progress__error' },
            }}
          />

          <form onSubmit={this.handleSubmit}>
            <p className="fa-icon is-fa-icon--100 fa-icon--neutral fa-icon--2_5">
              
            </p>
            <div className="c-rich-text">
              <h5 className="is--txt-blue">Personal Information</h5>
            </div>

            {this.renderInput(
              'name',
              'Legal Name',
              'text',
              ' Full Name (Legal name)',
              ''
            )}
            <div>
              <div className="is-2-col-c-grid">
                <div className="is-c-grid__item">
                  {this.renderInput(
                    'nationality',
                    'Nationality',
                    'text',
                    'Nationality',
                    ''
                  )}
                </div>
                <div className="is-c-grid__item">
                  {this.renderInput(
                    'stateOfOrigin',
                    'State Of Origin',
                    'text',
                    'State Of Origin',
                    ''
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="is-2-col-c-grid">
                <div className="is-c-grid__item">
                  {this.renderInput(
                    'city',
                    'City',
                    'text',
                    'City/Local Government',
                    ''
                  )}
                </div>
                <div className="is-c-grid__item">
                  <div className="is-c-grid__item">
                    <div className="s-form__input">
                      <div className="s-form__input__con is-wt-100">
                        <label className="s-form__input-label" for="Country">
                          Country
                        </label>
                        <div className="is-icon s-form__icon">
                          <p></p>
                        </div>

                        {this.renderSelect('gender', 'Gender', [
                          { name: 'Male', value: 'M' },
                          { name: 'Female', value: 'F' },
                        ])}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.renderInput(
              'permanentAddress',
              'Permanent Home Address',
              'text',
              'Permanent Home Address',
              ''
            )}
            {this.renderInput(
              'residenceAddress',
              'Residence Address',
              'text',
              'Residence Address',
              ''
            )}
            <div className="is-2-col-c-grid">
              <div className="is-c-grid__item">
                {this.renderInput(
                  'secondaryPhoneNumber',
                  'Secondary Phone Number',
                  'text',
                  'Phone Number',
                  ''
                )}
              </div>
              <div className="is-c-grid__item">
                {this.renderInput(
                  'secondaryEmail',
                  'Secondary Email',
                  'text',
                  'Secondary Email',
                  ''
                )}
              </div>
            </div>

            <div className="s-form-seperator"> </div>
            <p className="fa-icon is-fa-icon--100 fa-icon--neutral fa-icon--2_5">
              
            </p>
            <div className="c-rich-text">
              <h5 className="is--txt-blue">Credit Information</h5>
            </div>
            <div>
              <div className="is-2-col-c-grid">
                <div className="is-c-grid__item">
                  {this.renderInput(
                    'bankVerificationNumber',
                    'Bank Verification Number',
                    'number',
                    'BVN (Bank Verification Number)',
                    ''
                  )}
                </div>
                <div className="is-c-grid__item">
                  {this.renderInput(
                    'accountNumber',
                    'Account Number',
                    'number',
                    'Bank Account Number',
                    ''
                  )}
                </div>
              </div>

              {this.renderInput(
                'nameOfBank',
                'Name of Bank',
                'text',
                'Name of your Bank',
                ''
              )}
            </div>
            <div className="is-2-col-c-grid">
              <div className="is-c-grid__item">
                {this.renderCheckBox(
                  'consent',
                  'Consent',
                  'checkbox',
                  'I verify that the above pieces of information are provided by me and are accurate.'
                )}
              </div>
              <div className="is-c-grid__item">{this.renderButton('Next')}</div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default KYCForm;
