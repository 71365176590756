import React from "react";

const BoardOfDirectorsQuote = (props) => {
  return (
    <div className="wf-section bord-of-directors-quote">
      <div className="container">
        <div className="_2-col-st-grid">
          <div
            id="w-node-d32d7ecc-132a-3c09-e382-4dfad903fa9f-f14bd371"
            data-w-id="d32d7ecc-132a-3c09-e382-4dfad903fa9f"
            className="s-cards-slider"
          >
            <div className="div-block-129">
              <div className="s-cards-slider__wheel">
                <h3 className="s-cards-slider__title">
                  We have a diverse but complementary team
                </h3>
                <p className="s-cards-slider__p">
                  Our executives bring their knowledge, experience, and
                  leadership to bear on the development and delivery of
                  solutions to meet the needs of our customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardOfDirectorsQuote;
