import React from 'react';

const CarFeatureCapcel = (props) => {
  const { car } = props;

  return (
    <li className="flex flex-col bg-gray-50 py-2 px-3 rounded">
      <div className="flex align-center border-solid	border-gray-200	border-b pb-2 ">
        <span
          style={{
            color: car.exteriorColourCode ? car.exteriorColourCode : '',
          }}
          className={`is-fa-icon--300 mr-2 text-sm  `}
        >
          {car.icon}
        </span>
        <span className="text-sm leading-none	">
          {car.title && <>{car.title}</>}
        </span>
      </div>

      <div className="font-bold text-lg">
        {car.prefix && <>{car.prefix}</>}
        {car.feature} {car.suffix && <>{car.suffix}</>}
      </div>
    </li>
  );
};

export default CarFeatureCapcel;
