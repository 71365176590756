import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClientsTable from './clientsTable';
import ListGroup from './common/listGroup';
import Pagination from './common/pagination';
import { getUsers, deleteUser } from '../services/userService';
import { getGenres } from '../services/genreService';
import { paginate } from '../utils/paginate';
import _ from 'lodash';
import SearchBox from './searchBox';
import restartWebflowScript from './common/restartWebflowScript';
import restartWebflowScriptClassComponents from './common/restartWebflowScriptClassConponents';
import ProgressRender from './progressRender';
import { initProgressLotties } from '../utils/initProgressLottie';
import progressHandler from '../utils/progressHandler';

class Clients extends Component {
  state = {
    clients: [],
    genres: [],
    currentPage: 1,
    pageSize: 12,
    searchQuery: '',
    selectedGenre: null,
    sortColumn: { path: 'title', order: 'asc' },
  };

  async componentDidMount() {
    restartWebflowScriptClassComponents();

    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('client', 'loading');
    // await this.populateGenres();

    // const { data } = await getGenres();
    // const genres = [{ _id: "", name: "All Genres" }, ...data];

    try {
      const { data: clients } = await (await getUsers()).data.data;
      // this.setState({ clients, genres });

      this.setState({ clients });
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('client', 'loading');
        progressHandler.showProgress('client', 'error', msg);
      } else {
        progressHandler.hideProgress('client', 'loading');
        progressHandler.showProgress(
          'client',
          'error',
          'An error occurred while loading clients. Try reloading the page or try again later'
        );
      }

      if (ex.response && ex.response.status === 404)
        setTimeout(() => {
          this.props.history.replace('/not-found');
        }, 1500);
    }
  }

  handleDelete = async (client) => {
    const originalClients = this.state.clients;
    const clients = originalClients.filter((m) => m._id !== client._id);
    this.setState({ clients });

    try {
      // await deleteUser(client._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error('This client has already been deleted.');

      this.setState({ clients: originalClients });
    }
  };

  handleLike = (client) => {
    const clients = [...this.state.clients];
    const index = clients.indexOf(client);
    clients[index] = { ...clients[index] };
    clients[index].liked = !clients[index].liked;
    this.setState({ clients });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleGenreSelect = (genre) => {
    this.setState({ selectedGenre: genre, searchQuery: '', currentPage: 1 });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedGenre,
      searchQuery,
      clients: allClients,
    } = this.state;

    let filtered = allClients;
    if (searchQuery)
      filtered = allClients.filter((m) =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre && selectedGenre._id)
      filtered = allClients.filter((m) => m.genre._id === selectedGenre._id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const clients = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: clients };
  };

  render() {
    const { length: count } = this.state.clients;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    if (count === 0)
      return (
        <div className="st-form-blk">
          <ProgressRender
            elData={{
              loading: {
                name: 'client-progress__loading',
                transparency: true,
              },
              success: { name: 'client-progress__success' },
              error: { name: 'client-progress__error' },
            }}
          />
        </div>
      );

    const { totalCount, data: clients } = this.getPagedData();

    return (
      <div className="row">
        <div className="col-3">
          <ListGroup
            items={this.state.genres}
            selectedItem={this.state.selectedGenre}
            onItemSelect={this.handleGenreSelect}
          />
        </div>
        <div className="col h-100">
          {user && (
            <Link
              to="/clients/new"
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Client
            </Link>
          )}
          {/* <p>Showing {totalCount} clients in the database.</p> */}
          {/* <SearchBox value={searchQuery} onChange={this.handleSearch} /> */}
          <ClientsTable
            clients={clients}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default Clients;
