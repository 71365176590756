import React, {
  Component,
  useContext,
  useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import authService from '../../../services/authService';

import StepsIndicator from '../../common/steps-indicator';
import InvestmentForm from './investmentForm';
import InvestmentGuide from './investmentFormParamsGuide';
import FundInvestment from './fundInvestment';
import PendingInvestment from './pendingInvestment';
import InvestmentProgressHint from './investmentStepsHints';
import ToolTipOnHover from '../../common/toolTipOnHover';
import UnverifiedUser from '../userVerificationProcess';
import ActiveInvestment from './activeInvestment';

class InvestmentDashbord extends Component {
  state = {
    user: authService.getUVDALocal(),
  };

  render() {
    return (
      <main className="credit-dashbord">
        <div className="credit-dashbord__heading">
          <h3 className="heading-8">Investment</h3>
          <ToolTipOnHover
            tip={{
              tipTitle: 'Investment',
              tipIcon: '',
              tipText:
                'Invest and manage your investments with Skyewise from your personal dashboard.',
            }}
          />
        </div>

        {this.setState()}

        <InvestmentProgressHint />

        <div className="credit-dashbord__details">
          {this.state.user &&
            this.state.user.verificationState === 'unverified' && (
              <UnverifiedUser />
            )}

          {/* {this.state.user &&
            this.state.user.verificationState === "bio-data" &&
            !this.state.user.currentInvestment && (
              <div className="credit-dashbord__sum is-2_5em--white-wrap is-1_27-grid ">
                <InvestmentGuide />
                <InvestmentForm />
              </div>
            )} */}

          {this.state.user &&
            this.state.user.investmentState === 'none' &&
            this.state.user.verificationState !== 'unverified' && (
              <div className="credit-dashbord__sum is-2_5em--white-wrap is-1_27-grid ">
                <InvestmentGuide />
                <InvestmentForm />
              </div>
            )}

          {/* {currentUserLocal && VPSTStage == "2" && <InvestmentForm />}  */}

          {/* <div  className="credit-dashbord__sum is-2_5em--white-wrap is-1_27-grid ">
          <InvestmentGuide />
          <InvestmentForm />
        </div> */}

          {this.state.user &&
            this.state.user.currentInvestment &&
            this.state.user.verificationState !== 'unverified' &&
            this.state.user.investmentState === 'none-funded' && (
              <FundInvestment />
            )}

          {this.state.user &&
            this.state.user.currentInvestment &&
            this.state.user.verificationState !== 'unverified' &&
            this.state.user.investmentState === 'funded' && (
              <PendingInvestment />
            )}

          {this.state.user &&
            this.state.user.currentInvestment &&
            this.state.user.verificationState !== 'unverified' &&
            this.state.user.investmentState === 'active' && (
              <ActiveInvestment />
            )}
        </div>
      </main>
    );
  }
}

export default InvestmentDashbord;
