import React from 'react';
import { Link } from 'react-router-dom';

const MissionVissionCoreValues2 = (props) => {
  return (
    <div className="_3-col-grid is-mt-medium grid-gap--3em ">
      <div className="glue-mvc">
        <div className="glue-mvc__image">
          <img
            className="glue-mvc__image__image"
            src="/images/brnd/business-woman-finance-40322-p-1080.jpg"
            alt="Our mission"
          />
          <span className="glue-mvc__image__overlay"></span>
        </div>

        <div className="glue-mvc__block">
          <div className="glue-mvc__block__headling">
            <h4>OUR VISION</h4>
          </div>

          <div className="glue-mvc__block__details">
            <p className="glue-mvc__block__details--txt">
              To be the world choice company providing first class investment
              platforms and services.
            </p>
          </div>

          <div className="glue-mvc__block__cta">
            <Link to="/about-us/purpose-and-values">Learn more</Link>
          </div>
        </div>
      </div>

      <div className="glue-mvc">
        <div className="glue-mvc__image">
          <img
            className="glue-mvc__image__image"
            src="/images/brnd/bms-presentation.jpg"
            alt="Our mission"
          />
          <span className="glue-mvc__image__overlay"></span>
        </div>

        <div className="glue-mvc__block">
          <div className="glue-mvc__block__headling">
            <h4>OUR CORE VALUES (SPIRIT)</h4>
          </div>

          <div className="glue-mvc__block__details">
            <p className="glue-mvc__block__details--txt glue-initials ">
              <span className="glue-initial">S </span>
              <span className="glue-initial__text">
                Service Excellence &nbsp;{' '}
              </span>
              <br />
              <span className="glue-initial">P </span>
              <span className="glue-initial__text">
                Professionalism &nbsp;{' '}
              </span>
              <br />
              <span className="glue-initial">I </span>
              <span className="glue-initial__text">Integrity &nbsp;</span>{' '}
              <br />
              <span className="glue-initial">R </span>
              <span className="glue-initial__text">
                Respect for others &nbsp;
              </span>
              <br />
              <span className="glue-initial">I </span>
              <span className="glue-initial__text">Innovation &nbsp;</span>
              <br />
              <span className="glue-initial">T</span>
              <span className="glue-initial__text">Team work </span>
              <br />
            </p>
          </div>

          <div className="glue-mvc__block__cta">
            <Link to="/about-us/purpose-and-values">Learn more</Link>
          </div>
        </div>
      </div>

      <div className="glue-mvc">
        <div className="glue-mvc__image">
          <img
            className="glue-mvc__image__image"
            src="/images/brnd/lady-smilling-4042-1080.jpg"
            alt="Our mission"
          />
          <span className="glue-mvc__image__overlay"></span>
        </div>

        <div className="glue-mvc__block">
          <div className="glue-mvc__block__headling">
            <h4> OUR MISSION</h4>
          </div>

          <div className="glue-mvc__block__details">
            <p className="glue-mvc__block__details--txt">
              To provide wide range services to individuals and corporate bodies
              across the globe.
            </p>
          </div>

          <div className="glue-mvc__block__cta">
            <Link to="/about-us/purpose-and-values">Learn more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVissionCoreValues2;
