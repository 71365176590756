import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Fragment>
      <div className="header-static wf-section">
        <div className="overlay--dark"></div>
        <div className="container">
          <div className="header-statoc--flex">
            <div className="header-static__container">
              <h1> 404</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-middle wf-section">
        <div class="container">
          <div class="flex-center">
            <div class="flex-center__container">
              <div class="c-rich-text">
                <h2>404 (Document not found)</h2>
                <p>
                  Sorry, we couldn't find that page.
                  <br />
                  You may want to visit{' '}
                  <Link class="is--link" to="/">
                    Skyewise Group Homepage
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFound;
