import React, { Fragment, useEffect, Component } from "react";
import restartWebflowScript from "../common/restartWebflowScript";

import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import PageExitCTA from "../common/pageExitCTA";

import RealEstateHeader from "./realEstateHeroOne";
import RealEstateIntoText from "./realEstateIntroText";
import RealEstateBenefits from "./realEstateBenefits";
import RealEstateFeatures from "./realEstateFeatures";
import RealEstateVisualsWidget from "./realEstateVisualsWidget";
import RealEstateButtonGridCta from "./realEstateButtonGridCta";
import RealEstateGallery from "./realEstateGallery";
import RealEstateProperties from "./realEstateProperties";

const RealEstate = (props) => {
  useEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f4534e4bd366");
  });

  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <RealEstateHeader />
      <RealEstateIntoText />
      <RealEstateBenefits />
      <RealEstateGallery />
      <RealEstateProperties />
      {/* <RealEstateButtonGridCta /> */}
      {/* <RealEstateFeatures /> */}
      <PageExitCTA />

      <Footer />
    </Fragment>
  );
};

export default RealEstate;
