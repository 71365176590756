import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { openPlayer, closePlayer } from '../../utils/videoPlayerModalControls';
import VideoPlayerModal from '../common/videoPlayerModal';
import PlayerModalPlayBtn from '../common/videoPlayerModalPlayBtn';
import MissionVissionCoreValues from './missionVisionValues';
import MissionVissionCoreValues2 from './missionVisionValuesN';

function DiscoverSkyewise(props) {
  return (
    <div className="section _1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>Discover Skyewise Group</h2>
              <p>
                A diversified integrated conglomerate providing world-class
                investment opportunities and services for individuals and
                corporate citizens of all nations. With vibrant operations in
                Nigeria and Africa, across a wide range of sectors including
                investment, Credit Management, Real Estate, Automobiles...
              </p>
            </div>

            <PlayerModalPlayBtn
              props={{
                handleClick: openPlayer,
                playerId: 'playerID-0012',
                playerSkin: 'dark',
              }}
            />
            <Link
              to="/about-us/overview"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Learn more about what we do</p>
            </Link>
          </div>
        </div>

        {/* <div className="glue-devider"></div> */}
        <MissionVissionCoreValues2 />

        {/* <MissionVissionCoreValues /> */}
      </div>
    </div>
  );
}

export default DiscoverSkyewise;
