import React from 'react';
import TheCooperativeMSV from './theCoopeartiveMissionVisionValues';

const TheCooperativeIntroText = () => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>Skyewise P.M.C.S</h2>
              <p>
                Skyewise Progressive Multipurpose Cooperative Society Ltd. was
                registered for the sole purpose of bridging the liquidy gap in
                the society, for members, and to meet the members’ financial
                needs, while equipping them with alternative sources of wealth
                creation
              </p>
            </div>
          </div>
          {/* <TheCooperativeMSV /> */}
        </div>
      </div>
    </div>
  );
};

export default TheCooperativeIntroText;
