import React, { Fragment } from 'react';
import Footer from '../../common/footer/footer';
import Navbar from '../../common/navbar/navbar';
import VideoPlayerModal from '../../common/videoPlayerModal';
import MTCEOsHeader from './mtceosHeader';
import MTCEOsIntro from './mtceosIntro';
import { closePlayer } from '../../../utils/videoPlayerModalControls';
import MTCEOsBackdrop from './mtceosBackdrop';
import MTCEOsSpeakers from './mtceosSpeakers';
import MTCEOsWatchStream from './mtceosWatchStream';
import MTCEOsSEAFImpact from './mtceosSeeSkyewiseImpact';
import restartWebflowScript from '../../common/restartWebflowScript';

const TheCEOsSummit = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <MTCEOsHeader />
      <MTCEOsIntro />
      <MTCEOsWatchStream />
      <MTCEOsSpeakers />
      <MTCEOsBackdrop />
      <MTCEOsSEAFImpact />
      <VideoPlayerModal
        playerData={{
          playerId: 'p:id-mtceos-keynote',
          videoUrl: 'https://vimeo.com/735380123',
          closePlayer,
        }}
      />
      <Footer />
    </Fragment>
  );
};

export default TheCEOsSummit;
