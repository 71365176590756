import React from 'react';

const KYCPageHeader = () => {
  return (
    <header className="header-static header-static--contact wf-section">
      <div className="overlay--dark"></div>
      <div className="container">
        <div className="header-statoc--flex">
          <div className="header-static__container">
            <h1>Account Verification</h1>
          </div>
        </div>
      </div>
    </header>
  );
};

export default KYCPageHeader;
