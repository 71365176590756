import React from "react";
import HrDevider from "../common/hrDevider";
import { Link } from "react-router-dom";

const TravelAndTourServicesBooking = () => {
  return (
    <div className="_1-col-c-center base-section base-section--white-bg ">
      <div className="container container--np">
        <div className="glue-devider"></div>

        <div className="_1-col-c-center__wrp container-interstitial--top-bottom">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2 className="glue-headline glue-headline--headline-1">
                Services Booking
              </h2>
              <p className="glue-paragraph glue-paragraph--large">
                Whether you’re looking for a holiday to see Africa, visit a new
                country or marvel at National Parks’ natural beauties, you’ll
                find it here.
              </p>
            </div>
            <Link
              className="s-btn s-btn s-btn--blue w-inline-block is-btn--blue s-btn--inline featured-service__cta"
              to="/travel-and-tourism/ticket-booking"
            >
              <p>Book service</p>
            </Link>
          </div>
        </div>

        <div className="glue-devider"></div>
      </div>
    </div>
  );
};

export default TravelAndTourServicesBooking;
