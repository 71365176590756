import React from 'react';
import configData from '../../../config.json';
const storageUrl = configData.storageUrl;

const HeaderBlur = (props) => {
  const { client } = props;
  return (
    <div className="user-dashboard__header">
      <div className="user-dashboard__header__header-content">
        <div className="container is--narrow">
          <div className="_2-col-st-grid udh__grid">
            <div></div>

            <div className="udh__quick-acn">
              {client.email && (
                <a
                  href={`mailto: ${client.email}`}
                  className="udh__quick-acn__item udh__quick-acn__item--left "
                >
                  <div className="udh__quick-acn__item__icon">
                    <p className="is-icon"></p>
                  </div>
                  <div className="udh__quick-acn__item__text">
                    <p>{client.email}</p>
                  </div>
                </a>
              )}
              {/* Phone */}
              {client.phoneNumber && (
                <a
                  href={`tel:${client.phoneNumber}`}
                  className="udh__quick-acn__item"
                >
                  <div className="udh__quick-acn__item__icon">
                    <p className="is-icon"></p>
                  </div>
                  <div className="udh__quick-acn__item__text">
                    <p>{client.phoneNumber}</p>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="div-block-120"></div>
        <div className="overlay--dark"></div>
        <div className="user-dashboard__header__content">
          <img
            src={`${storageUrl}${client.photo}`}
            alt={client.name}
            className="user__image is-object-fit"
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderBlur;
