import React from 'react';
import configData from '../../../config.json';
const storageUrl = configData.storageUrl;

const ClientBrief = (props) => {
  const { client } = props;
  let clientNames;
  if (client) clientNames = client.name.split(' ');

  return (
    <div className="user__profile">
      <div className="user__photo">
        <img
          src={`${storageUrl}${client.photo}`}
          loading="lazy"
          alt={client.name}
          className="user__profile__photo__img"
        />
      </div>
      <div className="user__note">
        <div className="div-block-42 c-rich-text">
          {!client.isVerified && (
            <p className="user__verified">
              <span className="is-icon is--pending"></span>{' '}
              <span className="link--underline link--italics is-fw-400">
                Unverified
                <br />
              </span>
            </p>
          )}

          <div className="hr-line hr-line--dark space-min"></div>
          <h3 className="user__name">
            <span className="thin-text">{clientNames[0]} </span>
            {clientNames[1]}
          </h3>

          <p className="user__welcome-note">
            Welcome to Skyewise Group. <br />
            This is your personal dashboard. Here you will find details about
            all your credit history with Skyewise. Thank you for choosing us
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClientBrief;
