import React from "react";
import { Link } from "react-router-dom";

const OurValues = () => {
  return (
    <div className="_1-col-c-center wf-section" id="our-values">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>Our Values</h2>
              <h4>Client Service</h4>
              <p>
                We lead with a service mindset, enabling us to anticipate and
                adapt to the needs of our clients and consumers by delivering
                thoughtful, innovative solutions.
              </p>
            </div>

            <div className="c-rich-text">
              <h4>Excellence</h4>
              <p>
                Excellence We aspire to nothing less than excellence,
                consistently striving for exceptional performance and achieving
                outstanding results for our clients, our shareholders, and our
                company
              </p>
            </div>

            <div className="c-rich-text">
              <h4>Integrity</h4>
              <p>
                Integrity We hold ourselves accountable to the highest ethical
                standards, insisting on transparency and vigilance from our
                people as we learn from our experiences and make decisions that
                instill a sense of purpose and pride in our institution.
              </p>
            </div>

            <div className="c-rich-text">
              <h4>Partnership</h4>
              <p>
                We prioritize collaboration and value diversity, creating a
                culture that fosters inclusiveness, teamwork, and an
                entrepreneurial mindset in the pursuit of professional and
                personal excellence.
              </p>
            </div>
            <div className="c-rich-text">
              <h4>Sustainability</h4>
              <p>
                Sustainability is critical to achieving superior returns,
                building our portfolio, and attracting and keeping our clients.
                Sustainability is at the core of our company. We uphold
                sustainable finance by doing what we know best: investing,
                financing, advising, and innovating.
              </p>
            </div>
            <div className="c-rich-text">
              <h4> Time-Bound Service Delivery</h4>
              <p>
                We prioritize speed in our service delivery by creating a
                culture of client service at its core and operation that’s
                supported by innovative ICT solutions.
              </p>
            </div>
          </div>
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text"></div>
            <Link
              to="/what-we-do/products-and-services"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Products and Services</p>
            </Link>

            <Link
              to="/about-us/our-people"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>People {"&"} Leadership</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
