import React, { Fragment, useLayoutEffect } from "react";
import restartWebflowScript from "../../common/restartWebflowScript";

import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import SWIH from "./header";
import SWI from "./body";

const SWPIPage = (props) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f4534e4bd366");
  });
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <SWIH />
      <SWI />
      <Footer />
    </Fragment>
  );
};

export default SWPIPage;
