import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const UserRestricted = () => {
  return (
    <Fragment>
      <div className="header-static wf-section">
        <div className="overlay--dark"></div>
        <div className="container">
          <div className="header-statoc--flex">
            <div className="header-static__container">
              <h1> 401</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-middle wf-section">
        <div class="container">
          <div class="flex-center">
            <div class="flex-center__container">
              <div class="c-rich-text">
                <h2>401 (Unauthorized)</h2>
                <p>
                  You do not have access level permission
                  <br />
                  You may want contact{' '}
                  <a
                    className="is--link"
                    href="mailto:support@skyewisegroup.com"
                  >
                    support@skyewisegroup.com
                  </a>{' '}
                  or visit{' '}
                  <Link class="is--link" to="/">
                    Skyewise Group Homepage
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserRestricted;
