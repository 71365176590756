import React, { Fragment, useContext, useEffect, useState } from 'react';

import LoanServices from '../../../services/loanService';
import authService from '../../../services/authService';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import progressHandler from '../../../utils/progressHandler';
import ProgressRender from '../../progressRender';
import formatCurrency from '../../../utils/formatCurrency';
import CurrentyIcon from '../../common/currency';

const ActiveLoan = (props) => {
  const [userLoan, setUserLoan] = useState(null);

  useEffect(() => {
    // Initialize Lottie
    initProgressLotties();

    async function getCLoan() {
      const currentUser = authService.getUVDALocal();

      if (
        currentUser &&
        currentUser.currentLoan &&
        currentUser.loanState !== 'none'
      ) {
        // Show UI Loading
        progressHandler.showProgress('active-loan', 'loading');

        try {
          const result = await LoanServices.getLoan(currentUser.currentLoan);
          const { data: loanData } = result.data.data;
          progressHandler.hideProgress('active-loan', 'loading');

          setUserLoan(loanData);
        } catch (ex) {
          progressHandler.hideProgress('active-loan', 'loading');
        }
      }
    }
    getCLoan();
    // }
  }, []);

  return (
    <div
      data-w-id="494c59a3-cfee-f9d8-2af4-a9ac00b49cb7"
      className="credit-sum st-b-radius sm-b-shadow is-bg--light-blue hover--scale"
      onClick={() =>
        props.dashboardData({
          ...userLoan,
          credit: userLoan ? 'loan' : null,
        })
      }
    >
      <Fragment>
        <div className="investment-sum__cont">
          {/* Progress  */}
          <ProgressRender
            elData={{
              loading: {
                name: 'active-loan-progress__loading',
                size: 'small',
                transparency: true,
              },
              success: {
                name: 'active-loan-progress__success',
                size: 'small',
                transparency: true,
              },
              error: {
                name: 'active-loan-progress__error',
                size: 'small',
                transparency: true,
              },
            }}
          />

          {userLoan && (
            <Fragment>
              <div className="investment-sum__credit-type">
                <div className="is-icon is-blue credit__icon is-loan">
                  <p></p>
                </div>
                <h5 className="credit__title">Active Loan</h5>
              </div>
              <div className="investment-sum__amount">
                <p className="is-blue credit__amount is-credit__amount--loan">
                  {userLoan && (
                    <span>
                      <CurrentyIcon icon={'N'} />
                      {formatCurrency(
                        Number(
                          userLoan.approvedAmount
                            ? userLoan.approvedAmount
                            : userLoan.amount
                        )
                      )}
                    </span>
                  )}
                </p>
              </div>
              <div className="investment-sum__tags text--small">
                <p className="credit-sum__date">
                  <span className="is-icon"></span>{' '}
                  {new Date(
                    Date.parse(userLoan.createdAt)
                  ).toLocaleDateString()}
                </p>
                <p
                  className={'credit-sum__state ' + userLoan.status + '--solid'}
                >
                  {userLoan && userLoan.status}
                </p>
              </div>
            </Fragment>
          )}

          {!userLoan && (
            <Fragment>
              <div className="investment-sum__credit-type">
                <div className="is-icon is-blue credit__icon is-loan">
                  <p></p>
                </div>
                <h5 className="credit__title">Loan</h5>
              </div>
              <div className="investment-sum__amount">
                <p className="is-blue credit__amount is-credit__amount--loan">
                  <span>00.00</span>
                  {/* {userLoan && <span>{"N " + userLoan.amount}</span>} */}
                </p>
              </div>
              <div className="investment-sum__tags text--small">
                <p className="credit-sum__date">
                  <span className="is-icon"></span> 00.00.00
                </p>
                <p className="credit-sum__state credit--inactive">
                  {!userLoan && <span>No active loan </span>}
                </p>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default ActiveLoan;
