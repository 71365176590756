import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './loginForm';
import AuthPageBrand from './common/authPageBranding';
import ProgressRender from './progressRender';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

const loginPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Skyewisegroup.com | Users Authentication Page - Login</title>
        <meta
          name="description"
          content="Users authentication page - access our credit facility and manage your services."
        />

        <link rel="canonical" href="/login" />
      </Helmet>
      <div className="page__wrapper">
        <div className="section fs-page fs-page--login wf-section">
          <div className="overlay--dark overlay--fixed"></div>
          <div className="container fs-page__container">
            <div className="fs-grid fs-grid--flex">
              <div
                id="w-node-_341c5365-4f44-79a9-c83c-bd40a4004c26-f24bd36d"
                className="fs-grid__area"
              >
                <div className="div-block-25 psuedo-pos--relative">
                  <div className="sform__container st-b-radius st-b-shadow">
                    <div className="s-form__header c-rich-text">
                      <h1 className="c-h3">Sign in</h1>
                      <div className="div-block-23">
                        <p className="is-txt__x-small">
                          Don't have an account?{' '}
                          <Link
                            to="/sign-up"
                            className="st-link text-hover-highlight text-hover-highlight--blue is-blue st-link--bold"
                          >
                            Create account
                          </Link>
                        </p>
                        <p className="is-icon is-blue"></p>
                      </div>
                    </div>

                    <ProgressRender
                      elData={{
                        loading: {
                          name: 'login-progress__loading',
                        },
                        success: { name: 'login-progress__success' },
                        error: { name: 'login-progress__error' },
                      }}
                    />

                    <LoginForm />
                  </div>
                </div>
              </div>
              <div
                id="w-node-ddb1f4dc-c5da-4792-94f5-cf0446b268f6-f24bd36d"
                className="disclaimer"
              >
                <span></span>
              </div>
              <div
                id="w-node-_611b3d5e-9221-33cf-b586-523656955dd6-f24bd36d"
                className="fs-grid__area flex--first-item"
              >
                <AuthPageBrand />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default loginPage;
