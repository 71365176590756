import React from "react";
import TheCooperative from "./theCooperativeUserDirection";

const TheCooperativeMissionVision = (props) => {
  return (
    <div className="_1-col-c-center gray-bg-02 wf-section">
      <div className="container is--narrow">
        <div className="_2-col-st-grid is-txt--centered is-mt-medium ">
          <div className="our-mission">
            <div className="c-rich-text">
              <h4>VISION</h4>
              <p className="">
                Skyewise PMCS Ltd. is to meet the financial need of her members
                and to equip them with other sources of wealth creation.
              </p>
            </div>
          </div>
          <div className="our-mission">
            <div className="c-rich-text">
              <h4> MISSION</h4>
              <p className="">
                To build a cooperative economy through the creation and
                development of successful relationships networks in diverse
                communities in Nigeria.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheCooperativeMissionVision;
