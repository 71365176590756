import React from 'react';

const CheckBox = ({ name, label, error, ...rest }) => {
  return (
    <div className="checkbox__container ">
      <div className="s-form-input__checkbox ">
        <input
          className="s-form-input__checkbox__ticker checkbox--default"
          {...rest}
          name={name}
          id={name}
          type="checkbox"
          value="accepted"
        />
        <span className="s-form-input__checkbox__marker"></span>
      </div>
      <label className="s-form-input__checkbox__lable" for={name}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
