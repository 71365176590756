import React, { Fragment } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import Navbar from "../common/navbar/navbar";
import PurposeAndValuesHeader from "./purposeAndValuesHeader";
import OurPurpose from "./ourPurpose";
import OurValues from "./ourValues";
import Footer from "../common/footer/footer";
import ChatAndNavHelperBtn from "../common/chatAndNavHelperOpen";
import SleakSlide from "../common/g-sleak-slide/sleakSlidesBase";

const PurposeAndValues = () => {
  restartWebflowScript();
  return (
    <Fragment>
      <Navbar />
      {/* <ChatAndNavHelperBtn /> */}
      <PurposeAndValuesHeader />
      <OurPurpose />
      <SleakSlide />
      <OurValues />
      <Footer />
    </Fragment>
  );
};

export default PurposeAndValues;
