import React, { Fragment } from "react";
import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import WhatWeDoHeader from "./whatWeDoHeader";
import WhatWeDoOverviewIntro from "./whatWeDoIntro";
import restartWebflowScript from "../common/restartWebflowScript";

const WhatWeDoOverview = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <WhatWeDoHeader />
      <WhatWeDoOverviewIntro />
      <Footer />
    </Fragment>
  );
};

export default WhatWeDoOverview;
