import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const ManagementTeamMember = (member) => {
  const managementBaseImgUrl = "/images/content/our-people/management/";

  return (
    <div className="_4-col-st-grid__item">
      <div className="grid__item">
        <div className="management-team">
          <div className="management-team--photo">
            <img
              className="management-team--photo--img"
              src={managementBaseImgUrl + member.manager.photo}
              alt={member.manager.name}
            />
          </div>

          <div className="management-team--detials">
            <h3>{member.manager.name}</h3>
            <p>{member.manager.position}</p>
            <p className="fw-600-italics">{member.manager.location}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementTeamMember;
