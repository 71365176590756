import React from "react";

const TravelAndTourButtonGridCta = (props) => {
  return (
    <section className="section _1-col-c-center wf-section">
      <div className="container flx flx-c-ctr">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <h2 className="glue-eyebrow">TRAVEL &amp; TOUR SERVICES</h2>
            <div className="wrapper module-separator">
              <div className="button-grid__container button-grid__container--3-col">
                <div className="iconcta-tile">
                  <div className="iconcta-tile__tile">
                    <a
                      className="iconcta-tile__links"
                      href="/en/all-vehicles"
                      data-analytics-trigger="cta"
                    >
                      <picture
                        data-load-module="LazyLoader"
                        data-module-loaded="true"
                        className="lazy-loader lazy-loader--loaded"
                        data-lazyloader-id="_kzjxyv6vy0b8w"
                      >
                        <source
                          media="(max-width: 767px)"
                          srcSet="/images/temp/mb/SHOPPING-TOOL-ALL-VEHICLES-XL.jpg"
                          data-lazy-src="/images/temp/mb/SHOPPING-TOOL-ALL-VEHICLES-M.jpg"
                          className="lazy-loader__asset"
                        />
                        <img
                          src="/images/temp/mb/SHOPPING-TOOL-ALL-VEHICLES-XL.jpg"
                          alt=""
                          className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                          data-lazy-src="/images/temp/mb/SHOPPING-TOOL-ALL-VEHICLES-XL.jpg"
                          role="presentation"
                        />
                      </picture>

                      <div className="iconcta-tile__description-container">
                        <div className="iconcta-tile__indicator">
                          <div className="iconcta-tile__indicator__point"></div>
                          <p className="iconcta-tile__indicator__text ">
                            No Active Tours
                          </p>
                        </div>
                        <div className="icon-mb icon-model-chooser iconcta-tile__text">
                          <p className="iconcta-tile__label">Tours</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="iconcta-tile">
                  <div className="iconcta-tile__tile">
                    <a
                      className="iconcta-tile__links"
                      href="/en/vehicles/build"
                      data-analytics-trigger="cta"
                    >
                      <picture
                        data-load-module="LazyLoader"
                        data-module-loaded="true"
                        className="lazy-loader lazy-loader--loaded"
                        data-lazyloader-id="_kzjxyv6wtxz3m"
                      >
                        <source
                          media="(max-width: 767px)"
                          srcSet="/images/temp/mb/SHOPPING-TOOL-BUILD-YOUR-OWN-XL.jpg"
                          data-lazy-src="/images/temp/mb/SHOPPING-TOOL-BUILD-YOUR-OWN-M.jpg"
                          className="lazy-loader__asset"
                        />
                        <img
                          src="/images/temp/mb/SHOPPING-TOOL-BUILD-YOUR-OWN-XL.jpg"
                          alt=""
                          className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                          data-lazy-src="/images/temp/mb/SHOPPING-TOOL-BUILD-YOUR-OWN-XL.jpg"
                          role="presentation"
                        />
                      </picture>

                      <div className="iconcta-tile__description-container">
                        <div className="iconcta-tile__indicator  ">
                          <div className="iconcta-tile__indicator__point indicator--available"></div>
                          <p className="iconcta-tile__indicator__text ">
                            Service Available
                          </p>
                        </div>

                        <div className="icon-mb icon-gears iconcta-tile__text">
                          <p className="iconcta-tile__label">BUILD YOUR OWN</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="iconcta-tile">
                  <div className="iconcta-tile__tile">
                    <a
                      className="iconcta-tile__links"
                      href="/en/special-offers"
                      data-analytics-trigger="cta"
                    >
                      <picture
                        data-load-module="LazyLoader"
                        data-module-loaded="true"
                        className="lazy-loader lazy-loader--loaded"
                        data-lazyloader-id="_kzjxyv6w6vf7h"
                      >
                        <source
                          media="(max-width: 767px)"
                          srcSet="/images/temp/mb/SHOPPING-TOOL-SPECIAL-OFFERS-XL.jpg"
                          data-lazy-src="/images/temp/mb/SHOPPING-TOOL-SPECIAL-OFFERS-M.jpg"
                          className="lazy-loader__asset"
                        />
                        <img
                          src="/images/temp/mb/SHOPPING-TOOL-SPECIAL-OFFERS-XL.jpg"
                          alt=""
                          className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                          data-lazy-src="/images/temp/mb/SHOPPING-TOOL-SPECIAL-OFFERS-XL.jpg"
                          role="presentation"
                        />
                      </picture>

                      <div className="iconcta-tile__description-container">
                        <div className="iconcta-tile__indicator">
                          <div className="iconcta-tile__indicator__point indicator--available"></div>
                          <p className="iconcta-tile__indicator__text ">
                            Service Available
                          </p>
                        </div>
                        <div className="icon-mb icon-special-offers iconcta-tile__text">
                          <p className="iconcta-tile__label">SPECIAL OFFERS</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelAndTourButtonGridCta;
