import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CarFeaturedCardSkeleton = (props) => {
  return (
    <div className="l-car__card">
      <div className="st-link w-inline-block min-h-[105px] w-full">
        <div className="l-car__image__card">
          <div className="l-car l-car--mini">
            <div className="l-car__image">
              <div
                data-w-id="1bdc56e5-07fb-10a7-3851-d89f66b42766"
                className="st-img__wrp"
              >
                <Skeleton height={105} />
              </div>
            </div>
          </div>
          <div className="l-car__text w-full">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarFeaturedCardSkeleton;
