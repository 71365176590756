import React from 'react';
import Investments from '../investments';
import AdminInvestmentsHeader from './adminInvestmentsHeader';
import AboutUser from '../user/userDashbaordAboutUser';
import InvestmentsSummary from './adminInvestmentsSummary';

const CurrentInvestments = (props) => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          {/* <div className="_1-col-c-center__c-narrow is--left-align "> */}
          <Investments />

          {/* <InvestmentsSummary /> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default CurrentInvestments;
