import React, { useEffect } from 'react';
import Footer from 'react-images-viewer/lib/components/Footer';
import Navbar from '../../common/navbar/navbar';
import PageExitCTA from '../../common/pageExitCTA';
import restartWebflowScript from '../../common/restartWebflowScript';
import ProjectsParallaxSlide from '../foundationProjectsSlider';
import MeetTheCEOs2022Home from './meetTheCEOs2022Home';
import MeetTheCEOsContacts from './mtceos2022Contact';
import MTCEOs2022Cta from './mtceos2022Cta';
import MTCEOs2022Intro from './mtceos2022intro';

const MeetTheCEOs2022 = () => {
  useEffect(() => {
    var htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.setAttribute('data-wf-page', '61e539b9a949f4a3224bd367');
  });

  restartWebflowScript();

  return (
    <div>
      <Navbar />
      {/* <ChatAndNavHelperOpen /> */}

      <MeetTheCEOs2022Home />

      <MTCEOs2022Cta />

      <MTCEOs2022Intro />

      {/* <ProjectsParallaxSlide /> */}

      <MeetTheCEOsContacts />
      <PageExitCTA />
      <Footer />
    </div>
  );
};

export default MeetTheCEOs2022;
