import React from "react";

const PartnershipsGrid = (Partners) => {
  const brandImgUrl = "/images/content/auto-partners/";
  return (
    <div className="s-grid-6-col">
      {Partners.partners.map((partner) => {
        return (
          <div className="s-grid__item">
            <img src={brandImgUrl + partner.logo} alt={partner.name} />
          </div>
        );
      })}
    </div>
  );
};

export default PartnershipsGrid;
