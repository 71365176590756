import React, { Component } from 'react';
import auth from '../services/authService';
import { Link } from 'react-router-dom';
import Table from './common/table';
import Like from './common/like';
import configData from '../config.json';

const storageUrl = configData.storageUrl;

class ClientsTable extends Component {
  columns = [
    {
      path: 'name',
      label: 'Client',
      content: (client) => (
        <Link to={`/admin/clients/${client._id}`}>
          <div className="investment--investor">
            <span className="investment--investor--photo">
              <div className="nav__user-photo">
                {client && (
                  <img
                    src={
                      client.photo !== 'default.jpg'
                        ? storageUrl + client.photo
                        : '/images/thumb_15951118880user.png'
                    }
                    loading="lazy"
                    alt=""
                    className="user__profile__photo__img"
                  />
                )}
              </div>
            </span>
            {client && <span className="is-fw-600">{client.name}</span>}
          </div>
        </Link>
      ),
    },
    {
      path: 'email',
      label: 'Email',
      content: (client) => (
        <a href={`mailto: ${client.email}`} className="is-fw-600">
          {client.email}
        </a>
      ),
    },

    {
      path: 'phoneNumber',
      label: 'Phone Number',
      content: (client) => (
        <a href={`tel: ${client.phoneNumber}`} className="is-fw-600">
          {client.phoneNumber}
        </a>
      ),
    },

    {
      path: 'city',
      label: 'Location',
      // content: (client) => <span className="is-fw-600">{client.city}</span>,
    },
    {
      path: 'details',
      label: 'Details',
      key: 'details',
      content: (client) => (
        // <Like
        //   liked={client.liked}
        //   onClick={() => this.props.onLike(client)}
        // />

        <Link
          className="alt-btn btn-link--is-blue w-inline-block alt-bt--inline"
          to={`/admin/clients/${client._id}`}
        >
          {' '}
          View
        </Link>
      ),
    },
    // {
    //   path: "like",
    //   label: "Like",
    //   key: "like",
    //   content: (client) => (
    //     <Like
    //       liked={client.liked}
    //       onClick={() => this.props.onLike(client)}
    //     />
    //   ),
    // },
  ];

  deleteColumn = {
    key: 'delete',
    content: (client) => (
      <button
        onClick={() => this.props.onDelete(client)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { clients, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={clients}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ClientsTable;
