import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SubsectionTitleWIthFlip from '../UiLayoutsAndComponents/subsectionTItleWithFlip';
import Trend from './trend';
import ProgressRender from '../../progressRender';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import progressHandler from '../../../utils/progressHandler';
import checkPostReadyState from '../../../utils/remoteDataStateReadyRetry';

function Component(props) {
  const recentStories = JSON.parse(localStorage.getItem('recentBlogPosts'));
  const [blogPostsState, setBlogPostsState] = useState(null);

  useEffect(() => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('trends-update', 'loading');

    checkPostReadyState(
      recentStories,
      'recentBlogPosts',
      2000,
      0,
      setBlogPostsState
    );
  });

  return (
    <div
      data-w-id="9255ddcb-f6ca-5a3d-c034-3864284cdbbb"
      //   style="opacity:0"
      className="section trends__section wf-section"
    >
      <div className="container">
        <SubsectionTitleWIthFlip
          props={{
            title: 'Trends',
            text: 'Insignts on the latest stories from Skyewise',
          }}
        />

        {!recentStories && (
          <div className="progress-loader-wrapper--gray">
            <ProgressRender
              elData={{
                loading: {
                  name: 'trends-update-progress__loading',
                  transparency: true,
                },
                success: { name: 'trends-update-progress__success' },
                error: { name: 'trends-update-progress__error' },
              }}
            />
          </div>
        )}
        <div className="trends">
          <div className="trends__wrp">
            <div
              data-delay="15000"
              data-animation="fade"
              className="trends__slider w-slider"
              data-autoplay={true}
              data-easing="ease"
              data-hide-arrows="true"
              data-disable-swipe="false"
              data-autoplay-limit="0"
              data-nav-spacing="3"
              data-duration="900"
              data-infinite="true"
            >
              <div className="trends__slider__mask st-b-radius w-slider-mask">
                {/* <Trend />
                <Trend /> */}

                {/* UI blog data fetch progress */}

                {recentStories &&
                  recentStories.map((post, k) => {
                    return <Trend postData={post} key={k} />;
                  })}
              </div>
              <div className="trends__nav is-nav--right w-slider-arrow-left">
                <div className="trend__nav__icon w-icon-slider-left"></div>
              </div>
              <div className="trends__nav w-slider-arrow-right">
                <div className="trend__nav__icon w-icon-slider-right"></div>
              </div>
              <div className="slide-nav-2 w-slider-nav w-round"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
