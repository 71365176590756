import React from 'react';

const CIBH = (props) => {
  return (
    <div className="header-static static-header--leadership wf-section">
      <div className="container">
        <div className="header-statoc--flex">
          <div className="header-static__container">
            <h1>Benefits</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CIBH;
