import React from 'react';

const DashboardLocalFooter = (props) => {
  return (
    <div>
      <div className="hr-line hr-line--dark"></div>
      <p>Skyewie Cooperative</p>
    </div>
  );
};

export default DashboardLocalFooter;
