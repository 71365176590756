import React from 'react';

const MTCEOsBackdrop = (props) => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="glue__bg-video">
        <div className="glue__bg-video__overlay"></div>
        <div className="glue__bg-video__video">
          <video
            autoPlay={true}
            muted
            loop
            controls={false}
            className="glue-quote__quote-image"
            src="/images/content/seaf-foundation/moving-photos.mp4"
          />
        </div>
      </div>
    </div>
  );
};

export default MTCEOsBackdrop;
