import React, { Fragment } from 'react';
import Navbar from '../../common/navbar/navbar';
import Footer from '../../common/footer/footer';
import restartWebflowScript from '../../common/restartWebflowScript';
import CommunityEngagements from './communityEngagementsIntro';
import CommunityEngagementHeader from './communityEngagementHeader';
import SeafPorjects from './communityEngagementsProjects';
import CommunityEngagementBrief from '../communityEngagements/communtityEngagementBrief';

const SEAFEngagements = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <CommunityEngagementHeader />
      <CommunityEngagements />
      <SeafPorjects />
      <CommunityEngagementBrief />
      <Footer />
    </Fragment>
  );
};

export default SEAFEngagements;
