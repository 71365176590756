import React from 'react';

const ClientInfoBubbles = (props) => {
  const { bubbleData } = props;

  return (
    <div className="udm__info-bubbles">
      {bubbleData &&
        bubbleData.map((bubble) => {
          if (bubble.v) {
            return (
              <div className="udm__info-bubbles__bubble" key={bubble.k}>
                <div className="bubble__key-icon">
                  <span className="bubble__key-icon__i is-icon">
                    {bubble.i}
                  </span>
                  <span className="bubble__key-icon__k">{bubble.k}</span>
                </div>
                {bubble.v}
              </div>
            );
          }
        })}
    </div>
  );
};

export default ClientInfoBubbles;
