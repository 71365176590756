import React, { Component } from 'react';
import SubsectionTitleWIthFlip from '../UiLayoutsAndComponents/subsectionTItleWithFlip';
import Testimony from './testimony';

class Testimonials extends Component {
  state = {
    testimonies: [
      {
        image:
          '/images/temp/240_F_313889585_p6fR1kWe8dqHHlx2MrTKD07dU1Sg3DMi.jpeg',
        name: 'Anita Michael',
        designition: 'Investor, Skyewise Group',
        testimony:
          'I started intesting with Skyewise Group in 2015. It has been a great place to put money and have in grow. I love the idea that my funds are secured and guratanteed',
      },
      {
        image:
          '/images/temp/240_F_305689035_YdmyMvm5IzlL3wWNreQZkzwRrzl1Kd1i.jpeg',
        name: 'Mike Okoh',
        designition: 'Investor',
        testimony:
          'I first knew about this company in April, 2017. It has been a great place to put money and have in grow. I love the idea that my funds are secured and guratanteed',
      },
      {
        image:
          'images/temp/240_F_90405521_bB2oigogVPpQKIL33hnyR8IcqlqNfyn6.jpeg',
        name: 'Mary Okoh',
        designition: 'Customer, Skyewise autosf',
        testimony:
          'I first knew about this company in April, 2017. It has been a great place to put money and have in grow. I love the idea that my funds are secured and guratanteed',
      },
    ],
  };

  render() {
    return (
      <div className="c-active-slider wf-section">
        <div className="container">
          <SubsectionTitleWIthFlip
            props={{
              title: 'Testimonials',
              text: 'What clients say about us',
            }}
          />
          <div className="c-active-slider__wrap">
            <div
              data-delay="7000"
              data-animation="slide"
              className="c-active-slider__slider w-slider"
              data-autoplay={true}
              data-easing="ease-in-out-quad"
              data-hide-arrows="false"
              data-disable-swipe="false"
              data-autoplay-limit="0"
              data-nav-spacing="3"
              data-duration="700"
              data-infinite="true"
            >
              <div className="c-active-slider__slider__mask w-slider-mask">
                {this.state.testimonies.map((testimony, i) => (
                  <Testimony user={testimony} key={i} />
                ))}
              </div>
              <div className="display__none w-slider-arrow-left">
                <div className="w-icon-slider-left"></div>
              </div>
              <div className="display__none w-slider-arrow-right">
                <div className="w-icon-slider-right"></div>
              </div>
              <div className="w-slider-nav w-round"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Testimonials;
