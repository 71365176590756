import React from "react";

const RealEstateIntoText = () => {
  return (
    <div className="_1-col-c-center gray-bg-02 wf-section">
      <div className="container is--narrow">
        <div className="_2-col-st-grid is-txt--centered is-mt-medium ">
          <div className="our-mission">
            <div className="c-rich-text">
              <h4>VISION</h4>
              <p className="">
                Our vision is to be the first choice global logistics and
                transportation service provider to cooperate and individuals
                accross the continent
              </p>
            </div>
          </div>
          <div className="our-mission">
            <div className="c-rich-text">
              <h4> MISSION</h4>
              <p className="">
                Our mission is to provide affordable air fare to our customers
                and render world class service to our esteem clients
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateIntoText;
