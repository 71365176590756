import React, { Fragment } from 'react';
import { Route, Navigate } from 'react-router-dom';
import Users from './users';
import Posts from './posts';
import SideBar from './sidebar';
import auth from '../../services/authService';
import Navbar from '../common/navbar/navbar';
import Footer from '../common/footer/footer';
import AdminNav from './adminCategoryPageDirection';
import AdminPageHeader from './adminCategoryPageHeader';
import restartWebflowScript from '../common/restartWebflowScript';

const AdminCategoryDashboard = ({ match }) => {
  restartWebflowScript();

  if (!auth.getCurrentUser()) return <Navigate to="/login" />;

  return (
    <Fragment>
      <Navbar />
      <AdminPageHeader />
      <AdminNav />
      <Footer />
    </Fragment>
  );
};

export default AdminCategoryDashboard;
