import React from "react";
import Footer from "../common/footer/footer";
import Navbar from "../common/navbar/navbar";
import { Fragment } from "react";
import Tours from "./tours";
import ToursPageHeadline from "./toursHeadline";
import FeaturedTour from "./toursFeaturedTour";

const ToursPage = (prop) => {
  return (
    <Fragment>
      <Navbar />
      <ToursPageHeadline />
      <FeaturedTour />
      <Tours />
      <Footer />
    </Fragment>
  );
};

export default ToursPage;
