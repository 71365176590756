import React from 'react';
import SkyeiweSocialHighlight from './socialHighlightCard';
import BlogUpdateMini from './blogUpdateMini';
import { useEffect } from 'react';
import { useState } from 'react';

const SocialHighlights = (props) => {
  const socialData = [
    {
      social: 'twitter',
      handle: 'SkyewiseGroup',
      handle_url: 'https://twitter.com/skyewisegroup',
      post_url:
        'https://twitter.com/skyewisegroup/status/1527232370079850496?s=20',

      caption:
        'The world is evolving and we are evolving with it. These are 4 profitable investments...',
    },
    {
      social: 'instagram',
      handle: 'SkyewiseGroup',
      handle_url: 'https://www.instagram.com/skyewisegroup/',
      post_url:
        'https://www.instagram.com/p/Cqx5FQcthqa/?utm_source=ig_web_copy_link',

      caption:
        'Photo highlights at the grand opening - Skyewise Automobile | Abuja',
    },
    {
      social: 'linkedin',
      handle: 'SkyewiseGroup',
      handle_url: 'https://www.linkedin.com/company/skyewisegroup',
      post_url: 'https://www.linkedin.com/company/skyewisegroup',

      caption: 'Bridging Liquidity Gap In the Society',
    },
  ];

  // Innitialize post counter limit
  let postDisplayCount = 0;

  const postUISettings = {
    maxPostDisplay: 2,
  };

  const recentStories = JSON.parse(localStorage.getItem('recentBlogPosts'));

  const [recentBlogPostsState, setRecentBlogPostsState] = useState(null);

  useEffect(() => {
    if (recentStories && !recentBlogPostsState)
      setRecentBlogPostsState(recentStories);
  });

  return (
    <div className=" _5-col-st-grid">
      {socialData &&
        socialData.map((socialPost, i) => {
          return <SkyeiweSocialHighlight data={socialPost} key={i} />;
        })}

      {/* Render Blog UI when data is set */}
      {recentBlogPostsState &&
        recentBlogPostsState.map((blogPost, i) => {
          // Keep track of post index
          postDisplayCount += 1;

          // Limit UI posts to 4
          if (postDisplayCount <= postUISettings.maxPostDisplay) {
            return <BlogUpdateMini key={i} postData={blogPost} />;
          }

          if (postDisplayCount >= postUISettings.maxPostDisplay) {
            // Exit post render intteration on post UI limit
            return;
          }

          // Reset post display counter
          postDisplayCount = 0;

          // Render UI Blog Post
        })}
    </div>
  );
};

export default SocialHighlights;
