import React, { useEffect } from "react";
import restartWebflowScript from "../common/restartWebflowScript";

const ToursPageHeadline = (prop) => {
  restartWebflowScript();

  useEffect(() => {});

  return (
    <div className="_1-col-c-center gray-bg-02 wf-section">
      <div className="container ">
        <h1 className="jumbo-headline jumbo-headline--headline-1">Tours</h1>
      </div>
    </div>
  );
};

export default ToursPageHeadline;
