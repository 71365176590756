import React, { Component, Fragment } from "react";

import { getMe } from "../../services/userService";
import UserContext from "../../context/userContext";

class Step extends Component {
  render() {
    return (
      <UserContext.Consumer>
        {(userContext) => (
          <Fragment>
            {this.context && (
              <div className="c-steps-indicator__step">
                <div
                  className={
                    this.props.stepData.step.stepName ==
                    this.context[this.props.stepType]
                      ? "s-step s-step--is-current"
                      : "s-step"
                  }
                >
                  <h6 className="s-step__step-count">
                    <span className="s-step__step-count--is-lighter">Step</span>{" "}
                    {this.props.stepData.count}
                  </h6>
                  <div
                    className={
                      this.props.stepData.step.stepName ==
                      this.context[this.props.stepType]
                        ? "s-step__bubble infinite-pulse "
                        : "s-step__bubble"
                    }
                  >
                    <div className="s-step__bubble__inner"></div>
                  </div>
                  <p className="s-step__desc">
                    {this.props.stepData.step.desc}
                  </p>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}

Step.contextType = UserContext;

export default Step;
