import React, { Fragment, Component } from 'react';

import KycVerificationBioDataForm from './kycVerificationBioDataForm';
// import StepsIndicator from "../../common/stepsIndicator";
import restartWebflowScriptClassComponents from '../../common/restartWebflowScriptClassConponents';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import progressHandler from '../../../utils/progressHandler';
import { getMe } from '../../../services/userService';
import GeneralDataLoadingProgress from '../../generalDataLoadingProgress';
import UserProfilePhoto from './userProfilePhoto';
// import authService from "../../../services/authService";
import KycDocumentsUpload from './kycDocumentsUpload';
import KYCGlueTips from './kycGlueTips';
import { Link } from 'react-router-dom';

class KYCFormSection extends Component {
  state = {
    kycProgressData: '',
    currentKycStep: '',
  };

  async componentDidMount() {
    restartWebflowScriptClassComponents();

    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('gen-data', 'loading');

    try {
      const { data: step } = await (await getMe()).data.data;
      // this.setState({ investments, genres });
      progressHandler.hideProgress('gen-data', 'loading');
      progressHandler.hideProgress('gen-data', 'success');

      this.setState({ currentKycStep: step.verificationState });
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('gen-data', 'loading');
        progressHandler.showProgress('gen-data', 'error', msg);
      } else {
        progressHandler.hideProgress('gen-data', 'loading');
        progressHandler.showProgress(
          'gen-data',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <GeneralDataLoadingProgress />
              {this.state.currentKycStep &&
                this.state.currentKycStep === 'unverified' && (
                  <div className="_1-col-c-center__c-narrow">
                    <div className="c-rich-text">
                      <h3>Account Verification</h3>
                      <p>Complete the form below to verify your account</p>
                    </div>

                    <div className="s-form-centred">
                      <KycVerificationBioDataForm />
                    </div>
                  </div>
                )}

              {/* Profile Photo */}
              {this.state.currentKycStep &&
                this.state.currentKycStep === 'p-photo' && (
                  <div className="_1-col-c-center__c-narrow">
                    <div className="c-rich-text">
                      <h3>Profile Photo</h3>
                      <p> Update your profile photo</p>
                      <UserProfilePhoto />
                    </div>

                    <div className="s-form-centred"></div>
                  </div>
                )}

              {/* KYC Documents Upload*/}
              {this.state.currentKycStep &&
                this.state.currentKycStep === 'kyc-id' && (
                  <div className="_1-col-c-center__c-narrow">
                    <div className="c-rich-text">
                      <h3>Means of Identification</h3>
                      <p>Passport, Drivers License, Or Valid ID Card</p>

                      <KycDocumentsUpload />
                    </div>

                    <div className="s-form-centred"></div>
                  </div>
                )}

              {/* KYC Data Submission*/}
              {this.state.currentKycStep &&
                this.state.currentKycStep === 'data-submission' && (
                  <Fragment>
                    <div className="_1-col-c-center__c-narrow">
                      <div className="c-rich-text">
                        {/* <img
                          src="/images/user-guide/user-profile-unverified_1366x768.png"
                          alt="Investment pending confirmation"
                        /> */}
                        <h2>Successful!</h2>
                        <h4>
                          Thank you for completing the KYC verification process
                        </h4>

                        <div className="is-flex flex-c">
                          <div className="c-rich-text is-flex__80-jc-c">
                            <p>
                              We will review your details and if the
                              requirements are met, your account will be
                              verified automatically. You can now access our
                              credit facilities from your personal account.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <KYCGlueTips />

                    <Link
                      className="s-btn s-btn s-btn--blue w-inline-block is-btn--blue s-btn--inline featured-service__cta"
                      to="/user-dashboard"
                    >
                      <p>My Account</p>
                    </Link>
                  </Fragment>
                )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default KYCFormSection;
