import React from 'react';
import { openPlayer } from '../../../utils/videoPlayerModalControls';
import PlayerModalPlayBtn from '../../common/videoPlayerModalPlayBtn';

const MTCEOs2022Cta = (props) => {
  return (
    <div className="_1-col-c-center wf-section is-bg--gray-03">
      <div className="container">
        <div className="keynote-play">
          <div className="keynote-play__img">
            <img
              className="glue-quote__quote-image"
              src="/images/content/seaf-foundation/mtceos2022/meet-the-ceos-2022-cover-02.webp"
            />
          </div>
          <div className="keynote-play__play">
            <a
              href="https://bit.ly/meet-the-ceos-20"
              target={'_blank'}
              id="w-node-_827fcf9d-9921-95e4-e4a9-26c52e68d59e-224bd367"
              className="hero-style-fs__slider__slide__aside"
            >
              <div
                className="slide-aside__thumbnail
                       hero-style-fs__slider__slide__aside-text                        "
              >
                <div className={'player-modal__play-btn' + ' skin--light'}>
                  <span className="is-fa-icon--300"></span>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="keynote-play__on-demand">
          <div className="">
            <h3>Register now</h3>
            <p>Entry is free but registration compulsory</p>
          </div>

          <div className=" keynote-play__on-demand__btn">
            <a
              data-w-id="3c147205-aa54-b63f-fe34-d3ad94d3672f"
              target="_blank"
              className="s-btn  w-inline-block"
              href="https://bit.ly/meet-the-ceos-20"
            >
              <p>Register Now</p>
              {/* <img
                className="on-demand__btn__icon"
                alt="YouTube logo"
                // src="/images/icons/youtube-logo-2431.svg"
              /> */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MTCEOs2022Cta;
