import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from './common/formElements/form';
import auth from '../services/authService';
import ProgressRender from './progressRender';
import { initProgressLotties } from '../utils/initProgressLottie';
import progressHandler from '../utils/progressHandler';
import withRouter from '../HOC/withRouter';
import withLocation from '../HOC/withLocation';

class LoginForm extends Form {
  state = {
    data: { username: '', password: '' },
    errors: {},
  };

  schema = {
    username: Joi.string().required().email().label('Username'),
    password: Joi.string().required().min(5).label('Password'),
  };

  doSubmit = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('login', 'loading');
    try {
      const { data } = this.state;

      await auth.login(data.username, data.password);

      await auth.setUVDALocal();

      const { role: userRole } = await auth.getCurrentUser().currentUser;

      // const { state } = this.props.location;

      // window.location = state ? state.from.pathname : "/";

      // Hide UI loadin, show success
      progressHandler.hideProgress('login', 'loading');
      progressHandler.showProgress('login', 'success');

      // const { state } = this.props.location;
      // window.location = state ? state.from.pathname : '/';

      setTimeout(() => {
        // const { state } = this.props.location;
        // window.location = state ? state.from.pathname : '/';
        if (userRole === 'admin') {
          window.location = '/admin';
        } else {
          window.location = '/user-dashboard';
        }
      }, 1500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('login', 'loading');
        progressHandler.showProgress('login', 'error', msg);
      } else {
        console.log(ex);
        progressHandler.hideProgress('login', 'loading');
        progressHandler.showProgress(
          'login',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('login', 'error');
      }, 2000);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Navigate to="/user-dashboard" />;

    return (
      <div className="st-form-blk">
        <form className="st-form--auth" onSubmit={this.handleSubmit}>
          {this.renderInput(
            'username',
            'Username',
            'text',
            'Email address',
            ''
          )}
          {this.renderInput(
            'password',
            'Password',
            'password',
            '*********',
            ''
          )}

          <div className="st-form__auth__cta">
            <p className="is-txt__x-small">
              <Link
                to="#"
                className="st-link text-hover-highlight text-hover-highlight--blue st-link--bold"
              >
                Forgot password
              </Link>
            </p>
            {this.renderButton('Signin')}
          </div>
        </form>
      </div>
    );
  }
}

export default LoginForm;
