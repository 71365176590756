import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

const UnverifiedUser = (props) => {
  return (
    <div className="is-2_5em--white-wrap">
      <div className="c-rich-text is-txt--centered">
        <h4>Complete your KYC (Verification)</h4>
        <img
          src="\images\user-guide\verified-accounts.png"
          alt="user account verification"
        />
        <p>
          To identify you and keep you protectected, we require that you do a
          second level verification (KYC verification). The details you provide
          are not used publicly. <br /> This ensures that only you have access
          to your account.
        </p>
      </div>
      <div className="is-cta-align--center">
        <Link
          to="/account/kyc-verification"
          className="s-btn is-btn--black w-inline-block"
        >
          <p>Learn more</p>
          <p className="is-icon"></p>
        </Link>
      </div>
    </div>
  );
};

export default UnverifiedUser;
