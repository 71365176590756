import React from 'react';
import { Link } from 'react-router-dom';

const OurPurpose = () => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>Our Purpose</h2>
              <p className="p-medium">
                At Skyewise Group, we advance economic growth and financial
                opportunity.
              </p>
              <p>
                Our Mission is to provide a wide range of services to
                individuals and corporate bodies across the globe.
              </p>

              <p>
                Our purpose comes to life through our seven core values: Client
                Service, Excellence, Integrity, Partnership, Transparency,
                Sustainability, and time-bound service delivery.
              </p>
            </div>
          </div>
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text"></div>
            <a
              href="#our-values"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Our Values</p>
              <p className="is-icon"></p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPurpose;
