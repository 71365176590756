import React from 'react';

const Input = ({ name, label, placeholder, inputIcon, error, ...rest }) => {
  return (
    <div>
      <div className="s-form__input">
        <div className="s-form__input__con">
          <label className="s-form__input-label" htmlFor={name}>
            {label}
          </label>
          <div className="is-icon s-form__icon">
            <p>{inputIcon}</p>
          </div>
        </div>
        <input
          className="s-form__input__input w-input"
          {...rest}
          name={name}
          id={name}
          placeholder={placeholder}
        />
      </div>
      {error && (
        <div className="s-form-alert s-form-alert--is-error">{error}</div>
      )}
    </div>
  );
};

export default Input;
