import React, { Fragment, useContext, useEffect, useState } from 'react';

import LoanServices from '../../../services/loanService';
import InvestmentServices from '../../../services/investmentService';
import authService from '../../../services/authService';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import progressHandler from '../../../utils/progressHandler';
import TitleH4Devider from '../../common/titleH4withDevider';
import UserLoanHistory from './userCreditLoanRecord';
import UserInvestmentHistory from './userCreditInvestmentRecord';
import ProgressRender from '../../progressRender';

const UserCreditRecords = (props) => {
  const [hasLoanRecords, setHasLoanRecords] = useState(false);
  const [hasInvestmentsRecords, setHasInvestmentsRecords] = useState(false);

  const [loanPageSize, setLoanPageSize] = useState(2);
  const [investmentPageSize, setInvestmentPageSize] = useState(2);

  const [userLoanRecords, setUserLoanRecords] = useState(null);
  const [userInvestmentRecords, setUserInvestmentRecords] = useState(null);

  const currentUser = authService.getUVDALocal();

  useEffect(() => {
    // Initialize Lottie
    initProgressLotties();

    async function getUserCreditRecords() {
      const currentUser = authService.getUVDALocal();

      // if (
      //   currentUser &&
      //   currentUser.currentLoan &&
      //   currentUser.loanState !== "none"
      // ) {
      // Show UI Loading
      progressHandler.showProgress('credit-record', 'loading');

      try {
        setHasLoanRecords('loading');
        setHasInvestmentsRecords('loading');

        // const userLoans = await LoanServices.getLoanQuery({
        //   user: currentUser._id,
        //   limit: loanPageSize,
        // });
        const userLoans = await LoanServices.getLoanQuery({
          user: currentUser._id,
          limit: loanPageSize,
        });

        const { data: loanData } = userLoans.data.data;

        if (loanData.length < 1) {
          setHasLoanRecords(false);
        } else {
          setHasLoanRecords(true);
        }

        const userInvestment = await InvestmentServices.getInvestmentQuery({
          user: currentUser._id,
          limit: investmentPageSize,
        });

        const { data: investmentData } = userInvestment.data.data;

        if (investmentData.length < 1) {
          setHasInvestmentsRecords(false);
        } else {
          setHasInvestmentsRecords(true);
        }

        setUserLoanRecords(loanData);
        setUserInvestmentRecords(investmentData);

        progressHandler.hideProgress('credit-record', 'loading');
      } catch (ex) {
        // console.log(ex.response);
        if (ex.response) {
          let msg = '';

          if (typeof ex.response.data === 'string') {
            msg += msg + ex.response.data;
          }

          if (typeof ex.response.data === 'object') {
            msg += msg + ex.response.data.message;
          }

          // hide UI Loading, show Error
          progressHandler.hideProgress('credit-record', 'loading');
          progressHandler.showProgress('credit-record', 'error', msg);
        } else {
          progressHandler.hideProgress('credit-record', 'loading');
          progressHandler.showProgress(
            'credit-record',
            'error',
            'An error occurred, please try again later. This is not from you.'
          );
        }

        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.email = ex.response.data;
          this.setState({ errors });
        }
      }
      // }
    }
    getUserCreditRecords();
    // }
  }, []);

  return (
    <Fragment>
      <div className="psuedo-pos--relative">
        <ProgressRender
          elData={{
            loading: {
              name: 'credit-record-progress__loading',
              size: 'small',
              transparency: true,
            },
            success: {
              name: 'credit-record-progress__success',
              size: 'small',
              transparency: true,
            },
            error: {
              name: 'credit-record-progress__error',
              size: 'small',
              transparency: true,
            },
          }}
        />

        <div className="credit__history">
          <TitleH4Devider title={'Investments'} />

          {!hasInvestmentsRecords && (
            <p className="psuedo psuedo-txt">No Investment records</p>
          )}
          {hasInvestmentsRecords == 'loading' && <p className="psuedo-txt"></p>}
          {hasInvestmentsRecords && userInvestmentRecords && (
            <div>
              {' '}
              {userInvestmentRecords.map((record, k) => (
                <UserInvestmentHistory historyData={record} key={k} />
              ))}
              <button
                className="alt-btn btn-link--is-blue w-inline-block"
                onClick={() => {
                  props.onDashbordChange('all-investment-records');
                }}
              >
                <p>Show all investments</p>
              </button>
            </div>
          )}
        </div>

        <div className="credit__history">
          <TitleH4Devider title={'Loans'} />

          {!hasLoanRecords && <p className="psuedo-txt">No Loan records</p>}
          {hasLoanRecords == 'loading' && <p className="psuedo-txt"></p>}

          {hasLoanRecords && userLoanRecords && (
            <div>
              {userLoanRecords.map((record, k) => (
                <UserLoanHistory historyData={record} key={k} />
              ))}
              <button
                onClick={() => {
                  props.onDashbordChange('all-loan-records');
                }}
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Show all loans</p>
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UserCreditRecords;
