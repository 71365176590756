import React from "react";

const TravelAndTourFeaturedService = (props) => {
  return (
    <div className="section page__exit__cta__section wf-section">
      <div className="container scroll-end__container container--np-top ">
        <div className="featured-service">
          <div className="featured-service__grid">
            <div className="featured-service-grid__col featured-service">
              {/* <div className="c-rich-text"> */}
              <h3 className="featured-service__headline">
                Study Visa Procurement{" "}
              </h3>
              <p className="featured-service__detail">
                Affiliation with US, UK, Canada
              </p>
              {/* </div> */}

              <div className="glue-grid featured-service__affiliate">
                <div className="glue-grid__item">
                  <img
                    alt="Affiliate"
                    src="/content/images/travels-and-tour/affiliates/canada-b.png"
                  />
                </div>

                <div className="glue-grid__item">
                  <img
                    alt="Affiliate"
                    src="/content/images/travels-and-tour/affiliates/uk-a.png"
                  />
                </div>

                <div className="glue-grid__item">
                  <img
                    alt="Affiliate"
                    src="/content/images/travels-and-tour/affiliates/us-a.png"
                  />
                </div>
              </div>

              {/* <a
                className="s-btn is-btn--light s-btn--inline featured-service__cta"
                href="#"
              >
                <p>Learn More</p>
              </a> */}
            </div>

            <div className="featured-service-grid__col">
              <div className="featured-service__interstitial">
                <img
                  className="featured-service__interstitial--photo"
                  src="/content/images/travels-and-tour/lady-studying-1080.jpg"
                  alt="Study visa procurement, affiliation with US, UK, Canada"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelAndTourFeaturedService;
