import React, { Fragment, useLayoutEffect } from 'react';
import Footer from '../../common/footer/footer';
import Navbar from '../../common/navbar/navbar';
import restartWebflowScript from '../../common/restartWebflowScript';
import LRHB from './businessLoanRequirementsHeader';
import LRBB from './businessLoanRequirementsBody';

const BLR = (props) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName('html')[0];
    // htmlEl.setAttribute('data-wf-page', '61e539b9a949f4a1ef4bd375');
  });

  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <LRHB />
      <LRBB />
      <Footer />
    </Fragment>
  );
};

export default BLR;
