import React from "react";

function Slide(props) {
  return (
    <div
      data-w-id="da700eed-4ca2-9a0f-1f5f-910d3f1d96b6"
      className="hero-slider__slide hero-slider__slide--01 w-slide"
    >
      <div className="overlay--dark overlay--dark--65deg"></div>
      <div className="container hero-slider__container">
        <div className="slide__content">
          <h1 className="slide__content__title">Be the Architect</h1>
          <p className="slide__content__desc">
            Skyewise Cooperative gives you access to loans and other funding
            opportunity to support your business.
          </p>
          <div className="slide__cta">
            <a href="#" className="s-btn is-btn--light w-inline-block">
              <p>Invest Today</p>
              <p className="is-icon"></p>
            </a>
            <a
              data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
              href="#"
              target="_blank"
              className="alt-btn is-btn--alt w-inline-block"
            >
              <div className="alt-btn__wrp">
                <p>Investment Plans</p>
                <p className="is-icon"></p>
              </div>
              <div className="alt-btn__underline is-white__underline"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slide;
