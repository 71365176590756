import React from "react";

const CultureAndInnovationHeader = (props) => {
  return (
    <div className="full-screen-hero-v2 wf-section">
      <div className="full-height-hero__image-container full-height-hero__image-container--width">
        <picture
          className="responsive-image full-height-hero__picture lazy-loader lazy-loader--loaded full-height-hero__picture--width"
          data-load-module="LazyLoader"
          data-module-loaded="true"
          data-lazyloader-id="_kzk1cwouq281w"
        >
          <source
            media="(max-width: 979px)"
            srcSet="/content/images/community/community-1266x768.jpeg"
            data-lazy-src="/content/images/community/community-1266x768.jpeg"
            className="lazy-loader__asset"
          />
          <img
            src="/content/images/community/community-1266x768.jpeg"
            alt=""
            className="full-height-hero__image lazy-loader__asset lazy-loader__asset--loaded full-height-hero__image--width"
            data-lazy-src="/content/images/community/community-1266x768.jpeg"
            role="presentation"
          />
        </picture>
      </div>
      <div className="container container--no-space-buttom  container--full-height">
        <div className="full-screen-hero-v2__interior">
          <div className="full-screen-hero-v2__interior__content">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h4>Discover Skyewise Group</h4>
                  <p>
                    Skyewise Group is a diversified integrated conglomerate to
                    provide world investment opportunities and services for
                    individuals and corporate citizens of all nations.
                  </p>
                </div>
                <a
                  data-w-id="0c9cf166-a444-764c-94b8-e468009fc476"
                  href="#"
                  target="_blank"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Learn more about what we do</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CultureAndInnovationHeader;
