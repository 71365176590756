import React, { Fragment } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import Navbar from "../common/navbar/navbar";
import OurPeopleHeader from "./ourPeopleHeader";
import OurPeopleBodyContent from "./ourPeopleBodyContent";
import Footer from "../common/footer/footer";
import ChatAndNavHelperBtn from "../common/chatAndNavHelperOpen";

const OurPeople = () => {
  restartWebflowScript();
  return (
    <Fragment>
      <Navbar />
      {/* <ChatAndNavHelperBtn /> */}
      <OurPeopleHeader />
      <OurPeopleBodyContent />
      <Footer />
    </Fragment>
  );
};

export default OurPeople;
