import React, { Fragment, useLayoutEffect } from "react";
import restartWebflowScript from "../../common/restartWebflowScript";

import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import SWLH from "./header";
import SWL from "./body";

const SWPLPage = (props) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f487f14bd371");
  });
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <SWLH />
      <SWL />
      <Footer />
    </Fragment>
  );
};

export default SWPLPage;
