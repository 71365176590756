import React from "react";
import { logout } from "../../services/authService";

const NavItem = (props) => {
  const { navProps } = props;

  return (
    <li
      onClick={() => {
        //   Log User out if Logout is clicked

        if (navProps.navLabel === "Logout") {
          logout();
          return;
        }
        navProps.onNavClick(navProps.navDashboard);
      }}
      className={` user-credit-dash__nav__item hover--scale ${
        navProps.currentDasboard === navProps.navDashboard
          ? "credit-dash__nav--current"
          : ""
      } `}
    >
      <a href="#" className="car-sort__nav__link w-inline-block">
        <div className="is-icon side-nav__nav__link__icon">
          <p>{navProps.navIcon}</p>
        </div>
        <div>
          <p>{navProps.navLabel}</p>
        </div>
      </a>
    </li>
  );
};

export default NavItem;
