import {
  setBlogFeaturedMedia,
  getBlogQuery,
} from '../../../services/blogService';
import SubsectionTitleWIthFlip from '../UiLayoutsAndComponents/subsectionTItleWithFlip';
import HrDevider from '../hrDevider';
import { useState, useEffect, useRef } from 'react';
import BlogUpdateCard from './blogUpdateCard';
import ProgressRender from '../../progressRender';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import progressHandler from '../../../utils/progressHandler';
import SocialHighlights from './socialHighlights';
import temBlogPostData from './blogDataSample.json';
import checkPostReadyState from '../../../utils/remoteDataStateReadyRetry';

const postUISettings = {
  maxPostDisplay: 4,
};

const BlogUpdate = (props) => {
  // Innitialize post counter limit
  let postDisplayCount = 0;

  const blogPostLocal = JSON.parse(localStorage.getItem('recentBlogPosts'));
  const [blogPostsState, setBlogPostsState] = useState(null);

  useEffect(() => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('blog-update', 'loading');

    // Blog Posts Render - Steps
    // 1. Check if blog posts are ready for render
    // if (blogPostLocal === null) {
    //   console.log('Blog posts not set');

    //   let numRetries = 0,
    //     retryInterval = 2000;

    //   // 2. Check if the minimum post available locally is equal to or above 4
    //   const postCheckRetry = setInterval(() => {
    //     numRetries += 1;
    //     retryInterval += 2000;

    //     // console.log(blogPostLocal === null, `${retryInterval}s retry`);

    //     console.log(numRetries, 'Retry');

    //     const blogPostLocalRec = JSON.parse(
    //       localStorage.getItem('recentBlogPosts')
    //     );

    //     if (blogPostLocalRec !== null && blogPostLocalRec.length >= 4) {
    //       setBlogPostsState(new Date().now);
    //       clearInterval(postCheckRetry);
    //       return;
    //     }

    //     if (numRetries === 8) {
    //       clearInterval(postCheckRetry);
    //       console.log(
    //         'Failed to fetch post after maximum retry at: ',
    //         retryInterval + 'MS'
    //       );
    //     }
    //   }, retryInterval);
    // }

    checkPostReadyState(
      blogPostLocal,
      'recentBlogPosts',
      2000,
      0,
      setBlogPostsState
    );

    if (blogPostLocal && !blogPostsState) setBlogPostsState(blogPostLocal);
  });

  return (
    <div className="section wf-section">
      <div className="container">
        <SubsectionTitleWIthFlip
          props={{
            title: 'News',
            text: 'Latest news from Skyewise Group',
            link: 'blog.skyewisegroup.com',
            linkText: 'Visit Blog',
          }}
        />

        <div className="sw-blog-update _4-col-st-grid">
          {/* UI blog data fetch progress */}
          {!blogPostsState && (
            <div className="progress-loader-wrapper--gray">
              <ProgressRender
                elData={{
                  loading: {
                    name: 'blog-update-progress__loading',
                    transparency: true,
                  },
                  success: { name: 'blog-update-progress__success' },
                  error: { name: 'blog-update-progress__error' },
                }}
              />
            </div>
          )}

          {/* Render Blog UI when data is set */}
          {blogPostsState &&
            blogPostsState.map((blogPost, i) => {
              // Keep track of post index
              postDisplayCount += 1;

              // Limit UI posts to 4
              if (postDisplayCount <= postUISettings.maxPostDisplay) {
                return <BlogUpdateCard key={i} postData={blogPost} />;
              }

              if (postDisplayCount >= postUISettings.maxPostDisplay) {
                // Exit post render intteration on post UI limit
                return;
              }

              // Reset post display counter
              postDisplayCount = 0;

              // Render UI Blog Post
            })}
        </div>

        <HrDevider />

        <SocialHighlights />
      </div>
    </div>
  );
};

export default BlogUpdate;
