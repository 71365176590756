import React from "react";
const TitleH4Devider = (text) => {
  return (
    <div className="section-title-dvdr">
      <span className="section-title-dvdr--dvdr dvdr--mini"></span>
      <h4 className="section-title-dvdr--title">{text.title}</h4>
      <span className="section-title-dvdr--dvdr"></span>
    </div>
  );
};

export default TitleH4Devider;
