import React, { useEffect } from 'react';
import restartWebflowScript from '../common/restartWebflowScript';
import NavBar from '../common/navbar/navbar';

import Footer from '../common/footer/footer';
import PageExitCTA from '../common/pageExitCTA';

import StaticHeader from '../common/UiLayoutsAndComponents/staticHeaderOne';

import AutoPageSubHeading from './automobilePageSubTitle';
import AutoSideNav from './automobileSideNavLinks';
import AutoSideNavTitle from './automobileSideNavTitle';
import { useState } from 'react';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCar } from '../../services/carService';
import ManageCarListingForm from './manageCarListingForm';
import CarImagePreview from './manageCarListingImagePreview';
import { ActionGroup, Item, Text } from '@adobe/react-spectrum';
import Image from '@spectrum-icons/workflow/Image';
import ImageAlbum from '@spectrum-icons/workflow/ImageAlbum';

const ManageCarListing = () => {
  // useEffect(() => {
  //   var htmlEl = document.getElementsByTagName("html")[0];
  //   htmlEl.setAttribute("data-wf-page", "61c2e2d14d436da4d3382185");
  // });

  const [car, setCar] = useState(null);

  const carCached = useMemo(() => car, [car]);

  const { id: carId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!car) {
          const { data } = (await getCar(carId)).data.data;

          setCar(data);
        }
      } catch (e) {
        // console.log(e);
      }
    })();
  });

  restartWebflowScript();

  let manageCarImgUrl;

  carCached
    ? (manageCarImgUrl = `/automobile/listing/${carCached.id}?action=`)
    : null;

  return (
    <div>
      <NavBar />

      <StaticHeader
        pageData={{
          titleText: carCached
            ? `${carCached.make} ${carCached.model} ${carCached.year}`
            : 'Manage this car',
          altTitle: 'Manage car details',
        }}
      />

      <div className="car-listings__grid-wrapper section wf-section">
        <div className="container ">
          <div className="car-listings__grid-wrapper">
            <div className="car-listings__cars-nav-grod">
              <AutoSideNavTitle data={{ titleText: 'Navigation' }} />

              <div>
                <AutoPageSubHeading
                  data={{
                    headingText: carCached
                      ? `${carCached.make} ${carCached.model} ${carCached.year} `
                      : 'Car details',
                  }}
                />
              </div>

              <div className="car-listings__car-sort flex_order--03">
                <AutoSideNav
                  data={[
                    {
                      linkTxt: 'All cars',
                      linkIcon: '',
                      linkAddress: '/automobile/cars',
                    },
                  ]}
                />
              </div>
              <div className="car-listings__block flex_order--04">
                <h4>Car image gallery</h4>

                <div className="mb-6">
                  <ActionGroup
                    onAction={(key) => {
                      navigate(manageCarImgUrl + key);
                    }}
                  >
                    <Item key="featuredImage">
                      <Image />
                      <Text>Update cover photo</Text>
                    </Item>
                    <Item key="images">
                      <ImageAlbum />
                      <Text>Update all car images</Text>
                    </Item>
                  </ActionGroup>
                </div>

                <div className="">
                  {carCached && (
                    <CarImagePreview
                      images={[carCached.imageCover, ...carCached.images]}
                    />
                  )}
                </div>
                {carCached && (
                  <div>
                    <h4>Update car details</h4>
                    <ManageCarListingForm car={carCached} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* GLoable Trends Here */}
      {/* <FeaturedCars /> */}
      {/* <Trends /> */}
      <PageExitCTA />
      <Footer />
    </div>
  );
};

export default ManageCarListing;
