import React from 'react';
import restartWebflowScript from '../common/restartWebflowScript';
import NavBar from '../common/navbar/navbar';
import ContactHeader from './contactHeader';
import ContactFormSection from './contactFormSection';
import Footer from '../common/footer/footer';
import ChatAndNavHelperOpen from '../common/chatAndNavHelperOpen';
import ContactDetials from './contactContactDetials';
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
  restartWebflowScript();
  return (
    <div>
      <Helmet>
        <title>Contact us | Skyewise Group</title>
        <meta
          name="description"
          content="Contact information for all our branches and offices | Skyewise Group"
        />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <NavBar />
      {/* <ChatAndNavHelperOpen /> */}
      <ContactHeader />
      <ContactDetials />
      <ContactFormSection />
      <Footer />
    </div>
  );
};

export default ContactPage;
