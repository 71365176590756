import React from "react";
import StepsIndicator from "../../common/stepsIndicator";

const loanProgressHint = (props) => {
  return (
    <StepsIndicator
      CurrentSteps={[
        {
          desc: "Provide your loan detials",
          stepName: "none",
        },

        {
          desc: "Loan collateral",
          stepName: "collateral",
        },

        {
          desc: "Processing your request...",
          stepName: "pending",
        },

        {
          desc: "Approval",
          stepName: "approved",
        },
      ]}
      stepType="loanState"
    />
  );
};

export default loanProgressHint;
