import React from 'react';

const MTCEOs2022Intro = (props) => {
  return (
    <section className="wf-section ">
      <div className="container">
        <div className="c-rich-text">
          <h2> As part of our commitment to raising young entrepreneurs</h2>
          <p>
            Skyewise Entrepreneurship Summit (Meet The CEOs) is aimed at
            providing participants with shared, practical knowledge &
            experiences of leaders and successful entrepreneurs in Nigeria and
            across Africa
          </p>

          {/* <img
            className="glue-quote__quote-image"
            src="/images/content/seaf-foundation/mtceo-backdrop-02.jpg"
          /> */}
        </div>
      </div>
    </section>
  );
};

export default MTCEOs2022Intro;
