import React from "react";

const KYCGlueTips = (props) => {
  return (
    <div className="glue-tip">
      <div className="glue-tip__wrap">
        <ul className="glue-tip__tips _3-col-grid">
          <li className="glue-tip__tip">
            <div className="glue-tip__tip__thumb ">
              <img
                className="glue-tip__tip__thumb__img"
                src="/images/temp/finance.jpeg"
              ></img>
            </div>
            <div className="glue-tip__tip__details">
              <h4 className="glue-tip__tip__details__heading">Investment</h4>

              <p className="glue-tip__tip__details__desc">
                Investment and manage your investment from your dashboard
              </p>
            </div>
          </li>

          <li className="glue-tip__tip">
            <div className="glue-tip__tip__thumb">
              <img
                className="glue-tip__tip__thumb__img"
                src="/images/temp/woman-smilling-on-phone.jpeg"
              ></img>
            </div>
            <div className="glue-tip__tip__details">
              <h4 className="glue-tip__tip__details__heading">Loan</h4>

              <p className="glue-tip__tip__details__desc">
                Easily request for loan when you need on through your personal
                account
              </p>
            </div>
          </li>

          <li className="glue-tip__tip">
            <div className="glue-tip__tip__thumb  ">
              <img
                className="glue-tip__tip__thumb__img"
                src="/images/temp/support.jpeg"
              ></img>
            </div>
            <div className="glue-tip__tip__details">
              <h4 className="glue-tip__tip__details__heading">Support</h4>

              <p className="glue-tip__tip__details__desc">
                Get support at any time on loan, investments, automobiles, or
                account.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default KYCGlueTips;
