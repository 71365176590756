import React, { useEffect } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import NavBar from "../common/navbar/navbar";

import Footer from "../common/footer/footer";
import ChatAndNavHelperOpen from "../common/chatAndNavHelperOpen";
import PageExitCTA from "../common/pageExitCTA";
import AboutSkyewiseAutos from "./automobileHomeAbout";
import AutoBrandsLogos from "./autosLogoGrid";
import getBrands from "./autoBrands";
import AutoButtonGridCta from "./autoButtonGridCta";
import { Link } from "react-router-dom";

const Brands = getBrands();

const Automobile = () => {
  // useEffect(() => {
  //   var htmlEl = document.getElementsByTagName("html")[0];
  //   htmlEl.setAttribute("data-wf-page", "61c2e2d14d436da4d3382185");
  // });

  restartWebflowScript();

  return (
    <div>
      <NavBar />
      <section className="cl-hero wf-section">
        <div className="cl-hero__image-wrap">
          <div
            data-delay="7000"
            data-animation="fade"
            className="cl-hero__slider w-slider"
            data-autoplay={true}
            data-easing="ease"
            data-hide-arrows="false"
            data-disable-swipe="false"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="true"
          >
            <div className="w-slider-mask">
              <div
                data-w-id="0be0587e-ef81-8d94-1c08-08e86d245993"
                className="cl-hero__slider__slide w-slide slider__auto-welcome"
              >
                <div className="overlay--dark"></div>
                <div className="container cl-hero__container">
                  <div className="slide__content">
                    <div className="c-rich-text">
                      <h1
                        // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                        className="slide__content__title"
                      >
                        Welcome To Skyewise Automobiles
                      </h1>
                      <p
                        // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                        className="slide__content__desc"
                      >
                        If it's a car, you are at the right place.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="b13b6b7c-d2d5-93b9-eefa-45d78065b9d4"
                className="cl-hero__slider__slide w-slide slider__auto-onboard"
              >
                <div className="overlay--dark"></div>
                <div className="container cl-hero__container">
                  <div className="slide__content">
                    <div className="c-rich-text">
                      <h1
                        // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                        className="slide__content__title"
                      >
                        Expect the very best
                      </h1>
                      <p
                        // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                        className="slide__content__desc"
                      >
                        Choose from an exceptional selection of new, used and
                        certified pre-owned cars
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden--el w-slider-arrow-left">
              <div className="w-icon-slider-left"></div>
            </div>
            <div className="hidden--el w-slider-arrow-right">
              <div className="w-icon-slider-right"></div>
            </div>
            <div className="hidden--el w-slider-nav w-round"></div>
          </div>
        </div>
      </section>
      <div className="_1-col-c-center gray-bg-01 wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h2>Explore Skyewise Autos</h2>
                <p>
                  Skyewise Autos is a popular car dealer in Garki, Abuja and was
                  incorporated in 2017 offer diverse automobiles services
                  ranging from auto sales, maintenance services and procurement
                  services to discerning clients abound in the FCT and Nigeria
                  in general.
                </p>
              </div>
              <a
                href="/automobile/cars"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Shoping Center</p>
              </a>

              <AutoButtonGridCta />
            </div>
          </div>
        </div>
      </div>
      <div className="wf-section">
        <div className="container">
          <div className="_3-col-grid">
            <div className="onboard-waiver">
              <div
                className="onboard-waiver__img
    "
              >
                {" "}
                <img src="/images/icons/swa_onboardWaiver1.svg" alt="" />
              </div>
              <div className="onboard-waiver__txt">
                <div className="c-rich-text">
                  <h3>Incredible vehicle inventory</h3>
                  <p>
                    Choose from an exceptional selection of new, used and
                    certified pre-owned cars.
                  </p>
                </div>{" "}
              </div>
            </div>

            <div className="onboard-waiver">
              <div
                className="onboard-waiver__img
    "
              >
                {" "}
                <img src="/images/icons/swa_onboardWaiver3.svg" alt="" />
              </div>
              <div className="onboard-waiver__txt">
                <div className="c-rich-text">
                  <h3>Buying with ease & peace of mind</h3>
                  <p>
                    We know purchasing a vehicle is a big decision and can be
                    very stressful. We strive to help you through this process
                    making it as easy and stress-free as possible.
                  </p>
                </div>{" "}
              </div>
            </div>
            <div className="onboard-waiver">
              <div
                className="onboard-waiver__img
    "
              >
                <img src="/images/icons/swa_onboardWaiver2.svg" alt="" />
              </div>
              <div className="onboard-waiver__txt">
                <div className="c-rich-text">
                  <h3>Upgrade your car</h3>
                  <p>
                    All cars are good, but some suit better. Upgrade your car
                    conveniently at any time, with Skyewise Autos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-3 wf-section">
        <div className="container container--no-space-top">
          <div className="div-block-94">
            <div className="st-b-radius">
              <div className="grid-cc-large">
                <div className="wrrpr">
                  {/* <img
                    src="images/speaking2x.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 82vw, (max-width: 767px) 89vw, (max-width: 991px) 45vw, (max-width: 1919px) 41vw, 100vw"
                    srcSet="images/speaking2x-p-500.png 500w, images/speaking2x-p-800.png 800w, images/speaking2x-p-1080.png 1080w, images/speaking2x-p-1600.png 1600w, images/speaking2x.png 1920w"
                    alt=""
                    className="img-100-fit"
                  /> */}

                  <img
                    src="/images/bg/auto-geely-11031.jpg"
                    loading="lazy"
                    alt=""
                    className="img-100-fit"
                  />
                  <div className="overlay--dark"></div>
                  <div className="div-block-83">
                    <h3>View Our Inventory</h3>
                    <Link
                      to="/automobile/cars"
                      className="alt-btn iswhite w-inline-block"
                    >
                      <p>New, used, and certified pre-owned cars</p>
                      <p className="is-icon"></p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="st-b-radius">
              <div className="grid-cc-large">
                <div className="wrrpr">
                  {/* <img
                    src="images/speaking2x.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 82vw, (max-width: 767px) 89vw, (max-width: 991px) 45vw, (max-width: 1919px) 41vw, 100vw"
                    srcSet="images/speaking2x-p-500.png 500w, images/speaking2x-p-800.png 800w, images/speaking2x-p-1080.png 1080w, images/speaking2x-p-1600.png 1600w, images/speaking2x.png 1920w"
                    alt=""
                    className="img-100-fit"
                  /> */}
                  <img
                    src="images/bg/business-13232.jpg"
                    loading="lazy"
                    alt=""
                    className="img-100-fit"
                  />

                  <div className="overlay--dark"></div>
                  <div className="div-block-83">
                    <h3>Book an appointment</h3>
                    <Link
                      to="/contact/appointment"
                      className="alt-btn iswhite w-inline-block"
                    >
                      <p>Schedule a test drive today</p>
                      <p className="is-icon"></p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="is-bg--gray-01 wf-section">
        <div className="container container--small-top-btoom">
          <div>
            <p className="p-special">
              Automobiles have always been part of my life, and I'm sure they
              always will be. What is it about them that moves me? The sound of
              a great engine, the unity and uniqueness of an automobile's
              engineering and coachwork, the history of the company and the car,
              and, of course, the sheer beauty of the thing.
            </p>
          </div>
          <AutoBrandsLogos brands={Brands} />
        </div>
      </div>

      <AboutSkyewiseAutos />

      <PageExitCTA />
      <Footer />
    </div>
  );
};

export default Automobile;
