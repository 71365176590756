import React, { Fragment, Component } from 'react';
import KYCPageHeader from './kycPageHeader';
import Navbar from '../../common/navbar/navbar';
import Footer from '../../common/footer/footer';
import KYCFormSection from './kycFormSection';
import KycProgressHint from './kycStepsHint';
import restartWebflowScriptClassComponents from '../../common/restartWebflowScriptClassConponents';

class KYCVerification extends Component {
  componentDidMount() {
    restartWebflowScriptClassComponents();
  }

  render() {
    return (
      <Fragment>
        <Navbar />
        <KYCPageHeader />
        <KycProgressHint />
        <KYCFormSection />
        <Footer />
      </Fragment>
    );
  }
}

export default KYCVerification;
