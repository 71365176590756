import React, { Component } from 'react';
import Joi, { errors } from 'joi-browser';
import Input from './input';
import Select from './select';
import CheckBox from './checkBox';
import { Button, ProgressCircle } from '@adobe/react-spectrum';
import TextArea from './textArea';

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    // e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  renderButton(label, ctaActionState, variant = 'accent') {
    return (
      <div className="s-form__cta">
        {/* <button disabled={this.validate()}> */}
        <Button
          isDisabled={this.validate()}
          variant={variant}
          isPending={ctaActionState}
          onPress={this.handleSubmit}
        >
          {label}
        </Button>
        {/* </button> */}
      </div>
    );
  }

  renderSelect(name, label, options, inputIcon) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        inputIcon={inputIcon}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, type = 'text', placeholder, inputIcon) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        placeholder={placeholder}
        error={errors[name]}
        inputIcon={inputIcon}
      />
    );
  }

  renderTextArea(name, label, placeholder, inputIcon) {
    const { data, errors } = this.state;

    return (
      <TextArea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        placeholder={placeholder}
        error={errors[name]}
        inputIcon={inputIcon}
      />
    );
  }

  renderCheckBox(name, label, type = 'checkbox', placeholder, inputIcon) {
    const { data, errors } = this.state;

    return (
      <CheckBox
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        placeholder={placeholder}
        error={errors[name]}
      />
    );
  }
  // checkBox;

  renderFile(name, label, type = 'text', placeholder, inputIcon) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        placeholder={placeholder}
        error={errors[name]}
        inputIcon={inputIcon}
      />
    );
  }
}

export default Form;
