import React, { Fragment } from 'react';
import VideoPlayerModal from '../../common/videoPlayerModal';
import {
  closePlayer,
  openPlayer,
} from '../../../utils/videoPlayerModalControls';
import FoundationLocalNav from './../foundationLocalNav';
import PlayerModalPlayBtn from '../../common/videoPlayerModalPlayBtn';
import { Link } from 'react-router-dom';

const MeetTheCEOs2022 = (props) => {
  return (
    <div>
      <div className="hero-style-fs wf-section">
        <div
          data-delay="7000"
          data-animation="fade"
          className="hero-style-fs__slider w-slider"
          data-autoplay={true}
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="true"
        >
          <div className="w-slider-mask">
            <div
              data-w-id="87a7765b-008b-9b1c-b48c-7db7b2e2aad1"
              className="hero-style-fs__slider__slide w-slide"
            >
              <div className="hero-style-fs__slider__slide__img-container">
                <img
                  src="/images/content/seaf-foundation/mtceos2022/meet-the-ceos-2022-cover-01.webp"
                  loading="lazy"
                  sizes="100vw"
                  // srcSet="images/speaking2x-p-500.png 500w, images/speaking2x-p-800.png 800w, images/speaking2x-p-1080.png 1080w, images/speaking2x-p-1600.png 1600w, images/speaking2x.png 1920w"
                  alt=""
                  className="hero-style-fs__slider__slide-image"
                />
              </div>
              <div className="overlay--dark"></div>
              <div className="hero-style-fs__slider__slide-content">
                <div className="container container--flex hero-style-fs__slider__container">
                  <div className="hero-style-fs__slider__grid">
                    <div
                      // style="opacity:0"
                      className="hero-style-fs__slider__slide-content--main"
                    >
                      <div className="c-rich-text">
                        <h2 className="hero-style-fs__slider__slide-heading">
                          Meet The CEOs 2022
                        </h2>
                        <p className="hero-style-fs__slider__slide-paragraph">
                          Powered by Skyewise Foundation in Partnership with
                          Skyewise Group
                        </p>
                      </div>
                    </div>
                    <a
                      href="https://bit.ly/meet-the-ceos-20"
                      target={'_blank'}
                      id="w-node-_827fcf9d-9921-95e4-e4a9-26c52e68d59e-224bd367"
                      className="hero-style-fs__slider__slide__aside"
                    >
                      <div
                        className="slide-aside__thumbnail
                       hero-style-fs__slider__slide__aside-text                        "
                      >
                        <div
                          className={'player-modal__play-btn' + ' skin--light'}
                        >
                          <span className="is-fa-icon--300"></span>
                        </div>
                      </div>
                      <div
                        // style="opacity:0"
                        className="hero-style-fs__slider__slide__aside-text"
                      >
                        <p className="is-txt__small">FREE Entry</p>
                        <p className="c-h4">Register now</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* 
           <div
              data-w-id="87a7765b-008b-9b1c-b48c-7db7b2e2aad1"
              className="hero-style-fs__slider__slide w-slide"
            >
              <div className="hero-style-fs__slider__slide__img-container">
                <img
                  src="images/speaking2x.png"
                  loading="lazy"
                  sizes="100vw"
                  srcSet="images/speaking2x-p-500.png 500w, images/speaking2x-p-800.png 800w, images/speaking2x-p-1080.png 1080w, images/speaking2x-p-1600.png 1600w, images/speaking2x.png 1920w"
                  alt=""
                  className="hero-style-fs__slider__slide-image"
                />
              </div>
              <div className="overlay--dark"></div>
              <div className="hero-style-fs__slider__slide-content">
                <div className="container container--flex hero-style-fs__slider__container">
                  <div className="hero-style-fs__slider__grid">
                    <div
                      // style="opacity:0"
                      className="hero-style-fs__slider__slide-content--main"
                    >
                      <div className="c-rich-text">
                        <h2 className="hero-style-fs__slider__slide-heading">
                          Stand out as an entrepreneur
                        </h2>
                        <p className="hero-style-fs__slider__slide-paragraph">
                          Discuss, learn, and tap into the experiences of great
                          entrepreneurs.
                        </p>
                      </div>
                    </div>
                    <div
                      id="w-node-_827fcf9d-9921-95e4-e4a9-26c52e68d59e-224bd367"
                      className="hero-style-fs__slider__slide__aside"
                    >
                      <div
                        // style="opacity:0"
                        className="hero-style-fs__slider__slide__aside-text"
                      >
                        <p className="is-txt__small">Upcoming Event - Date</p>
                        <p className="c-h4">2022</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  */}
          </div>
          <div className="display__none w-slider-arrow-left">
            <div className="w-icon-slider-left"></div>
          </div>
          <div className="display__none w-slider-arrow-right">
            <div className="w-icon-slider-right"></div>
          </div>
          {/* <div className="st-slider-dots w-slider-nav w-round"></div> */}
        </div>
      </div>

      {/* <FoundationLocalNav /> */}

      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h2>MEET THE CEOS 2.0</h2>
                <p>
                  Understanding Personal Development - Learning, Enhancing, and
                  Implementing
                </p>
              </div>
              <a
                href="#seaf-foundation-projects"
                target="_blank"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                {/* <p>Projects</p> */}
              </a>
            </div>
          </div>

          {/* <div className="_2-col-st-grid is-txt--centered is-mt-medium ">
            <div className="our-mission">
              <div className="c-rich-text">
                <h4>VISION</h4>
                <p className="">
                  To be the leading foundation in Nigeria, empowering fresh
                  graduates, youths, and young entrepreneurs with other sources
                  of wealth creation
                </p>
              </div>
            </div>
            <div className="our-mission">
              <div className="c-rich-text">
                <h4> MISSION</h4>
                <p className="">
                  Raising young people to be self-reliant by reducing
                  unemployment in Nigeria and providing start-ups access to the
                  credit facility
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <VideoPlayerModal
        playerData={{
          playerId: 'playerID-0012',
          videoUrl: 'https://vimeo.com/734981169',
          closePlayer,
        }}
      />

      <VideoPlayerModal
        playerData={{
          playerId: 'p:id-mtceos-keynote',
          videoUrl: 'https://vimeo.com/735380123',
          closePlayer,
        }}
      />
    </div>
  );
};

export default MeetTheCEOs2022;
