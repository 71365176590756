import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import stripHTMLChar from '../../../utils/stringStripHTMLCharacters';
import truncateString from '../../../utils/stringTruncationV2';

function Trend(props) {
  const { postData } = props;

  return (
    <div
      data-w-id="63581b06-d05a-fb6e-5680-16f2bf761b50"
      className="trends__trend w-slide"
    >
      <div className="trends__content__wrp">
        <div className="trends__bg-img">
          <img
            src={postData.featured_media_url}
            loading="lazy"
            // sizes="(max-width: 479px) 82vw, (max-width: 767px) 89vw, (max-width: 991px) 92vw, (max-width: 1919px) 84vw, 100vw"
            // srcSet="images/04-p-500.jpeg 500w, images/04-p-1600.jpeg 1600w, images/04.jpg 1920w"
            alt={postData.title.rendered}
            className="trends__bg-img--img"
          />
          <div className="overlay--dark solid--black"></div>
        </div>
        <div className="trend__content">
          <div className="trend__content__title">
            <h3 className="trend__title text-hover-highlight text-hover-highlight--light">
              <a href={postData.link} target="_blank">
                {truncateString(postData.title.rendered, 55)}
              </a>
            </h3>
            <p className="trend__author is-txt__x-small">
              {new Date(postData.date).toDateString()}
            </p>
          </div>
          <div className="trend__content__desc">
            <div
              //   style="-webkit-transform:translate3d(0, 0, 0) scale3d(null, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(null, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(null, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(null, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
              className="vt-line vt-line--white"
            ></div>
            <div>
              <p
                // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                className="trend__featured__caption text-hover-highlight text-hover-highlight--light"
              >
                <a href={postData.link} target="_blank">
                  {stripHTMLChar(
                    truncateString(postData.excerpt.rendered, 145),
                    ['<p>', '</p>', '<strong>', '</strong>']
                  )}
                </a>
              </p>
              <div className="trend__copy">
                <div>
                  <img
                    src="/images/partners/geely-w.png"
                    loading="lazy"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="/images/partners/mikano-w.png"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trend;
