import React, { Fragment, useLayoutEffect } from "react";
import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import TicketBookingPageHeader from "./ticketBookingHeader";
import BookTicketHomeSection from "./travelAndTicketBookingSection";
import TicketBookingFormSection from "./travelAndTicketBookingFormSection";
import TicketBookingVisitorsTips from "./ticketBookingVisitorsTips";
import restartWebflowScript from "../common/restartWebflowScript";

const TicketBookingPage = (props) => {
  restartWebflowScript();

  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f460034bd35f");
  });

  return (
    <Fragment>
      <Navbar />
      <TicketBookingPageHeader />
      {/* <TicketBookingVisitorsTips /> */}
      <TicketBookingFormSection />
      <Footer />
    </Fragment>
  );
};

export default TicketBookingPage;
