import React from "react";
import ProgressRender from "./progressRender";

const GeneralDataLoadingProgress = (props) => {
  return (
    <ProgressRender
      elData={{
        loading: { name: "gen-data-progress__loading" },
        success: { name: "gen-data-progress__success" },
        error: { name: "gen-data-progress__error" },
      }}
    />
  );
};

export default GeneralDataLoadingProgress;
