import React, { Component } from 'react';

import configData from '../../.../../config.json';
import displayHoverElement from '../../utils/hoverElementDisplay';
import TitleH4Devider from '../common/titleH4withDevider';
import AdminClientVerification from './adminClientComponents/adminClientVerification';
import ClientIdCapcel from './adminClientComponents/clientIdentityCapcel';
import ClientInfoBubbles from './adminClientComponents/clientInfoBubbles';
import ClientKYC from './adminClientComponents/clientKYCInformations';
import AdminClientNotice from './adminClientNotice';

const storageUrl = configData.storageUrl;

class AdminInfoDashboard extends Component {
  state = { dashboard: '' };

  render() {
    const client = this.props.client;
    let bubbleData,
      kycData = [];

    kycData[0] = { file: client.photo, name: 'Photograph', icon: '' };

    client.kycDocuments.map((file, i) => {
      if (file) {
        let cFIle = {
          file: file,
          name: 'ID ' + (i + 1),
          icon: '',
        };

        kycData.push(cFIle);
      }
    });

    if (client) {
      bubbleData = [
        { v: client.email, i: '', k: 'Email' },
        { v: client.phoneNumber, i: '', k: 'Phone Number' },
        { v: client.nationality, i: '', k: 'Nationality' },
        { v: client.city, i: '', k: 'City' },
        { v: client.address, i: '', k: 'Address' },
      ];
    }

    return (
      client && (
        <div className="ud-main">
          <div className="ud-main__title">
            <div className="ud-main__title__user-id">
              <ClientIdCapcel
                client={client}
                updateDashboardLocation={this.props.updateDashboardLocation}
              />
              <AdminClientVerification
                tip={{
                  tipTitle: 'Unverified',
                  tipIcon: '',
                  tipText: 'This account is yet to be verified.',
                }}
              />
            </div>

            <h3 className="ud-main__title__txt">Information</h3>
          </div>
          <AdminClientNotice notice={`${client.name}'s account information`} />
          <ClientInfoBubbles bubbleData={bubbleData} />
          <TitleH4Devider title={'KYC (Passport & ID)'} />

          {/* Client's KYC Verification data */}
          <ClientKYC kycData={kycData} />
        </div>
      )
    );
  }
}

export default AdminInfoDashboard;
