import http from './httpService';
import configData from '../config.json';
import { update } from 'lodash';
import authService from './authService';

const apiUrl = configData.apiUrl;

const apiEndpoint = apiUrl + '/cars';

function carUrl(query) {
  return `${apiEndpoint}/${query}`;
}

export function createCar(newCar) {
  return http.post(apiEndpoint, newCar);
}

export function getCars(queryParams) {
  let query = '';

  const queryKeys = Object.keys(queryParams);

  queryKeys.forEach((key, i) => {
    query += `${key}=${queryParams[key]}${i + 1 < queryKeys.length ? '&' : ''}`;
    // console.log(i, queryKeys.length);
  });

  // console.log(query);

  // console.log(queryParams);
  return http.get(`${apiEndpoint}/?${query}`);
}

export function getActiveCar(carId) {
  return http.get(carUrl(carId));
}

export function getCar(carId) {
  return http.get(carUrl(carId));
}

export function getCarQuery(query) {
  let queryStr = '';

  if (query) {
    //

    Object.keys(query).map((prop, k) => {
      queryStr +=
        k == 0
          ? '?' + prop + '=' + query[prop]
          : '&' + prop + '=' + query[prop];
    });

    return http.get(apiEndpoint + queryStr);
  }
}

export function saveCar(car) {
  if (car._id) {
    const body = { ...car };
    delete body._id;
    return http.put(carUrl(car._id), body);
  }

  return http.post(apiEndpoint, car);
}

export function updateCar(car, data) {
  if (car) {
    const carUrl = apiEndpoint + '/' + car;
    return http.patch(carUrl, data);
  }
}

export function deleteCar(carId) {
  return http.delete(carUrl(carId));
}

export default {
  getActiveCar,
  createCar,
  getCar,
  getCars,
  updateCar,
  getCarQuery,
};
