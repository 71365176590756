import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const RealEstateHeroOne = (props) => {
  return (
    <Fragment>
      {/* Hero One */}
      <section className="full-screen-hero__sticky-top">
        <div className="full-height-hero__image-container full-height-hero__image-container--width">
          <picture
            className="responsive-image full-height-hero__picture lazy-loader lazy-loader--loaded full-height-hero__picture--width"
            data-load-module="LazyLoader"
            data-module-loaded="true"
            data-lazyloader-id="_kzk1cwouq281w"
          >
            <source
              media="(max-width: 979px)"
              srcSet="/content/images/travels-and-tour/tour-route.jpg"
              data-lazy-src="/content/images/travels-and-tour/tour-route.jpg"
              className="lazy-loader__asset"
            />
            <img
              src="/content/images/travels-and-tour/tour-route.jpg"
              alt=""
              className="full-height-hero__image lazy-loader__asset lazy-loader__asset--loaded full-height-hero__image--width"
              data-lazy-src="/content/images/travels-and-tour/tour-route.jpg"
              role="presentation"
            />
          </picture>
          <div className="overlay--dark overlay--gray-01"></div>
        </div>
        <div className="container">
          <div className="full-screen-hero__interior" aria-hidden="true">
            <div className="c-rich-text">
              <h2 className="full-screen-hero__interior__title">
                Avoid the hassle. <br />
                <span className="fw-100" aria-hidden="true">
                  Book your travel tickets with Convenience and Ease.
                </span>
              </h2>
              <p
                className="full-screen-hero__interior__desc"
                aria-hidden="true"
              >
                Let us handle the ticketing for you and your organisation
              </p>
            </div>

            <div
              className="full-screen-hero__cta slide__cta"
              aria-hidden="true"
            >
              <Link
                to="/travel-and-tourism/ticket-booking"
                className="s-btn is-btn--black w-inline-block"
                aria-hidden="true"
              >
                <p aria-hidden="true">Ticketing</p>
              </Link>
              <a
                data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
                className="alt-btn is-btn--alt btn-link--black w-inline-block"
                href="#"
              >
                <div className="alt-btn__wrp">
                  <span> Learn more about Skyewise Travel</span>
                </div>
                <div className="alt-btn__underline is-dark__underline"></div>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Hero Two */}
      <section className="full-screen-hero__sticky-top">
        <div className="full-height-hero__image-container full-height-hero__image-container--width">
          <picture
            className="responsive-image full-height-hero__picture lazy-loader lazy-loader--loaded full-height-hero__picture--width"
            data-load-module="LazyLoader"
            data-module-loaded="true"
            data-lazyloader-id="_kzk1cwouq281w"
          >
            <source
              media="(max-width: 979px)"
              srcSet="/content/images/travels-and-tour/tourist-01-19x9.jpeg"
              data-lazy-src="/content/images/travels-and-tour/tourist-01-19x9.jpeg"
              className="lazy-loader__asset"
            />
            <img
              src="/content/images/travels-and-tour/tourist-01-19x9.jpeg"
              alt=""
              className="full-height-hero__image lazy-loader__asset lazy-loader__asset--loaded full-height-hero__image--width"
              data-lazy-src="/content/images/travels-and-tour/tourist-01-19x9.jpeg"
              role="presentation"
            />
          </picture>
          {/* <div className="overlay--light overlay--dark"></div> */}
        </div>
        <div className="container">
          <div className="full-screen-hero__interior " aria-hidden="true">
            <div className="c-rich-text">
              <h2 className="full-screen-hero__interior__title">
                Discover &nbsp;
                <span className="fw-100" aria-hidden="true">
                  Nature
                </span>
              </h2>
              <p
                className="full-screen-hero__interior__desc"
                aria-hidden="true"
              >
                Skyewise Travel & Tour is a specialist tour operator providing
                high-quality holidays Around the world.
              </p>
            </div>

            <div
              className="full-screen-hero__cta slide__cta"
              aria-hidden="true"
            >
              <a
                href="#"
                className="s-btn is-btn--light w-inline-block"
                aria-hidden="true"
              >
                <span aria-hidden="true">Find Tour</span>
              </a>

              <a
                data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
                className="alt-btn is-btn--alt btn-link--black w-inline-block"
                href="#"
              >
                <div className="alt-btn__wrp">
                  <span> Learn more about Skyewise Travel</span>
                </div>
                <div className="alt-btn__underline is-dark__underline"></div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RealEstateHeroOne;
