import React, { Fragment } from "react";

const WIWSQS = (props) => {
  return (
    <Fragment>
      <div className="flex-middle gray-bg-02 wf-section">
        <div className="container">
          <div className="flex-center">
            <div className="flex-center__container">
              <div className="c-rich-text">
                <h2>Invest With Confidence</h2>
                <p>
                  Over the years, we’ve provided finacial and advisory services
                  to our clients on some of their most important strategic
                  decisions and transactions.
                </p>
              </div>
              <div>
                <div>
                  <ul role="list" className="w-list-unstyled">
                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          Good reason why customers should invest
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + "px" }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div
                        data-w-id="e2b000fa-d202-cd58-f8c2-fadc7b8bc9be"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          Good reason why customers should invest
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + "px" }}
                        className="expand__details"
                      >
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div
                        data-w-id="dc94326b-1051-216b-634e-83984cb5e1eb"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          Good reason why customers should invest
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + "px" }}
                        className="expand__details"
                      >
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div
                        data-w-id="3327623c-cbc7-c8ff-a869-d89739b19108"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          Good reason why customers should invest
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + "px" }}
                        className="expand__details"
                      >
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementumna
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>Talk to someone?</h3>
                <p>Give us a call if you wish to speak with us in person</p>
              </div>
              <a
                href="/contact"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Contact</p>
                <p className="is-icon"></p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WIWSQS;
