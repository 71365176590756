import React, { Component } from 'react';

import configData from '../../config.json';
import displayHoverElement from '../../utils/hoverElementDisplay';
import AdminClientVerification from './adminClientComponents/adminClientVerification';
import ClientIdCapcel from './adminClientComponents/clientIdentityCapcel';
import ClientInfoBubbles from './adminClientComponents/clientInfoBubbles';
import ClientInvestmentsRecords from './adminClientComponents/clientInvestmentsRecords';
import ClientLoansRecords from './adminClientComponents/clientLoanssRecords';
import AdminClientNotice from './adminClientNotice';

const storageUrl = configData.storageUrl;

class AdminLoansDashboard extends Component {
  state = { dashboard: 'client-info' };

  render() {
    const client = this.props.client;
    return (
      client && (
        <div className="ud-main">
          <div className="ud-main__title">
            <div className="ud-main__title__user-id">
              <ClientIdCapcel
                client={client}
                updateDashboardLocation={this.props.updateDashboardLocation}
              />
              <AdminClientVerification
                tip={{
                  tipTitle: 'Verified',
                  tipIcon: '',
                  tipText: 'This account has been verified.',
                }}
              />
            </div>

            <h3 className="ud-main__title__txt">Loans</h3>
          </div>

          <AdminClientNotice notice={`${client.name}'s loan records`} />

          <ClientLoansRecords client={client} />
        </div>
      )
    );
  }
}

export default AdminLoansDashboard;
