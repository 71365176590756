import React from 'react';
import UserSupportForm from './userSupportForm';

const UserSupportDashboard = () => {
  return (
    <main className="credit-dashbord">
      <div className="credit-dashbord__heading">
        <h3 className="heading-8">Need help?</h3>
      </div>
      <div className="credit-dashbord__details">
        <div className="credit-dashbord__sum">
          <UserSupportForm />
        </div>
      </div>
    </main>
  );
};

export default UserSupportDashboard;
