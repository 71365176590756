import React from "react";
import StepsIndicator from "../../common/stepsIndicator";

const InvestmentProgressHint = (props) => {
  return (
    <StepsIndicator
      CurrentSteps={[
        {
          desc: "Start investment",
          stepName: "none",
        },

        {
          desc: "Fund your investment",
          stepName: "none-funded",
        },

        {
          desc: "Pending Confirmation",
          stepName: "funded",
        },

        {
          desc: "Confirmed",
          stepName: "active",
        },
      ]}
      stepType="investmentState"
    />
  );
};

export default InvestmentProgressHint;
