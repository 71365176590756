import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

const DueLoan = (props) => {
  return (
    <div>
      <div className=" is-2_5em--white-wrap is-1_27-grid  is-txt--centered">
        <div>
          <div className="">
            <img
              src="/images/user-guide/loan-due_1366x575.png"
              alt="Investment pending confirmation"
            ></img>
          </div>
          <div className="c-rich-text">
            <h3>Loan repayment</h3>
            <h5>Your loan is due for repayment</h5>
          </div>
        </div>
        <div className="is-flex flex-c">
          <div className="c-rich-text is-flex__80-jc-c">
            <p>
              Dear member, your loan is
              <span className="credit-sum__state due--solid glue-c-capcel">
                due
              </span>
              for repayment. We have sent you email with instructions on the
              repayment process. <br />
            </p>

            <p>Best, Skyewise Team</p>

            <p>Thank you for choosing Skyewise</p>
          </div>
        </div>
      </div>
      <div className="hr-line hr-line--dark"></div>
      <p>Skyewie Cooperative</p>
    </div>
  );
};

export default DueLoan;
