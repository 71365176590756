import React from "react";
import Loans from "../loans";

const CurrentLoans = (props) => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          {/* <div className="_1-col-c-center__c-narrow is--left-align "> */}
          <Loans />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default CurrentLoans;
