import React, { useEffect, useState } from 'react';
import userService from '../services/userService';
import configData from '../config.json';
import CryptoJS from 'crypto-js';

const cryptoKey = configData.CRYPTO_KEY;

export function CUDA(props) {
  //   const currentUserLocal = useContext(UserContext);
  // const [cUser, setCUser] = useState({});

  // useEffect(() => {
  async function getMe() {
    const currentUserConfirm = await userService.getMe().data.data;
    const { data: user } = currentUserConfirm;

    const encryptedUserData = CryptoJS.AES.encrypt(
      JSON.stringify(user),
      cryptoKey
    ).toString();

    localStorage.setItem('UVDA', encryptedUserData);
  }

  // console.log("Test Teset Test");

  getMe();
  // });
}

export default {
  CUDA,
};
