import React, { Fragment } from "react";
import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import CultureAndInnovationPageHeader from "./cultureAndInnovationHeader";
import CultureAndinnovationHistory from "./cultureAndinnovationHistory";
import CultureAndInnvationPartnerships from "./cultureAndInnvationPartnerships";
import CultureAndInnvationStory from "./cultureAndInnvationStory";

const CultureAndInnovation = (props) => {
  return (
    <Fragment>
      <Navbar />
      <CultureAndInnovationPageHeader />
      <CultureAndInnvationStory />
      <CultureAndInnvationPartnerships />
      <CultureAndinnovationHistory />
      <Footer />
    </Fragment>
  );
};

export default CultureAndInnovation;
