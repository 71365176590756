import React from 'react';
import DashboardLocalNavMobileMenuItem from './userDashboardMobileNavMenuItem';
import LocalMenuToggle from './userDashboardLocalMenuToggle';

const DashboardLocalNavMobile = (props) => {
  // console.log(props.onMenuData.menuState);

  return (
    <div className="user-dash__local-menu">
      <LocalMenuToggle onToggleMenu={props.onMenuData} />

      {props.onMenuData.menuState === 'opened' && (
        <div className="user-dash__local-menu__menu menu--opened">
          <div className="user-dash__local-menu__container">
            <ul
              role="list"
              className="user-dash__local-menu__items w-list-unstyled"
            >
              <DashboardLocalNavMobileMenuItem
                onToggleMenu={props.onMenuData}
                menuData={{
                  menuLable: 'Credit Summary',
                  menuIcon: '',
                  currentMenu: 'credit-summary',
                }}
                menuClick={props.onDashbordChange}
              />

              <DashboardLocalNavMobileMenuItem
                onToggleMenu={props.onMenuData}
                menuData={{
                  menuLable: 'Investment',
                  menuIcon: '',
                  currentMenu: 'investment',
                }}
                menuClick={props.onDashbordChange}
              />

              <DashboardLocalNavMobileMenuItem
                onToggleMenu={props.onMenuData}
                menuData={{
                  menuLable: 'Loan',
                  menuIcon: '',
                  currentMenu: 'loan',
                }}
                menuClick={props.onDashbordChange}
              />

              <DashboardLocalNavMobileMenuItem
                onToggleMenu={props.onMenuData}
                menuData={{
                  menuLable: 'My Account',
                  menuIcon: '',
                  currentMenu: 'my-profile',
                }}
                menuClick={props.onDashbordChange}
              />

              <DashboardLocalNavMobileMenuItem
                onToggleMenu={props.onMenuData}
                menuData={{
                  menuLable: 'Help',
                  menuIcon: '',
                  currentMenu: 'support',
                }}
                menuClick={props.onDashbordChange}
              />

              <DashboardLocalNavMobileMenuItem
                onToggleMenu={props.onMenuData}
                menuData={{
                  menuLable: 'Logout',
                  menuIcon: '',
                  currentMenu: '',
                }}
                menuClick={props.onDashbordChange}
              />
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardLocalNavMobile;
