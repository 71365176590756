import Lottie from "lottie-web";
import svgLoading from "../statics/loading-svg.json";
import svgError from "../statics/error-svg.json";
import svgSuccess from "../statics/success-svg.json";

export function initProgressLotties() {
  document.querySelectorAll("#pwi__progress__loading").forEach((el) => {
    el.innerHTML = "";
    Lottie.loadAnimation({
      container: el,
      animationData: svgLoading,
    });
  });

  document.querySelectorAll("#pwi__progress__error").forEach((el) => {
    el.innerHTML = "";

    Lottie.loadAnimation({
      container: el,
      animationData: svgError,
      loop: false,
    });
  });

  document.querySelectorAll("#pwi__progress__success").forEach((el) => {
    el.innerHTML = "";

    Lottie.loadAnimation({
      container: el,
      animationData: svgSuccess,
      loop: false,
    });
  });
}
