import React from 'react';

import SkyewiseProduct from './whatWeDoSingleProduct';
const ProductsAndServices = (props) => {
  return (
    <div
      data-w-id="348bf267-469b-36c0-cb3e-1f2b349e3346"
      //   style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
      className="excerpts section is-gray-bg--01 wf-section"
    >
      <div className="container blog-updates__container">
        <div className="_4-col-st-grid">
          <SkyewiseProduct
            details={{
              name: 'Investment',
              description: 'Helping our clients build a treasury of the future',

              image: '/images/temp/share-800x450.webp',
              url: 'what-we-do/products-and-services/investment',
              altUrl: 'user-dashboard',
            }}
          />
          <SkyewiseProduct
            details={{
              name: 'Loan',
              description: 'Get access to fast and reliable credit facility',
              image: '/images/content/finance/loan-department.jpg',
              url: 'what-we-do/products-and-services/loan',
              altUrl: 'user-dashboard',
            }}
          />
          <SkyewiseProduct
            details={{
              name: 'Automobiles',
              description: 'Superior automobile sales and after-sales services',
              image: '/images/content/autos/1350x696-desktop-hero-audi-4q.jpg',
              url: 'automobile',
            }}
          />
          <SkyewiseProduct
            details={{
              name: 'Travel & Tour',
              description: 'Flexible travel bookings, and tour packages',
              image: '/images/content/travel-tour/hiking.jpg',
              url: 'travel-and-tourism',
            }}
          />
          <SkyewiseProduct
            details={{
              name: 'Real Estate',
              description: 'Smart homes with excellent modern facilities',
              image: '/images/content/real-estate/landscape.jpg',
              url: 'real-estate',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsAndServices;
