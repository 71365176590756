import React from 'react';
import { Link } from 'react-router-dom';
import HrDevider from '../../common/hrDevider';

const SeafPorjects = (props) => {
  return (
    <div
      className="_1-col-c-center wf-section is-bg--gray-02 overflow-hidden"
      id="seaf-projects"
    >
      <div className="container">
        <div className="_2-col-st-grid sce-project is-mt-medium grid-gap--3em ">
          <div className="grid-item ">
            <div className="sce-project__img sce-item">
              <img
                src="/images/content/seaf-foundation/mtceo-2021-speaker-2552x913.jpg"
                loading="lazy"
                sizes="100vw"
                alt=""
                className="hero-style-fs__slider__slide-image"
              />
            </div>
          </div>

          <div className="grid-item ">
            <div className="sce-item">
              <div className="c-rich-text">
                <h3>Skyewise Entrepreneurship Summit (Meet The CEOs)</h3>
                <p>
                  At the core of any nation-building, there are always
                  individuals who dare to offer something of great value.
                  Skeywise Entrepreneurship Summit is an annual event organized
                  by Skyewise Foundation (SEAF), in partnership with Skyewise
                  Group.
                </p>

                <p>
                  As part of our commitment to raising young entrepreneurs, this
                  event is aimed at providing participants with shared,
                  practical knowledge & experiences of leaders and successful
                  entrepreneurs in Nigeria and across Africa
                </p>
              </div>
            </div>
          </div>
        </div>

        <HrDevider />

        <div className="_2-col-st-grid sce-project is-mt-medium grid-gap--3em ">
          <div className="grid-item grid-visual-order--01">
            <div className="sce-project__img sce-item">
              <img
                src="/images/content/seaf-foundation/skyewise-school-outreach-1080x557.jpg"
                loading="lazy"
                sizes="100vw"
                alt=""
                className="hero-style-fs__slider__slide-image"
              />
            </div>
          </div>

          <div className="grid-item grid-visual-order--00">
            <div className="sce-item">
              <div className="c-rich-text">
                <h3> SEAF School Outreach</h3>
                <p>
                  Donating educational materials to schools and students to
                  enhance a better learning process and a conducive learning
                  environment.
                </p>
                <p>
                  It’s our hope and belief that education is one of the best
                  things you can give to a child and equipping with learning
                  materials is our greatest joy and the reason why the
                  cooperative was established to help the government reach out
                  in all spheres of life by adding our own contributions as we
                  make progress in the journey of the cooperative
                </p>
              </div>
            </div>
          </div>
        </div>

        <HrDevider />

        <div className="_2-col-st-grid sce-project is-mt-medium grid-gap--3em ">
          <div className="grid-item">
            <div className="sce-project__img sce-item">
              <img
                src="/images/content/seaf-foundation/startup-ent-training-2552x913.jpg"
                loading="lazy"
                sizes="100vw"
                alt=""
                className="hero-style-fs__slider__slide-image"
              />
            </div>
          </div>

          <div className="grid-item">
            <div className="sce-item">
              <div className="c-rich-text">
                <h3>Startup Entrepreneurial Training (S.E.T)</h3>
                <p>
                  S.E.T is a mentorship program developed by Skyewise
                  Empowerment {'&'} Advancement Foundation (S.E.A.F), to mentor
                  young people on entrepreneurship and help them kick-start
                  their businesses by providing them with capital at 0%
                  interest.
                </p>

                <p>
                  If you are an entrepreneur, you understand there’s hardly a
                  linear path to creating a successful business. This program is
                  developed to help startups navigate the challenges and
                  potential pitfalls businesses face.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeafPorjects;
