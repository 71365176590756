import React from 'react';

const CommunityEngagementBrief = (props) => {
  return (
    <section className="wf-section ">
      <div className="container ">
        <div className="">
          <h4
            className="hero-style-fs__slider__slide-heading fs-slide-heading--large is-txt-shadow"
            aria-hidden="true"
          >
            <span className="heading-large--tag">Community </span>
            Service
          </h4>
          <div className="">
            <p>
              Life's most persistent and urgent question is,{' '}
              <strong>'What are you doing for others? </strong>
              These words from Dr. Martin Luther King still ring true today and
              every day.
            </p>

            <p>
              Our answer to this question is <strong>Service.</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommunityEngagementBrief;
