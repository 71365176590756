import React from 'react';

const BackToHomeBtn = (props) => {
  return (
    <button
      className="s-btn is-btn--outline"
      onClick={() => {
        props.updateDashboardLocation('client-info');
        // console.log(props.onUpdateDashboardLocation);
      }}
    >
      <span className="is-fa-icon--300"></span>
      <span className="btn-outline--txt-alt">Back</span>
    </button>
  );
};

export default BackToHomeBtn;
