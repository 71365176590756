import React, { Component } from 'react';

const InvestmentGuide = () => {
  return (
    <div className="c-rich-text">
      <div className="glue-hint__highlight">
        <h6 className="is-fw-700">Date</h6>
        <p className="is-txt__x-small--zoomable">
          Investment date. This field is included automatically.
        </p>
      </div>
      <div className="glue-hint__highlight">
        <h6 className="is-fw-700">Purpose</h6>
        <p className="is-txt__x-small--zoomable">
          Please give us a statment for your investment.
        </p>
      </div>
      <div className="glue-hint__highlight">
        <h6 className="is-fw-700">Amount</h6>
        <p className="is-txt__x-small--zoomable">
          Specifies the amount you want to investment
        </p>
      </div>
    </div>
  );
};

export default InvestmentGuide;
