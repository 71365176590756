import React from 'react';

const MTCEOsSEAFImpact = (props) => {
  return (
    <section className="wf-section ">
      <div className="container">
        <div className="">
          <h4
            className="hero-style-fs__slider__slide-heading fs-slide-heading--large is-txt-shadow"
            aria-hidden="true"
          >
            <span className="heading-large--tag">S.E.A.F</span>
            Impact.
          </h4>
          <p>
            At the core of any nation-building, there are always individuals who
            dare to offer something of great value.
          </p>
          <p>
            S.E.A.F - <strong>Raising young people to be self-reliant</strong>
          </p>
        </div>
      </div>
    </section>
  );
};

export default MTCEOsSEAFImpact;
