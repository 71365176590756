import React, { Fragment, useLayoutEffect } from "react";
import Footer from "../../common/footer/footer";
import Navbar from "../../common/navbar/navbar";
import CIBPG from "./clientBenefitsBody";
import CIBH from "./clientBenefitsHeader";
import restartWebflowScript from "../../common/restartWebflowScript";

const CIB = (props) => {
  useLayoutEffect(() => {
    // var htmlEl = document.getElementsByTagName("html")[0];
    // htmlEl.setAttribute("data-wf-page", "61e539b9a949f4a1ef4bd375");
  });

  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <CIBH />
      <CIBPG />
      <Footer />
    </Fragment>
  );
};

export default CIB;
