import React from 'react';

const CarNote = (props) => {
  return (
    <div className="c-rich-text mt-8">
      <h3>Note</h3>
      <p>
        This listing is proudly presented by Skyewise Automobile. For additional
        information about this car, feel free to reach out to us directly
        through our website or visit any of our showrooms:
      </p>
      <ul className="mt-2 list-disc	">
        <li>Plot 2402, Shehu Shagari Way, Maitama, Abuja.</li>
        <li>Area 11, Plot 704 Ahmadu Bello Way, Garki, Abuja.</li>
        <li>Jakande Junction, Lekki Expressway, Lagos.</li>
      </ul>

      <p>We look forward to assisting you in finding your perfect ride!</p>
    </div>
  );
};

export default CarNote;
