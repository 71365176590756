import React from "react";

const AutoBrandsLogos = (Brands) => {
  const brandImgUrl = "/images/content/auto-brands/";
  return (
    <div className="s-grid-6-col">
      {Brands.brands.map((band) => {
        return (
          <div className="s-grid__item">
            <img src={brandImgUrl + band.logo} alt={band.name} />
          </div>
        );
      })}
    </div>
  );
};

export default AutoBrandsLogos;
