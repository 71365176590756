import React, { Fragment, Component } from "react";
import TicketBookingForm from "./ticketBookingForm";
import { Link } from "react-router-dom";

const TicketBookingFormSection = (props) => {
  return (
    <Fragment>
      <section className="section">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h3>Talk to someone?</h3>
                  <p>Give us a call if you wish to speak with us in person</p>
                </div>
                <Link
                  to="/contact"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Contact</p>
                  <p className="is-icon"></p>
                </Link>

                <TicketBookingForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-bg--gray-02">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h3>Talk to someone?</h3>
                  <p>Give us a call if you wish to speak with us in person</p>
                </div>
                <Link
                  to="/contact"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Contact</p>
                  <p className="is-icon"></p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default TicketBookingFormSection;
