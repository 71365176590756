function stripHTMLChar(string, chars) {
  // Apply default characters to clean

  const cleanUpSpaces = ['  ', '   ', '    ', '     '];

  if (chars) {
    let stripedChar = string;

    chars.map((char, i) => {
      stripedChar = stripedChar.replaceAll(char, '');
    });

    cleanUpSpaces.map((space) => {
      stripedChar = stripedChar.replaceAll(space, ' ');
    });

    return stripedChar;
  } else {
    return string;
  }
}

export default stripHTMLChar;
