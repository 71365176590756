import React from "react";

const LRHP = (props) => {
  return (
    <div className="header-static static-header--leadership wf-section">
      <div className="container">
        <div className="header-statoc--flex">
          <div className="header-static__container">
            <h1>Personal Loan</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LRHP;
