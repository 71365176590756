import React from 'react';

const QuoteHighlight = (props) => {
  return (
    <div className="_1-col-c-center wf-section is-bg--gray-01 is--ht-100">
      <div className="container flx flx-c-ctr">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h3>
                Take your finances to the next level. Go beyond generic
                financial planning. Partner with us to design a resource
                management plan that meets the unique needs of your business.
              </h3>
              <p>Give us a call if you wish to speak with us in person</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteHighlight;
