import React from 'react';
import { Link } from 'react-router-dom';

// const theCooperativeCStyle = {
//   WebkitTransform:
//     "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
//   MozTransform:
//     "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
//   msTransform:
//     "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
//   transform:
//     "translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
//   opacity: "0",
// };

const TheCooperatives = () => {
  return (
    <section className="section the-cooperatives__section wf-section">
      <div className="container">
        <div
          data-w-id="3c147205-aa54-b63f-fe34-d3ad94d36724"
          className="the-coorp st-b-radius"
        >
          <div
            // style="WebkitTransform:translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);MozTransform:translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);msTransform:translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
            className="the-coorp__bg-img"
          >
            <div className="overlay--dark solid--black the-coorp__overlay"></div>
          </div>
        </div>
        <div className="_2-col-st-grid is-the-coorp__grid">
          <div
            id="w-node-_3c147205-aa54-b63f-fe34-d3ad94d36728-7f382170"
            data-w-id="3c147205-aa54-b63f-fe34-d3ad94d36728"
            // style="WebkitTransform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);MozTransform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);msTransform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
            className="the-coorp__grid__col-1 st-b-radius"
          >
            <div className="c-rich-text">
              <h2 className="c-title--large"> Skyewise Cooperative</h2>
              <p>
                Meeting the financial needs of her members and equipping them
                with other sources of wealth creation.{' '}
              </p>
            </div>
            <div className="ns-top">
              <Link
                data-w-id="3c147205-aa54-b63f-fe34-d3ad94d3672f"
                to="/the-cooperative"
                className="s-btn s-btn--blue w-inline-block"
              >
                <p>Learn more</p>
                <p className="is-icon"></p>
              </Link>
            </div>
          </div>
          <div
            data-w-id="3c147205-aa54-b63f-fe34-d3ad94d36734"
            style={{ opacity: 0 }}
            className="the-coorp__grid__col-2"
          >
            <div className="c-rich-text">
              <p>
                The impact of the economic crisis on our society has made a
                long-term change of the corporate culture in the financial
                sector hence imperative that cultural change is needed.
              </p>{' '}
              <p>
                We at Skyewise Cooperative understand the message that
                responsibility has to be the focus of our action, by bridging
                the liquidity gap in the society
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheCooperatives;
