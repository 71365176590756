import React from 'react';
import { Link } from 'react-router-dom';

const SiteDirectionSectionRowCta = (props) => {
  return (
    <div className="wrapper module-separator">
      <div className="button-grid__container">
        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <Link
              className="iconcta-tile__links"
              to="/automobile/"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6vy0b8w"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/content/autos/300x705-audi-4q.jpg"
                  data-lazy-src="/images/content/autos/300x705-audi-4q.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/content/autos/300x705-audi-4q.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/content/autos/300x705-audi-4q.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container is-fa-icon--100">
                <div className="icon-mb icon-model-chooser iconcta-tile__text">
                  <p className="iconcta-tile__label">Automobile</p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <Link
              className="iconcta-tile__links"
              to="/real-estate"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6wtxz3m"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/content/real-estate/300x705-property-01.jpg"
                  data-lazy-src="/images/content/real-estate/300x705-property-01.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/content/real-estate/300x705-property-01.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/content/real-estate/300x705-property-01.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container is-fa-icon--100">
                <div className="icon-mb icon-real-estate iconcta-tile__text">
                  <p className="iconcta-tile__label">Real Estate</p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <Link
              className="iconcta-tile__links"
              to="/travel-and-tourism"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6w6vf7h"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/content/travel-tour/300x705-lady-at-the-airport-02.jpg"
                  data-lazy-src="/images/content/travel-tour/300x705-lady-at-the-airport-02.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/content/travel-tour/300x705-lady-at-the-airport-02.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/content/travel-tour/300x705-lady-at-the-airport-02.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container is-fa-icon--100">
                <div className="icon-mb icon-travel-and-tour iconcta-tile__text">
                  <p className="iconcta-tile__label">Travel & Tour</p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <Link
              className="iconcta-tile__links"
              to="/the-cooperative"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6wgl9t5"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/content/finance/300x705-loan-service.jpg"
                  data-lazy-src="/images/content/finance/300x705-loan-service.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/content/finance/300x705-loan-service.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/content/finance/300x705-loan-service.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container is-fa-icon--100">
                <div className="icon-mb icon-finance iconcta-tile__text">
                  <p className="iconcta-tile__label">Finance</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteDirectionSectionRowCta;
