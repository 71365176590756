function validateFileExt(input, exts) {
  //  var fileName = document.getElementById(inputID).value;
  //  return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(input);
  // return (input.type === 'image/jpeg' || input.type === 'image/tiff' || input.type === 'image/webp' || input.type === 'image/png' || input.type === 'image/gif' || input.type === 'image/heic');

  for (let i = 0; i < exts.length; i++) {
    if (input.type === exts[i]) {
      // console.log('Valid extension ', exts[i] );
      return true;
    }
  }

  // console.log('Not a valid extension');
  return false;
}

export default validateFileExt;
