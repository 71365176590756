import React from 'react';
import StepsIndicator from '../../common/stepsIndicator';

const KycProgressHint = (props) => {
  return (
    <section className="section is-c-bg-grey--04">
      <div className="_1-col-c-center wf-section">
        <div className="container is--narrow">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <StepsIndicator
                CurrentSteps={[
                  {
                    desc: 'Bio-data & Bank Information',
                    stepName: 'unverified',
                  },

                  {
                    desc: 'Profile Photo (Head Shot)',
                    stepName: 'p-photo',
                  },

                  {
                    desc: 'Means of Identification',
                    stepName: 'kyc-id',
                  },

                  {
                    desc: 'Verification Approval',
                    stepName: 'data-submission',
                  },
                ]}
                stepType="verificationState"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KycProgressHint;
