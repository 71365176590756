import React, { Fragment } from "react";
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { Link } from "react-router-dom";
import restartWebflowScript from "../../common/restartWebflowScript";

const DirectorDrElisAbuyere = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="internal-nav w-nav"
        >
          <div className="header-static static-header--leadership wf-section">
            <div className="container">
              <div className="header-statoc--flex">
                <div className="header-static__container">
                  <h1>Dr. Elvis L. Abuyere</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow is--left-align">
                <div className="c-rich-text">
                  <div className="div-block-96 ">
                    <div className="div-block-97">
                      <img
                        src="/images/content/our-people/directors/dr_elvis_abuyere.webp"
                        loading="lazy"
                        alt="Dr. Elvis L. Abuyere"
                      />
                    </div>
                    <div className="div-block-97">
                      {/* <a className="st-link w-inline-block" href="/"> */}
                      <h4 className="c-h4">Dr. Elvis L. Abuyere</h4>
                      {/* </a> */}
                      <p>MD/CEO </p>
                      <p className="p-medium">
                        Awarded the SME CEO of the year 2021 organized by
                        Nigerians Business Day News Papers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="_1-col-c-center__c-narrow is--left-align st-r-mg">
                <div className="c-rich-text">
                  <p className="p-small">
                    Dr. Elvis L. Abuyere, MD/CEO, Skyewise Group is a
                    Nigerian-driven entrepreneur with over 14 years of
                    professional experience in banking and business management.
                    His career spans from Skyebank PLC to First Bank PLC where
                    he managed and achieved several business results, including,
                    yet not limited to Finance and Accounts, Marketing and
                    Procurement Unit, and Human Resource Management.
                  </p>

                  <p className="p-small">
                    He has held various other offices in the Ministry of Foreign
                    Affairs and Nigerian admissions abroad. He served in
                    Canberra (Australia), Jeddah (Saudi Arabia), Geneva
                    (Switzerland), and New York (the United States of America)
                    at different periods. Upon retirement, he was appointed as
                    Directing Staff in Nigeria’s apex think-tank, the National
                    Institute for Policy and Strategic Studies (NIPSS), Kuru
                    where he lectures on diplomacy and public policy issues.
                  </p>

                  <p className="p-small">
                    He is a self-motivated and goal-oriented individual who
                    responds to any challenges life throws at him. He uses an
                    analytical style of negotiation, combined with positive
                    thinking to generate effective solutions.
                  </p>

                  <p className="p-small">
                    Elvis is a graduate of Accounting from Dorben Polytechnic,
                    Abuja. He holds an HND in Accounting, a PGD in Business
                    Administration from the National Open University, and a
                    Ph.D. in banking and business management from Escae
                    University Benin. He is also currently enrolled in the MBA
                    in Business Management program at the Lagos Business School.
                    Elvis is currently studying Law at Baze University, Abuja.
                    He is a member of the Chartered Institute of Leadership and
                    Governance, (USA) and a member of Abuja commerce of
                    industry.
                  </p>

                  <p className="p-small">
                    His quest for success has been a major driving force that
                    has motivated him to pursue greener pastures in
                    entrepreneurship, strategic management, and business
                    development. He established the Skyewise Group companies and
                    has other businesses within Nigeria. He has received several
                    awards in humanitarian service and won a deserving award in
                    the category of Excellence in Business Management from the
                    Africa Value Awards, in July 2021.
                  </p>

                  <p className="p-small">
                    He was awarded the SME CEO of the year 2021 organized by
                    Nigerians Business Day News Papers and he was also awarded
                    business tycoon of the year by Abuja University.
                  </p>
                </div>
              </div>

              <div className="_1-col-c-center__c-narrow st-r-mg">
                <div className="c-rich-text">
                  <h3>Directors</h3>
                </div>
                <Link
                  to="/about-us/people-and-leadership/directors"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Go back to all directors</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DirectorDrElisAbuyere;
