import React from 'react';
import configData from '../../../config.json';
const { storageUrl } = configData;

const ClientKYCData = (props) => {
  const { kycData } = props;

  return (
    <a
      href={`${storageUrl}${kycData.file}`}
      target="_blank"
      download="file"
      className="glue-tip__tip tip--mini"
    >
      <div className="glue-tip__tip__thumb thumb--icon  ">
        <span className="is-fa-icon--300">{kycData.icon}</span>
      </div>
      <div className="glue-tip__tip__details">
        <h4 className="glue-tip__tip__details__heading tip-heading--blue">
          {kycData.name}
        </h4>
        <p className="glue-tip__tip__details__desc">Download</p>
      </div>
    </a>
  );
};

export default ClientKYCData;
