import React, {
  Fragment,
  useLayoutEffect,
  useEffect,
  useState,
  useContext,
} from 'react';

// import InvestmentService from "../../services/investmentService";
import UserContext from '../../context/userContext';
import InvestmentContext from '../../context/investmentContext';

import DashboardHeader from './userDashboardHeader';
import AboutUser from './userDashbaordAboutUser';
import UserActiveInvestment from './creditSummary/userActiveInvestment';
import UserActiveLoan from './creditSummary/userActiveLoan';
import UserDashboardModalBox from './creditSummary/userDashboardModalBox';
import InvestmentDashboard from './investment/investmentDashboard';
import LoanDashboard from './loan/loanDashboard';
import UserProfileDashboard from './userProfile/userProfileDashboard';
import UserSupportDashboard from './userSupport/userSupportDashboard';
import DashboardNav from './userDashboardNav';

import Navbar from '../common/navbar/navbar';
import Footer from '../common/footer/footer';

import restartWebflowScript from '../common/restartWebflowScript';
import UserCreditRecords from './creditSummary/userCreditRecords';
import AllInvestmentsRecords from './userCreditRecords/allInvestmentsRecords';
import AllLoansRecords from './userCreditRecords/allLoansRecords';
import { Helmet } from 'react-helmet-async';
import DashboardLocalNavMobile from './userDashboardMobileNavMenu';
import ToolTipOnHover from '../common/toolTipOnHover';
import DashboardLocalFooter from '../userDashboardLocalFooter';

const Dashboard = ({ props }) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.setAttribute('data-wf-page', '61e539b9a949f4bbd54bd36f');
  });
  restartWebflowScript();

  if (!localStorage.getItem('u-dashboard')) {
    localStorage.setItem('u-dashboard', 'credit-summary');
  }

  const [currentDasboard, changeCurrentDashboard] = useState(
    localStorage.getItem('u-dashboard')
  );

  const updateCurrentDashbordLocal = (dashboard) => {
    localStorage.setItem('u-dashboard', dashboard);
    changeCurrentDashboard(localStorage.getItem('u-dashboard'));
  };

  // ModalBox Data
  const [showModalBox, updateShowModalBox] = useState(true);
  const [modalBoxData, updateModalBoxData] = useState(null);

  const updateCurrentModalBox = (data) => {
    updateModalBoxData(data);
  };

  // Toggle Local Nav Mobile

  const [localMenuMobile, updateLocalMenuMobile] = useState('closed');

  // if (!localStorage.getItem("u-dashboard")) {
  //   localStorage.setItem("u-dashboard", "credit-summary");
  // }

  return (
    <Fragment>
      <Helmet>
        <title>My Dashboard | Skyewise cooperative</title>
        <meta name="description" content="User personal account dashboard" />
        <link rel="canonical" href="/user-dashboard" />
      </Helmet>
      <Navbar />
      {/* {getCurrentUserData()} */}
      <InvestmentContext.Provider value={{ investment: 'true' }}>
        <div className="user-dashboard wf-section">
          <DashboardHeader />
          <section className="section user-dash__main">
            <div className="container">
              {/* Local Menu Start */}

              <DashboardLocalNavMobile
                onDashbordChange={updateCurrentDashbordLocal}
                onMenuData={{
                  updateMenuState: updateLocalMenuMobile,
                  menuState: localMenuMobile,
                }}
              />

              {/* Local Menu End */}
              <div className="user-dash__grid">
                <AboutUser />

                <div className="user-credit-dash st-b-radius">
                  <DashboardNav
                    onDashbordChange={updateCurrentDashbordLocal}
                    currentDasboard={currentDasboard}
                  />
                  {currentDasboard === 'credit-summary' && (
                    <main
                      className="credit-dashbord"
                      // Close Mobile Menu on Out Click
                      onClick={() => {
                        updateLocalMenuMobile('closed');
                      }}
                    >
                      <div className="credit-dashbord__heading">
                        <h3 className="heading-8">Credit summary</h3>
                        <ToolTipOnHover
                          tip={{
                            tipTitle: 'Credit Summary',
                            tipIcon: '',
                            tipText: 'This account has been verified.',
                          }}
                        />
                      </div>
                      <div className="credit-dashbord__details">
                        <div className="credit-dashbord__sum">
                          <UserActiveInvestment
                            dashboardData={updateCurrentModalBox}
                            onShowModalBox={updateShowModalBox}
                          />
                          <UserActiveLoan
                            dashboardData={updateCurrentModalBox}
                            onShowModalBox={updateShowModalBox}
                          />
                        </div>

                        {/* <HrDevider /> */}
                        <UserCreditRecords
                          onDashbordChange={updateCurrentDashbordLocal}
                          dashboardData={updateCurrentModalBox}
                          onShowModalBox={updateShowModalBox}
                        />
                      </div>
                      <DashboardLocalFooter />
                    </main>
                  )}
                  {currentDasboard === 'investment' && <InvestmentDashboard />}
                  {currentDasboard === 'loan' && <LoanDashboard />}
                  {currentDasboard === 'my-profile' && <UserProfileDashboard />}
                  {currentDasboard === 'support' && <UserSupportDashboard />}
                  {currentDasboard === 'all-investment-records' && (
                    <AllInvestmentsRecords
                      onDashbordChange={updateCurrentDashbordLocal}
                    />
                  )}
                  {currentDasboard === 'all-loan-records' && (
                    <AllLoansRecords
                      onDashbordChange={updateCurrentDashbordLocal}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>

          {showModalBox && (
            <UserDashboardModalBox
              dashboardData={modalBoxData}
              onShowModalBox={updateShowModalBox}
            />
          )}
        </div>
      </InvestmentContext.Provider>
      <Footer />
    </Fragment>
  );
};

export default Dashboard;
