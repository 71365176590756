import React, { Component } from 'react';

const InvestmentGuide = () => {
  return (
    <div className="c-rich-text">
      <div className="glue-hint__highlight">
        <h6 className="is-fw-600">Date</h6>
        <p className="is-txt__x-small--zoomable">
          Loan date. This field is included automatically.
        </p>
      </div>
      <div className="glue-hint__highlight">
        <h6 className="is-fw-600">Purpose</h6>
        <p className="is-txt__x-small--zoomable">
          Please tell us the purpose of your loan.
        </p>
      </div>
      <div className="glue-hint__highlight">
        <h6 className="is-fw-600">Amount</h6>
        <p className="is-txt__x-small--zoomable">
          Specifies the amount you want to loan
        </p>
      </div>
    </div>
  );
};

export default InvestmentGuide;
