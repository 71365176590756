import http from './httpService';
import configData from '../config.json';
import { update } from 'lodash';
import authService from './authService';

const apiUrl = configData.apiUrl;

const apiEndpoint = apiUrl + '/loans';

function loanUrl(query) {
  return `${apiEndpoint}/${query}`;
}

export function createLoan(newLoan) {
  return http.post(apiEndpoint, {
    loan: newLoan.loan,
    amount: newLoan.amount,
    amountInWords: newLoan.amountInWords,
    loanCollateral: newLoan.collateral,
  });
}

export function getLoans() {
  return http.get(apiEndpoint);
}

export function getActiveLoan(loanId) {
  return http.get(loanUrl(loanId));
}

export function getLoan(loanId) {
  return http.get(loanUrl(loanId));
}

export function getLoanQuery(query) {
  let queryStr = '';

  if (query) {
    //

    Object.keys(query).map((prop, k) => {
      queryStr +=
        k == 0
          ? '?' + prop + '=' + query[prop]
          : '&' + prop + '=' + query[prop];
    });

    return http.get(apiEndpoint + queryStr);
  }
}

export function saveLoan(loan) {
  if (loan._id) {
    const body = { ...loan };
    delete body._id;
    return http.put(loanUrl(loan._id), body);
  }

  return http.post(apiEndpoint, loan);
}

export function updateLoan(loan, data) {
  if (loan) {
    let loanUrl = apiEndpoint + '/' + loan;
    return http.patch(loanUrl, data);
  }
}

export function deleteLoan(loanId) {
  return http.delete(loanUrl(loanId));
}

export default {
  getActiveLoan,
  createLoan,
  getLoan,
  getLoans,
  updateLoan,
  getLoanQuery,
};
