import React from "react";
import Joi from "joi-browser";
import Form from "../../common/formElements/form";
import * as userService from "../../../services/userService";
import auth from "../../../services/authService";

class UserBDF extends Form {
  state = {
    data: { username: "", password: "", name: "" },
    errors: {},
  };

  schema = {
    name: Joi.string().required().label("Name"),
    username: Joi.string().required().email().label("Username"),
    password: Joi.string().required().min(5).label("Password"),
    passwordconfirm: Joi.string().required().label("PasswordConfirm"),
    phone: Joi.string().required().label("Phone"),
    address: Joi.string().required().label("Address"),
  };

  doSubmit = async () => {
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.data.token, response.data.data);
      window.location = "/user-dashboard";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="is-2_5em--white-wrap ">
        <div className="c-rich-text">
          <h5> Update Your Bio Data</h5>
        </div>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Name", "text", "Full Name", "")}
          {this.renderInput(
            "username",
            "Username",
            "text",
            "Email address",
            ""
          )}
          {this.renderInput("phone", "Phone", "text", "Phone Number", "")}

          {this.renderInput(
            "address",
            "Address",
            "text",
            "Contact Address",
            ""
          )}

          {this.renderButton("Update")}
        </form>
      </div>
    );
  }
}

export default UserBDF;
