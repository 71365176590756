import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import useAuth from '../services/authService';
import UserRestricted from './userRestricted';

const ProtectedRouteAdmin = ({ children }) => {
  const auth = useAuth.getCurrentUser();

  if (!auth) return <Navigate to="/login" />;

  return auth.currentUser.role === 'admin' ? (
    children
  ) : (
    <Navigate to="/access-restricted" />
  );
};

export default ProtectedRouteAdmin;
