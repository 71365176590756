import React, { Fragment } from "react";
import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import restartWebflowScript from "../common/restartWebflowScript";
import StaticHeaderOne from "../common/UiLayoutsAndComponents/staticHeaderOne";
import AwardsWelcome from "./awardsWelcome";
import QuoteHighlight from "../common/UiLayoutsAndComponents/fullScreenQuoteHighlight";
import AwardsAndRecorgnitions from "./recorgnitionsCollection.jsx";

const Recorgnitions = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <StaticHeaderOne
        pageData={{
          titleText: "Awards & Recorgnitions",
          headerLocalClass: "recorgnitions",
          overlay: true,
        }}
      />
      <AwardsWelcome />
      <QuoteHighlight />
      <AwardsAndRecorgnitions />

      <Footer />
    </Fragment>
  );
};

export default Recorgnitions;
