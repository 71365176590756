import React, { useState } from 'react';
import configData from '../../config.json';
import { Link } from 'react-router-dom';
import { Button } from '@adobe/react-spectrum';
import { useContext } from 'react';
import UserContext from '../../context/userContext';
import Skeleton from 'react-loading-skeleton';

const storageUrl = configData.storageUrl;

const Car = (props) => {
  const user = useContext(UserContext);
  const { carData: car } = props;

  const [imgState, setImgState] = useState(false);

  return (
    <>
      {car && (
        <div className="l-car__card">
          <Link
            to={`/automobile/cars/${car.id}`}
            className="st-link w-inline-block"
          >
            <div className="l-car">
              <div className="excerpts__card__content--img relative">
                <div className="l-car__image">
                  <div
                    data-w-id="9f4b3681-6958-d3e3-247c-43e50ff82409"
                    className="st-img__wrp min-h-[150px]"
                  >
                    {car.imageCover && (
                      <img
                        src={`${storageUrl}${car.imageCover}`}
                        loading="lazy"
                        alt={`${car.make} ${car.model} | ${car.bodyType} | ${car.exteriorColour}`}
                        className="st__img z-10 absolute"
                        onLoad={() => {
                          setImgState(true);
                        }}
                      />
                    )}

                    {car.imageCover && !imgState && (
                      <div className="absolute h-[160px] w-full top-0 left-0 z-0">
                        <Skeleton height={150} />
                      </div>
                    )}
                    <div
                      // style="display:block;opacity:0"
                      className="st-img__overlay overlay--dark"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="excerpts__card__content--txt">
                <div className="l-car__title">
                  <h4 className="mb-0">
                    {`${car.make} ${car.model} | ${car.bodyType} | ${car.exteriorColour}`}
                  </h4>
                  {/* Car Year */}

                  {car.year && <span className="h6">{car.year}</span>}
                </div>
                {/* {car.description && <p>{car.description}</p>} */}
              </div>
            </div>
          </Link>

          <div className="w-full flex gap-4 mt-6">
            <Button
              // elementType={'a'}
              // href={`/automobile/manage-listing/${car.id}`}
              variant="accent"
              staticColor="black"
            >
              <span>
                <Link to={`/automobile/cars/${car.id}`}> View car</Link>
              </span>
            </Button>

            {user && user.role === 'auto-manager' && (
              <Button
                // elementType={'a'}
                // href={`/automobile/manage-listing/${car.id}`}
                variant="secondary"
                staticColor="black"
                style="outline"
              >
                <span>
                  <Link to={`/automobile/manage-listing/${car.id}`}>
                    Manage car
                  </Link>
                </span>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Car;
