import React from 'react';
import ToolTipOnHover from '../../common/toolTipOnHover';
import UserProfileFrom from './userProfileForms';

const UserProfileDashboard = () => {
  return (
    <main className="credit-dashbord">
      <div className="credit-dashbord__heading">
        <h3 className="heading-8">Manage account</h3>
        <ToolTipOnHover
          tip={{
            tipTitle: 'Account',
            tipIcon: '',
            tipText: 'Manage your account information and passwords.',
          }}
        />
      </div>
      <div className="credit-dashbord__details">
        {/* <div  className="credit-dashbord__sum"> */}
        <UserProfileFrom />
      </div>
    </main>
  );
};

export default UserProfileDashboard;
