import React, { Fragment } from 'react';
import withRouter from '../../HOC/withRouter';

import Form from '../common/formElements/form';
import { getUser, updateUser, deleteUser } from '../../services/userService';
import Navbar from '../common/navbar/navbar';
import Footer from '../common/footer/footer';
import restartWebflowScriptClassComponents from '../common/restartWebflowScriptClassConponents';
import StaticHeaderOne from '../common/UiLayoutsAndComponents/staticHeaderOne';
import ProgressRender from '../progressRender';
import { initProgressLotties } from '../../utils/initProgressLottie';
import progressHandler from '../../utils/progressHandler';
import { formatCurrency } from '../../utils/formatCurrency';
import ClientBrief from './adminClientComponents/clientBrief';
import HeaderBlur from './adminClientComponents/headerBlur';
import ClientNav from './adminClientComponents/clientNav';

import configData from '../../.../../config.json';
const storageUrl = configData.storageUrl;
import AdminInfoDashboard from './adminClientInfoDashboard';
import BackToHomeBtn from './adminClientComponents/dashboardHomeButton';
import AdminInvestmentsDashboard from './adminClientInvestmentsDashboard';
import AdminLoansDashboard from './adminClientLoansDashboard';

class ClientSelected extends Form {
  state = {
    data: {
      title: '',
      genreId: '',
      numberInStock: '',
      dailyRentalRate: '',
    },
    genres: [],
    errors: {},
    dataLoadState: 'loading',
    dashboardLocation: 'client-info',
  };

  async populateClient() {
    try {
      // Access router params in <= 5
      // const clientId = this.props.match.params.id;

      // Custom HOC to pass the routers url params to class component
      const clientId = this.props.params.id;

      if (clientId === 'new') return;

      const { data: client } = await (await getUser(clientId)).data.data;
      // this.setState({ data: this.mapToViewModel(client) });
      this.setState({ client: client });
      this.setState({ dataLoadState: 'loadingComplete' });
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('client', 'loading');
        progressHandler.showProgress('client', 'error', msg);
      } else {
        progressHandler.hideProgress('client', 'loading');
        progressHandler.showProgress(
          'client',
          'error',
          'An error occurred, try reloading the page or try again later'
        );
      }

      if (ex.response && ex.response.status === 404)
        setTimeout(() => {
          this.props.history.replace('/not-found');
        }, 1500);
    }
  }

  async componentDidMount() {
    restartWebflowScriptClassComponents();

    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('client', 'loading');
    // await this.populateGenres();
    await this.populateClient();
  }

  mapToViewModel(client) {
    return {
      _id: client._id,
      title: client.client,
      genreId: client.genre._id,
      numberInStock: client.numberInStock,
      dailyRentalRate: client.dailyRentalRate,
    };
  }

  updateClient = async (client, clientData, user, userData) => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('load', 'loading');

    try {
      // throw { name: 'Err', message: 'Nothing really' };
      // return;

      let { data } = await (await updateClient(client, clientData)).data.data;

      // Update user client
      if (user && userData) {
        await updateUser(user, userData);
      }

      // Hide UI loadin, show success
      progressHandler.hideProgress('load', 'loading');
      progressHandler.showProgress('load', 'success');

      // const clientState = { ...this.state.client, data };
      data.user = this.state.client.user;

      setTimeout(() => {
        // window.location.reload(false);
        // this.setState( clientResult);

        this.setState({ client: data });
        progressHandler.hideProgress('load', 'success');
      }, 1500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress('load', 'error', msg);
      } else {
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress(
          'load',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('load', 'error');
      }, 2000);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/clients");
  };

  withdrawClient = () => {
    const client = this.state.client;

    this.updateClient(
      client._id,
      {
        status: 'completed',
      },
      client.user.id,
      {
        clientState: 'none',
      }
    );
  };

  approveClient = () => {
    const client = this.state.client;

    this.updateClient(client._id, {
      status: 'active',
      funded: true,
    });
  };

  cancelClient = () => {
    const client = this.state.client;

    this.updateClient(
      client._id,
      {
        status: 'cancelled',
      },
      client.user.id,
      {
        clientState: 'none',
      }
    );
  };

  deleteClient = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('load', 'loading');

    try {
      const client = this.state.client;

      await deleteClient(client.id);
      // await updateUser(data.user.id, { clientState: 'none' });

      // Hide UI loadin, show success

      progressHandler.hideProgress('load', 'loading');
      initProgressLotties();
      progressHandler.showProgress('load', 'success');

      setTimeout(() => {
        window.location = '/admin/clients';
      }, 1500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress('load', 'error', msg);
      } else {
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress(
          'load',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('load', 'error');
      }, 3500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/clients");
  };

  updateDashboardLocation = (location) => {
    this.setState({ dashboardLocation: location });
  };

  render() {
    const client = this.state.client;

    if (client) {
      let clientDate = client.createdAt;
      clientDate = new Date(clientDate).toLocaleDateString();
    }

    if (this.state.dataLoadState === 'loading') {
      return (
        <Fragment>
          <Navbar />
          <div className="user-dashboard__header">
            <div className="user-dashboard__header__header-content">
              <div className="container is--narrow"></div>
              <div className="div-block-120"></div>
              <div className="overlay--dark"></div>
              <div className="user-dashboard__header__content"></div>
            </div>
          </div>

          <div className="_1-col-c-center wf-section">
            <div className="container">
              <div className="_1-col-c-center__wrp">
                <div className="_1-col-c-center__c-narrow ">
                  <div className="st-form-blk">
                    <ProgressRender
                      elData={{
                        loading: { name: 'client-progress__loading' },
                        success: { name: 'client-progress__success' },
                        error: { name: 'client-progress__error' },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }

    if (this.state.dataLoadState === 'loadingComplete') {
      const client = this.state.client;
      let clientFirstName;

      if (client) clientFirstName = client.name.split(' ')[0];

      return (
        <Fragment>
          <Navbar />

          <div className="user-dashboard wf-section">
            <HeaderBlur client={client} />
            <section className="section user-dash__main">
              <div className="container">
                <div className="user-dash__local-menu">
                  <button className="user-dash__local-menu-toggle closed">
                    <div className="user-dash__local-menu-toggle__container">
                      <div className="user-dash__local-menu-toggle__icon closed">
                        <span className="is-icon">+</span>
                      </div>
                      <div className="user-dash__local-menu-toggle__menu-label">
                        <p className="">Menu</p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="user-dash__grid">
                  <ClientBrief client={client} />
                  <div className="user-credit-dash st-b-radius">
                    <aside
                      id="w-node-_4217e38b-4f10-cd14-e0a3-0c6874739bb2-39382180"
                      className="user-credit-dash__col-left"
                    >
                      <ClientNav
                        navProps={[
                          {
                            navLink: ``,
                            navLinkTxt: `Personal Info`,
                            navIcon: '',
                            location: 'client-info',
                          },
                          {
                            navLink: `mailto:${client.email}`,
                            navLinkTxt: 'Email ' + clientFirstName,
                            navIcon: '',
                          },
                          {
                            navLink: `tel:${client.phoneNumber}`,
                            navLinkTxt: 'Call ' + clientFirstName,
                            navIcon: '',
                          },
                          {
                            navLink: ``,
                            navLinkTxt: `${clientFirstName}'s invest.`,
                            navIcon: '',
                            location: 'client-investments',
                          },
                          {
                            navLink: ``,
                            navLinkTxt: `${clientFirstName}'s loans`,
                            navIcon: '',
                            location: 'client-loans',
                          },
                        ]}
                        updateDashboardLocation={this.updateDashboardLocation}
                      />

                      {this.state.dashboardLocation !== 'client-info' && (
                        <div className="offest-top--3em">
                          <BackToHomeBtn
                            updateDashboardLocation={
                              this.updateDashboardLocation
                            }
                          />
                        </div>
                      )}
                    </aside>
                    <main className="credit-dashbord">
                      {this.state.dashboardLocation === 'client-info' && (
                        <AdminInfoDashboard
                          client={client}
                          updateDashboardLocation={this.updateDashboardLocation}
                        />
                      )}

                      {this.state.dashboardLocation ===
                        'client-investments' && (
                        <AdminInvestmentsDashboard
                          client={client}
                          updateDashboardLocation={this.updateDashboardLocation}
                        />
                      )}

                      {this.state.dashboardLocation === 'client-loans' && (
                        <AdminLoansDashboard
                          client={client}
                          updateDashboardLocation={this.updateDashboardLocation}
                        />
                      )}
                    </main>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Footer />
        </Fragment>
      );
    }
  }
}

export default withRouter(ClientSelected);
