import React from 'react';
import { Link } from 'react-router-dom';

function Slide(props) {
  return (
    <div
      data-w-id="da700eed-4ca2-9a0f-1f5f-910d3f1d96b2"
      className="hero-slider__slide  w-slide img--share-02"
    >
      <div className="overlay--dark overlay--dark--65deg"></div>
      {/* <div
        data-poster-url="videos/the-cooperative-hero-vid-mod-poster-00001.jpg"
        data-video-urls="videos/the-cooperative-hero-vid-mod-transcode.mp4,videos/the-cooperative-hero-vid-mod-transcode.webm"
        data-autoplay={true}
        data-loop={true}
        data-wf-ignore="true"
        className="hero-slider__bg-video w-background-video w-background-video-atom"
      >
        <video
          autoPlay=""
          loop=""
          style={{
            backgroundImage:
              "url(/videos/the-cooperative-hero-vid-mod-poster-00001.jpg)",
          }}
          muted=""
          playsInline=""
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source
            src="videos/the-cooperative-hero-vid-mod-transcode.mp4"
            data-wf-ignore="true"
          />
          <source
            src="videos/the-cooperative-hero-vid-mod-transcode.webm"
            data-wf-ignore="true"
          />
        </video>
      </div> */}

      <div className="container hero-slider__container">
        <div className="slide__content ">
          <h1 className="slide__content__title">
            The Future
            <span style={{ fontWeight: 100 }}> Together</span>
          </h1>
          <p className="slide__content__desc">
            Building a cooperative economy through partnership
          </p>
          <div className="slide__cta">
            <Link
              to="/the-cooperative"
              className="s-btn is-btn--light w-inline-block"
            >
              <p>Learn more</p>
            </Link>
            <Link
              data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
              to="/sign-up"
              className="alt-btn is-btn--alt w-inline-block"
            >
              <div className="alt-btn__wrp">
                <p>Membership</p>
              </div>
              <div className="alt-btn__underline is-white__underline"></div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slide;
