import React from 'react';
import { Link } from 'react-router-dom';

const SWAutomobiles = (props) => {
  return (
    <div
      // data-w-id="da700eed-4ca2-9a0f-1f5f-910d3f1d96b2"
      className="hero-slider__slide w-slide hero-slider__slide hero-slider__slide--automobile"
    >
      {/* <div
        data-poster-url="/content/videos/the-cooperative-hero-vid-mod-poster-00001.jpg"
        data-video-urls="videos/the-cooperative-hero-vid-mod-transcode.mp4,videos/the-cooperative-hero-vid-mod-transcode.webm"
        data-autoplay={true}
        data-loop={true}
        data-wf-ignore="true"
        className="hero-slider__bg-video w-background-video w-background-video-atom"
      >
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline=""
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source
            src="/content/videos/geely-exteror-16x9.mp4"
            data-wf-ignore="true"
          />
          <source
            src="/content/videos/geely-exteror-16x9.webp"
            data-wf-ignore="true"
          />
        </video>
      </div> */}
      <div className="overlay--dark overlay--dark--65deg"></div>
      <div className="container hero-slider__container">
        <div className="slide__content">
          <h1 className="slide__content__title">
            <span style={{ fontWeight: 100 }}>Skyewise</span> Automobile
          </h1>
          <p className="slide__content__desc">
            Drive the real comfort, drive with Skyewise Autos
          </p>
          <div className="slide__cta">
            <Link
              to="/automobile/cars"
              className="s-btn is-btn--light w-inline-block"
            >
              <p>Car Center</p>
            </Link>
            <Link
              data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
              to="/automobile"
              className="alt-btn is-btn--alt w-inline-block"
            >
              <div className="alt-btn__wrp">
                <p>Learn more</p>
              </div>
              <div className="alt-btn__underline is-white__underline"></div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SWAutomobiles;
