import React from 'react';
import { Navigate } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from '../common/formElements/form';
import ProgressRender from '../progressRender';
import auth from '../../services/authService';
import { initProgressLotties } from '../../utils/initProgressLottie';
import progressHandler from '../../utils/progressHandler';
import { updateLoan } from '../../services/loanService';
import { updateUser } from '../../services/userService';

class LoanDeclineForm extends Form {
  state = {
    data: { loanDeclineReason: '' },
    errors: {},
  };

  schema = {
    loanDeclineReason: Joi.string().required().label('LoanDeclineReason'),
  };

  doSubmit = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('loan-decline', 'loading');
    try {
      const loanId = this.props.loanData._id;
      const userId = this.props.loanData.user._id;
      const { data: formDate } = this.state;

      let { data: loanData } = await (
        await updateLoan(loanId, {
          ...formDate,
          status: 'declined',
        })
      ).data.data;

      loanData.user = this.props.loanData.user;

      await updateUser(userId, { loanState: 'none', currentLoan: null });

      // Hide UI loadin, show success
      progressHandler.hideProgress('loan-decline', 'loading');
      progressHandler.showProgress('loan-decline', 'success');

      setTimeout(() => {
        // window.location.reload(false);
        this.props.doUpdatePageData(loanData);
        progressHandler.hideProgress('loan-decline', 'success');
      }, 2500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('loan-decline', 'loading');
        progressHandler.showProgress('loan-decline', 'error', msg);
      } else {
        progressHandler.hideProgress('loan-decline', 'loading');
        progressHandler.showProgress(
          'loan-decline',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('loan-decline', 'error');
      }, 1500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    // if (auth.getCurrentUser()) return <Navigate to="/user-dashboard" />;

    return (
      <div className="st-form-blk">
        <ProgressRender
          elData={{
            loading: { name: 'loan-decline-progress__loading', size: 'small' },
            success: { name: 'loan-decline-progress__success', size: 'small' },
            error: { name: 'loan-decline-progress__error', size: 'small' },
          }}
        />

        <form onSubmit={this.handleSubmit}>
          <p className="f-field__m-desc">
            Please state a reason for loan decline
          </p>
          {this.renderInput(
            'loanDeclineReason',
            'LoanDeclineReason',
            'text',
            'Reason For Loan decline',
            ''
          )}

          {this.renderButton('Decline', 'reject')}
        </form>
      </div>
    );
  }
}

export default LoanDeclineForm;
