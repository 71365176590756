import React from 'react';
import { openPlayer } from '../../../utils/videoPlayerModalControls';
import PlayerModalPlayBtn from '../../common/videoPlayerModalPlayBtn';

const CommunityEngagementHeader = (props) => {
  return (
    <header className="hero-style-fs wf-section">
      <div className="hero-style-fs__slider__slide__img-container">
        <img
          src="/images/content/seaf-foundation/skyewise-school-outreach-1080x557.jpg"
          loading="lazy"
          sizes="100vw"
          alt=""
          className="hero-style-fs__slider__slide-image"
        />
      </div>

      <div className="overlay--dark"></div>

      <div className="hero-style-fs__slider__slide-content" aria-hidden="true">
        <div
          className="container container--flex hero-style-fs__slider__container"
          aria-hidden="true"
        >
          <div
            className="hero-style-fs__slider__grid  fs-slider--grid-2-1"
            aria-hidden="true"
          >
            <div
              className="hero-style-fs__slider__slide-content--main"
              aria-hidden="true"
            >
              <div className="c-rich-text" aria-hidden="true">
                <h2
                  className="hero-style-fs__slider__slide-heading fs-slide-heading--xx-large is-txt-shadow"
                  aria-hidden="true"
                >
                  <span className="heading-large--tag">S.E.A.F </span>
                  Impact
                  <span className="is--txt-gray">.</span>
                </h2>
                <p
                  className="hero-style-fs__slider__slide-paragraph"
                  aria-hidden="true"
                >
                  Skyewise Empowerment & Advancement Foundation (S.E.A.F)
                </p>
              </div>
            </div>
            <div
              id="w-node-_827fcf9d-9921-95e4-e4a9-26c52e68d59e-224bd367"
              className="hero-style-fs__slider__slide__aside"
              aria-hidden="true"
            >
              <div
                className="slide-aside__thumbnail hero-style-fs__slider__slide__aside-text"
                aria-hidden="true"
              >
                <PlayerModalPlayBtn
                  props={{
                    handleClick: openPlayer,
                    playerId: 'p:id-mtceos-keynote',
                    playerSkin: 'light',
                  }}
                />
              </div>
              <div
                className="hero-style-fs__slider__slide__aside-text"
                aria-hidden="true"
              >
                <p className="is-txt__small" aria-hidden="true">
                  Past projects
                </p>
                <p className="c-h4" aria-hidden="true">
                  Highlight
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default CommunityEngagementHeader;
