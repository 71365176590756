import React, { Component } from "react";

const ProgressError = ({ name, message, size, transparency }) => {
  return (
    <div
      id={name}
      className="pwi__progress__flex"
      className={
        transparency ? "pwi__progress__flex transparent" : "pwi__progress__flex"
      }
    >
      <div className="pwi__progress__flex__item">
        <div className="pwi__progress">
          <div
            id="pwi__progress__error"
            className={"pwi__progress__svg " + size}
          ></div>
          <div className="pwi__progress__msg is-txt--small">
            <p id={name + "--msg"}> An error occurred, please try again</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressError;
