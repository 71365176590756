import React from 'react';
import truncateString from '../../../utils/stringTruncationV2';

const BlogUpdateMini = (props) => {
  const { postData } = props;

  return (
    <div className="sw-blog-update__card--mini">
      <a
        target="_blank"
        href={postData.link}
        className="st-link w-inline-block text-hover-highlight text-hover-highlight--red"
      >
        <h4 className="sw-blog-update__card--mini__title">
          {truncateString(postData.title.rendered, 85)}
        </h4>
      </a>
      <div className="sw-blog-update__card__metadata">
        <ul>
          <li className="metadata-tag--cat">
            <span target="">Report</span>
          </li>
          <li>{new Date(postData.date).toDateString()}</li>
        </ul>
      </div>
    </div>
  );
};

export default BlogUpdateMini;
