import React from 'react';
import configData from '../../../.../../config.json';
const storageUrl = configData.storageUrl;

const ClientIdCapcel = (props) => {
  const { client } = props;

  const { updateDashboardLocation } = props;

  return (
    <div
      onClick={() => updateDashboardLocation('client-info')}
      className="ud-main__user"
    >
      <div className="ud-main__user__icon">
        {/* {client.photo} */}
        <img src={`${storageUrl}${client.photo}`} />
      </div>
      <div className="ud-main__user__name">{client.name}'s</div>
    </div>
  );
};

export default ClientIdCapcel;
