import React, { Fragment } from 'react';
import withRouter from '../../HOC/withRouter';
import { Link } from 'react-router-dom';

import Joi from 'joi-browser';
import Form from '../common/formElements/form';
import {
  deleteLoan,
  getLoan,
  saveLoan,
  updateLoan,
} from '../../services/loanService';
import { getGenres } from '../../services/genreService';
import Navbar from '../common/navbar/navbar';
import Footer from '../common/footer/footer';
import restartWebflowScriptClassComponents from '../common/restartWebflowScriptClassConponents';
import StaticHeaderOne from '../common/UiLayoutsAndComponents/staticHeaderOne';
import ProgressRender from '../progressRender';
import { initProgressLotties } from '../../utils/initProgressLottie';
import progressHandler from '../../utils/progressHandler';
import LoanApprovalForm from './loanApprovalForm';
import LoanCancellationForm from './loanCancellationForm';
import ImageLightbox from '../common/imageLightbox';
import configData from '../../config.json';
import { formatCurrency } from '../../utils/formatCurrency';
import LoanDueForm from './loanDueForm';
import LoanSettledForm from './loanSettledForm';
import CheckerButton from '../../hooks/checkerButton';
import { updateUser } from '../../services/userService';

class LoanSelectedForm extends Form {
  state = {
    data: {
      paymentConfirmed: false,
      genreId: '',
      numberInStock: '',
      dailyRentalRate: '',
    },
    genres: [],
    errors: {},
    dataLoadState: 'loading',
  };

  schema = {
    _id: Joi.string(),
    title: Joi.string().required().label('Title'),
    genreId: Joi.string().required().label('Genre'),
    numberInStock: Joi.number()
      .required()
      .min(0)
      .max(100)
      .label('Number in Stock'),
    dailyRentalRate: Joi.number()
      .required()
      .min(0)
      .max(10)
      .label('Daily Rental Rate'),
  };

  async populateGenres() {
    const { data: genres } = await getGenres();
    this.setState({ genres });
  }

  async populateLoan() {
    try {
      // const loanId = this.props.match.params.id;

      // URL parameter HOC
      const loanId = this.props.params.id;

      if (loanId === 'new') return;

      const { data: loan } = await (await getLoan(loanId)).data.data;
      // this.setState({ data: this.mapToViewModel(loan) });
      this.setState({ loan: loan });
      this.setState({ dataLoadState: 'complete' });
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('loan', 'loading');
        progressHandler.showProgress('loan', 'error', msg);
      } else {
        progressHandler.hideProgress('loan', 'loading');
        progressHandler.showProgress(
          'loan',
          'error',
          'An error occurred, try reloading the page or try again later'
        );
      }

      if (ex.response && ex.response.status === 404)
        setTimeout(() => {
          this.props.history.replace('/not-found');
        }, 1500);
    }
  }

  async componentDidMount() {
    restartWebflowScriptClassComponents();

    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('loan', 'loading');
    // await this.populateGenres();
    await this.populateLoan();
  }

  doSubmit = async () => {
    await saveLoan(this.state.data);

    this.props.history.push('/loans');
  };

  updatePageData = (data) => {
    this.setState({ loan: data });
  };

  deleteLoan = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('load', 'loading');

    try {
      const loan = this.state.loan;

      await deleteLoan(loan.id);
      // await updateUser(data.user.id, { loanState: 'none' });

      // Hide UI loadin, show success

      progressHandler.hideProgress('load', 'loading');
      initProgressLotties();
      progressHandler.showProgress('load', 'success');

      setTimeout(() => {
        window.location = '/admin/loans';
      }, 1500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress('load', 'error', msg);
      } else {
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress(
          'load',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('load', 'error');
      }, 3500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/loans");
  };

  updateLoan = async (loan, loanData, user, userData) => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('load', 'loading');

    try {
      // throw { name: 'Err', message: 'Nothing really' };
      // return;

      let { data } = await (await updateLoan(loan, loanData)).data.data;

      // Update user loan
      if (user && userData) {
        await updateUser(user, userData);
      }

      // Hide UI loadin, show success
      progressHandler.hideProgress('load', 'loading');
      progressHandler.showProgress('load', 'success');

      // const loanState = { ...this.state.loan, data };
      data.user = this.state.loan.user;

      setTimeout(() => {
        // window.location.reload(false);
        // this.setState( loanResult);

        this.setState({ loan: data });
        progressHandler.hideProgress('load', 'success');
      }, 1500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress('load', 'error', msg);
      } else {
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress(
          'load',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('load', 'error');
      }, 2000);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/loans");
  };

  dueLoan = () => {
    const loan = this.state.loan;

    this.updateLoan(
      loan._id,
      {
        status: 'due',
        funded: true,
      },
      loan.user._id,
      {
        loanState: 'due',
      }
    );
  };

  paidLoan = () => {
    const loan = this.state.loan;

    this.updateLoan(
      loan._id,
      {
        status: 'settled',
      },
      loan.user._id,
      {
        loanState: 'none',
      }
    );
  };

  updateConfirmationState = (state) => {
    const newState = this.state[state] ? false : true;

    this.setState({
      [state]: newState,
    });
  };

  psuedoAction = () => {
    alert('Nothing really');
  };

  render() {
    const loan = this.state.loan;

    if (loan) {
      let loanDate = loan.createdAt;
      loanDate = new Date(loanDate).toLocaleDateString();
    }

    if (this.state.dataLoadState === 'loading') {
      return (
        <Fragment>
          <Navbar />

          <StaticHeaderOne
            pageData={{
              titleText:
                this.state.loan && this.state.loan.name
                  ? this.state.loan.user.name
                  : 'Loan',
              headerLocalClass: 'about-us',
            }}
          />

          <div className="_1-col-c-center wf-section">
            <div className="container">
              <div className="_1-col-c-center__wrp">
                <div className="_1-col-c-center__c-narrow">
                  <div className="st-form-blk">
                    <ProgressRender
                      elData={{
                        loading: { name: 'loan-progress__loading' },
                        success: { name: 'loan-progress__success' },
                        error: { name: 'loan-progress__error' },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }

    if (this.state.dataLoadState === 'complete') {
      return (
        <Fragment>
          <Navbar />
          <StaticHeaderOne
            pageData={{
              titleText:
                this.state.loan && this.state.loan.loan
                  ? this.state.loan.user.name
                  : 'loan',
              headerLocalClass: 'about-us',
              altTitle: 'Loaner',
            }}
          />
          {/* <div>
          <h1>Loan Form</h1>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("title", "Title")}
            {this.renderSelect("genreId", "Genre", this.state.genres)}
            {this.renderInput("numberInStock", "Number in Stock", "number")}
            {this.renderInput("dailyRentalRate", "Rate")}
            {this.renderButton("Save")}
          </form>
        </div> */}

          <div className="_1-col-c-center wf-section">
            <div className="container">
              <div className="_1-col-c-center__wrp">
                <div className="_1-col-c-center__c-narrow psuedo-pos--relativ">
                  <div className="s-dialuge-box__content__grid is--width-fill">
                    <div className="s-dialuge-box__content__details ">
                      <div className="c-rich-text">
                        <h2 className="s-dialuge-box__content__details__title">
                          Loan information
                        </h2>

                        {loan.loan && (
                          <Fragment>
                            {' '}
                            <p className="s-dialuge-box__content__details__desc st-r-mg ">
                              A loan request of{' '}
                              <span className="is-fw-600">
                                <strong>
                                  {' '}
                                  ₦
                                  {formatCurrency(
                                    Number(
                                      loan.approvedAmount > 0
                                        ? loan.approvedAmount
                                        : loan.amount
                                    )
                                  )}
                                </strong>
                              </span>{' '}
                              from{' '}
                              <span className="is-fw-600">
                                <strong>{loan.user.name} </strong>
                              </span>{' '}
                              on{' '}
                              <strong>{`${new Date(
                                loan.createdAt
                              ).toDateString()}`}</strong>
                              .
                            </p>
                            <ul>
                              <li>Loan collateral: {loan.loanCollateral}</li>

                              <li>
                                Approved amount:{' '}
                                <strong>
                                  {loan.approvedAmount > 0
                                    ? `
                                    ₦${formatCurrency(
                                      Number(loan.approvedAmount)
                                    )}`
                                    : 'Pending approval'}
                                </strong>
                              </li>

                              <li>
                                Amount requested:{' '}
                                <strong>
                                  ₦{formatCurrency(Number(loan.amount))}
                                </strong>
                              </li>

                              <li>
                                Loan purpose:{' '}
                                <span className="st-note-block">
                                  {loan.loan}
                                </span>
                              </li>
                            </ul>
                          </Fragment>
                        )}
                      </div>
                    </div>

                    <div className="c-rich-text">
                      <aside className="s-dialuge-box__content__aside">
                        <div
                          className={`s-dialuge-box__status s-dialuge-box__status--flx s-dialuge-box__status `}
                        >
                          {loan && (
                            <Fragment>
                              <span
                                className={`is-icon sm-rt-margin ${loan.status}`}
                              >
                                
                              </span>
                              <p>{loan.status}</p>
                            </Fragment>
                          )}
                        </div>
                        <div className="s-dialuge-box__amount">
                          <p className="text--big text--big--bold">
                            {loan && (
                              <span>
                                ₦
                                {formatCurrency(
                                  Number(
                                    loan.approvedAmount > 0
                                      ? loan.approvedAmount
                                      : loan.amount
                                  )
                                )}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="s-dialuge-box__aside__inner-container">
                          {loan && (
                            <p className="s-dialuge-box__aside__caption">
                              <span
                                className={`is-icon sm-rt-margin is-icon--inline`}
                              >
                                
                              </span>
                              Date of request
                              <h4 className="s-dialuge-box__aside__date">
                                {
                                  // (loanDate = new Date(
                                  //   loan.createdAt
                                  // ))
                                }
                                {`${new Date(loan.createdAt).toDateString()}`}
                              </h4>
                            </p>
                          )}

                          {loan && loan.user && (
                            <Fragment>
                              <div className="s-dialuge-box__aside_grouper ">
                                <p className="s-dialuge-box__aside__caption">
                                  <span
                                    className={`is-icon sm-rt-margin is-icon--inline`}
                                  >
                                    
                                  </span>
                                  Loaner
                                </p>
                                <div className=" grouper__user-info">
                                  <div class="nav__user-photo">
                                    <img
                                      src={`${configData.storageUrl}${loan.user.photo}`}
                                      loading="lazy"
                                      alt=""
                                      class="user__profile__photo__img"
                                    />
                                  </div>

                                  <h5 className="">
                                    <Link
                                      target="_blank"
                                      to={'/admin/clients/' + loan.user._id}
                                      className="is-fw-600 s-dialuge-box__aside__date"
                                    >
                                      <strong> {loan.user.name} </strong>
                                    </Link>
                                  </h5>
                                </div>
                              </div>

                              {/* Loan Delete Action */}
                              <CheckerButton
                                label={{ name: 'Delete loan request' }}
                                btn={{
                                  btnText: 'Delete',
                                  onCheck: this.updateConfirmationState,
                                  action: this.deleteLoan,
                                  styleClasses: 'alt-btn delete w-inline-block',
                                }}
                                parentState={{
                                  name: 'checkerConfirmDelete',
                                  state: this.state.checkerConfirmDelete,
                                }}
                              />
                            </Fragment>
                          )}
                        </div>
                      </aside>
                    </div>
                  </div>

                  <div className="hr-line hr-line--dark"></div>

                  {this.state.loan.collateralImages.length >= 1 && (
                    <div className="is--txt-left c-rich-text is--width-fill ">
                      <h3>Loan collateral</h3>
                      <ImageLightbox
                        images={this.state.loan.collateralImages}
                      />
                    </div>
                  )}

                  {this.state.loan.collateralImages.length == 0 && (
                    <div className="is--txt-left c-rich-text is--width-fill ">
                      <h3>Loan collateral</h3>
                      <p>No loan collateral available for this loan</p>
                    </div>
                  )}

                  {/* Admin Loan Management */}

                  <div className="hr-line hr-line--dark"></div>

                  <div className="is--txt-left c-rich-text is--width-fill ">
                    <h3>Manage loan</h3>
                  </div>

                  <div className="_2-col-st-grid _2-col-st-grid--mini-gap  is-wt-100 ">
                    {this.state.loan.status === 'pending' && (
                      <Fragment>
                        <LoanApprovalForm
                          doUpdatePageData={this.updatePageData}
                          loanData={this.state.loan}
                        />
                        <LoanCancellationForm
                          doUpdatePageData={this.updatePageData}
                          loanData={this.state.loan}
                        />
                      </Fragment>
                    )}

                    {this.state.loan.status === 'declined' && (
                      <p className="is--txt-left">
                        This loan request was declined
                      </p>
                    )}

                    {this.state.loan.status === 'settled' && (
                      <p className="is--txt-left">
                        This loan has been completed
                      </p>
                    )}

                    {this.state.loan.status === 'disbursed' && (
                      <CheckerButton
                        label={{
                          name: 'This loan is due for repayment (notify loaner)',
                        }}
                        btn={{
                          btnText: 'Loan due',
                          onCheck: this.updateConfirmationState,
                          action: this.dueLoan,
                          styleClasses: 's-btn s-btn--reject w-inline-block',
                        }}
                        parentState={{
                          name: 'checkerConfirmDue',
                          state: this.state.checkerConfirmDue,
                        }}
                      />
                    )}

                    {this.state.loan.status === 'due' && (
                      <CheckerButton
                        label={{ name: 'Confirm this loan has been paid' }}
                        btn={{
                          btnText: 'Loan settled',
                          onCheck: this.updateConfirmationState,
                          action: this.paidLoan,
                          styleClasses:
                            's-btn s-btn--blue w-inline-block is-btn--mgr-2',
                        }}
                        parentState={{
                          name: 'checkerConfirmDisbursed',
                          state: this.state.checkerConfirmDisbursed,
                        }}
                      />
                    )}
                  </div>

                  <ProgressRender
                    elData={{
                      loading: {
                        name: 'load-progress__loading',
                        size: 'small',
                        // transparency: true,
                      },
                      success: { name: 'load-progress__success' },
                      error: { name: 'load-progress__error' },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
  }
}

export default withRouter(LoanSelectedForm);
