import React, { Fragment } from "react";
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { Link } from "react-router-dom";
import restartWebflowScript from "../../common/restartWebflowScript";

const DirectorDrNosiruGbadamosi = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="internal-nav w-nav"
        >
          <div className="header-static static-header--leadership wf-section">
            <div className="container">
              <div className="header-statoc--flex">
                <div className="header-static__container">
                  <h1>Dr. Nosiru U Gbadamosi</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow is--left-align ">
                <div className="c-rich-text">
                  <div className="div-block-96 ">
                    <div className="div-block-97">
                      <img
                        src="/images/content/our-people/directors/dr_nosiru_gbadamosi.webp"
                        loading="lazy"
                        alt="Dr. Nosiru U Gbadamosi"
                      />
                    </div>
                    <div className="div-block-97">
                      {/* <a className="st-link w-inline-block" href="/"> */}
                      <h4 className="c-h4">Dr. Nosiru U Gbadamosi</h4>
                      {/* </a> */}
                      <p>Director </p>
                      <p className="p-medium">
                        Dr. Nasir has over 20 years of cognate core banking
                        experience with quality knowledge in operations, credit
                        and marketing, relationship, and bank management.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="_1-col-c-center__c-narrow is--left-align  st-r-mg ">
                <div className="c-rich-text">
                  <p className="p-small">
                    He holds a Ph.D. in Credit Management, M.Sc. Economics
                    (Distinction), MBA (Banking and Finance), B.Sc. Business
                    Administration.
                  </p>

                  <p className="p-small">
                    He is a seasoned banker with vast knowledge of the
                    regulatory, business environment, and banking guidelines. He
                    has local and international banking management experience
                    and has worked in various banking institutions, namely;
                    Citizens International Bank, Platinum Habib later Bank PHB
                    PLC now Keystone Bank, Standard Trust Bank now UBA Bank PLC,
                    Heritage/Enterprise Bank Ltd. He was the pioneer MD /CEO of
                    Keystone Bank Sierra Leone.
                  </p>

                  <p className="p-small">
                    Nosiru has domestic and international training in
                    leadership, corporate finance, risk management, and
                    relationship management. He is a member of various
                    professional associations including Fellow Chartered
                    Economist (FCE) - the Institute of Chartered Economist of
                    Nigeria, Honorary Senior Member (HCIB) -Chartered Institute
                    of Bankers of Nigeria, Fellow (FNIM) - Nigerian Institute of
                    Management, Fellow (FCIA)- Chartered Institute of
                    Administration, Fellow (FICA)- Institute of Credit
                    Administration, Fellow (FIMC)-Institute of Management
                    Consultants and Fellow (FCImpt) Chartered Institute of
                    Marine, Port and Terminal Management Professionals, USA.
                  </p>

                  <p className="p-small">
                    He is currently the MD/CEO of Jexster Investment Limited, a
                    Management and Financial Consulting and Advisory Company,
                    and a board member (Deputy Managing Director ) of Aquashiled
                    Oil and Marine Services Limited, a Marine Security and
                    Offshore Services Company.
                  </p>
                </div>
              </div>

              <div className="_1-col-c-center__c-narrow st-r-mg">
                <div className="c-rich-text">
                  <h3>Directors</h3>
                </div>
                <Link
                  to="/about-us/people-and-leadership/directors"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Go back to all directors</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DirectorDrNosiruGbadamosi;
