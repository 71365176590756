import { Button } from '@adobe/react-spectrum';
import { ToastQueue } from '@react-spectrum/toast';
import React from 'react';

const TestComp = (props) => {
  return (
    <Button
      onPress={() => ToastQueue.positive('Toast is done!', { timeout: 5000 })}
      variant="primary"
    >
      Show toast
    </Button>
  );
};

export default TestComp;
