import React from 'react';
import { logout } from '../../services/authService';

const DashboardLocalNavMobileMenuItem = (props) => {
  const menuProps = props.onToggleMenu;
  const { menuData } = props;

  return (
    <li className="user-dash__local-menu__items__item">
      <button
        className="user-dash__local-menu__items__item--btn"
        onClick={() => {
          props.onToggleMenu.updateMenuState('closed');

          if (menuData.menuLable == 'Logout') {
            logout();
            return;
          }
          props.menuClick(menuData.currentMenu);
        }}
      >
        <div className="user-dash__local-menu__items__item--icon is-icon">
          <span>{menuData.menuIcon}</span>
        </div>
        <div>
          <p>{menuData.menuLable}</p>
        </div>
      </button>
    </li>
  );
};

export default DashboardLocalNavMobileMenuItem;
