import React from 'react';
import { Link } from 'react-router-dom';

const RealEstateGallery = () => {
  return (
    <div className="_1-col-c-center white-bg-01 wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          {/* <div className="_1-col-c-center__c-narrow"> */}
          <div className="_3-col-st-grid ">
            <div className="grid__item is-txt-align--left ">
              {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
              {/* <img className="img--st-b-radius" src="/content/images/real-estate/city-render-16x9.jpeg" /> */}
              <h2 className="c-title--large"> Skyewise Smart Homes</h2>
              <p> Providing affordable smart homes</p>
            </div>

            <div className="grid__item">
              {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
              <img
                className="img--st-b-radius"
                src="/content/images/real-estate/properties/rs-01.jpg"
              />
            </div>

            <div className="grid__item">
              {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
              <img
                className="img--st-b-radius"
                src="/content/images/real-estate/properties/rs-02.jpg"
              />
            </div>

            <div className="grid__item">
              {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
              <img
                className="img--st-b-radius"
                src="/content/images/real-estate/properties/rs-03.jpg"
              />
            </div>
            <div className="grid__item">
              {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
              <img
                className="img--st-b-radius"
                src="/content/images/real-estate/properties/rs-04.jpg"
              />
            </div>
            <div className="grid__item">
              {/* <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p> */}
              <img
                className="img--st-b-radius"
                src="/content/images/real-estate/properties/rs-05.jpg"
              />
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default RealEstateGallery;
