import React from 'react';
import tabNavigator from '../utils/tabNavigator';

const TabContentAndImage = (props) => {
  const { content, tabTitle } = props;
  const tabImgs = content.map((i) => {
    return i.img;
  });

  let baseClass = 'glue__content-tab__item',
    baseClassImg = 'glue__content-tab__item__img',
    activeClass = 'tab__content--active';

  return (
    <div className="_2-col-st-grid">
      <div className="grid-item">
        <h2>{tabTitle}</h2>
        <div className="glue__content-tabs">
          {content.map((i, n) => (
            <div
              className={
                n + 1 === 1 ? baseClass + ' ' + activeClass : baseClass
              }
              id={`tab${n + 1}`}
              onClick={(e) =>
                tabNavigator(
                  'glue__content-tab__item',
                  'tab__content--active',
                  e.currentTarget
                )
              }
            >
              <h4 className="glue__content-tab__title">{i.title} </h4>
              <p>{i.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="glue__content-tab__imgs">
        {tabImgs.map((i, n) => (
          <img
            className={
              n + 1 === 1 ? baseClassImg + ' ' + activeClass : baseClassImg
            }
            id={`tab${n + 1}__img`}
            src={i}
          />
        ))}
      </div>
    </div>
  );
};

export default TabContentAndImage;
