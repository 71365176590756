import React, { Fragment } from "react";
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { Link } from "react-router-dom";
import restartWebflowScript from "../../common/restartWebflowScript";

const DirectorUsmanSarki = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="internal-nav w-nav"
        >
          <div className="header-static static-header--leadership wf-section">
            <div className="container">
              <div className="header-statoc--flex">
                <div className="header-static__container">
                  <h1>Ambassador Usman Sarki</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow is--left-align ">
                <div className="c-rich-text">
                  <div className="div-block-96 ">
                    <div className="div-block-97">
                      <img
                        src="/images/content/our-people/directors/ambassador_usman_sarki.webp"
                        loading="lazy"
                        alt="Ambassador Usman Sarki"
                      />
                    </div>
                    <div className="div-block-97">
                      {/* <a className="st-link w-inline-block" href="/"> */}
                      <h4 className="c-h4">Ambassador Usman Sarki</h4>
                      {/* </a> */}
                      <p>Chairman </p>
                      <p className="p-medium">
                        Ambassador Sarki’s career highlights consisted largely
                        of policy and program development at the United Nations
                        and at the Ministry of Foreign Affairs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="_1-col-c-center__c-narrow is--left-align st-r-mg ">
                <div className="c-rich-text">
                  <p className="p-small">
                    Ambassador Usman Sarki is a retired career diplomat of the
                    Federal Republic of Nigeria who was involved in the crafting
                    and execution of his country’s foreign policy. He was born
                    in Maiduguri, Borno State, and graduated from the Ahmadu
                    Bello University Zaria and the University of Lagos in
                    Nigeria. He was appointed an Ambassador in 2012 and posted
                    to the Permanent Mission of Nigeria to the United Nations in
                    New York as Deputy Permanent Representative. He served in
                    that capacity until 2016.
                  </p>

                  <p className="p-small">
                    He has held various other offices in the Ministry of Foreign
                    Affairs and Nigerian admissions abroad. He served in
                    Canberra (Australia), Jeddah (Saudi Arabia), Geneva
                    (Switzerland), and New York (the United States of America)
                    at different periods. Upon retirement, he was appointed as
                    Directing Staff in Nigeria’s apex think-tank, the National
                    Institute for Policy and Strategic Studies (NIPSS), Kuru
                    where he lectures on diplomacy and public policy issues.
                  </p>

                  <p className="p-small">
                    He is currently the Chairman of the Advisory Committee on
                    Governance and Development in Borno State that was appointed
                    to advise the Executive Governor of the State on all public
                    policy and development issues. He is also a member of the
                    Board of Governors of the Renaissance Development Forum
                    (RDF), a public policy research and advocacy civil society
                    organization that is composed of eminent and highly
                    experienced men and women drawn from across Northern
                    Nigeria.
                  </p>

                  <p className="p-small">
                    Ambassador Sarki’s career highlights consisted largely of
                    policy and program development at the United Nations and at
                    the Ministry of Foreign Affairs. As Deputy Permanent
                    Representative in New York, he was engaged in numerous
                    processes in the United Nations that included leading the
                    African Group in the negotiation and adoption of the
                    Sustainable Development Goals (SDGs) in September 2015. He
                    also led the African Group in the negotiations at the Third
                    United Nations Conference on Financing for Development
                    (UNCFFD) at Addis Ababa, Ethiopia, in July 2015.
                  </p>

                  <p className="p-small">
                    He represented Nigeria in the United Nations Security
                    Council during Nigeria’s tenure in 2014-2015 as a
                    non-permanent member. In that capacity, Ambassador Sarki
                    toured several countries to discuss with the leadership on
                    matters before the Security Council concerning those
                    countries. These included Mali, Haiti, the Central African
                    Republic, Somalia, the Democratic Republic of Congo, South
                    Sudan, and others. He also attended high-level meetings on
                    the reform of the Security Council in Turkey, Algeria,
                    Italy, and several other meetings in New York.
                  </p>

                  <p className="p-small">
                    Ambassador Sarki also participated in the work of the
                    Intergovernmental Negotiations on the Reform of the Security
                    Council that involved interactions with all the regional
                    political groupings in the United Nations. He was also
                    involved in the processes and activities of the
                    Peacebuilding Commission and the U.N. Peacekeeping mandates
                    in Africa, whereby matters related to peace and security in
                    affected countries like Sierra Leone, Liberia, Cote
                    D’Ivoire, CAR, Guinea Bissau, etc, were discussed.
                  </p>

                  <p className="p-small">
                    Ambassador Sarki interacted with non-governmental
                    organizations and civil society groups throughout his
                    career, thereby making him sensitive and alert to their
                    significance and roles in deepening democracy and good
                    governance in countries around the world. He was deeply
                    involved as a Foreign Service Officer serving in Geneva,
                    Switzerland, in the UN’s human rights processes and
                    mechanisms. He participated in the work of the Commission on
                    Human Rights (CHR) and actively took part in the
                    negotiations and discussions on the transition of the CHR to
                    the Human Rights Council (HRC) in 2006.
                  </p>

                  <p className="p-small">
                    He also took part in the norm-setting work of the World
                    Intellectual Property Organisation (WIPO) particularly in
                    establishing the Development Agenda program of the
                    organization, in which he led the African Group of Experts
                    in 2005. He also participated in discussions related to
                    intellectual property rights and development with a
                    particular focus on issues like access to affordable
                    medicine, food security, renewable energy technology, the
                    role of patents and trademarks in national development, etc.
                    He led the African Group in the negotiations that led to the
                    adoption of the WIPO Convention on Trademarks (Singapore
                    Treaty) in 2006. He also participated in many intellectual
                    property-related meetings in such countries as India, China,
                    Kyrgyzstan, Brazil, Zimbabwe, and Kenya.
                  </p>
                </div>
              </div>

              <div className="_1-col-c-center__c-narrow st-r-mg">
                <div className="c-rich-text">
                  <h3>Directors</h3>
                </div>
                <Link
                  to="/about-us/people-and-leadership/directors"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Go back to all directors</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DirectorUsmanSarki;
