import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';

const SWI = (prop) => {
  return (
    <Fragment>
      <div className="_1-col-c-center  wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <p className="p-medium st--mb-0">
                  Our purpose is to advance sustainable economic growth and
                  financial opportunity.
                </p>
                <p>
                  Through our investment platform, we help our clients build a
                  treasury for the future
                </p>
              </div>
            </div>
          </div>

          {/* <div className="_3-col-grid__wt-devider is-mt-mediom">
            <div className="grid__item">
              <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p>
              <div className="c-rich-text">
                <h4>Cooperative Loan</h4>
                <p>
                  Driven by significant growth, sustained confidence from
                  investors, and increasingly high valuations
                </p>
              </div>
            </div>
            <div
              id="w-node-bc0de631-570d-5bb4-52cf-5d16ee2a6836-06d8b623"
              className="grid__wt-devider"
            ></div>
            <div className="grid__item">
              <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p>
              <div className="c-rich-text">
                <h4>Investors Confidence</h4>
                <p>
                  Providing financing and advisory services to our clients on
                  some of their most important strategic decisions and
                  transactionsimport { Link } from 'react-router-dom';

                </p>
              </div>
            </div>
            <div
              id="w-node-_19653f1c-c289-f06d-9f60-9749c231dae3-06d8b623"
              className="grid__wt-devider"
            ></div>
            <div className="grid__item">
              <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p>
              <div className="c-rich-text">
                <h4>Heading</h4>
                <p>
                  Our structure allows us to better serve the strategic and
                  financing needs of our clients across all geographies and
                  industries
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="section--is-grey wf-section">
        <div className="is--c-pg-section-bg is--c-pg-section-bg__investment">
          <div className="overlay--dark"></div>
        </div>
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text is--txt-white ">
                <h2>Taking our clients beyond the generic.</h2>
                <p>
                  Partner with us to design a resource management plan that
                  meets the unique needs of your business and personal finances.
                </p>
              </div>
            </div>
          </div>
          <div className="_2-col-st-grid is--t-mg-2vh">
            <div
              id="w-node-d32d7ecc-132a-3c09-e382-4dfad903fa9f-f14bd371"
              data-w-id="d32d7ecc-132a-3c09-e382-4dfad903fa9f"
              className="s-cards-slider"
            >
              <div className="div-block-129">
                <div
                  className="s-cards-slider__wheel"
                  //   style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <h3 className="s-cards-slider__title">Investment Returns</h3>
                  <p className="s-cards-slider__p">
                    Our investment gives you highly competitive returns on your
                    investment, realizing the true value of your funds. At the
                    crux of our firm is advancing sustainable economic growth
                    and financial opportunity for our clients.
                  </p>
                  <div className="content-rw__cta justify-center s-cards-slider__cta">
                    <a
                      href="#investment-benefits"
                      className="st-link st-link--is-blue"
                    >
                      Benefits
                    </a>
                    <span className="content-rw__cta-devider"></span>
                    <Link
                      to="/user-dashboard"
                      className="st-link st-link--is-blue"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-ba24641a-8683-b280-ea9a-ca514c457bd2-f14bd371"
              data-w-id="ba24641a-8683-b280-ea9a-ca514c457bd2"
              className="s-cards-slider"
            >
              <div className="div-block-129">
                <div
                  className="s-cards-slider__wheel"
                  //   style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <h3 className="s-cards-slider__title">Targeted savings</h3>
                  <p className="s-cards-slider__p">
                    Our target savings allow you to set savings order to your
                    business or salary account, an automated instruction which
                    credits your target account with your desired amount of
                    money at a set interval e.g., weekly/monthly
                  </p>
                  <div className="content-rw__cta justify-center s-cards-slider__cta">
                    <a
                      href="#investment-benefits"
                      className="st-link st-link--is-blue"
                    >
                      Learn more
                    </a>
                    <span className="content-rw__cta-devider"></span>
                    <Link
                      to="/user-dashboard"
                      className="st-link st-link--is-blue"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits */}

      <section
        id="investment-benefits"
        className="section site-intro wf-section"
      >
        <div className="container">
          <div className="_2-col-st-grid _2-col-st-grid--make-1-col">
            <div className="grid-col--left">
              <div className="c-rich-text">
                <h3>Investment</h3>
                <ul className="i-benefits">
                  <li className="i-benefit">
                    <h4>Competitive Investment Benefits</h4>
                    <p>
                      This product is designed to specifically pay you higher
                      interest rate on your savings.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid-col--right">
              <div className="c-rich-text">
                <h3>Targeted Savings</h3>
                <ul className="i-benefits">
                  <li className="i-benefit">
                    <h4>Future Security</h4>
                    <p>
                      This is a product that guarantees customers high returns
                      on their medium and long term savings.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>
                  Before we get to know your money, we get to know you first
                </h3>
                <p>
                  We take the time to get to know you personally. Investing with
                  us, you’ll gain clarity, confidence, and control over your
                  decisions and know where you stand at all times, on your
                  terms.
                </p>
              </div>
              <Link
                to="/sign-up"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Start Today</p>
                <p className="is-icon"></p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SWI;
