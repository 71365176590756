import React, { useLayoutEffect } from 'react';
import restartWebflowScript from '../restartWebflowScript';
import SleakSlide from './sleakSlide';

const slidesData = [
  {
    title:
      ' We offer our people the opportunity to move ahead more rapidly than is possible at most other places.',
    description:
      ' Advancement depends on merit and we have yet to find the limit to the responsibility our best people are able to assume For us to be successful, our people must reflect the diversity of the communities and cultures in which we operate.',
    img: '/images/content/finance/loan-department.jpg',
  },

  {
    title: 'The child in pursuit of knowledge',
    description:
      ' Skyewise Multi Purpose Cooperative Society Back to School product is aimed at availing customers with the much needed funds to send their kids or wards to school without the initial fear generated when school resumption dates draw near. To accomplish this goal, Skyewise requires customers to follow register with the cooperative and domicile savings according to stipulated plans.',
    img: '/images/content/finance/building-the-futre-together-1080x557.jpg',
  },

  {
    title:
      'Partner with us to develop an investment plan for you, and make the right choice of investment.',
    description:
      'Most of us don’t spend time considering the implication of choosing a particular investment asset, until it goes wrong, by which time it can be too late. There are many options out there for people to save or invest in. Skyewise Multi-Purpose Cooperative Society bridging products can help you make the right choice today.',
    img: '/images/content/finance/support-1080x57.jpg',
  },
];

const SleakSlides = (props) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.setAttribute('data-wf-page', '61e539b9a949f460034bd35f');
  });

  restartWebflowScript();

  return (
    <div className="wf-section is-bg--gray-02 overflow-hidden">
      <div className="container is--no-px">
        <div className="c-active-slider__wrap">
          <div
            data-delay="7000"
            data-animation="slide"
            className="c-active-slider__slider w-slider"
            data-autoplay={true}
            data-easing="ease-in-out-quad"
            data-hide-arrows="false"
            data-disable-swipe="true"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="700"
            data-infinite="true"
          >
            <div className="c-active-slider__slider__mask w-slider-mask">
              {slidesData.map((data) => (
                <SleakSlide data={data} />
              ))}
            </div>
            <div className="display__none w-slider-arrow-left">
              <div className="w-icon-slider-left"></div>
            </div>
            <div className="display__none w-slider-arrow-right">
              <div className="w-icon-slider-right"></div>
            </div>
            <div className="w-slider-nav w-round"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SleakSlides;
