import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/formElements/form';
import * as userService from '../../services/userService';
import auth from '../../services/authService';
// import CUDA from "../hooks/GetUserData";

class TicketBookingForm extends Form {
  state = {
    data: {
      username: '',
      password: '',
      name: '',
      phone: '',
      address: '',
      visitDate: '',
    },
    errors: {},
  };

  schema = {
    name: Joi.string().required().label('Name'),
    username: Joi.string().required().email().label('Email Address'),
    password: Joi.string().required().min(5).label('Password'),
    passwordconfirm: Joi.string().required().label('Confirm Password'),
    phone: Joi.string().required().min(11).label('Phone Number'),
    address: Joi.string().required().min(10).label('Contact Address'),
    vistDate: Joi.string().required().min(10).label('Visit Date'),
  };

  doSubmit = async () => {
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.data.token, response.data.data);

      if (response) {
        const currentUserLocal = await auth.setUVDALocal();
        window.location = '/user-dashboard';
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="s-form-centred">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'Name', 'text', 'Full Name', '')}
          {this.renderInput(
            'username',
            'Email Address',
            'text',
            'Email address',
            ''
          )}
          {this.renderInput(
            'phone',
            'Phone Number',
            'text',
            'Phone Number',
            ''
          )}

          {this.renderInput(
            'address',
            'Contact Address',
            'text',
            'Contact Address',
            ''
          )}
          {this.renderInput('date', 'visit Date', 'date', 'Visit Date', '')}

          {this.renderButton('Currently unavailable')}
        </form>
      </div>
    );
  }
}

export default TicketBookingForm;
