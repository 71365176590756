import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const SWL = (prop) => {
  return (
    <Fragment>
      <div className="_1-col-c-center  wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <p className="p-medium st--mb-0">
                  We provide credit management solutions to a diverse set of
                  institutional and individual clients.
                </p>
                <p>
                  You can access loans within two hours of request through our
                  partner; &nbsp;
                  <Link
                    to="/the-cooperative"
                    className="st-link st-link--is-blue"
                  >
                    Skyewise Cooperative.
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wf-section is--ht-100 cooperative-share">
        <div className="container flx">
          <div className="flx-i--self-btm">
            <div className="_3_1-grod ">
              <div className="is--st-p-all is--blk-white">
                <div className="c-rich-text">
                  <h3>
                    Meeting the Financial Needs of Businesses and Individuals
                  </h3>
                  <p>
                    We know that you may need little help from time to time with
                    finances running a business, starting a business, or
                    personal demands. Whatever your financial needs are, our
                    special loans may be an option for you. Being a member of
                    The Cooperative gives you access to loans and other members
                    inclusive benefits
                  </p>
                </div>
                <div className="content-rw__cta s-cards-slider__cta">
                  <a
                    href="#laon-requirements-section"
                    className="st-link st-link--is-blue"
                  >
                    Loan Terms {'&'} Requirements
                  </a>

                  <span className="content-rw__cta-devider"></span>
                  <Link
                    to="/the-cooperative"
                    className="st-link st-link--is-blue"
                  >
                    Learn More About The Cooperative
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section: SECTION NAME */}
      <div className="is-bg--gray-02 wf-section">
        <div className="container container--small-top-btoom">
          <div>
            <p className="p-special">
              Skyewise Group believes "Progress is Everyone’s Business." We
              commits people, capital, and ideas to support startups, and help
              existing businesses thrive. This is our definition of community
            </p>
          </div>
        </div>
      </div>

      <div
        className="section--is-grey wf-section"
        id="laon-requirements-section"
      >
        <div className="container">
          <div className="_2-col-st-grid">
            <div
              id="w-node-d32d7ecc-132a-3c09-e382-4dfad903fa9f-f14bd371"
              data-w-id="d32d7ecc-132a-3c09-e382-4dfad903fa9f"
              className="s-cards-slider"
            >
              <div className="div-block-129">
                <div
                  className="s-cards-slider__wheel"
                  //   style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <h3 className="s-cards-slider__title">Personal Loan</h3>
                  <p className="s-cards-slider__p">
                    Skyewise Cooperative gives members and intending members
                    access to soft loans with flexible and competitive interest
                    rates. Get access to fast and reliable credit facility
                  </p>
                  <div className="content-rw__cta justify-center s-cards-slider__cta">
                    <Link
                      to="/services/loan/personal-loan/requirements"
                      className="st-link st-link--is-blue"
                    >
                      Personal loan requirements
                    </Link>
                    <span className="content-rw__cta-devider"></span>
                    <Link
                      to="/user-dashboard"
                      className="st-link st-link--is-blue"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-ba24641a-8683-b280-ea9a-ca514c457bd2-f14bd371"
              data-w-id="ba24641a-8683-b280-ea9a-ca514c457bd2"
              className="s-cards-slider"
            >
              <div className="div-block-129">
                <div
                  className="s-cards-slider__wheel"
                  //   style="transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  <h3 className="s-cards-slider__title">Business Loan</h3>
                  <p className="s-cards-slider__p">
                    We know that you may need help with finances from time to
                    time running a business. Whatever your business finance
                    needs are, our quick loan may be an option for you.
                  </p>

                  <div className="content-rw__cta justify-center s-cards-slider__cta">
                    <Link
                      to="/services/loan/business-loan/requirements"
                      className="st-link st-link--is-blue"
                    >
                      Business loan requirements
                    </Link>
                    <span className="content-rw__cta-devider"></span>
                    <Link
                      to="/user-dashboard"
                      className="st-link st-link--is-blue"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>
                  {' '}
                  Take your finances to the next level. Go beyond generic
                  financial planning. Partner with us to design a resource
                  management plan that meets the unique needs of your business.
                </h3>
                <p>Give us a call if you wish to speak with us in person</p>
              </div>
              <Link
                to="/contact"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Contact Us</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SWL;
