import React from 'react';
import { Navigate } from 'react-router-dom';
import Joi from 'joi-browser';
import Form from '../common/formElements/form';
import ProgressRender from '../progressRender';
import auth from '../../services/authService';
import { initProgressLotties } from '../../utils/initProgressLottie';
import progressHandler from '../../utils/progressHandler';
import { updateLoan } from '../../services/loanService';
import { updateUser } from '../../services/userService';

class LoanApprovalForm extends Form {
  state = {
    data: {
      approvedAmount: '',
      loanStartDate: '',
      loanDueDate: '',
      interestRate: '',
    },
    errors: {},
  };

  schema = {
    approvedAmount: Joi.number().required().label('ApprovedAmount'),
    interestRate: Joi.string().required().label('InterestRate'),
    loanStartDate: Joi.string().required().min(5).label('LoanStartDate'),
    loanDueDate: Joi.string().required().min(5).label('LoanDueDate'),
  };

  doSubmit = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('loan-approval', 'loading');
    try {
      const loanId = this.props.loanData._id;
      const userId = this.props.loanData.user._id;
      const { data: formDate } = this.state;

      let { data: loanData } = await (
        await updateLoan(loanId, { ...formDate, status: 'disbursed' })
      ).data.data;

      loanData.user = this.props.loanData.user;

      await updateUser(userId, { loanState: 'approved' });

      // Hide UI loadin, show success
      progressHandler.hideProgress('loan-approval', 'loading');
      progressHandler.showProgress('loan-approval', 'success');

      setTimeout(() => {
        // window.location.reload(false);
        this.props.doUpdatePageData(loanData);
        progressHandler.hideProgress('loan-approval', 'success');
      }, 2500);
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('loan-approval', 'loading');
        progressHandler.showProgress('loan-approval', 'error', msg);
      } else {
        progressHandler.hideProgress('loan-approval', 'loading');
        progressHandler.showProgress(
          'loan-approval',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('loan-approval', 'error');
      }, 1500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    // if (auth.getCurrentUser()) return <Navigate to="/user-dashboard" />;

    return (
      <div className="st-form-blk">
        <ProgressRender
          elData={{
            loading: { name: 'loan-approval-progress__loading', size: 'small' },
            success: { name: 'loan-approval-progress__success', size: 'small' },
            error: { name: 'loan-approval-progress__error', size: 'small' },
          }}
        />

        <form onSubmit={this.handleSubmit}>
          <p className="f-field__m-desc">Approved Amount</p>
          {this.renderInput(
            'approvedAmount',
            'ApprovedAmmount',
            'text',
            '1,000,000',
            ''
          )}

          <p className="f-field__m-desc">Loan interest rate (percentage)</p>
          {this.renderInput(
            'interestRate',
            'InterestRate',
            'number',
            '10%',
            '%'
          )}

          <p className="f-field__m-desc">Loan duration (Start - End date)</p>

          {/* <div className="is-flex is-flex--row is-flex--no-wrap"> */}
          {/* <div className="flex-i-45"> */}
          <h6 className="f-field__m-title ">Loan Start Date</h6>
          {this.renderInput(
            'loanStartDate',
            'LoanStartDate',
            'date',
            '00-00-00',
            ''
          )}
          {/* </div> */}
          <div className="">
            <h6 className="f-field__m-title f-field__m-title--due">
              Loan Due Date
            </h6>
            {this.renderInput(
              'loanDueDate',
              'LoanDueDate',
              'date',
              '00-00-00',
              ''
            )}
          </div>
          {/* </div> */}
          {this.renderButton('Approve Loan')}
        </form>
      </div>
    );
  }
}

export default LoanApprovalForm;
