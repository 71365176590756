import React from 'react';

const AutoSideNavTitle = (props) => {
  const { data } = props;

  return (
    <div className="car-listings__block-header flex_order--02">
      <h4>{data.titleText}</h4>
    </div>
  );
};

export default AutoSideNavTitle;
