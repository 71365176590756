import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Component(props) {
  return (
    <div
      data-w-id="348bf267-469b-36c0-cb3e-1f2b349e3346"
      //   style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
      className="excerpts section is-gray-bg--01 wf-section"
    >
      <div className="container blog-updates__container">
        <div className="_4-col-st-grid">
          <div className="_4-col-st-grid__item">
            <div className="excerpts__card">
              <div className="excerpts__card__content--img">
                <div className="grid-col--left">
                  <div
                    data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66a2"
                    className="st-img__wrp"
                  >
                    <img
                      src="images/happy-faces-grid-01.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                      srcSet="images/happy-faces-grid-01-p-500.jpeg 500w, images/happy-faces-grid-01-p-800.jpeg 800w, images/happy-faces-grid-01-p-1080.jpeg 1080w, images/happy-faces-grid-01.jpg 1440w"
                      alt=""
                      className="st__img"
                    />
                    <div
                      //   style="display:block;opacity:0"
                      className="st-img__overlay overlay--dark"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="excerpts__card__content--txt">
                <div className="txt-icon-caption">
                  <p>Caption </p>
                  <p className="is-icon"></p>
                </div>
                <a href="#" className="st-link w-inline-block">
                  <h4 className="heading-2">
                    Our commitment to creating and sustaining a diverse work
                    environment is absolute
                  </h4>
                </a>
              </div>
            </div>
          </div>
          <div className="_4-col-st-grid__item">
            <div className="excerpts__card">
              <div className="excerpts__card__content--img">
                <div className="grid-col--left">
                  <div
                    data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66a2"
                    className="st-img__wrp"
                  >
                    <img
                      src="images/temp/240_F_166746644_tALSW2lOF53eWDmfkwxCo6c3vTA3DT1w.jpeg"
                      loading="lazy"
                      // sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                      // srcSet="images/05-p-500.jpeg 500w, images/05-p-1080.jpeg 1080w, images/05-p-1600.jpeg 1600w, images/05.jpg 1920w"
                      alt=""
                      className="st__img"
                    />
                    <div
                      //   style="display:block;opacity:0"
                      className="st-img__overlay overlay--dark"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="excerpts__card__content--txt">
                <div className="txt-icon-caption">
                  <p>Caption </p>
                  <p className="is-icon"></p>
                </div>
                <a href="#" className="st-link w-inline-block">
                  <h4 className="heading-2">
                    Our commitment to creating and sustaining a diverse work
                    environment is absolute
                  </h4>
                </a>
              </div>
            </div>
          </div>
          <div className="_4-col-st-grid__item">
            <div className="excerpts__card">
              <div className="excerpts__card__content--img">
                <div className="grid-col--left">
                  <div
                    data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66a2"
                    className="st-img__wrp"
                  >
                    <img
                      src="images/happy-faces-grid-01.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                      srcSet="images/happy-faces-grid-01-p-500.jpeg 500w, images/happy-faces-grid-01-p-800.jpeg 800w, images/happy-faces-grid-01-p-1080.jpeg 1080w, images/happy-faces-grid-01.jpg 1440w"
                      alt=""
                      className="st__img"
                    />
                    <div
                      //   style="display:block;opacity:0"
                      className="st-img__overlay overlay--dark"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="excerpts__card__content--txt">
                <div className="txt-icon-caption">
                  <p>Caption </p>
                  <p className="is-icon"></p>
                </div>
                <a href="#" className="st-link w-inline-block">
                  <h4 className="heading-2">
                    Our commitment to creating and sustaining a diverse work
                    environment is absolute
                  </h4>
                </a>
              </div>
            </div>
          </div>
          <div className="_4-col-st-grid__item">
            <div className="excerpts__card">
              <div className="excerpts__card__content--img">
                <div className="grid-col--left">
                  <div
                    data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66a2"
                    className="st-img__wrp"
                  >
                    <img
                      src="images/05.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                      srcSet="images/05-p-500.jpeg 500w, images/05-p-1080.jpeg 1080w, images/05-p-1600.jpeg 1600w, images/05.jpg 1920w"
                      alt=""
                      className="st__img"
                    />
                    <div
                      //   style="display:block;opacity:0"
                      className="st-img__overlay overlay--dark"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="excerpts__card__content--txt">
                <div className="txt-icon-caption">
                  <p>Caption </p>
                  <p className="is-icon"></p>
                </div>
                <a href="#" className="st-link w-inline-block">
                  <h4 className="heading-2">
                    Our commitment to creating and sustaining a diverse work
                    environment is absolute
                  </h4>
                </a>
              </div>
            </div>
          </div>
          {/* <div  className="_4-col-st-grid__item">
            <div  className="excerpts__card">
              <div  className="excerpts__card__content--img">
                <div  className="grid-col--left">
                  <div
                    data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66b2"
                     className="st-img__wrp"
                  >
                    <img
                      src="images/06.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                      srcSet="images/06-p-500.jpeg 500w, images/06-p-1080.jpeg 1080w, images/06-p-1600.jpeg 1600w, images/06.jpg 1920w"
                      alt=""
                       className="st__img"
                    />
                    <div
                      // style="display:block;opacity:0"
                       className="st-img__overlay overlay--dark"
                    ></div>
                  </div>
                </div>
              </div>
              <div  className="excerpts__card__content--txt">
                <div  className="txt-icon-caption">
                  <p>Caption </p>
                  <p  className="is-icon"></p>
                </div>
                <a href="#"  className="st-link w-inline-block">
                  <h4  className="heading-2">
                    Our commitment to creating and sustaining a diverse work
                    environment is absolute
                  </h4>
                </a>
              </div>
            </div>
          </div>
          <div  className="_4-col-st-grid__item">
            <div  className="excerpts__card__content--img">
              <div  className="grid-col--left">
                <div
                  data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66c2"
                   className="st-img__wrp"
                >
                  <img
                    src="images/05.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                    srcSet="images/05-p-500.jpeg 500w, images/05-p-1080.jpeg 1080w, images/05-p-1600.jpeg 1600w, images/05.jpg 1920w"
                    alt=""
                     className="st__img"
                  />
                  <div
                    // style="display:block;opacity:0"
                     className="st-img__overlay overlay--dark"
                  ></div>
                </div>
              </div>
            </div>
            <div  className="excerpts__card__content--txt">
              <div  className="txt-icon-caption">
                <p>Caption </p>
                <p  className="is-icon"></p>
              </div>
              <a href="#"  className="st-link w-inline-block">
                <h4  className="heading-2">
                  Our commitment to creating and sustaining a diverse work
                  environment is absolute
                </h4>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Component;
