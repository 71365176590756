import React, { Fragment } from "react";

const RealEstateHeroOne = (props) => {
  return (
    <Fragment>
      {/* Hero One */}
      <section className="full-screen-hero__sticky-top">
        <div className="full-height-hero__image-container full-height-hero__image-container--width">
          <picture
            className="responsive-image full-height-hero__picture lazy-loader lazy-loader--loaded full-height-hero__picture--width"
            data-load-module="LazyLoader"
            data-module-loaded="true"
            data-lazyloader-id="_kzk1cwouq281w"
          >
            <div className="overlay--dark overlay--dark__t-b-103deg"></div>
            <source
              media="(max-width: 979px)"
              srcSet="/content/images/real-estate/properties/rs-03.jpg"
              data-lazy-src="/content/images/real-estate/properties/rs-03.jpg"
              className="lazy-loader__asset"
            />
            <img
              src="/content/images/real-estate/properties/rs-03.jpg"
              alt=""
              className="full-height-hero__image lazy-loader__asset lazy-loader__asset--loaded full-height-hero__image--width"
              data-lazy-src="/content/images/real-estate/properties/rs-03.jpg"
              role="presentation"
            />
          </picture>
        </div>
        <div className="container">
          <div className="full-screen-hero__interior " aria-hidden="true">
            <div className="c-rich-text">
              <h2 className="full-screen-hero__interior__title">
                <span className="fw-100" aria-hidden="true">
                  Introducing Skyewise
                </span>
                &nbsp; Real Estate
              </h2>
              <p
                className="full-screen-hero__interior__desc"
                aria-hidden="true"
              >
                Brilliant design, unparalleled craftsmanship
              </p>
            </div>

            {/* <div className="full-screen-hero__cta" aria-hidden="true">
              <a
                href="#"
                className="s-btn is-btn--light w-inline-block"
                aria-hidden="true"
              >
                <p aria-hidden="true">Learn more</p>
              </a>
              <a
                data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
                href="#"
                className="alt-btn is-btn--alt w-inline-block"
                aria-hidden="true"
              >
                <div className="alt-btn__wrp" aria-hidden="true">
                  <p aria-hidden="true">Become a member</p>
                </div>
                <div
                  className="alt-btn__underline is-white__underline"
                  aria-hidden="true"
                ></div>
              </a>
            </div> */}
          </div>
        </div>
      </section>

      {/* Hero Two */}
      <section className="full-screen-hero__sticky-top">
        <div className="full-height-hero__image-container full-height-hero__image-container--width">
          <picture
            className="responsive-image full-height-hero__picture lazy-loader lazy-loader--loaded full-height-hero__picture--width"
            data-load-module="LazyLoader"
            data-module-loaded="true"
            data-lazyloader-id="_kzk1cwouq281w"
          >
            <source
              media="(max-width: 979px)"
              srcSet="/content/images/real-estate/city-render-16x9.jpeg"
              data-lazy-src="/content/images/real-estate/city-render-16x9.jpeg"
              className="lazy-loader__asset"
            />
            <img
              src="/content/images/real-estate/city-render-16x9.jpeg"
              alt=""
              className="full-height-hero__image lazy-loader__asset lazy-loader__asset--loaded full-height-hero__image--width"
              data-lazy-src="/content/images/real-estate/city-render-16x9.jpeg"
              role="presentation"
            />
          </picture>
        </div>
        <div className="container">
          <div className="full-screen-hero__interior " aria-hidden="true">
            <div className="c-rich-text">
              <h2 className="full-screen-hero__interior__title">
                <span className="fw-100" aria-hidden="true">
                  {" "}
                  Welcome to
                </span>
                &nbsp; Skyewise Real Estate
              </h2>
              <p
                className="full-screen-hero__interior__desc"
                aria-hidden="true"
              >
                Providing affordable smart homes with excellent modern
                facilities.
              </p>
            </div>

            {/* <div className="full-screen-hero__cta" aria-hidden="true">
              <a
                href="#"
                className="s-btn is-btn--light w-inline-block"
                aria-hidden="true"
              >
                <p aria-hidden="true">Properties</p>
              </a>
              <a
                data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
                href="#"
                className="alt-btn is-btn--alt w-inline-block"
                aria-hidden="true"
              >
                <div className="alt-btn__wrp" aria-hidden="true">
                  <p aria-hidden="true">Learn more</p>
                </div>
                <div
                  className="alt-btn__underline is-white__underline"
                  aria-hidden="true"
                ></div>
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default RealEstateHeroOne;
