import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import InvestmentsTable from './investmentsTable';
import ListGroup from './common/listGroup';
import Pagination from './common/pagination';
import {
  getInvestments,
  deleteInvestment,
} from '../services/investmentService';
import { getGenres } from '../services/genreService';
import { paginate } from '../utils/paginate';
import _ from 'lodash';
import SearchBox from './searchBox';
import restartWebflowScript from './common/restartWebflowScript';
import restartWebflowScriptClassComponents from './common/restartWebflowScriptClassConponents';
import ProgressRender from './progressRender';
import { initProgressLotties } from '../utils/initProgressLottie';
import progressHandler from '../utils/progressHandler';

class Investments extends Component {
  state = {
    investments: [],
    genres: [],
    currentPage: 1,
    pageSize: 12,
    searchQuery: '',
    selectedGenre: null,
    sortColumn: { path: 'title', order: 'asc' },
  };

  async componentDidMount() {
    restartWebflowScriptClassComponents();

    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('investment', 'loading');
    // await this.populateGenres();

    // const { data } = await getGenres();
    // const genres = [{ _id: "", name: "All Genres" }, ...data];

    try {
      const { data: investments } = await (await getInvestments()).data.data;
      // this.setState({ investments, genres });

      if (investments.length < 1) alert('no investmetns found');

      this.setState({ investments });
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('investment', 'loading');
        progressHandler.showProgress('investment', 'error', msg);
      } else {
        progressHandler.hideProgress('investment', 'loading');
        progressHandler.showProgress(
          'investment',
          'error',
          'An error occurred while loading investments. Try reloading the page or try again later'
        );
      }

      if (ex.response && ex.response.status === 404)
        setTimeout(() => {
          this.props.history.replace('/not-found');
        }, 1500);
    }
  }

  handleDelete = async (investment) => {
    const originalInvestments = this.state.investments;
    const investments = originalInvestments.filter(
      (m) => m._id !== investment._id
    );
    this.setState({ investments });

    try {
      // await deleteInvestment(investment._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error('This investment has already been deleted.');

      this.setState({ investments: originalInvestments });
    }
  };

  handleLike = (investment) => {
    const investments = [...this.state.investments];
    const index = investments.indexOf(investment);
    investments[index] = { ...investments[index] };
    investments[index].liked = !investments[index].liked;
    this.setState({ investments });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleGenreSelect = (genre) => {
    this.setState({ selectedGenre: genre, searchQuery: '', currentPage: 1 });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedGenre,
      searchQuery,
      investments: allInvestments,
    } = this.state;

    let filtered = allInvestments;
    if (searchQuery)
      filtered = allInvestments.filter((m) =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre && selectedGenre._id)
      filtered = allInvestments.filter(
        (m) => m.genre._id === selectedGenre._id
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const investments = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: investments };
  };

  render() {
    const { length: count } = this.state.investments;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    if (count === 0)
      return (
        <div className="st-form-blk">
          <ProgressRender
            elData={{
              loading: {
                name: 'investment-progress__loading',
                transparency: true,
              },
              success: { name: 'investment-progress__success' },
              error: { name: 'investment-progress__error' },
            }}
          />
        </div>
      );

    const { totalCount, data: investments } = this.getPagedData();

    return (
      <div className="row">
        <div className="col-3">
          <ListGroup
            items={this.state.genres}
            selectedItem={this.state.selectedGenre}
            onItemSelect={this.handleGenreSelect}
          />
        </div>
        <div className="col h-100">
          {user && (
            <Link
              to="/investments/new"
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Investment
            </Link>
          )}
          {/* <p>Showing {totalCount} investments in the database.</p> */}
          {/* <SearchBox value={searchQuery} onChange={this.handleSearch} /> */}
          <InvestmentsTable
            investments={investments}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default Investments;
