import React from 'react';
import Joi from 'joi-browser';
import Form from '../../common/formElements/form';
import * as LoanService from '../../../services/loanService';
import auth from '../../../services/authService';
import { updateMe } from '../../../services/userService';
import progressHandler from '../../../utils/progressHandler';
// import auth from "../services/authService";
import { initProgressLotties } from '../../../utils/initProgressLottie';
import ProgressRender from '../../progressRender';

class LoanForm extends Form {
  state = {
    data: { loan: '', amount: '' },
    errors: {},
  };

  schema = {
    loan: Joi.string().required().label('Loan'),
    amount: Joi.number().required().label('Amount'),
    amountInWords: Joi.string().required().label('Amount In Words'),
    collateral: Joi.string().required().label('Collateral'),

    // password: Joi.string().required().min(5).label("Password"),
    // passwordconfirm: Joi.string().required().label("PasswordConfirm"),
    // phone: Joi.string().required().label("Phone"),
    // address: Joi.string().required().label("Address"),
  };

  doSubmit = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('loan-form', 'loading');

    try {
      const response = await (
        await LoanService.createLoan(this.state.data)
      ).data.data.data;

      console.log(response);

      localStorage.setItem('loan_id', response._id);

      await updateMe({ loanState: 'collateral' });

      // Hide UI loadin, show success
      progressHandler.hideProgress('loan-form', 'loading');
      progressHandler.showProgress('loan-form', 'success');

      const cUserLocal = await auth.setUVDALocal();

      window.location.reload(false);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('loan-form', 'loading');
        progressHandler.showProgress('loan-form', 'error', msg);
      } else {
        progressHandler.hideProgress('loan-form', 'loading');
        progressHandler.showProgress(
          'loan-form',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('loan-form', 'error');
      }, 2500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="st-form-blk">
        <ProgressRender
          elData={{
            loading: {
              name: 'loan-form-progress__loading',
              size: 'small',
              // transparency: true,
            },
            success: {
              name: 'loan-form-progress__success',
              size: 'small',
              transparency: true,
            },
            error: {
              name: 'loan-form-progress__error',
              size: 'small',
              transparency: true,
            },
          }}
        />

        <form onSubmit={this.handleSubmit}>
          <h4 className="is-fw-600"> Request Loan</h4>
          <div className="s-form__input s-form__input--is-disabled">
            <div className="s-form__input__con">
              <label className="s-form__input-label" for="loan">
                Loan Date
              </label>
              <div className="is-icon s-form__icon">
                <p></p>
              </div>
            </div>
            <div className="s-form__input__input w-input">
              {`${new Date().toLocaleDateString()}`}
            </div>
          </div>
          {this.renderInput('loan', 'Loan', 'textarea', 'Loan purpose', '')}
          {this.renderInput(
            'amount',
            'Amount',
            'number',
            'Enter the amount',
            ''
          )}
          {this.renderInput(
            'amountInWords',
            'Amount In Words',
            'text',
            'Total Amount in Words',
            ''
          )}

          {this.renderInput(
            'collateral',
            'Collateral',
            'text',
            'Loan Collateral',
            ''
          )}
          {/* {this.renderInput("phone", "Phone", "text", "Phone Number", "")}

          {this.renderInput(
            "address",
            "Address",
            "text",
            "Contact Address",
            ""
          )} */}

          {this.renderButton('Request Loan')}
        </form>
      </div>
    );
  }
}

export default LoanForm;
