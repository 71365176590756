// Blog Posts Render - Steps

const checkPostReadyState = (
  stateLocal,
  stateLocalRef,
  retryIntervalMS,
  numOfRetries = 0,
  updateState
) => {
  // 1. Check if blog posts are ready for render
  if (stateLocal === null) {
    let numRetries = numOfRetries,
      retryInterval = retryIntervalMS;

    // 2. Retry post ready state check
    const postCheckRetry = setInterval(() => {
      numRetries += 1;
      retryInterval += 2000;

      const blogPostLocalRec = JSON.parse(localStorage.getItem(stateLocalRef));

      // 3. Update post record state if post is available, and stop retry
      if (blogPostLocalRec !== null && blogPostLocalRec.length >= 4) {
        updateState(new Date().now);
        clearInterval(postCheckRetry);
        return;
      }

      // 4. Stop retry at the maximum post retry
      if (numRetries === 8) {
        clearInterval(postCheckRetry);
      }
    }, retryInterval);
  }
};

export default checkPostReadyState;
