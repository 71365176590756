import React, { Fragment, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import restartWebflowScript from '../../common/restartWebflowScript';

const LRB = (props) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.setAttribute('data-wf-page', '61e539b9a949f4534e4bd366');
  });
  restartWebflowScript();

  return (
    <Fragment>
      <div className="flex-middle gray-bg-02 wf-section">
        <div className="container">
          <div className="flex-center">
            <div className="flex-center__container">
              <div className="c-rich-text">
                <h2>Business Loan Requirements</h2>
                <p>
                  Requirements for obtaining a loan from N500,000 to N10,000,000
                </p>
              </div>
              <div>
                <div>
                  <ul role="list" className="w-list-unstyled">
                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h4 className="expand__title c-h4">
                          Applicant must be 18 years and above
                        </h4>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          Applicant must be 18 years and above ( Applicant must
                          be at least 18 years to be eligible for the
                          Cooperative membership
                        </p>
                      </div>
                    </li>

                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h4 className="expand__title c-h4">
                          Active job or business
                        </h4>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          Applicant must be productively engaged in a legitimate
                          business or work
                        </p>
                      </div>
                    </li>

                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h4 className="expand__title c-h4">
                          Cooperative Membership
                        </h4>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          Application letter to be a member of the Cooperative
                          or signup on our website
                        </p>
                      </div>
                    </li>

                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h4 className="expand__title c-h4">
                          Membership Verification
                        </h4>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          <ol>
                            <li>
                              Copy of valid identity card or complete KYC
                              verification after registering on the website
                            </li>
                            <li>Two passport photograph</li>
                          </ol>
                        </p>
                      </div>
                    </li>

                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h4 className="expand__title c-h4">Membership fee</h4>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          N10,000 (Then Thousand Naira) membership fee
                          non-refundable
                        </p>
                      </div>
                    </li>

                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h4 className="expand__title c-h4">
                          Obtaining a loan from N500,000 to N10,000,000
                        </h4>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          <ol>
                            <li>An application letter</li>
                            <li>60% of the value of the vehicle forced sale</li>
                            <li>Cheques</li>
                          </ol>
                        </p>
                      </div>
                    </li>

                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h4 className="expand__title c-h4">
                          Obtaining a loan with properties (within Abuja
                          Central)
                        </h4>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          <ol>
                            <li>
                              An application letter and state the purpose of the
                              loan
                            </li>
                            <li>The original property document (CoFO)</li>
                            <li>
                              Seach and valuation fee of N150,000 (One Hundred
                              and Fifty Thousand Naira only)
                            </li>
                            <li>A court affidavit</li>
                            <li>Cheques</li>
                          </ol>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>Need a business loan?</h3>
                <p>
                  Simply request for a loan directly from your personal account,
                  or give us a{' '}
                  <a className="st-link st-link--is-blue" href="#">
                    Call
                  </a>{' '}
                  if you wish to speak with someone.
                </p>
              </div>
              <Link
                to="/user-dashboard"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Account</p>
                <p className="is-icon"></p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LRB;
