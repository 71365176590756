import React, { useEffect } from 'react';
import restartWebflowScript from '../common/restartWebflowScript';
import NavBar from '../common/navbar/navbar';

import Footer from '../common/footer/footer';
import ChatAndNavHelperOpen from '../common/chatAndNavHelperOpen';
import PageExitCTA from '../common/pageExitCTA';
import Trends from '../common/trends/trendsBase';
import Car from './carCardMain';
import FeaturedCars from './carsFeatured';
import CarListingForm from './carListingForm';
import StaticHeader from '../common/UiLayoutsAndComponents/staticHeaderOne';

import AutoPageSubHeading from './automobilePageSubTitle';
import AutoSideNav from './automobileSideNavLinks';
import AutoSideNavTitle from './automobileSideNavTitle';

const CarListingData = () => {
  // useEffect(() => {
  //   var htmlEl = document.getElementsByTagName("html")[0];
  //   htmlEl.setAttribute("data-wf-page", "61c2e2d14d436da4d3382185");
  // });

  restartWebflowScript();

  return (
    <div>
      <NavBar />

      <StaticHeader
        pageData={{ titleText: 'List new car', altTitle: 'Fill car detials' }}
      />

      <div className="car-listings__grid-wrapper section wf-section">
        <div className="container ">
          <div className="car-listings__grid-wrapper">
            <div className="car-listings__cars-nav-grod">
              <AutoSideNavTitle data={{ titleText: 'Navigation' }} />

              <div>
                <AutoPageSubHeading data={{ headingText: 'Car details' }} />
              </div>

              <div className="car-listings__car-sort flex_order--03">
                <AutoSideNav
                  data={[
                    {
                      linkTxt: 'All cars',
                      linkIcon: '',
                      linkAddress: '/newLinkAddress',
                    },
                  ]}
                />
              </div>
              <div className="car-listings__block flex_order--04">
                <CarListingForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* GLoable Trends Here */}
      {/* <FeaturedCars /> */}
      {/* <Trends /> */}
      <PageExitCTA />
      <Footer />
    </div>
  );
};

export default CarListingData;
