import React from 'react';

const SeafProject = (project) => {
  const { projectData } = project;
  return (
    <div className="parallax-slide w-slide">
      <div className="ratio-200">
        <div className="parallax__image-wrapper">
          <img
            src={projectData.photo}
            loading="lazy"
            sizes="(max-width: 479px) 70vw, (max-width: 767px) 50vw, (max-width: 991px) 240px, (max-width: 1919px) 21vw, 16vw"
            //   srcSet="images/01-p-500.jpeg 500w, images/01-p-800.jpeg 800w, images/01.jpg 1080w"
            alt=""
            className="parallax__image"
          />
          <div className="overlay--dark"></div>
        </div>
        <div className="parallax__card-content">
          <div className="parallax__card__head">
            <div>
              <p className="is-icon parallax__card__icon"></p>
            </div>
            <h4 className="iscptitle">{projectData.sponsor}</h4>
          </div>
          <div>
            <h4 className="parallax__content-title">{projectData.title}</h4>
            <p>{projectData.brief}</p>

            {projectData.url && (
              <a
                href="#"
                target="_blank"
                className="alt-btn iswhite w-inline-block"
              >
                <p>Continue reading</p>
                <p className="is-icon"></p>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeafProject;
