import React from 'react';
import configData from '../../config.json';
const storageUrl = configData.storageUrl;

const CarImagePreview = (props) => {
  const { images } = props;

  return (
    <>
      <div className="_5-col-st-grid mb-8">
        {images.map((src, idx) => (
          <img
            className="rounded-md"
            key={idx}
            src={`${storageUrl}${src}`}
            alt=""
          />
        ))}
      </div>
    </>
  );
};

export default CarImagePreview;
