import React from 'react';
import { Link } from 'react-router-dom';
const CooperativeProdutcs = (props) => {
  return (
    <div className="section--is-grey wf-section " id="cooperative-services">
      <div className="is--c-pg-section-bg is--bg-future-together">
        <div className="overlay--dark"></div>
      </div>
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text is--txt-white ">
              <h3>Skyewise Progressive Multipurpose Cooperative</h3>
              <p>
                Building a cooperative economy through the creation and
                development of partnership networks in diverse communities in
                Nigeria m dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
        </div>

        <div className="_2-col-st-grid is--t-mg-2vh">
          <div
            id="w-node-d32d7ecc-132a-3c09-e382-4dfad903fa9f-f14bd371"
            data-w-id="d32d7ecc-132a-3c09-e382-4dfad903fa9f"
            className="s-cards-slider"
          >
            <div className="div-block-129">
              <div className="s-cards-slider__wheel is-txt--centered">
                <h3 className="s-cards-slider__title">Cooperative Loan</h3>
                <p className="s-cards-slider__p">
                  As a member of Skyewise Cooperative, you have access to the
                  Cooperative on request
                </p>
                <div className="content-rw__cta justify-center s-cards-slider__cta is-txt--centered">
                  <Link
                    to="/services/loan"
                    className="st-link st-link--is-blue"
                  >
                    Learn more
                  </Link>
                  <span className="content-rw__cta-devider"></span>
                  <Link
                    to="/user-dashboard"
                    className="st-link st-link--is-blue"
                  >
                    Request for a loan
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            id="w-node-ba24641a-8683-b280-ea9a-ca514c457bd2-f14bd371"
            data-w-id="ba24641a-8683-b280-ea9a-ca514c457bd2"
            className="s-cards-slider"
          >
            <div className="div-block-129">
              <div className="s-cards-slider__wheel is-txt--centered">
                <h3 className="s-cards-slider__title">
                  Redeemable Contribution
                </h3>
                <p className="s-cards-slider__p">
                  Members are entitled to{' '}
                  <span className="is-fw-600">24% </span>
                  annual return on any amount contributed or invested with
                  Skyewise PMCS Ltd.
                </p>
                <div className="content-rw__cta justify-center s-cards-slider__cta is-txt--centered">
                  <Link
                    to="/services/investment"
                    className="st-link st-link--is-blue"
                  >
                    Learn more
                  </Link>
                  <span className="content-rw__cta-devider"></span>
                  <Link
                    to="/user-dashboard"
                    className="st-link st-link--is-blue"
                  >
                    Invest with us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CooperativeProdutcs;
