import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../../HOC/withRouter';

import Form from '../common/formElements/form';
import {
  getInvestment,
  updateInvestment,
  deleteInvestment,
} from '../../services/investmentService';
import Navbar from '../common/navbar/navbar';
import Footer from '../common/footer/footer';
import restartWebflowScriptClassComponents from '../common/restartWebflowScriptClassConponents';
import StaticHeaderOne from '../common/UiLayoutsAndComponents/staticHeaderOne';
import ProgressRender from '../progressRender';
import { initProgressLotties } from '../../utils/initProgressLottie';
import progressHandler from '../../utils/progressHandler';
import { formatCurrency } from '../../utils/formatCurrency';
import { updateUser } from '../../services/userService';
import CheckerButton from '../../hooks/checkerButton';

class InvestmentSelectedForm extends Form {
  state = {
    data: {
      title: '',
      genreId: '',
      numberInStock: '',
      dailyRentalRate: '',
    },
    genres: [],
    errors: {},
    dataLoadState: 'loading',
    investmentState: '',
  };

  async populateInvestment() {
    try {
      // Access router params in <= 5
      // const investmentId = this.props.match.params.id;

      // Custom HOC to pass the routers url params to class component
      const investmentId = this.props.params.id;

      if (investmentId === 'new') return;

      const { data: investment } = await (
        await getInvestment(investmentId)
      ).data.data;
      // this.setState({ data: this.mapToViewModel(investment) });
      this.setState({ investment: investment });
      this.setState({ dataLoadState: 'loadingComplete' });
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('investment', 'loading');
        progressHandler.showProgress('investment', 'error', msg);
      } else {
        progressHandler.hideProgress('investment', 'loading');
        progressHandler.showProgress(
          'investment',
          'error',
          'An error occurred, try reloading the page or try again later'
        );
      }

      if (ex.response && ex.response.status === 404)
        setTimeout(() => {
          this.props.history.replace('/not-found');
        }, 1500);
    }
  }

  async componentDidMount() {
    restartWebflowScriptClassComponents();

    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('investment', 'loading');
    // await this.populateGenres();
    await this.populateInvestment();
  }

  mapToViewModel(investment) {
    return {
      _id: investment._id,
      title: investment.investment,
      genreId: investment.genre._id,
      numberInStock: investment.numberInStock,
      dailyRentalRate: investment.dailyRentalRate,
    };
  }

  updateInvestment = async (investment, investmentData, user, userData) => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('load', 'loading');

    try {
      // throw { name: 'Err', message: 'Nothing really' };
      // return;

      let { data } = await (
        await updateInvestment(investment, investmentData)
      ).data.data;

      // Update user investment
      if (user && userData) {
        await updateUser(user, userData);
      }

      // Hide UI loadin, show success
      progressHandler.hideProgress('load', 'loading');
      progressHandler.showProgress('load', 'success');

      // const investmentState = { ...this.state.investment, data };
      data.user = this.state.investment.user;

      setTimeout(() => {
        // window.location.reload(false);
        // this.setState( investmentResult);

        this.setState({ investment: data });
        progressHandler.hideProgress('load', 'success');
      }, 1500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress('load', 'error', msg);
      } else {
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress(
          'load',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('load', 'error');
      }, 2000);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/investments");
  };

  withdrawInvestment = () => {
    const investment = this.state.investment;

    this.updateInvestment(
      investment._id,
      {
        status: 'completed',
      },
      investment.user._id,
      {
        investmentState: 'none',
      }
    );
  };

  approveInvestment = () => {
    const investment = this.state.investment;

    this.updateInvestment(
      investment._id,
      {
        status: 'active',
        funded: true,
      },
      investment.user._id,
      {
        investmentState: 'active',
      }
    );
  };

  cancelInvestment = () => {
    const investment = this.state.investment;

    this.updateInvestment(
      investment._id,
      {
        status: 'cancelled',
      },
      investment.user._id,
      {
        investmentState: 'none',
      }
    );
  };

  deleteInvestment = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('load', 'loading');

    try {
      const investment = this.state.investment;

      await deleteInvestment(investment.id);
      // await updateUser(data.user.id, { investmentState: 'none' });

      // Hide UI loadin, show success

      progressHandler.hideProgress('load', 'loading');
      initProgressLotties();
      progressHandler.showProgress('load', 'success');

      setTimeout(() => {
        window.location = '/admin/investments';
      }, 1500);
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress('load', 'error', msg);
      } else {
        progressHandler.hideProgress('load', 'loading');
        progressHandler.showProgress(
          'load',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('load', 'error');
      }, 3500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }

    // this.props.history.push("/investments");
  };

  updateConfirmationState = (state) => {
    const newState = this.state[state] ? false : true;

    this.setState({
      [state]: newState,
    });
  };

  render() {
    const investment = this.state.investment;

    if (investment) {
      let investmentDate = investment.createdAt;
      investmentDate = new Date(investmentDate).toLocaleDateString();
    }

    if (this.state.dataLoadState === 'loading') {
      return (
        <Fragment>
          <Navbar />
          <StaticHeaderOne
            pageData={{
              titleText: this.state.investment
                ? this.state.investment.user.name
                : 'Investment',
              headerLocalClass: 'about-us',
            }}
          />

          <div className="_1-col-c-center wf-section">
            <div className="container">
              <div className="_1-col-c-center__wrp">
                <div className="_1-col-c-center__c-narrow ">
                  <div className="st-form-blk">
                    <ProgressRender
                      elData={{
                        loading: { name: 'investment-progress__loading' },
                        success: { name: 'investment-progress__success' },
                        error: { name: 'investment-progress__error' },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }

    if (this.state.dataLoadState === 'loadingComplete') {
      return (
        <Fragment>
          <Navbar />
          <StaticHeaderOne
            pageData={{
              titleText:
                this.state.investment && this.state.investment.user
                  ? this.state.investment.user.name
                  : 'Investment',
              headerLocalClass: 'about-us',
              altTitle: 'Investor',
            }}
          />

          <div className="_1-col-c-center wf-section">
            <div className="container">
              <div className="_1-col-c-center__wrp">
                <div className="_1-col-c-center__c-narrow psuedo-pos--relative">
                  <div className="s-dialuge-box__content__grid">
                    <div className="s-dialuge-box__content__details">
                      <div className="c-rich-text">
                        <h2 className="s-dialuge-box__content__details__title">
                          Investment information
                        </h2>
                      </div>

                      {investment && (
                        <Fragment>
                          {' '}
                          <p className="s-dialuge-box__content__details__desc st-r-mg ">
                            An investment request of{' '}
                            <span className="is-fw-600">
                              <strong>
                                {' '}
                                ₦{formatCurrency(Number(investment.amount))}
                              </strong>
                            </span>{' '}
                            from{' '}
                            <span className="is-fw-600">
                              <strong>{investment.user.name} </strong>
                            </span>{' '}
                            on{' '}
                            <strong>{`${new Date(
                              investment.createdAt
                            ).toDateString()}`}</strong>
                            .
                          </p>
                          <div className="is-mt-min">
                            <ul>
                              {!investment.funded && (
                                <li>This is investment is yet to be funded</li>
                              )}
                              {investment.status == 'pending' &&
                                investment.funded && (
                                  <li>
                                    {` ${investment.user.name.split(' ')[0]} 
                                  states that this investment has been funded.`}
                                  </li>
                                )}
                              <li>
                                Investment amount:{' '}
                                <strong>
                                  ₦{formatCurrency(Number(investment.amount))}
                                </strong>
                              </li>
                              <li>
                                Purpose:{' '}
                                <span className="st-note-block">
                                  {investment.investment}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Fragment>
                      )}
                    </div>
                    <div className="c-rich-text">
                      <aside className="s-dialuge-box__content__aside">
                        <div
                          className={`s-dialuge-box__status s-dialuge-box__status--flx s-dialuge-box__status `}
                        >
                          {investment && (
                            <Fragment>
                              <span
                                className={`is-icon sm-rt-margin ${investment.status}`}
                              >
                                
                              </span>
                              <p>{investment.status}</p>
                            </Fragment>
                          )}
                        </div>
                        <div className="s-dialuge-box__amount">
                          <p className="text--big text--big--bold">
                            {investment && (
                              <span>
                                ₦{formatCurrency(Number(investment.amount))}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="s-dialuge-box__aside__inner-container">
                          {investment && (
                            <p className="s-dialuge-box__aside__caption">
                              Investment date
                              <h4 className="s-dialuge-box__aside__date">
                                {
                                  // (investmentDate = new Date(
                                  //   investment.createdAt
                                  // ))
                                }
                                {`${new Date(
                                  investment.createdAt
                                ).toDateString()}`}
                              </h4>
                            </p>
                          )}

                          {investment && investment.user && (
                            <Fragment>
                              <div className="s-dialuge-box__aside_grouper ">
                                <p className="s-dialuge-box__aside__caption">
                                  Investor
                                </p>
                                <div className=" grouper__user-info">
                                  <div class="nav__user-photo">
                                    <img
                                      src="https://cdn.skyewisegroup.com/users/photo/user-62f027c41e91b0ac9d0286cb-1659906045857.jpg"
                                      loading="lazy"
                                      alt=""
                                      class="user__profile__photo__img"
                                    />
                                  </div>

                                  <h5 className="">
                                    <Link
                                      target="_blank"
                                      to={
                                        '/admin/clients/' + investment.user._id
                                      }
                                      className="is-fw-600 s-dialuge-box__aside__date"
                                    >
                                      {investment.user.name}
                                    </Link>
                                  </h5>
                                </div>
                              </div>

                              {/* Investment Delete Action */}
                              <CheckerButton
                                label={{ name: 'Confirm investment delete' }}
                                btn={{
                                  btnText: 'Delete',
                                  onCheck: this.updateConfirmationState,
                                  action: this.deleteInvestment,
                                  styleClasses: 'alt-btn delete w-inline-block',
                                }}
                                parentState={{
                                  name: 'checkerConfirmDelete',
                                  state: this.state.checkerConfirmDelete,
                                }}
                              />
                            </Fragment>
                          )}
                        </div>
                      </aside>
                    </div>

                    {this.state.investment.status === 'cancelled' && (
                      <p className="is--txt-left">
                        This investment has been completed
                      </p>
                    )}
                    <div className="">
                      {investment.status === 'pending' && (
                        <div className="_2-col-st-grid">
                          {/* Investment approval button */}
                          <CheckerButton
                            label={{ name: 'Approval investment' }}
                            btn={{
                              btnText: 'Approve ',
                              onCheck: this.updateConfirmationState,
                              action: this.approveInvestment,
                              styleClasses:
                                's-btn s-btn--blue w-inline-block is-btn--mgr-2',
                            }}
                            parentState={{
                              name: 'checkerConfirmApprove',
                              state: this.state.checkerConfirmApprove,
                            }}
                          />

                          {/* Investment cancelation button */}
                          <CheckerButton
                            label={{ name: 'Cancel investment' }}
                            btn={{
                              btnText: 'Cancel',
                              onCheck: this.updateConfirmationState,
                              action: this.cancelInvestment,
                              styleClasses:
                                's-btn s-btn--reject w-inline-block',
                            }}
                            parentState={{
                              name: 'checkerConfirmCancel',
                              state: this.state.checkerConfirmCancel,
                            }}
                          />
                        </div>
                      )}

                      {/* Withdraw investment */}
                      {this.state.investment.status === 'active' && (
                        <CheckerButton
                          label={{ name: 'Confirm investment withdrawal' }}
                          btn={{
                            btnText: 'Withdraw',
                            onCheck: this.updateConfirmationState,
                            action: this.withdrawInvestment,
                            styleClasses:
                              's-btn is-btn--black w-inline-block is-btn--mgr-2',
                          }}
                          parentState={{
                            name: 'checkerConfirmWithdraw',
                            state: this.state.checkerConfirmWithdraw,
                          }}
                        />
                      )}

                      {this.state.investment.status === 'withdraw' && (
                        <button
                          className="s-btn is-btn--black w-inline-block is-btn--mgr-2 "
                          onClick={() => this.withdrawInvestment()}
                        >
                          <p>Withdraw Investment</p>
                        </button>
                      )}
                    </div>
                  </div>

                  <ProgressRender
                    elData={{
                      loading: {
                        name: 'load-progress__loading',
                        size: 'small',
                        // transparency: true,
                      },
                      success: { name: 'load-progress__success' },
                      error: { name: 'load-progress__error' },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
  }
}

export default withRouter(InvestmentSelectedForm);
