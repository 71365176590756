import React from 'react';
import truncateString from '../../../utils/stringTruncationV2';

const SkyeiweSocialHighlight = (props) => {
  const postData = props.data;

  return (
    <div className="sw-blog-update__card--mini ">
      <a
        target="_blank"
        href={postData.post_url}
        className="st-link w-inline-block"
      >
        <i
          className={`sw-blog-update__source-icon is-icon--brand is-icon--brand--${postData.social}`}
        ></i>
        <h4 className="sw-blog-update__card--mini__title text-hover-highlight text-hover-highlight--red">
          {truncateString(postData.caption, 85)}
        </h4>
      </a>
      <div className="sw-blog-update__card__metadata">
        <ul>
          <li className="metadata-tag--handle">
            <a href={postData.handle_url} target="_blank">
              @{postData.handle}
            </a>
          </li>
        </ul>

        <div className="">
          <a href={postData.post_url} target="_blank">
            <span className="is-icon sw-blog-update__icon "></span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SkyeiweSocialHighlight;
