import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoansTable from './loansTable';
import ListGroup from './common/listGroup';
import Pagination from './common/pagination';
import { getLoans, deleteLoan } from '../services/loanService';
import { getGenres } from '../services/genreService';
import { paginate } from '../utils/paginate';
import _ from 'lodash';
import SearchBox from './searchBox';
import restartWebflowScript from './common/restartWebflowScript';
import restartWebflowScriptClassComponents from './common/restartWebflowScriptClassConponents';
import ProgressRender from './progressRender';
import { initProgressLotties } from '../utils/initProgressLottie';
import progressHandler from '../utils/progressHandler';

class Loans extends Component {
  state = {
    loans: [],
    genres: [],
    currentPage: 1,
    pageSize: 12,
    searchQuery: '',
    selectedGenre: null,
    sortColumn: { path: 'title', order: 'asc' },
  };

  async componentDidMount() {
    restartWebflowScriptClassComponents();

    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('loan', 'loading');
    // await this.populateGenres();

    // const { data } = await getGenres();
    // const genres = [{ _id: "", name: "All Genres" }, ...data];

    try {
      const { data: loans } = await (await getLoans()).data.data;
      // this.setState({ loans, genres });
      this.setState({ loans });
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('loan', 'loading');
        progressHandler.showProgress('loan', 'error', msg);
      } else {
        progressHandler.hideProgress('loan', 'loading');
        progressHandler.showProgress(
          'loan',
          'error',
          'An error occurred while loading loans. Try reloading the page or try again later'
        );
      }

      if (ex.response && ex.response.status === 404)
        setTimeout(() => {
          this.props.history.replace('/not-found');
        }, 1500);
    }
  }

  handleDelete = async (loan) => {
    const originalLoans = this.state.loans;
    const loans = originalLoans.filter((m) => m._id !== loan._id);
    this.setState({ loans });

    try {
      // await deleteLoan(loan._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error('This loan has already been deleted.');

      this.setState({ loans: originalLoans });
    }
  };

  handleLike = (loan) => {
    const loans = [...this.state.loans];
    const index = loans.indexOf(loan);
    loans[index] = { ...loans[index] };
    loans[index].liked = !loans[index].liked;
    this.setState({ loans });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleGenreSelect = (genre) => {
    this.setState({ selectedGenre: genre, searchQuery: '', currentPage: 1 });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedGenre,
      searchQuery,
      loans: allLoans,
    } = this.state;

    let filtered = allLoans;
    if (searchQuery)
      filtered = allLoans.filter((m) =>
        m.title.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedGenre && selectedGenre._id)
      filtered = allLoans.filter((m) => m.genre._id === selectedGenre._id);

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const loans = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: loans };
  };

  render() {
    const { length: count } = this.state.loans;
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    if (count === 0)
      return (
        <div className="st-form-blk">
          <ProgressRender
            elData={{
              loading: { name: 'loan-progress__loading' },
              success: { name: 'loan-progress__success' },
              error: { name: 'loan-progress__error' },
            }}
          />
        </div>
      );

    const { totalCount, data: loans } = this.getPagedData();

    return (
      <div className="row">
        <div className="col-3">
          <ListGroup
            items={this.state.genres}
            selectedItem={this.state.selectedGenre}
            onItemSelect={this.handleGenreSelect}
          />
        </div>
        <div className="col">
          {user && (
            <Link
              to="/loans/new"
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              New Loan
            </Link>
          )}
          {/* <p>Showing {totalCount} loans in the database.</p> */}
          {/* <SearchBox value={searchQuery} onChange={this.handleSearch} /> */}
          <LoansTable
            loans={loans}
            sortColumn={sortColumn}
            onLike={this.handleLike}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default Loans;
