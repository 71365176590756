import React from "react";
import UserInvestmentsRecords from "../creditSummary/userAllInvestmentsRecords";

const AllInvestmentsRecords = (props) => {
  return (
    <main className="credit-dashbord">
      <div className="credit-dashbord__heading">
        <h3 className="heading-8">All Investments Records</h3>
      </div>
      <div className="credit-dashbord__details">
        <UserInvestmentsRecords />

        <button
          className="alt-btn btn-link--is-blue w-inline-block"
          onClick={() => {
            props.onDashbordChange("credit-summary");
          }}
        >
          <p>Back</p>
        </button>
      </div>
    </main>
  );
};

export default AllInvestmentsRecords;
