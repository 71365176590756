import React, { Fragment, useContext, useEffect, useState } from 'react';
import InvestmentServices from '../../../services/investmentService';
import authService from '../../../services/authService';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import progressHandler from '../../../utils/progressHandler';
import TitleH4Devider from '../../common/titleH4withDevider';

import UserInvestmentHistory from './userCreditInvestmentRecord';
import ProgressRender from '../../progressRender';

const UserInvestmentsRecords = (props) => {
  const [hasLoanRecords, setHasLoanRecords] = useState(false);
  const [hasInvestmentsRecords, setHasInvestmentsRecords] = useState(false);

  const [userInvestmentRecords, setUserInvestmentRecords] = useState(null);

  const currentUser = authService.getUVDALocal();

  useEffect(() => {
    // Initialize Lottie
    initProgressLotties();

    async function getUserCreditRecords() {
      const currentUser = authService.getUVDALocal();

      // Show UI Loading
      progressHandler.showProgress('credit-record', 'loading');

      try {
        setHasLoanRecords('loading');
        setHasInvestmentsRecords('loading');

        const userInvestment = await InvestmentServices.getInvestmentQuery({
          user: currentUser._id,
        });

        const { data: investmentData } = userInvestment.data.data;

        if (investmentData.length > 0) {
          setHasInvestmentsRecords(true);
        } else {
          setHasInvestmentsRecords(false);
        }

        progressHandler.hideProgress('credit-record', 'loading');

        setUserInvestmentRecords(investmentData);
      } catch (ex) {
        // console.log(ex.response);
        if (ex.response) {
          let msg = '';

          if (typeof ex.response.data === 'string') {
            msg += msg + ex.response.data;
          }

          if (typeof ex.response.data === 'object') {
            msg += msg + ex.response.data.message;
          }

          // hide UI Loading, show Error
          progressHandler.hideProgress('credit-record', 'loading');
          progressHandler.showProgress('credit-record', 'error', msg);
        } else {
          progressHandler.hideProgress('credit-record', 'loading');
          progressHandler.showProgress(
            'credit-record',
            'error',
            'An error occurred, please try again later. This is not from you.'
          );
        }

        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.email = ex.response.data;
          this.setState({ errors });
        }
      }
    }
    getUserCreditRecords();
  }, []);

  return (
    <Fragment>
      <div className="psuedo-pos--relative">
        <ProgressRender
          elData={{
            loading: {
              name: 'credit-record-progress__loading',
              size: 'small',
              transparency: true,
            },
            success: {
              name: 'credit-record-progress__success',
              size: 'small',
              transparency: true,
            },
            error: {
              name: 'credit-record-progress__error',
              size: 'small',
              transparency: true,
            },
          }}
        />

        <div className="credit__history">
          <TitleH4Devider title={'Investments'} />

          {!hasInvestmentsRecords && (
            <p className="psuedo psuedo-txt">No investment records</p>
          )}
          {hasInvestmentsRecords == 'loading' && <p className="psuedo-txt"></p>}

          {hasInvestmentsRecords &&
            userInvestmentRecords &&
            userInvestmentRecords.map((record, k) => (
              <UserInvestmentHistory historyData={record} key={k} />
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default UserInvestmentsRecords;
