import React, { Fragment, Component } from "react";

const CultureAndInnvationStory = (props) => {
  return (
    <Fragment>
      <section className="section">
        <div className="_1-col-c-center wf-section">
          <div className="container is--no-pt ">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow expanded-65">
                <div className="_3-col-grid">
                  <div className="">
                    <div className="">
                      <img
                        className="c-nafta-icon st-r-mb"
                        src="/images/icons/swa_onboardWaiver3.svg"
                        alt=""
                      />
                      <div className="c-rich-text">
                        <h3>Incredible vehicle inventory</h3>
                        <p>
                          Choose from an exceptional selection of new, used and
                          certified pre-owned cars.
                        </p>
                      </div>{" "}
                    </div>
                  </div>

                  <div className="">
                    <div className="">
                      <img
                        className="c-nafta-icon st-r-mb"
                        src="/images/icons/swa_onboardWaiver3.svg"
                        alt=""
                      />
                      <div className="c-rich-text">
                        <h3>Incredible vehicle inventory</h3>
                        <p>
                          Choose from an exceptional selection of new, used and
                          certified pre-owned cars.
                        </p>
                      </div>{" "}
                    </div>
                  </div>

                  <div className="">
                    <div className="">
                      <img
                        className="c-nafta-icon st-r-mb"
                        src="/images/icons/swa_onboardWaiver3.svg"
                        alt=""
                      />
                      <div className="c-rich-text">
                        <h3>Incredible vehicle inventory</h3>
                        <p>
                          Choose from an exceptional selection of new, used and
                          certified pre-owned cars.
                        </p>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default CultureAndInnvationStory;
