import React, { Component, Fragment } from "react";
import UserBDFUpdate from "./userBioUpdate";
import UserBioUpdate from "./userBioUpdate";
import UserPasswordUpdate from "./userPasswordUpdate";

const UserProfileForm = () => {
  return (
    <Fragment>
      <div className="hr-line hr-line--dark"></div>
      <UserBioUpdate />
      <div className="hr-line hr-line--dark"></div>
      <div className="credit-dashbord__sum is-2_5em--white-wrap is-1_27-grid ">
        <div>
          <div className="c-rich-text">
            <h5>Password Update</h5>
            <p className="is-txt__x-small--zoomable">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique.
            </p>
          </div>
        </div>
        <UserPasswordUpdate />
      </div>
    </Fragment>
  );
};

export default UserProfileForm;
