import React from 'react';
import { Link } from 'react-router-dom';

const CtaFullRowWithTPA = (props) => {
  const { content } = props;

  return (
    <div className="_1-col-c-center">
      <div className="container flx flx-c-ctr">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            {/* <div className="c-rich-text"> */}
            {content.title && (
              <h3 className="glue-headline glue-headline--headline-1">
                {content.title}
              </h3>
            )}
            {content.desc && <p>{content.desc}</p>}
            {/* <p>Give us a call if you wish to speak with us in person</p> */}
            {/* </div> */}
            <Link
              className="s-btn s-btn s-btn--blue w-inline-block is-btn--blue s-btn--inline featured-service__cta"
              to={content.destUrl}
            >
              <p>Start now</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaFullRowWithTPA;
