import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

const ActiveInvestment = (props) => {
  return (
    <div>
      <div className=" is-2_5em--white-wrap is-1_27-grid  is-txt--centered">
        <div>
          <div className="">
            <img
              src="/images/user-guide/confirmation-pending-timing_1366x575.png"
              alt="Investment pending confirmation"
            ></img>
          </div>
          <div className="c-rich-text">
            <h3>Pending Confirmation</h3>
          </div>
        </div>
        <div className="is-flex flex-c">
          <div className="c-rich-text is-flex__80-jc-c">
            <p>
              Your investment is
              <span className="credit-sum__state pending--solid glue-c-capcel">
                pending
              </span>
              we will notify you as soon as your investment is
              <span className="credit-sum__state active--solid glue-c-capcel">
                confirmed
              </span>
            </p>

            <p>
              This process is usually completed within 24 hours. Feel free to
              reach out to us if your investment is not confirmed within 24hrs.
            </p>

            <p>Thank you for choosing Skyewise</p>
          </div>
        </div>
      </div>
      <div className="hr-line hr-line--dark"></div>
      <p>Skyewie Cooperative</p>
    </div>
  );
};

export default ActiveInvestment;
