import React from "react";
import Joi from "joi-browser";
import Form from "../common/formElements/form";
import * as userService from "../../services/userService";
import auth from "../../services/authService";
// import CUDA from "../hooks/GetUserData";

class AppointmentScheduleForm extends Form {
  state = {
    data: { username: "", password: "", name: "", phone: "", address: "" },
    errors: {},
  };

  schema = {
    name: Joi.string()
      .required()
      .label("Name"),
    username: Joi.string()
      .required()
      .email()
      .label("Email Address"),
    phone: Joi.string()
      .required()
      .min(11)
      .label("Phone Number"),
    address: Joi.string()
      .required()
      .min(10)
      .label("Contact Address"),
    disableSubmit: Joi.string()
      .required()
      .min(10)
      .label("Disable Submit"),
  };

  doSubmit = async () => {
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.data.token, response.data.data);

      if (response) {
        const currentUserLocal = await auth.setUVDALocal();
        window.location = "/user-dashboard";
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="s-form-centred">
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Name", "text", "Full Name", "")}
          {this.renderInput(
            "username",
            "Email Address",
            "text",
            "Email address",
            ""
          )}
          {this.renderInput(
            "phone",
            "Phone Number",
            "text",
            "Phone Number",
            ""
          )}

          {this.renderInput(
            "address",
            "Contact Address",
            "text",
            "Contact Address",
            ""
          )}
          {this.renderInput(
            "address",
            "Contact Address",
            "date",
            "Contact Address",
            ""
          )}

          {this.renderButton("Currently Unavailable")}
        </form>
      </div>
    );
  }
}

export default AppointmentScheduleForm;
