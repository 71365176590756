import React from "react";

const TravelAndTourServices = (props) => {
  return (
    <div className="section page__exit__cta__section wf-section">
      <div className="container scroll-end__container">
        <h2 className="glue-eyebrow">TRAVEL {"&"} TOUR SERVICES</h2>

        <div className="_3-col-grid travel-services__grid">
          <div className="grid__item">
            <div className="travel-service">
              <div className="travel-service__thumb">
                <img
                  className="travel-service__thumb__img"
                  src="/content/images/travels-and-tour/traveler-270x142.jpg"
                  alt="Booking of local and international tickets"
                />
              </div>
              <p className="travel-service__details">
                Booking of local and international tickets{" "}
              </p>
            </div>
          </div>

          <div className="grid__item">
            <div className="travel-service">
              <div className="travel-service__thumb">
                <img
                  className="travel-service__thumb__img"
                  src="/content/images/travels-and-tour/booking-confrimation-blur-270x142.jpg"
                  alt=" Hotel Reservation and Confirmation"
                />
              </div>
              <p className="travel-service__details">
                Hotel Reservation and Confirmation{" "}
              </p>
            </div>
          </div>

          <div className="grid__item">
            <div className="travel-service">
              <div className="travel-service__thumb">
                <img
                  className="travel-service__thumb__img"
                  src="/content/images/travels-and-tour/passport-270x142.jpg"
                  alt="Processing of E-Passport and renewal"
                />
              </div>
              <p className="travel-service__details">
                Processing of E-Passport and renewal{" "}
              </p>
            </div>
          </div>

          <div className="grid__item">
            <div className="travel-service">
              <div className="travel-service__thumb">
                <img
                  className="travel-service__thumb__img"
                  src="/content/images/travels-and-tour/airport-pick-up-270x142.jpg"
                  alt="Airport pickup - Travel service"
                />
              </div>
              <p className="travel-service__details">Airport pickup</p>
            </div>
          </div>

          <div className="grid__item">
            <div className="travel-service">
              <div className="travel-service__thumb">
                {/* <img
                  autoPlay="true"
                  loop
                  className="travel-service__thumb__img"
                  src="/content/images/travels-and-tour/tours-270x142.jpg"
                  alt="Travel tour thumbnais"
                /> */}

                <video
                  poster="/content/images/travels-and-tour/tours-270x142.jpg"
                  className="travel-service__thumb__img"
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsInline=""
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src="/content/images/travels-and-tour/tour-clip.mp4"
                    data-wf-ignore="true"
                  />
                  <source
                    // src="videos/the-cooperative-hero-vid-mod-transcode.webm"
                    data-wf-ignore="true"
                  />
                </video>
              </div>
              <p className="travel-service__details">Tour packaging</p>
            </div>
          </div>

          <div className="grid__item">
            <div className="travel-service">
              <div className="travel-service__thumb">
                <img
                  className="travel-service__thumb__img"
                  src="/content/images/travels-and-tour/booking-lady-at-the-office-270x142.jpg "
                  alt="Travel tour thumbnais"
                />
              </div>
              <p className="travel-service__details">
                Visa consulting and processing
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelAndTourServices;
