import React from 'react';
import { Link } from 'react-router-dom';

const AutoSideNav = (props) => {
  const { data } = props;

  return (
    <div className="user-credit-dash__nav-cont">
      <ul role="list" className="user-credit-dash__nav__nav w-list-unstyled">
        {data.map((linkData, idx) => (
          <li key={idx} className="user-credit-dash__nav__item">
            <Link to={linkData.linkAddress}>
              <div className="car-sort__nav__link w-inline-block">
                <div className="is-icon side-nav__nav__link__icon is-fa-icon--300">
                  <p>{linkData.linkIcon}</p>
                </div>
                <div>
                  <p>{linkData.linkTxt}</p>
                </div>
              </div>
              {/* </a> */}
            </Link>
          </li>
        ))}
        {/* <li className="user-credit-dash__nav__item">
          <a href="#" className="car-sort__nav__link w-inline-block">
            <div className="is-icon side-nav__nav__link__icon">
              <p></p>
            </div>
            <div>
              <p>All Cars</p>
            </div>
          </a>
        </li>
        <li className="user-credit-dash__nav__item">
          <a href="#" className="car-sort__nav__link w-inline-block">
            <div className="is-icon side-nav__nav__link__icon">
              <p></p>
            </div>
            <div>
              <p>Brand-new</p>
            </div>
          </a>
        </li>
        <li className="user-credit-dash__nav__item">
          <a href="#" className="car-sort__nav__link w-inline-block">
            <div className="is-icon side-nav__nav__link__icon">
              <p></p>
            </div>
            <div>
              <p>Used</p>
            </div>
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default AutoSideNav;
