import React from 'react';

const CheckerButton = (props) => {
  return (
    <div className="">
      <div className="checker-button">
        <p className="checker-button__note">{props.label.name}</p>
      </div>
      <div className="checker-button">
        <div className=" checker-button__container checkbox__container">
          <div className="s-form-input__checkbox">
            <input
              className="checker-button__checker s-form-input__checkbox__ticker checkbox--default"
              type="checkbox"
              id={props.parentState.name}
              onClick={() => {
                props.btn.onCheck(props.parentState.name);
              }}
            />

            <span className="checker-button__marker s-form-input__checkbox__marker"></span>

            <label
              className=" s-form-input__checkbox__lable"
              for={props.parentState.name}
            >
              {' '}
            </label>
          </div>
        </div>

        <button
          disabled={!props.parentState.state}
          className={`checker-button__button ${props.btn.styleClasses}`}
          onClick={() => props.btn.action()}
        >
          {props.btn.btnText}
        </button>
      </div>
    </div>
  );
};

export default CheckerButton;
