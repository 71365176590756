import React, { Fragment, useEffect } from 'react';
import restartWebflowScript from '../common/restartWebflowScript';
import Navbar from '../common/navbar/navbar';
import ChatAndNavHelperBtn from '../common/chatAndNavHelperOpen';

import Footer from '../common/footer/footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const FAQ = () => {
  useEffect(() => {
    var htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.setAttribute('data-wf-page', '61e539b9a949f4534e4bd366');
  });
  restartWebflowScript();
  return (
    <Fragment>
      <Helmet>
        <title>FAQs | About Skyewise Group</title>
        <meta
          name="description"
          content="Frequently asked questions - about Skyewise Group"
        />
        <link rel="canonical" href="/about-us/faq" />
      </Helmet>

      <Navbar />

      <div className="header-static static-header--faq wf-section">
        <div className="overlay--dark"></div>
        <div className="container">
          <div className="header-statoc--flex">
            <div className="header-static__container">
              <h1>Frequently Asked Questions</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-middle gray-bg-02 wf-section">
        <div className="container">
          <div className="flex-center">
            <div className="flex-center__container">
              <div className="c-rich-text">
                <h2>FAQs</h2>
                <p>Frequently Asked Questions</p>
              </div>
              <div>
                <div>
                  <ul role="list" className="w-list-unstyled">
                    <li className="list-item">
                      <div
                        data-w-id="883f5942-7614-d7a4-7364-3140eb66a41f"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          What is the interest rate on loans?
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p style={{ opacity: 0 }} className="expand__text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div
                        data-w-id="e2b000fa-d202-cd58-f8c2-fadc7b8bc9be"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          How can i become a cooperative member?
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div
                        data-w-id="dc94326b-1051-216b-634e-83984cb5e1eb"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          What is the least investment i can make?
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementum
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div
                        data-w-id="3327623c-cbc7-c8ff-a869-d89739b19108"
                        className="expand__trigger"
                      >
                        <h3 className="expand__title c-h4">
                          When can i liquidate my investment
                        </h3>
                        <div className="is-icon is-icon--square">
                          <p></p>
                        </div>
                      </div>
                      <div
                        style={{ height: 0 + 'px' }}
                        className="expand__details"
                      >
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse varius enim in eros elementumna
                          tristique. Duis cursus, mi quis viverra ornare, eros
                          dolor interdum nulla, ut commodo diam libero vitae
                          erat. Aenean faucibus nibh et justo cursus id rutrum
                          lorem imperdiet. Nunc ut sem vitae risus tristique
                          posuere.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>Talk to someone?</h3>
                <p>Give us a call if you wish to speak with us in person</p>
              </div>
              <Link
                to="/contact"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Contact</p>
                <p className="is-icon"></p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default FAQ;
