import React from 'react';
import Clients from '../clients';

const ActiveClients = (props) => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <Clients />
        </div>
      </div>
    </div>
  );
};

export default ActiveClients;
