import React from 'react';
import Joi from 'joi-browser';
import Form from './common/formElements/form';
import * as userService from '../services/userService';
import auth from '../services/authService';
import CUDA from '../hooks/GetUserData';
import { initProgressLotties } from '../utils/initProgressLottie';
import progressHandler from '../utils/progressHandler';
import ProgressRender from './progressRender';

class SignupForm extends Form {
  state = {
    data: { username: '', password: '', name: '', phone: '', address: '' },
    errors: {},
  };

  schema = {
    name: Joi.string().required().label('Name'),
    username: Joi.string().required().email().label('Email Address'),
    password: Joi.string().required().min(5).label('Password'),
    passwordconfirm: Joi.string().required().label('Confirm Password'),
    phone: Joi.string().required().min(11).label('Phone Number'),
    address: Joi.string().required().min(10).label('Contact Address'),

    // disableForm: Joi.string()
    //   .required()
    //   .min(5)
    //   .label("DisableForm"),
  };

  doSubmit = async () => {
    // Initialize Lottie
    initProgressLotties();

    progressHandler.showProgress('signup', 'loading');

    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.data.token, response.data.data);

      if (response) {
        const currentUserLocal = await auth.setUVDALocal();
        // Hide UI loadin, show success
        progressHandler.hideProgress('signup', 'loading');
        progressHandler.showProgress('signup', 'success');
        setTimeout(() => {
          window.location = '/user-dashboard';
        }, 1500);
      }
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }
        // hide UI Loading, show Error
        progressHandler.hideProgress('signup', 'loading');
        progressHandler.showProgress('signup', 'error', msg);
      } else {
        progressHandler.hideProgress('signup', 'loading');
        progressHandler.showProgress(
          'signup',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('signup', 'error');
      }, 1500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        // this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="st-form-blk">
        <form className="st-form--auth" onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'Name', 'text', 'Name (full name)', '')}
          {this.renderInput(
            'username',
            'Email Address',
            'text',
            'Email address',
            ''
          )}
          {this.renderInput(
            'phone',
            'Phone Number',
            'text',
            'Phone Number',
            ''
          )}

          {this.renderInput(
            'address',
            'Contact Address',
            'text',
            'Contact Address',
            ''
          )}
          {this.renderInput(
            'password',
            'Password',
            'Password',
            'Password',
            ''
          )}
          {this.renderInput(
            'passwordconfirm',
            'Confirm Password',
            'password',
            'Confirm your password',
            ''
          )}

          {this.renderButton('Signup')}
        </form>
      </div>
    );
  }
}

export default SignupForm;
