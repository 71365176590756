import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ShoppingCentreCarPageHeaderSkeleton = () => {
  return (
    <>
      <section className="cl-hero wf-section">
        <div className="cl-hero__image-wrap">
          <div
            data-delay="7000"
            data-animation="fade"
            className="cl-hero__slider w-slider"
            data-autoplay={true}
            data-easing="ease"
            data-hide-arrows="false"
            data-disable-swipe="false"
            data-autoplay-limit="0"
            data-nav-spacing="3"
            data-duration="500"
            data-infinite="true"
          >
            <div className="w-slider-mask">
              <div
                data-w-id="91b65c63-f4e4-4e28-0269-100f8d912c9d"
                className="cl-hero__slider__slide w-slide no-bg-image bg-[#f1f1f1]"
              >
                <div className="container cl-hero__container z-1">
                  <div className="slide__content">
                    <div className="c-rich-text">
                      <h1 className="slide__content__title">
                        <Skeleton width={340} />
                      </h1>
                      {/* <span className="pt-2 pl-2 h6">{car.year}</span> */}

                      <p>
                        <Skeleton width={210} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShoppingCentreCarPageHeaderSkeleton;
