import React from "react";

const ContactHeader = () => {
  return (
    <section className="cl-hero wf-section">
      <div className="cl-hero__image-wrap">
        <div
          data-delay="7000"
          data-animation="fade"
          className="cl-hero__slider w-slider"
          data-autoplay={true}
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="true"
        >
          <div className="w-slider-mask">
            <div
              data-w-id="5f5e5926-9115-5306-4161-8af4f507efe3"
              className="cl-hero__slider__slide w-slide is--bg-cooperative-a"
            >
              <div className="overlay--dark"></div>
              <div className="container cl-hero__container">
                <div className="slide__content">
                  <h1
                    // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                    className="slide__content__title"
                  >
                    The Cooperative
                  </h1>
                  <p
                    // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                    className="slide__content__desc"
                  >
                    Meeting the financial needs of her members and equipping
                    them with other sources of wealth creation.
                  </p>
                </div>
              </div>
            </div>
            <div
              data-w-id="5f5e5926-9115-5306-4161-8af4f507eff8"
              className="cl-hero__slider__slide w-slide is--bg-cooperative-b"
            >
              <div className="overlay--dark"></div>
              <div className="container cl-hero__container">
                <div className="slide__content">
                  <h1 className="slide__content__title">
                    Don&#x27;t leave your finances to chance
                  </h1>
                  <p className="slide__content__desc">
                    Skyeiwse provides a guaranteed investment option for you
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden--el w-slider-arrow-left">
            <div className="w-icon-slider-left"></div>
          </div>
          <div className="hidden--el w-slider-arrow-right">
            <div className="w-icon-slider-right"></div>
          </div>
          <div className="hidden--el w-slider-nav w-round"></div>
        </div>
      </div>
    </section>
  );
};

export default ContactHeader;
