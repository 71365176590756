const currentSelectId = (id, state) => {
  return id + "-progress__" + state;
};

const currentSelectIdMsg = (id, state) => {
  return id + "-progress__" + state + "--msg";
};

// Global Resusable
export function showProgress(name, state, message) {
  if (document.getElementById(currentSelectId(name, state)))
    document.getElementById(currentSelectId(name, state)).classList.add("show");

  if (document.getElementById(currentSelectIdMsg(name, state)) && message)
    document.getElementById(currentSelectIdMsg(name, state)).innerText =
      message;
}

export function hideProgress(name, state) {
  if (document.getElementById(currentSelectId(name, state)))
    document
      .getElementById(currentSelectId(name, state))
      .classList.remove("show");
}

export default {
  showProgress,
  hideProgress,
};
