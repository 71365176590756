import React from "react";
import Joi from "joi-browser";
import Form from "../../common/formElements/form";
import * as userService from "../../../services/userService";
import auth from "../../../services/authService";

class UserBDF extends Form {
  state = {
    data: { passwordCurrent: "", password: "", passwordConfirm: "" },
    errors: {},
  };

  schema = {
    passwordCurrent: Joi.string().required().min(5).label("PasswordCurrent"),
    password: Joi.string().required().min(5).label("Password"),
    passwordConfirm: Joi.string().required().label("PasswordConfirm"),
  };

  doSubmit = async () => {
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.data.token, response.data.data);
      window.location = "/user-dashboard";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput(
            "passwordCurrent",
            "PasswordCurrent",
            "Password",
            "Current Password",
            ""
          )}
          {this.renderInput(
            "password",
            "Password",
            "Password",
            "New Password",
            ""
          )}
          {this.renderInput(
            "passwordConfirm",
            "PasswordConfirm",
            "password",
            "Confirm New Password",
            ""
          )}

          {this.renderButton("Update Password")}
        </form>
      </div>
    );
  }
}

export default UserBDF;
