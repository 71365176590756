import React, { Fragment } from "react";
import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";
import AppointmentSchedulePageHeader from "./appointmentScheduleHeader";
import AppointmentScheduleFormSection from "./appointmentScheduleFormSection";
import AppointmentScheduleVisitorsTips from "./appointmentScheduleVisitorsTips";
import restartWebflowScript from "../common/restartWebflowScript";

const AppointmentSchedulePage = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <AppointmentSchedulePageHeader />
      <AppointmentScheduleVisitorsTips />
      <AppointmentScheduleFormSection />
      <Footer />
    </Fragment>
  );
};

export default AppointmentSchedulePage;
