import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ContactDetials from '../contact/contactContactDetials';
import HrDevider from '../common/hrDevider';
import SonarPulse from '../common/sonarPulse';

const Offices = () => {
  return (
    <Fragment>
      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="gps-static">
                <img
                  className="gps-static__map"
                  src="/content/images/general/skyewise-locations-nigeria.png"
                  alt="OFfice Locations Map"
                />
                <div className="gps-static__map__point gps-s-m-p--abuja">
                  <span className="gps-static__map__point__marker">
                    <SonarPulse />
                  </span>
                  <div className="gps-static__map__point__data">
                    <div className="point__data__location odd">
                      <h5>Head Office | Abuja</h5>
                      <p className="txt--pt6">
                        13 Uruguay Crescent, Maitama, Abuja
                      </p>
                    </div>

                    <div className="point__data__location">
                      <h5>Automobile | Abuja</h5>
                      <p className="txt--pt6">
                        Area 11, Plot 704 Ahmadu Bello Way, Garki, Abuja
                      </p>
                    </div>
                  </div>
                </div>

                <div className="gps-static__map__point gps-s-m-p--lagos">
                  <span className="gps-static__map__point__marker">
                    <SonarPulse />
                  </span>
                  <div className="gps-static__map__point__data">
                    <div className="point__data__location">
                      <h5>Automobile | Lagos</h5>
                      <p className="txt--pt6">
                        Jakande Junction, Lekki express way, Lagos{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <HrDevider />
              <div className="c-rich-text ">
                <h3>Office Locations</h3>
                <p>
                  Find our office closest to you
                  <br />
                </p>
              </div>
              <a
                href="https://goo.gl/maps/XysMzvHLiBPcTEPD6"
                target="_blank"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Find on Map</p>
                <p className="is-icon"></p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ContactDetials />
    </Fragment>
  );
};

export default Offices;
