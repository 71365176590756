import React from "react";

const WIWSH = (props) => {
  return (
    <div className="header-static static-header--leadership wf-section">
      <div className="container">
        <div className="header-statoc--flex">
          <div className="header-static__container">
            <h1>Why Invest With Us?</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WIWSH;
