function errorFactory(errName, errMsg = '') {
  this.name = errName;
  this.message = errMsg;

  if (errName) console.error(this.name);
}

errorFactory.prototype = Error.prototype;

export default errorFactory;
