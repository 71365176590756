import React from 'react';
import SubsectionTitleWIthFlip from '../common/UiLayoutsAndComponents/subsectionTItleWithFlip';
import { Button, Text } from '@adobe/react-spectrum';
import Delete from '@spectrum-icons/workflow/CallCenter';

const CarContact = (props) => {
  return (
    <div id="get-car-contact" className=" mt-8">
      <h3>Contact</h3>
      <div className="">
        {/* <SubsectionTitleWIthFlip
          props={{
            title: 'Head Office',
            text: 'Abuja',
          }}
        /> */}

        <div className="glue__flex-icon pt-4 mb-4">
          {/* <span className="is-fa-icon--300"></span> */}
          {/* <p className="contact-info__details__item"> */}
          <p className="text-5xl font-extralight	">
            Interested in learning more about this car? Call us now to explore
            and elevate your driving experience!
          </p>
        </div>

        <Button
          elementType={'a'}
          href="tel:+2348094653000"
          variant="accent"
          staticColor="black"
        >
          <Delete />
          <Text>Call now</Text>
        </Button>

        <div className="contact-info__details _5-col-st-grid">
          <div className="glue__flex-icon">
            <span className="is-fa-icon--300"></span>
            <a
              href="tel:+2348094653000"
              className="is--link link--blue contact-info__details__item w-inline-block"
            >
              <p>+234 809 465 3000</p>
            </a>
          </div>

          <div className="glue__flex-icon">
            <span className="is-fa-icon--300"></span>
            <a
              href="tel:+2349126033701"
              className="is--link link--blue contact-info__details__item w-inline-block"
            >
              <p>+234 912 603 3701</p>
            </a>
          </div>

          <div className="glue__flex-icon">
            <span className="is-fa-icon--300"></span>
            <a
              href="tel:+2347063884216"
              className="is--link link--blue contact-info__details__item w-inline-block"
            >
              <p>+234 706 388 4216</p>
            </a>
          </div>
        </div>

        <div className="glue__flex-icon">
          <span className="is-fa-icon--300"></span>
          <p className="contact-info__details__item">
            <a href="mailto:support@skyewisegroup.com">
              support@skyewisegroup.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarContact;
