import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function PageExitCTA(props) {
  return (
    <div className="section page__exit__cta__section wf-section">
      <div className="container scroll-end__container">
        <div className="page__exit__cta__wrp">
          <div className="page__exit__cta__content">
            <div className="c-rich-text">
              <h3 className="s-large-text thin">
                Advancing sustainable economic growth & financial opportunity.
              </h3>
              <p>
                Discover the best investment Opportunities for you to invest
              </p>
            </div>
            <div className="page__exit__cta">
              <div className="scroll-end__section__cta__btn">
                <Link
                  data-w-id="22f52e7a-2d5d-bbe3-c7f3-b23455abd4c5"
                  to="/what-we-do/products-and-services"
                  className="s-btn is-btn--black w-inline-block"
                >
                  <p>Our products</p>
                  <p className="is-icon"></p>
                </Link>
              </div>
              <div className="scroll-end__section__cta__btn">
                <Link
                  data-w-id="22f52e7a-2d5d-bbe3-c7f3-b23455abd4cb"
                  to="/contact"
                  className="s-btn is-btn--light w-inline-block"
                >
                  <p>Get in touch</p>
                  <p className=" is-icon is-fa-icon--300"></p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageExitCTA;
