import React from 'react';
import { useEffect } from 'react';
import ReactPlayer from 'react-player/';
import { initProgressLotties } from '../../utils/initProgressLottie';
import ProgressRender from './../progressRender';
import progressHandler from '../../utils/progressHandler';
import { useState } from 'react';

const VideoPlayerModal = (props) => {
  const { playerData } = props;
  const { playerId, videoUrl } = playerData;
  const { closePlayer } = playerData;

  useEffect(() => {
    initProgressLotties();
    progressHandler.showProgress(playerId, 'loading');
  });

  const [modalplayerState, setModalPlayerState] = useState(true);
  //   const modalplayerState = localStorage.getItem(playerId);

  const hideLoader = () => {
    progressHandler.hideProgress(playerId, 'loading');
  };

  return (
    <div>
      {/* <button onClick={(e) => openPlayer(playerId, e)}>Play</button> */}

      <div id={playerId} className="modal-player player--open_ ">
        {/* {setModalPlayerState(true)} */}
        <div className="modal-player__bg">
          <div className="modal-player__container container">
            <div
              id={playerId + '__modal-overlay'}
              className="modal-player__overlay"
              onClick={(e) => closePlayer(playerId, setModalPlayerState)}
            ></div>

            <div
              className="modal-player__content"
              id={playerId + '__modal-content'}
            >
              <ProgressRender
                elData={{
                  loading: {
                    name: playerId + '-progress__loading',
                    transparency: true,
                  },
                  success: { name: playerId + '-progress__success' },
                  error: { name: playerId + '-progress__error' },
                }}
              />

              {modalplayerState && (
                <ReactPlayer
                  className="react-player"
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  controls={true}
                  autoPlay={true}
                  onReady={hideLoader}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerModal;
