import React, { useEffect } from 'react';
import restartWebflowScript from '../common/restartWebflowScript';
import NavBar from '../common/navbar/navbar';

import Footer from '../common/footer/footer';
import ChatAndNavHelperOpen from '../common/chatAndNavHelperOpen';
import PageExitCTA from '../common/pageExitCTA';
import Trends from '../common/trends/trendsBase';
import Car from './carCardMain';
import FeaturedCars from './carsFeatured';
import CarListingForm from './carListingForm';
import StaticHeader from '../common/UiLayoutsAndComponents/staticHeaderOne';

import AutoPageSubHeading from './automobilePageSubTitle';
import AutoSideNav from './automobileSideNavLinks';
import AutoSideNavTitle from './automobileSideNavTitle';
import CarImageUploadHandler from './carImageUploadHandler';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import CarListingActionDone from './carListingActionDone';

const CarListingImages = () => {
  // useEffect(() => {
  //   var htmlEl = document.getElementsByTagName("html")[0];
  //   htmlEl.setAttribute("data-wf-page", "61c2e2d14d436da4d3382185");
  // });

  restartWebflowScript();

  // Get current car ID from URL
  const { id: carId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const actionQuery = queryParams.get('action');

  function handleParamUpdate(nextAction) {
    // update query parameters
    queryParams.set('action', nextAction);

    // create new search string and navigate to it
    const newActionParams = `?${queryParams.toString()}`;
    navigate({ search: newActionParams });
  }

  return (
    <div>
      <NavBar />

      <StaticHeader
        pageData={{ titleText: 'List new car', altTitle: 'Fill car detials' }}
      />

      <section className="section is-bg--gray-02">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="s-form-centred">
                  <div className="car-listings__block flex_order--04">
                    {actionQuery === 'featuredImage' && (
                      <CarImageUploadHandler
                        multiple={false}
                        uploadConfig={{
                          fieldName: 'imageCover',
                          fieldTitle: 'Cover image',
                          fieldNote:
                            "Upload the car's cover image (featured image)",
                          endpoint: carId,
                          handleParamUpdate,
                          nextAction: 'images',
                        }}
                      />
                    )}

                    {actionQuery === 'images' && (
                      <CarImageUploadHandler
                        multiple={true}
                        uploadConfig={{
                          fieldName: 'images',
                          fieldTitle: 'Car images',
                          fieldNote: 'Upload upto 15 images of this car',
                          endpoint: carId,
                          handleParamUpdate,
                          nextAction: 'done',
                        }}
                      />
                    )}

                    {actionQuery === 'done' && (
                      <CarListingActionDone car={carId} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* GLoable Trends Here */}
      {/* <FeaturedCars /> */}
      {/* <Trends /> */}
      <PageExitCTA />
      <Footer />
    </div>
  );
};

export default CarListingImages;
