import React, { Fragment } from 'react';
import TabContentAndImage from '../../../hooks/tabContentAndImage';
import CtaFullRowWithTPA from '../../common/UiLayoutsAndComponents/ctaWithTPAFullRow';

const WhyKycVerification = (props) => {
  return (
    <Fragment>
      <section className="section wf-section  is-bg--gray-02">
        <div className="container">
          <TabContentAndImage
            tabTitle="Get more from your account"
            content={[
              {
                title: 'Experience personalized service',
                text: 'To better serve our clients and keep the community safe, we require all onboarding clients/customers to complete the basic level verification to access our facilities.',
                img: '/images/user-guide/verified-accounts.png',
              },
              {
                title: 'Personal identity and security',
                text: 'This is to ensure that only legitimate access is allowed to your account and that every credit or security information is delivered directly to you. We never share or use your information publicly.',
                img: '/images/user-guide/personalized_access_dark.png',
              },
              {
                title: 'Credit information and signature',
                text: 'To better serve you and save you time, we give you the option of providing your default bank information for instant pay-out.',
                img: '/images/user-guide/profile_photo_sample.png',
              },
            ]}
          />
        </div>
      </section>

      {/* <QuoteModuleFS /> */}
      <CtaFullRowWithTPA
        content={{
          title: 'Start KYC verification',
          desc: 'It only takes 5 minutes (in 3 quick steps)',
          destUrl: '/account/kyc-verification/start',
        }}
      />

      <section className="section  is-bg--gray-02">
        <div className="container ">
          <div className="_3-col-grid user-guide__tips">
            <div className="grid-item user-guide__tip">
              <div className="c-rich-text">
                <h4>Bio Data</h4>
                <p>
                  The bio-data information you provide must be legal and
                  verifiable.
                </p>
              </div>
              <img src="/images/user-guide/kyc-bio-data.png" alt="" />
            </div>
            <div className="grid-item user-guide__tip">
              <div className="c-rich-text">
                <h4>Your potrait (photograph)</h4>
                <p>
                  Color photo taken in the last 6 months, Use a clear image of
                  your face. Do not use filters commonly used on social media.
                  Have someone else take your photo. No selfies. Take off your
                  eyeglasses for your photo.
                </p>
              </div>
              <img src="/images/user-guide/kyc-passport-photo.png" alt="" />
            </div>
            <div className="grid-item user-guide__tip">
              <div className="c-rich-text">
                <h4>Means of identity</h4>
                <p>
                  Any valid government-issued ID (passport, driver’s license, or
                  national id), in picture format.
                </p>
              </div>
              <img src="/images/user-guide/kyc-id.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <CtaFullRowWithTPA
        content={{
          desc: 'Access our credit facility when you verify your account.',
          destUrl: '/account/kyc-verification/start',
        }}
      />
    </Fragment>
  );
};

export default WhyKycVerification;
