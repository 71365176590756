import React from 'react';

const AutoPageSubHeading = (props) => {
  const { data } = props;

  return (
    <div className="car-listings__block-header flex_order--01">
      <h2>{data.headingText}</h2>
    </div>
  );
};

export default AutoPageSubHeading;
