import React from "react";

import SingleTour from "./toursSingleTour";

const ProductsAndServices = (props) => {
  return (
    <div
      data-w-id="348bf267-469b-36c0-cb3e-1f2b349e3346"
      //   style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
      className="excerpts section is-gray-bg--01 wf-section"
    >
      <div className="container blog-updates__container">
        <div className="_3-col-st-grid">
          <SingleTour
            details={{
              name: "Explore Africa",
              description: "Helping our clients build a treasury of the future",

              image:
                "/images/temp/240_F_166746793_1m0ZI9JSvr3ZJMCxoQdaCa5AdgY91yWs.jpeg",
              url: "/what-we-do/products-and-services/investment",
              // altUrl: "#",
              location: "Africa",
            }}
          />

          <SingleTour
            details={{
              name: "Explore Africa",
              description: "Helping our clients build a treasury of the future",

              image:
                "/images/temp/240_F_166746793_1m0ZI9JSvr3ZJMCxoQdaCa5AdgY91yWs.jpeg",
              url: "/what-we-do/products-and-services/investment",
              // altUrl: "#",
              location: "Africa",
            }}
          />

          <SingleTour
            details={{
              name: "Explore Africa",
              description: "Helping our clients build a treasury of the future",

              image:
                "/images/temp/240_F_166746793_1m0ZI9JSvr3ZJMCxoQdaCa5AdgY91yWs.jpeg",
              url: "/what-we-do/products-and-services/investment",
              // altUrl: "#",
              location: "Africa",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsAndServices;
