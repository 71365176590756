import React from 'react';

const SubsectionTitleWIthFlip = (data) => {
  const { props } = data;

  return (
    <div className="sub-section">
      <div className="sub-section__title">
        <h3>{props.title}</h3>
      </div>
      <div className="sub-section__content">{<p>{props.text}</p>}</div>

      {/* <a className="sub-section__link">
       <p>More</p>
     </a> */}
    </div>
  );
};

export default SubsectionTitleWIthFlip;
