import React from "react";
import { Link } from "react-router-dom";

const CultureAndinnovationHistory = (props) => {
  return (
    <section className="section wf-section ">
      <div className="container">
        <div className="_2-col-st-grid">
          <div>
            <div className="div-block-124">
              <div className="c-rich-text w-richtext">
                <h2>Book Ticket</h2>
                <p>
                  The rich text element allows you to create and format
                  headings, paragraphs, blockquotes, images, and video all in
                  one place instead of having to add and format them
                  individually. Just double-click and easily create content.
                </p>
                <h4>Static and dynamic content editing</h4>
                <p>
                  Headings, paragraphs, blockquotes, figures, images, and figure
                  captions can all be styled.
                </p>
              </div>
              <Link
                data-w-id="b223c505-921d-f061-452d-a81289d6e754"
                to="/travel-and-tourism/ticket-booking"
                className="s-btn is-btn--black w-inline-block"
              >
                <p>The Cooperative</p>
                <p
                  className="is-icon"
                  // style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  
                </p>
              </Link>
            </div>
          </div>
          <div className="">
            <img src="/images/content/cars/2022-GLS-SUV-CT-1-5-02-DR.jpeg" />
          </div>
        </div>

        <div className="_2-col-st-grid">
          <div>
            <div className="div-block-124">
              <div className="c-rich-text w-richtext">
                <h2>Book Ticket</h2>
                <p>
                  The rich text element allows you to create and format
                  headings, paragraphs, blockquotes, images, and video all in
                  one place instead of having to add and format them
                  individually. Just double-click and easily create content.
                </p>
                <h4>Static and dynamic content editing</h4>
                <p>
                  Headings, paragraphs, blockquotes, figures, images, and figure
                  captions can all be styled.
                </p>
              </div>
              <Link
                data-w-id="b223c505-921d-f061-452d-a81289d6e754"
                to="/travel-and-tourism/ticket-booking"
                className="s-btn is-btn--black w-inline-block"
              >
                <p>The Cooperative</p>
                <p
                  className="is-icon"
                  // style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                >
                  
                </p>
              </Link>
            </div>
          </div>
          <div className="">
            <img src="/images/content/cars/2022-GLS-SUV-CT-1-5-02-DR.jpeg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CultureAndinnovationHistory;
