import React from 'react';
import SubsectionTitleWIthFlip from '../common/UiLayoutsAndComponents/subsectionTItleWithFlip';

const ContactDetials = (props) => {
  return (
    <section className="section">
      <div className="container gray-bg-02">
        <div className="_3-col-grid">
          <div className="">
            <div className="">
              <SubsectionTitleWIthFlip
                props={{
                  title: 'Head Office',
                  text: 'Abuja',
                }}
              />
              <div className="contact-info__details">
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item">
                    Plot 2402, Shehu Shagari Way, Maitama, Abuja
                  </p>
                </div>

                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item">
                    <a href="mailto:support@skyewisegroup.com">
                      support@skyewisegroup.com
                    </a>
                  </p>
                </div>

                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <a
                    href="tel:+2348094653000"
                    className="is--link link--blue contact-info__details__item w-inline-block"
                  >
                    <p>+234 809 465 3000</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
              <SubsectionTitleWIthFlip
                props={{
                  title: 'Automobile',
                  text: 'Abuja',
                }}
              />
              <div className="contact-info__details">
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item ">
                    Plot 2402, Shehu Shagari Way, Maitama, Abuja
                  </p>
                </div>
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item ">
                    Area 11, Plot 704 Ahmadu Bello Way, Garki, Abuja
                  </p>
                </div>
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <a
                    href="tel:+2349126033701"
                    className="contact-info__details__item is--link link--blue w-inline-block"
                  >
                    <p>+234 912 603 3701</p>
                  </a>
                </div>

                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <a
                    href="tel:+2347063884216"
                    className="contact-info__details__item is--link link--blue w-inline-block"
                  >
                    <p>+234 706 388 4216</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
              <SubsectionTitleWIthFlip
                props={{
                  title: 'Automobile',
                  text: 'Lagos',
                }}
              />
              <div className="contact-info__details">
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item ">
                    Jakande Junction, Lekki express way, Lagos
                  </p>
                </div>
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <a
                    href="tel:+2347067945150"
                    className="contact-info__details__item is--link link--blue w-inline-block"
                  >
                    <p>+234 809 465 3000</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetials;
