import React, {
  Component,
  useContext,
  useLayoutEffect,
  useEffect,
  useState,
  Fragment,
} from 'react';
import authService from '../../../services/authService';

import StepsIndicator from '../../common/steps-indicator';
import LoanForm from './loanForm';
import LoanGuide from './loanFormParamsGuide';
import UnverifiedUser from '../userVerificationProcess';
import FundLoan from './fundLoan';
import PendingLoan from './pendingLoan';
import ActiveLoan from './activeLoan';
import LoanCollateral from './loanCollateral';
import LoanProgressHint from './loanStepsHints';
import ToolTipOnHover from '../../common/toolTipOnHover';
import DashboardLocalFooter from '../../userDashboardLocalFooter';
import DueLoan from './dueLoan';

class LoanDashbord extends Component {
  state = {
    user: authService.getUVDALocal(),
  };

  render() {
    return (
      <main className="credit-dashbord">
        <div className="credit-dashbord__heading">
          <h3 className="heading-8">Loan</h3>
          <ToolTipOnHover
            tip={{
              tipTitle: 'Loan',
              tipIcon: '',
              tipText:
                'Request loans and get instant approval. Manage your credit records with Skyewise',
            }}
          />
        </div>

        {this.setState()}

        {this.state.user && this.state.user.loanState !== 'due' && (
          <LoanProgressHint />
        )}

        <div className="credit-dashbord__details">
          {this.state.user &&
            this.state.user.verificationState === 'unverified' && (
              <UnverifiedUser />
            )}
          {/* 
          {this.state.user &&
            this.state.user.verificationState === "bio-data" &&
            !this.state.user.currentLoan && (
              <div className="credit-dashbord__sum is-2_5em--white-wrap is-1_27-grid ">
                <LoanGuide />
                <LoanForm />
              </div>
            )} */}

          {this.state.user &&
            this.state.user.loanState == 'none' &&
            this.state.user.verificationState !== 'unverified' && (
              <Fragment>
                <div className="credit-dashbord__sum is-2_5em--white-wrap is-1_27-grid ">
                  <LoanGuide />
                  <LoanForm />
                </div>
                <DashboardLocalFooter />
              </Fragment>
            )}

          {/* {currentUserLocal && VPSTStage == "2" && <LoanForm />}  */}

          {/* <div  className="credit-dashbord__sum is-2_5em--white-wrap is-1_27-grid ">
          <LoanGuide />
          <LoanForm />
        </div> */}

          {this.state.user.currentLoan &&
            this.state.user.verificationState !== 'unverified' &&
            this.state.user.loanState === 'collateral' && <LoanCollateral />}

          {this.state.user.currentLoan &&
            this.state.user.verificationState !== 'unverified' &&
            this.state.user.loanState === 'pending' && <PendingLoan />}

          {this.state.user.currentLoan &&
            this.state.user.verificationState !== 'unverified' &&
            // this.state.user.loanState === "active" && <ActiveLoan />
            this.state.user.loanState === 'approved' && <ActiveLoan />}

          {this.state.user.currentLoan &&
            this.state.user.verificationState !== 'unverified' &&
            // this.state.user.loanState === "active" && <ActiveLoan />
            this.state.user.loanState === 'due' && <DueLoan />}
        </div>
      </main>
    );
  }
}

export default LoanDashbord;
