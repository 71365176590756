import React from "react";
import { Link } from "react-router-dom";

const AutoButtonGridCta = (props) => {
  return (
    <div className="wrapper module-separator">
      <div className="button-grid__container">
        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <a
              className="iconcta-tile__links"
              href="/automobile/cars"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6vy0b8w"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/temp/gen/auto-sl-01.jpg"
                  data-lazy-src="/images/temp/gen/auto-sl-01.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/temp/gen/auto-sl-01.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/temp/gen/auto-sl-01.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container">
                <div className="icon-mb icon-model-chooser iconcta-tile__text">
                  <p className="iconcta-tile__label">ALL VEHICLES</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <Link
              className="iconcta-tile__links"
              to="contact"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6wtxz3m"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/temp/gen/auto-special-contact.jpg"
                  data-lazy-src="/images/temp/gen/auto-special-contact.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/temp/gen/auto-special-contact.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/temp/gen/auto-special-contact.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container">
                <div className="icon-mb icon-gears iconcta-tile__text">
                  <p className="iconcta-tile__label">Get In Touch</p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <a
              className="iconcta-tile__links"
              href="#"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6w6vf7h"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/temp/gen/auto-offers.jpg"
                  data-lazy-src="/images/temp/gen/auto-offers.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/temp/gen/auto-offers.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/temp/gen/auto-offers.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container">
                <div className="icon-mb icon-special-offers iconcta-tile__text">
                  <p className="iconcta-tile__label">SPECIAL OFFERS</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="iconcta-tile">
          <div className="iconcta-tile__tile">
            <Link
              className="iconcta-tile__links"
              to="/contact/appointment"
              data-analytics-trigger="cta"
            >
              <picture
                data-load-module="LazyLoader"
                data-module-loaded="true"
                className="lazy-loader lazy-loader--loaded"
                data-lazyloader-id="_kzjxyv6wgl9t5"
              >
                <source
                  media="(max-width: 767px)"
                  srcSet="/images/temp/mb/SHOPPING-TOOL-INVENTORY-XL.jpg"
                  data-lazy-src="/images/temp/mb/SHOPPING-TOOL-INVENTORY-M.jpg"
                  className="lazy-loader__asset"
                />
                <img
                  src="/images/temp/mb/SHOPPING-TOOL-INVENTORY-XL.jpg"
                  alt=""
                  className="iconcta-tile__image-transform lazy-loader__asset lazy-loader__asset--loaded"
                  data-lazy-src="/images/temp/mb/SHOPPING-TOOL-INVENTORY-XL.jpg"
                  role="presentation"
                />
              </picture>

              <div className="iconcta-tile__description-container">
                <div className="icon-mb icon-view-inventory iconcta-tile__text">
                  <p className="iconcta-tile__label">Schedule Appointment</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoButtonGridCta;
