import React from 'react';

const FileUploadPreviewThumbnail = (props) => {
  const { files } = props;

  return (
    <div className="max-w-[520px]">
      <div className="_5-col-st-grid">
        {Object.values(files).map((file, i) => (
          <img
            className="rounded mb-2 max-h-[55px] object-cover	"
            key={i}
            src={window.URL.createObjectURL(file)}
          />
        ))}
      </div>
      <div className="hr-line hr-line--dark"></div>
    </div>
  );
};

export default FileUploadPreviewThumbnail;
