import React, { Fragment, useEffect, useContext } from 'react';
import UserContext from '../../context/userContext';
import configData from '../../config.json';
const storageUrl = configData.storageUrl;

const DashboradHeader = () => {
  const currentUser = useContext(UserContext);

  return (
    <div className="user-dashboard__header">
      <div className="user-dashboard__header__header-content">
        <div className="container is--narrow">
          <div className="_2-col-st-grid udh__grid">
            <div></div>
            <div className="udh__quick-acn mobile-disabled">
              <a
                href="mailto: support@skyewisegroup.com"
                className="udh__quick-acn__item udh__quick-acn__item--left "
              >
                <div className="udh__quick-acn__item__icon">
                  <p className="is-icon"></p>
                </div>
                <div className="udh__quick-acn__item__text">
                  <p>support@skyewisegroup.com</p>
                </div>
              </a>
              <a href="tel:07067945150" className="udh__quick-acn__item">
                <div className="udh__quick-acn__item__icon">
                  <p className="is-icon"></p>
                </div>
                <div className="udh__quick-acn__item__text">
                  <p>+234 809 465 3000</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="div-block-120"></div>
        <div className="overlay--dark"></div>
        <div className="user-dashboard__header__content">
          {currentUser && (
            <img
              src={
                currentUser.profilePhoto !== 'default.jpg'
                  ? storageUrl + currentUser.profilePhoto
                  : '/images/pexels-pnw-production-8091213.jpg'
              }
              alt={currentUser.name + ' Profile Photo'}
              className="user__image is-object-fit"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboradHeader;
