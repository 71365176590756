import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { formatCurrency } from '../../../utils/formatCurrency';
import getTimeAMPMFormat from '../../../utils/format12HTime';
import ProgressRender from '../../progressRender';
import progressHandler from '../../../utils/progressHandler';
import { updateInvestment } from '../../../services/investmentService';
import { initProgressLotties } from '../../../utils/initProgressLottie';

const DashbordModal = (props) => {
  const withdrawInvestment = async () => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('modalbox-credit', 'loading');
    try {
      const InvestmentId = props.dashboardData._id;
      // const userId = props.user;
      // const data = new FormData({});

      await updateInvestment(InvestmentId, {
        // ...formDate,
        status: 'withdraw',
      });
      // await updateUser(userId, { loanState: 'approved' });

      // Hide UI loadin, show success
      progressHandler.hideProgress('modalbox-credit', 'loading');
      progressHandler.showProgress('modalbox-credit', 'success');

      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 2500);
    } catch (ex) {
      // console.log(ex);

      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        // hide UI Loading, show Error
        progressHandler.hideProgress('modalbox-credit', 'loading');
        progressHandler.showProgress('modalbox-credit', 'error', msg);
      } else {
        progressHandler.hideProgress('modalbox-credit', 'loading');
        progressHandler.showProgress(
          'modalbox-credit',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('modalbox-credit', 'error');
      }, 3500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  return (
    <Fragment>
      {/* Render Investment Modal */}
      {props.dashboardData &&
        props.dashboardData.credit !== null &&
        props.dashboardData.credit === 'investment' && (
          <div
            data-w-id="b61e2cc4-ecce-ce9d-b5e2-4e33b5a5b171"
            className="s-dialuge-box"
            style={{ display: 'none' }}
          >
            <div
              data-w-id="e213b90b-64f3-f27e-4de7-2136a80b5e3c"
              style={{ opacity: 0 }}
              className="overlay--dark is-overlay--solid-blk"
            ></div>
            <div className="s-dialuge-box__flex">
              <div
                data-w-id="c7b2c2d1-c289-c856-b795-74f35aeb5595"
                // style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                className="s-dialuge-box__container st-b-radius st-b-shadow"
              >
                <div
                  data-w-id="6448275c-7759-24db-522b-04958a73cd96"
                  className="close--btn"
                >
                  <p className="is-icon is-icon--no-m-rt">
                    <span></span>
                  </p>
                </div>
                <div className="s-dialuge-box__content__grid">
                  <div className="s-dialuge-box__content__details">
                    <div className="c-rich-text">
                      <div id="data--investment-details">
                        <h3 className="s-dialuge-box__content__details__title">
                          {`Current ${props.dashboardData.credit}`}
                        </h3>
                        <p className="s-dialuge-box__content__details__desc">
                          {`Your ${
                            props.dashboardData.credit
                          } of   ${formatCurrency(
                            Number(props.dashboardData.amount)
                          )} on the ${new Date(
                            props.dashboardData.createdAt
                          ).toDateString()}`}
                        </p>
                        <span className="section-title-dvdr--dvdr"></span>
                        <p>Thank you for choosing Skyewise</p>
                      </div>

                      <div
                        style={{ display: 'none' }}
                        id="data--confirm-withdraw"
                      >
                        <h3>Confirm withrawal</h3>
                        <form>
                          <input
                            type="checkbox"
                            id="withdrawal-confrimation"
                            name="withdrawal-confrimation"
                            value="Confirmed"
                            onClick={(e) => {
                              console.log(e.target.checked);
                              const withdrawConfirmBtn =
                                document.getElementById(
                                  'btn--withraw-confirmation'
                                );
                              e.target.checked
                                ? withdrawConfirmBtn.removeAttribute('disabled')
                                : withdrawConfirmBtn.setAttribute(
                                    'disabled',
                                    true
                                  );
                            }}
                          />
                          <label for="withdrawal-confrimation">Confirm</label>
                        </form>
                        <span className="section-title-dvdr--dvdr"></span>
                        <p>Thank you for choosing Skyewise</p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <aside className="s-dialuge-box__content__aside">
                      <p className="s-dialuge-box__status s-dialuge-box__status  st-r-mb">
                        <span
                          className={
                            'is-icon ' +
                            props.dashboardData.status +
                            ' sm-rt-margin'
                          }
                        >
                          
                        </span>
                        {props.dashboardData.status}
                      </p>
                      <div className="s-dialuge-box__amount  st-r-mb">
                        <span>Amount (N)</span>
                        <p className="text--big text--big--bold">
                          {formatCurrency(Number(props.dashboardData.amount))}
                        </p>
                      </div>
                      <div className="s-dialuge-box__aside__inner-container st-r-mb">
                        <h5 className="s-dialuge-box__aside__date">
                          {new Date(
                            props.dashboardData.createdAt
                          ).toDateString()}
                        </h5>

                        <p className="s-dialuge-box__aside__caption">
                          {getTimeAMPMFormat(
                            new Date(props.dashboardData.createdAt)
                          )}
                        </p>
                      </div>

                      {props.dashboardData.credit === 'investment' && (
                        <div className="s-dialuge-box__aside__inner-container">
                          <h5 className="s-dialuge-box__aside__caption">
                            Duration
                          </h5>
                          <p className="s-dialuge-box__aside__date">
                            {` ${props.dashboardData.period} Months`}
                          </p>
                        </div>
                      )}
                    </aside>
                  </div>

                  {props.dashboardData.status === 'pending' && (
                    <div className="s-dialuge-box__cta">
                      <a
                        href="#"
                        className="s-btn is-btn--black w-inline-block"
                      >
                        <p>Cancel</p>
                      </a>
                    </div>
                  )}

                  {props.dashboardData.status === 'active' && (
                    <div className="s-dialuge-box__cta">
                      <button
                        onClick={(e) => {
                          let el = document.getElementById(
                              'data--investment-details'
                            ),
                            WithdrawBtn = e.target,
                            withrawConfirmation = document.getElementById(
                              'btn--withraw-confirmation'
                            ),
                            confirmBlock = document.getElementById(
                              'data--confirm-withdraw'
                            );

                          el.style.display !== 'none'
                            ? ((el.style.display = 'none'),
                              (WithdrawBtn.innerText = 'Back'),
                              (withrawConfirmation.style.display = 'block'),
                              (confirmBlock.style.display = 'block'))
                            : ((el.style.display = 'block'),
                              (WithdrawBtn.innerText = 'Withdraw'),
                              (withrawConfirmation.style.display = 'none'),
                              (confirmBlock.style.display = 'none'));
                        }}
                        className="s-btn is-btn--outline w-inline-block"
                      >
                        Withdraw
                      </button>

                      <button
                        style={{ display: 'none' }}
                        onClick={(e) => {
                          // alert('Confirmed');
                          withdrawInvestment();
                        }}
                        // disabled
                        className="s-btn s-btn--blue w-inline-block"
                        id="btn--withraw-confirmation"
                      >
                        Withdraw
                      </button>
                    </div>
                  )}
                </div>
                <ProgressRender
                  elData={{
                    loading: {
                      name: 'modalbox-credit-progress__loading',
                      size: 'small',
                    },
                    success: {
                      name: 'modalbox-credit-progress__success',
                      size: 'small',
                    },
                    error: {
                      name: 'modalbox-credit-progress__error',
                      size: 'small',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )}

      {/* Render Loan Modal */}
      {props.dashboardData &&
        props.dashboardData.credit !== null &&
        props.dashboardData.credit === 'loan' && (
          <div
            data-w-id="b61e2cc4-ecce-ce9d-b5e2-4e33b5a5b171"
            className="s-dialuge-box"
            style={{ display: 'none' }}
          >
            <div
              data-w-id="e213b90b-64f3-f27e-4de7-2136a80b5e3c"
              style={{ opacity: 0 }}
              className="overlay--dark is-overlay--solid-blk"
            ></div>
            <div className="s-dialuge-box__flex">
              <div
                data-w-id="c7b2c2d1-c289-c856-b795-74f35aeb5595"
                // style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                className="s-dialuge-box__container st-b-radius st-b-shadow"
              >
                <div
                  data-w-id="6448275c-7759-24db-522b-04958a73cd96"
                  className="close--btn"
                >
                  <p className="is-icon is-icon--no-m-rt">
                    <span></span>
                  </p>
                </div>
                <div className="s-dialuge-box__content__grid">
                  <div className="s-dialuge-box__content__details">
                    <div className="c-rich-text">
                      <h3 className="s-dialuge-box__content__details__title">
                        {`Current ${props.dashboardData.credit}`}
                      </h3>
                      <div id="data--loan-details">
                        <p className="s-dialuge-box__content__details__desc">
                          {`Your ${
                            props.dashboardData.credit
                          } of   ${formatCurrency(
                            Number(props.dashboardData.amount)
                          )} on the ${new Date(
                            props.dashboardData.createdAt
                          ).toDateString()}`}
                        </p>
                        <span className="section-title-dvdr--dvdr"></span>
                        <p>Thank you for choosing Skyewise</p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <aside className="s-dialuge-box__content__aside">
                      <p className="s-dialuge-box__status s-dialuge-box__status  st-r-mb">
                        <span
                          className={
                            'is-icon ' +
                            props.dashboardData.status +
                            ' sm-rt-margin'
                          }
                        >
                          
                        </span>
                        {props.dashboardData.status}
                      </p>
                      <div className="s-dialuge-box__amount  st-r-mb">
                        <span>Amount (N)</span>
                        <p className="text--big text--big--bold">
                          {formatCurrency(Number(props.dashboardData.amount))}
                        </p>
                      </div>
                      <div className="s-dialuge-box__aside__inner-container st-r-mb">
                        <h5 className="s-dialuge-box__aside__date">
                          {new Date(
                            props.dashboardData.createdAt
                          ).toDateString()}
                        </h5>

                        <p className="s-dialuge-box__aside__caption">
                          {getTimeAMPMFormat(
                            new Date(props.dashboardData.createdAt)
                          )}
                        </p>
                      </div>

                      {props.dashboardData.credit === 'investment' && (
                        <div className="s-dialuge-box__aside__inner-container">
                          <h5 className="s-dialuge-box__aside__caption">
                            Duration
                          </h5>
                          <p className="s-dialuge-box__aside__date">
                            {` ${props.dashboardData.period} Months`}
                          </p>
                        </div>
                      )}
                    </aside>
                  </div>

                  {props.dashboardData.status === 'pending' && (
                    <div className="s-dialuge-box__cta">
                      <a
                        href="#"
                        className="s-btn is-btn--black w-inline-block"
                      >
                        <p>Cancel</p>
                      </a>
                    </div>
                  )}

                  {/* {props.dashboardData.status === 'active' && (
                    <div className="s-dialuge-box__cta">
                      <button
                        onClick={(e) => {
                          let el = document.getElementById(
                              'data--investment-details'
                            ),
                            WithdrawBtn = e.target,
                            withrawConfirmation = document.getElementById(
                              'btn--withraw-confirmation'
                            );

                          el.style.display !== 'none'
                            ? ((el.style.display = 'none'),
                              (WithdrawBtn.innerText = 'Back'),
                              (withrawConfirmation.style.display = 'block'))
                            : ((el.style.display = 'block'),
                              (WithdrawBtn.innerText = 'Withdraw'))(
                                (withrawConfirmation.style.display = 'none')
                              );
                        }}
                        className="s-btn is-btn--black w-inline-block"
                      >
                        Withdraw
                      </button>

                      <button
                        style={{ display: 'none' }}
                        onClick={(e) => {
                          alert('Confirmed');
                        }}
                        className="s-btn s-btn--blue w-inline-block"
                        id="btn--withraw-confirmation"
                      >
                        Withdraw
                      </button>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        )}

      {props.dashboardData && props.dashboardData.credit === null && (
        <div
          data-w-id="b61e2cc4-ecce-ce9d-b5e2-4e33b5a5b171"
          className="s-dialuge-box"
          style={{ display: 'none' }}
        >
          <div
            data-w-id="e213b90b-64f3-f27e-4de7-2136a80b5e3c"
            style={{ opacity: 0 }}
            className="overlay--dark is-overlay--solid-blk"
          ></div>
          <div className="s-dialuge-box__flex">
            <div
              data-w-id="c7b2c2d1-c289-c856-b795-74f35aeb5595"
              // style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
              className="s-dialuge-box__container st-b-radius st-b-shadow"
            >
              <div
                data-w-id="6448275c-7759-24db-522b-04958a73cd96"
                className="close--btn"
              >
                <p className="is-icon is-icon--no-m-rt">
                  <span></span>
                </p>
              </div>
              <div>
                <h3> You do not have active credit record.</h3>{' '}
                <p>Access our credit facility through your dashboard.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DashbordModal;
