import React, { Fragment, useContext, useEffect, useState } from "react";

import InvestmentService from "../../../services/investmentService";
import authService from "../../../services/authService";
import { initProgressLotties } from "../../../utils/initProgressLottie";
import progressHandler from "../../../utils/progressHandler";
import ProgressRender from "../../progressRender";
import formatCurrency from "../../../utils/formatCurrency";
import CurrentyIcon from "../../common/currency";

const ActiveCredit = (props) => {
  const currentUser = authService.getUVDALocal();
  const [userInvestment, setUserInvestmentData] = useState(null);

  // Filterd Investment Query
  const activeInvestmentQueryStr = "?isActive=false";

  useEffect(() => {
    // Initialize Lottie
    initProgressLotties();

    async function getCInvestment() {
      if (
        currentUser &&
        currentUser.currentInvestment &&
        currentUser.investmentState !== "none"
      ) {
        // Show UI Loading
        progressHandler.showProgress("active-investment", "loading");

        try {
          const result = await InvestmentService.getActiveInvestment(
            currentUser.currentInvestment
          );

          const { data: InvestmentData } = result.data.data;

          progressHandler.hideProgress("active-investment", "loading");

          setUserInvestmentData(InvestmentData);
        } catch (ex) {
          progressHandler.hideProgress("active-investment", "loading");
        }
      }
    }
    getCInvestment();
  }, []);

  return (
    <div
      data-w-id="fdf06859-a35f-327f-360b-fe33609aa9b6"
      className="credit-sum st-b-radius sm-b-shadow hover--scale"
      onClick={() =>
        props.dashboardData({
          ...userInvestment,
          credit: userInvestment ? "investment" : null,
        })
      }
    >
      <div className="investment-sum__cont">
        {/* Progress  */}
        <ProgressRender
          elData={{
            loading: {
              name: "active-investment-progress__loading",
              size: "small",
              transparency: true,
            },
            success: { name: "active-investment-progress__success" },
            error: { name: "active-investment-progress__error" },
          }}
        />

        {userInvestment && (
          <Fragment>
            <div className="investment-sum__credit-type">
              <div className="is-icon is-blue credit__icon">
                <p></p>
              </div>
              <h5 className="credit__title">Active Investment</h5>
            </div>
            <div className="investment-sum__amount">
              <p className="is-blue credit__amount is-credit__amount--invest">
                {userInvestment && (
                  <span>
                    <CurrentyIcon icon={"N"} />

                    {formatCurrency(Number(userInvestment.amount))}
                  </span>
                )}
              </p>
            </div>

            <div className="investment-sum__tags text--small">
              <p className="credit-sum__date">
                <span className="is-icon"></span>{" "}
                {userInvestment &&
                  `${new Date(
                    Date.parse(userInvestment.createdAt)
                  ).toLocaleDateString()}`}
              </p>
              <p
                className={
                  "credit-sum__state " + userInvestment.status + "--solid"
                }
              >
                {userInvestment && userInvestment.status}
              </p>
            </div>
          </Fragment>
        )}

        {!userInvestment && (
          <Fragment>
            <div className="investment-sum__credit-type">
              <div className="is-icon is-blue credit__icon">
                <p></p>
              </div>
              <h5 className="credit__title">Investment</h5>
            </div>
            <div className="investment-sum__amount">
              <p className="is-blue credit__amount is-credit__amount--investr">
                <span>00.00</span>
              </p>
            </div>
            <div className="investment-sum__tags text--small">
              <p className="credit-sum__date">
                <span className="is-icon"></span> 00.00.00
              </p>
              <p className="credit-sum__state credit--inactive">
                <span>No active Investment </span>
              </p>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ActiveCredit;
