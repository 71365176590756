import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/formElements/form';
import * as userService from '../../services/userService';
import auth from '../../services/authService';
// import CUDA from '../hooks/GetUserData';
import Delete from '@spectrum-icons/workflow/Delete';

import progressHandler from '../../utils/progressHandler';
import {
  ActionButton,
  AlertDialog,
  Button,
  DialogTrigger,
  Flex,
  Heading,
  IllustratedMessage,
  ProgressCircle,
  Text,
} from '@adobe/react-spectrum';
import { deleteCar, updateCar } from '../../services/carService';
import CarImageUploadHandler from './carImageUploadHandler';
import { useNavigate } from 'react-router-dom';
import { ToastQueue } from '@react-spectrum/toast';
// import { Navigate } from 'react-router-dom';
// import { UploadToCloud } from '@spectrum-icons/workflow';
// import UploadToCloudOutline from '@spectrum-icons/workflow/UploadToCloudOutline';
// import ProgressRender from '../progressRender';

class ManageCarListingForm extends Form {
  state = {
    data: {
      make: this.props.car ? this.props.car.make : '',
      model: this.props.car ? this.props.car.model : '',
      year: this.props.car ? this.props.car.year : '',
      bodyType: this.props.car ? this.props.car.bodyType : '',
      mileage: this.props.car ? this.props.car.mileage : '',
      transmissionSystem: this.props.car
        ? this.props.car.transmissionSystem
        : '',
      condition: this.props.car ? this.props.car.condition : '',
      exteriorColour: this.props.car ? this.props.car.exteriorColour : '',
      wheelDrive: this.props.car ? this.props.car.wheelDrive : '',
      location: this.props.car ? this.props.car.location : '',
      exteriorColourCode: this.props.car
        ? this.props.car.exteriorColourCode
        : '',
      cylinder: this.props.car ? this.props.car.cylinder : '',
      engineCapacity: this.props.car ? this.props.car.engineCapacity : '',
      securityFeature: this.props.car ? this.props.car.securityFeature : '',
      description: this.props.car ? this.props.car.description : '',
    },
    errors: {},
    carProgressState: 'init',
    ctaActionState: false,
    deleteAction: false,
    filledSrc: '',
  };

  schema = {
    make: Joi.string().required().label('Make'),
    model: Joi.string().required().label('Model'),
    year: Joi.number().required().allow().label('Year'),
    bodyType: Joi.string().required().label('Body Type'),
    mileage: Joi.number().optional().allow('').label('Mileage'),
    transmissionSystem: Joi.string().required().label('Transmission System'),
    exteriorColour: Joi.string().required().label('Exterior Colour'),
    location: Joi.string().required().allow('').label('Location'),
    wheelDrive: Joi.string().optional().allow('').label('Wheel Drive'),
    condition: Joi.string().required().label('Condition'),
    exteriorColourCode: Joi.string()
      .optional()
      .allow('')
      .label('Exterior Colour Code'),
    cylinder: Joi.string().required().label('Cylinder'),
    engineCapacity: Joi.string().required().allow('').label('Engine Capacity'),
    securityFeature: Joi.string()
      .optional()
      .allow('')
      .label('Security Feature'),
    description: Joi.string().required().allow('').label('Car Description'),

    // disableForm: Joi.string()
    //   .required()
    //   .min(5)
    //   .label("DisableForm"),
  };

  doSubmit = async () => {
    // Initialize Lottie
    this.setState({ ctaActionState: true });

    const carId = this.props.car.id;

    try {
      const { data: response } = (await updateCar(carId, this.state.data)).data
        .data;

      this.setState({ ctaActionState: false });
      // this.setState({ carProgressState: 'car-photos' });
      // window.location = `/automobile/listing/${response.id}?action=featuredImage`;

      // if (response) {
      // const currentUserLocal = await auth.setUVDALocal();
      // Hide UI loadin, show success
      // progressHandler.hideProgress('signup', 'loading');
      // progressHandler.showProgress('signup', 'success');
      // setTimeout(() => {
      // }, 1500);
    } catch (ex) {
      this.setState({ ctaActionState: false });

      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }
      } else {
        //Handle unknown error
      }

      setTimeout(() => {
        progressHandler.hideProgress('signup', 'error');
      }, 1500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        // this.setState({ errors });
      }
    }
  };

  doDelete = async () => {
    // const navigate = useNavigate();
    // ToastQueue.positive('Toast is done!');

    this.setState({ deleteAction: true });

    const carId = this.props.car.id;

    try {
      await deleteCar(carId);
      // this.setState({ carProgressState: 'car-photos' });
      // window.location = `/automobile/listing/${response.id}?action=featuredImage`;

      setTimeout(() => {
        ToastQueue.info('Car has been deleted!', { timeout: 3000 });
        this.setState({ deleteAction: false });

        window.location = '/automobile/cars';
        // navigate({ search: 'new search url' });
        // Replace URL without rerender
        // window.history.replaceState(
        //   null,
        //   'New Page Title',
        //   '/pathname/goes/here'
        // );
        // this.props.history.push({
        //   pathname: `/automobile/cars`,
        // });
      }, 2500);

      // if (response) {
      // const currentUserLocal = await auth.setUVDALocal();
      // Hide UI loadin, show success
      // progressHandler.hideProgress('signup', 'loading');
      // progressHandler.showProgress('signup', 'success');
      // setTimeout(() => {
      // }, 1500);
    } catch (ex) {
      this.setState({ deleteAction: false });

      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        ToastQueue.negative(msg, { timeout: 3000 });
      } else {
        //Handle unknown error
        ToastQueue.negative('Unexpected error occured.', { timeout: 3000 });
      }

      //  setTimeout(() => {
      //    progressHandler.hideProgress('signup', 'error');
      //  }, 1500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        // this.setState({ errors });
      }
    }
  };
  render() {
    return (
      <>
        {this.state.carProgressState &&
          this.state.carProgressState === 'init' && (
            <div className="st-form-blk ">
              <form className="" onSubmit={this.handleSubmit}>
                <div className="_4-col-st-grid">
                  {this.renderInput(
                    'make',
                    'Make',
                    'text',
                    'Car Make (Brand)',
                    ''
                  )}

                  {this.renderInput('model', 'Model', 'text', 'Car Model', '')}

                  {this.renderInput('year', 'Year', 'Number', 'Year', '')}

                  {this.renderSelect(
                    'condition',
                    'Condition',
                    [
                      { name: 'New', value: 'New' },
                      { name: 'Used', value: 'used' },
                    ],
                    ''
                  )}
                </div>

                <div className="_4-col-st-grid">
                  {this.renderInput(
                    'mileage',
                    'Mileage',
                    'number',
                    'Mileage',
                    ''
                  )}

                  {this.renderSelect(
                    'transmissionSystem',
                    'Transmission System',
                    [
                      { name: 'Automatic', value: 'automatic' },
                      { name: 'Manual', value: 'manual' },
                    ],
                    ''
                  )}

                  {this.renderSelect(
                    'bodyType',
                    'Body Type',
                    [
                      { name: 'Sedan', value: 'Sedan' },
                      { name: 'SUV', value: 'SUV' },
                      { name: 'Van', value: 'Van' },
                      { name: 'Minivan', value: 'Minivan' },
                      { name: 'Wagon', value: 'Wagon' },
                      { name: 'Hatchback', value: 'Hatchback' },
                      { name: 'Coupe', value: 'Coupe' },
                      { name: 'Convertible', value: 'Convertible' },
                      { name: 'Truck', value: 'Truck' },
                    ],
                    ''
                  )}

                  {this.renderSelect(
                    'wheelDrive',
                    'Wheel Drive',
                    [
                      { name: 'AWD', value: 'AWD' },
                      { name: '4WD', value: '4WD' },
                      { name: '2WD', value: '2WD' },
                    ],
                    'O'
                  )}
                </div>

                <div className="_4-col-st-grid ">
                  {this.renderInput(
                    'cylinder',
                    'Cylinder',
                    'number',
                    'Cylinder i.e 4, 6 or 8',
                    ''
                  )}
                  {this.renderInput(
                    'engineCapacity',
                    'Engine Capacity',
                    'number',
                    'Engine Capacity e.g 2.0L',
                    ' '
                  )}

                  {this.renderInput(
                    'exteriorColour',
                    'Exterior Colour',
                    'text',
                    'Exterior Colour',
                    ''
                  )}

                  {this.renderInput(
                    'exteriorColourCode',
                    'Exterior Colour Code',
                    'color',
                    '', //No placeholder
                    ''
                  )}
                </div>
                <div className="_2-col-st-grid gap--narrow">
                  {this.renderTextArea(
                    'description',
                    'Car Description',
                    'Car description',
                    ''
                  )}

                  <div className="_2-col-st-grid gap--narrow">
                    <div>
                      {this.renderSelect(
                        'securityFeature',
                        'Security Feature',
                        [{ name: 'Bulletproof', value: 'bulletproof' }],
                        ''
                      )}
                    </div>

                    <div>
                      {this.renderInput(
                        'location',
                        'Location',
                        'text',
                        'Location',
                        ''
                      )}
                    </div>
                    <div className="justify-self-end">
                      <DialogTrigger>
                        <Button
                          isPending={this.state.deleteAction}
                          variant="negative"
                        >
                          <Delete />
                          <Text>Delist car</Text>
                        </Button>
                        <AlertDialog
                          variant="destructive"
                          title="Delete car"
                          primaryActionLabel="Delete"
                          cancelLabel="Cancel"
                          isPending={this.state.deleteAction}
                          onPrimaryAction={this.doDelete}
                        >
                          This will permanently delete the selected car.
                          Continue?
                        </AlertDialog>
                      </DialogTrigger>
                    </div>

                    <div className="">
                      {this.renderButton(
                        'Update car',
                        this.state.ctaActionState
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}

        {/* {this.state.carProgressState &&
          this.state.carProgressState === 'creating' && (
            <div>
              <ProgressCircle
                aria-label="Loading…"
                staticColor="black"
                isIndeterminate
              />
            </div>
          )} */}
      </>
    );
  }
}

export default ManageCarListingForm;
