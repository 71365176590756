import React, { Fragment, useContext, useEffect, useState } from 'react';
import LoanServices from '../../../services/loanService';
import authService from '../../../services/authService';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import progressHandler from '../../../utils/progressHandler';
import TitleH4Devider from '../../common/titleH4withDevider';

import UserLoanHistory from '../../user/creditSummary/userCreditLoanRecord';
import ProgressRender from '../../progressRender';

const ClientLoansRecords = (props) => {
  const [hasLoanRecords, setHasLoanRecords] = useState(false);
  const [hasLoansRecords, setHasLoansRecords] = useState(false);

  const [userLoanRecords, setUserLoanRecords] = useState(null);

  const currentUser = authService.getUVDALocal();

  const { client } = props;

  useEffect(() => {
    // Initialize Lottie
    initProgressLotties();

    async function getUserCreditRecords() {
      const currentUser = authService.getUVDALocal();

      // Show UI Loading
      progressHandler.showProgress('credit-record', 'loading');

      try {
        setHasLoanRecords('loading');
        setHasLoansRecords('loading');

        const userLoan = await LoanServices.getLoanQuery({
          user: client._id,
        });

        const { data: loanData } = userLoan.data.data;

        if (loanData.length > 0) {
          setHasLoansRecords(true);
        } else {
          setHasLoansRecords(false);
        }

        progressHandler.hideProgress('credit-record', 'loading');

        setUserLoanRecords(loanData);
      } catch (ex) {
        // console.log(ex.response);
        if (ex.response) {
          let msg = '';

          if (typeof ex.response.data === 'string') {
            msg += msg + ex.response.data;
          }

          if (typeof ex.response.data === 'object') {
            msg += msg + ex.response.data.message;
          }

          // hide UI Loading, show Error
          progressHandler.hideProgress('credit-record', 'loading');
          progressHandler.showProgress('credit-record', 'error', msg);
        } else {
          progressHandler.hideProgress('credit-record', 'loading');
          progressHandler.showProgress(
            'credit-record',
            'error',
            'An error occurred, please try again later. This is not from you.'
          );
        }

        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.email = ex.response.data;
          this.setState({ errors });
        }
      }
    }
    getUserCreditRecords();
  }, []);

  return (
    <Fragment>
      <div className="psuedo-pos--relative">
        <ProgressRender
          elData={{
            loading: {
              name: 'credit-record-progress__loading',
              size: 'small',
              transparency: true,
            },
            success: {
              name: 'credit-record-progress__success',
              size: 'small',
              transparency: true,
            },
            error: {
              name: 'credit-record-progress__error',
              size: 'small',
              transparency: true,
            },
          }}
        />

        <div className="credit__history">
          <TitleH4Devider title={'Loans'} />

          {!hasLoansRecords && (
            <p className="psuedo psuedo-txt">No loan records</p>
          )}
          {hasLoansRecords == 'loading' && <p className="psuedo-txt"></p>}

          {hasLoansRecords &&
            userLoanRecords &&
            userLoanRecords.map((record, k) => (
              <UserLoanHistory historyData={record} key={k} />
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default ClientLoansRecords;
