import React, { Component, Fragment } from 'react';
import auth from '../services/authService';
import { Link } from 'react-router-dom';
import Table from './common/table';
import Like from './common/like';
import configData from '../config.json';

const storageUrl = configData.storageUrl;

class InvestmentsTable extends Component {
  columns = [
    {
      path: 'user.name',
      label: 'Investor',
      content: (investment) => (
        <Fragment>
          {investment.user && (
            <Link
              to={investment && `/admin/clients/${investment.user._id}`}
              className="investment--investor"
            >
              <span className="investment--investor--photo">
                <div className="nav__user-photo">
                  {investment.user && (
                    <img
                      src={
                        investment.user.photo !== 'default.jpg'
                          ? storageUrl + investment.user.photo
                          : '/images/thumb_15951118880user.png'
                      }
                      loading="lazy"
                      alt=""
                      className="user__profile__photo__img"
                    />
                  )}
                </div>
              </span>{' '}
              {investment.user && (
                <span className="is-fw-600">{investment.user.name}</span>
              )}
            </Link>
          )}
        </Fragment>
      ),
    },
    {
      path: 'user.name',
      label: 'Investment Date',
      content: (investment) => (
        // <span>{`${new Date(investment.createdAt).toLocaleString()}`}</span>
        <span>{`${new Date(investment.createdAt).toDateString()}`}</span>
      ),
    },

    {
      path: 'status',
      label: 'Status',
      content: (investment) => (
        <p className={`credit-status credit-status--${investment.status}`}>
          {investment.status}
        </p>
      ),
    },

    {
      path: 'amount',
      label: 'Amount',
      content: (investment) => (
        <span className="is-fw-600">₦ {investment.amount}</span>
      ),
    },
    {
      path: 'details',
      label: 'Details',
      key: 'details',
      content: (investment) => (
        // <Like
        //   liked={investment.liked}
        //   onClick={() => this.props.onLike(investment)}
        // />

        <Link
          className="alt-btn btn-link--is-blue w-inline-block alt-bt--inline"
          to={`/admin/investments/${investment._id}`}
        >
          {' '}
          View
        </Link>
      ),
    },
    // {
    //   path: "like",
    //   label: "Like",
    //   key: "like",
    //   content: (investment) => (
    //     <Like
    //       liked={investment.liked}
    //       onClick={() => this.props.onLike(investment)}
    //     />
    //   ),
    // },
  ];

  deleteColumn = {
    key: 'delete',
    content: (investment) => (
      <button
        onClick={() => this.props.onDelete(investment)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { investments, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={investments}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default InvestmentsTable;
