import React from "react";
import { Link } from "react-router-dom";

const FoundationLocalNav = (props) => {
  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="internal-nav w-nav is--bg-white"
    >
      <div className="container container--internal-nav w-container">
        <nav role="navigation" className="w-nav-menu nav--is-page-local">
          <a
            href="#cooperative-services"
            aria-current="page"
            className="internal-nav--item w-nav-link"
          >
            Services
          </a>
          <Link to="/user-dashboard" className="internal-nav--item w-nav-link">
            Membership
          </Link>
          <a
            href="#the-cooperative-benefits"
            className="internal-nav--item w-nav-link"
          >
            Benefits
          </a>
        </nav>
      </div>
      <div
        className="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-1"
      ></div>
    </div>
  );
};

export default FoundationLocalNav;
