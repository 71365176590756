import React from 'react';
import ClientKYCData from './clientKYCData';

const ClientKYC = (props) => {
  const { kycData } = props;

  return (
    <div className="glue-tip">
      <div className="glue-tip__wrap">
        <ul className="glue-tip__tips _3-col-grid">
          {kycData.map((kyc) => (
            <ClientKYCData key={kyc.name} kycData={kyc} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ClientKYC;
