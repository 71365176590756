import React from 'react';
import Joi from 'joi-browser';
import Form from './../common/formElements/form';
import * as userService from '../../services/userService';
import auth from '../../services/authService';
// import CUDA from '../hooks/GetUserData';
import progressHandler from '../../utils/progressHandler';
import {
  Flex,
  Heading,
  IllustratedMessage,
  ProgressCircle,
  Text,
} from '@adobe/react-spectrum';
import { createCar } from '../../services/carService';
import CarImageUploadHandler from './carImageUploadHandler';
import { ToastQueue } from '@react-spectrum/toast';
// import { UploadToCloud } from '@spectrum-icons/workflow';
// import UploadToCloudOutline from '@spectrum-icons/workflow/UploadToCloudOutline';
// import ProgressRender from '../progressRender';

class CarListingForm extends Form {
  state = {
    data: {
      make: '',
      model: '',
      year: '',
      bodyType: '',
      mileage: '',
      transmissionSystem: '',
      condition: '',
      exteriorColour: '',
      wheelDrive: '',
      location: '',
      exteriorColourCode: '',
      cylinder: '',
      engineCapacity: '',
      securityFeature: '',
      description: '',
    },
    errors: {},
    carProgressState: 'init',
    ctaActionState: false,
    filledSrc: '',
  };

  schema = {
    make: Joi.string().required().label('Make'),
    model: Joi.string().required().label('Model'),
    year: Joi.number().required().allow().label('Year'),
    bodyType: Joi.string().required().label('Body Type'),
    mileage: Joi.string().optional().allow('').label('Mileage'),
    transmissionSystem: Joi.string().required().label('Transmission System'),
    exteriorColour: Joi.string().required().label('Exterior Colour'),
    location: Joi.string().required().allow('').label('Location'),
    wheelDrive: Joi.string().required().label('Wheel Drive'),
    condition: Joi.string().required().label('Condition'),
    exteriorColourCode: Joi.string()
      .optional()
      .allow('')
      .label('Exterior Colour Code'),
    cylinder: Joi.string().required().label('Cylinder'),
    engineCapacity: Joi.string().required().allow('').label('Engine Capacity'),
    securityFeature: Joi.string()
      .required()
      .allow('')
      .label('Security Feature'),
    description: Joi.string().required().allow('').label('Car Description'),

    // disableForm: Joi.string()
    //   .required()
    //   .min(5)
    //   .label("DisableForm"),
  };

  doSubmit = async () => {
    // Initialize Lottie
    this.setState({ ctaActionState: true });

    try {
      const { data: response } = (await createCar(this.state.data)).data.data;

      this.setState({ ctaActionState: false });
      ToastQueue.positive('Car listed!', { timeout: 3000 });

      // this.setState({ carProgressState: 'car-photos' });
      setTimeout(() => {
        window.location = `/automobile/listing/${response.id}?action=featuredImage`;
      }, 3000);

      // if (response) {
      // const currentUserLocal = await auth.setUVDALocal();
      // Hide UI loadin, show success
      // progressHandler.hideProgress('signup', 'loading');
      // progressHandler.showProgress('signup', 'success');
      // setTimeout(() => {
      // }, 1500);
    } catch (ex) {
      this.setState({ ctaActionState: false });

      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        ToastQueue.negative(msg, { timeout: 3000 });
      } else {
        //Handle unknown error
        ToastQueue.negative('Unknown error occured!', { timeout: 3000 });
      }

      setTimeout(() => {
        progressHandler.hideProgress('signup', 'error');
      }, 1500);

      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        // this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.carProgressState &&
          this.state.carProgressState === 'init' && (
            <div className="st-form-blk ">
              <form className="" onSubmit={this.handleSubmit}>
                <div className="_4-col-st-grid">
                  {this.renderInput(
                    'make',
                    'Make',
                    'text',
                    'Car Make (Brand)',
                    ''
                  )}

                  {this.renderInput('model', 'Model', 'text', 'Car Model', '')}

                  {this.renderInput('year', 'Year', 'Number', 'Year', '')}

                  {this.renderSelect(
                    'condition',
                    'Condition',
                    [
                      { name: 'New', value: 'New' },
                      { name: 'Used', value: 'used' },
                    ],
                    ''
                  )}
                </div>

                <div className="_4-col-st-grid">
                  {this.renderInput(
                    'mileage',
                    'Mileage',
                    'number',
                    'Mileage',
                    ''
                  )}

                  {this.renderSelect(
                    'transmissionSystem',
                    'Transmission System',
                    [
                      { name: 'Automatic', value: 'automatic' },
                      { name: 'Manual', value: 'manual' },
                    ],
                    ''
                  )}

                  {this.renderSelect(
                    'bodyType',
                    'Body Type',
                    [
                      { name: 'Sedan', value: 'Sedan' },
                      { name: 'SUV', value: 'SUV' },
                      { name: 'Van', value: 'Van' },
                      { name: 'Minivan', value: 'Minivan' },
                      { name: 'Wagon', value: 'Wagon' },
                      { name: 'Hatchback', value: 'Hatchback' },
                      { name: 'Coupe', value: 'Coupe' },
                      { name: 'Convertible', value: 'Convertible' },
                      { name: 'Truck', value: 'Truck' },
                    ],
                    ''
                  )}

                  {this.renderSelect(
                    'wheelDrive',
                    'Wheel Drive',
                    [
                      { name: 'AWD', value: 'AWD' },
                      { name: '4WD', value: '4WD' },
                      { name: '2WD', value: '2WD' },
                    ],
                    'O'
                  )}
                </div>

                <div className="_4-col-st-grid ">
                  {this.renderInput(
                    'cylinder',
                    'Cylinder',
                    'number',
                    'Cylinder i.e 4, 6 or 8',
                    ''
                  )}
                  {this.renderInput(
                    'engineCapacity',
                    'Engine Capacity',
                    'number',
                    'Engine Capacity e.g 2.0L',
                    ' '
                  )}

                  {this.renderInput(
                    'exteriorColour',
                    'Exterior Colour',
                    'text',
                    'Exterior Colour',
                    ''
                  )}

                  {this.renderInput(
                    'exteriorColourCode',
                    'Exterior Colour Code',
                    'color',
                    '', //No placeholder
                    ''
                  )}
                </div>
                <div className="_2-col-st-grid gap--narrow">
                  {this.renderTextArea(
                    'description',
                    'Car Description',
                    'Car description',
                    ''
                  )}

                  <div className="_2-col-st-grid gap--narrow">
                    <div>
                      {this.renderSelect(
                        'securityFeature',
                        'Security Feature',
                        [{ name: 'Bulletproof', value: 'bulletproof' }],
                        ''
                      )}
                    </div>

                    <div>
                      {this.renderInput(
                        'location',
                        'Location',
                        'text',
                        'Location',
                        ''
                      )}
                    </div>
                  </div>
                </div>

                {this.renderButton(
                  'Add car',
                  this.state.ctaActionState ? this.state.ctaActionState : false
                )}
              </form>
            </div>
          )}

        {this.state.carProgressState &&
          this.state.carProgressState === 'creating' && (
            <div>
              <ProgressCircle
                aria-label="Loading…"
                staticColor="black"
                isIndeterminate
              />
            </div>
          )}
      </>
    );
  }
}

export default CarListingForm;
