import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import BusinessIntroSlide from ".//businessIntroSlide";
import AutosHeroSlide from "./heroSlideAutos";
import TheCooperativeHeroSlide from "./heroSlideTheCooperative";
import SWTravelAndTour from "./heroSlideTravelAndTour";
import SWFoundation from "./heroSlideSWFoundation";
import SWRealEstate from "./heroSlideRealEstate";

function HeroSlider(props) {
  // useEffect(() => {
  //   window.Webflow && window.Webflow.destroy();
  //   window.Webflow && window.Webflow.ready();
  //   window.Webflow && window.Webflow.require("ix2").init();
  //   document.dispatchEvent(new Event("readystatechange"));
  // });

  return (
    <section className="section hero-section wf-section">
      <div
        data-delay="10000"
        data-animation="fade"
        className="hero__slider w-slider"
        data-autoplay={true}
        data-easing="ease"
        data-hide-arrows="false"
        data-disable-swipe="false"
        data-autoplay-limit="0"
        data-nav-spacing="3"
        data-duration="1000"
        data-infinite="true"
      >
        <div className="hero-slider__mask w-slider-mask">
          {/* Slides Area */}
          <TheCooperativeHeroSlide />
          <AutosHeroSlide />
          <SWRealEstate />
          <SWTravelAndTour />
          <SWFoundation />
        </div>
        <div className="hero-slider__arrow left w-slider-arrow-left">
          <div className="hero-slider__arrow__icon w-icon-slider-left"></div>
        </div>
        <div className="hero-slider__arrow right w-slider-arrow-right">
          <div className="hero-slider__arrow__icon w-icon-slider-right"></div>
        </div>
        <div className="st-slider-dots w-slider-nav w-round"></div>
      </div>
    </section>
  );
}

export default HeroSlider;
