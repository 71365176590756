import React, { Fragment } from 'react';
import VideoPlayerModal from '../common/videoPlayerModal';
import { closePlayer, openPlayer } from '../../utils/videoPlayerModalControls';
import FoundationLocalNav from './foundationLocalNav';
import PlayerModalPlayBtn from '../common/videoPlayerModalPlayBtn';
import { Link } from 'react-router-dom';

const FoundationPage = (props) => {
  return (
    <div>
      <div className="hero-style-fs wf-section">
        <div
          data-delay="7000"
          data-animation="fade"
          className="hero-style-fs__slider w-slider"
          data-autoplay={true}
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="true"
        >
          <div className="w-slider-mask">
            <div
              data-w-id="87a7765b-008b-9b1c-b48c-7db7b2e2aad1"
              className="hero-style-fs__slider__slide w-slide"
            >
              <div className="hero-style-fs__slider__slide__img-container">
                <img
                  src="/images/content/seaf-foundation/landscape.jpg"
                  loading="lazy"
                  sizes="100vw"
                  // srcSet="images/speaking2x-p-500.png 500w, images/speaking2x-p-800.png 800w, images/speaking2x-p-1080.png 1080w, images/speaking2x-p-1600.png 1600w, images/speaking2x.png 1920w"
                  alt=""
                  className="hero-style-fs__slider__slide-image"
                />
              </div>
              <div className="overlay--dark"></div>
              <div className="hero-style-fs__slider__slide-content">
                <div className="container container--flex hero-style-fs__slider__container">
                  <div className="hero-style-fs__slider__grid">
                    <div
                      // style="opacity:0"
                      className="hero-style-fs__slider__slide-content--main"
                    >
                      <div className="c-rich-text">
                        <h2 className="hero-style-fs__slider__slide-heading">
                          Meet The CEOs 2021
                        </h2>
                        <p className="hero-style-fs__slider__slide-paragraph">
                          Powered by Skyewise Foundation in Partnership with
                          Skyewise Group
                        </p>
                      </div>
                    </div>
                    <div
                      id="w-node-_827fcf9d-9921-95e4-e4a9-26c52e68d59e-224bd367"
                      className="hero-style-fs__slider__slide__aside"
                    >
                      <div
                        className="slide-aside__thumbnail
                       hero-style-fs__slider__slide__aside-text                        "
                      >
                        <PlayerModalPlayBtn
                          props={{
                            handleClick: openPlayer,
                            playerId: 'playerID-0012',
                            playerSkin: 'light',
                          }}
                        />
                      </div>
                      <div
                        // style="opacity:0"
                        className="hero-style-fs__slider__slide__aside-text"
                      >
                        <p className="is-txt__small">
                          Previously - Meet The CEOs
                        </p>
                        <p className="c-h4">'21 Edition</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              data-w-id="87a7765b-008b-9b1c-b48c-7db7b2e2aad1"
              className="hero-style-fs__slider__slide w-slide"
            >
              <div className="hero-style-fs__slider__slide__img-container">
                <img
                  src="images/speaking2x.png"
                  loading="lazy"
                  sizes="100vw"
                  srcSet="images/speaking2x-p-500.png 500w, images/speaking2x-p-800.png 800w, images/speaking2x-p-1080.png 1080w, images/speaking2x-p-1600.png 1600w, images/speaking2x.png 1920w"
                  alt=""
                  className="hero-style-fs__slider__slide-image"
                />
              </div>
              <div className="overlay--dark"></div>
              <div className="hero-style-fs__slider__slide-content">
                <div className="container container--flex hero-style-fs__slider__container">
                  <div className="hero-style-fs__slider__grid">
                    <div
                      // style="opacity:0"
                      className="hero-style-fs__slider__slide-content--main"
                    >
                      <div className="c-rich-text">
                        <h2 className="hero-style-fs__slider__slide-heading">
                          Stand out as an entrepreneur
                        </h2>
                        <p className="hero-style-fs__slider__slide-paragraph">
                          Discuss, learn, and tap into the experiences of great
                          entrepreneurs.
                        </p>
                      </div>
                    </div>
                    <div
                      id="w-node-_827fcf9d-9921-95e4-e4a9-26c52e68d59e-224bd367"
                      className="hero-style-fs__slider__slide__aside"
                    >
                      <div
                        // style="opacity:0"
                        className="hero-style-fs__slider__slide__aside-text"
                      >
                        <p className="is-txt__small">Upcoming Event - Date</p>
                        <p className="c-h4">2022</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="display__none w-slider-arrow-left">
            <div className="w-icon-slider-left"></div>
          </div>
          <div className="display__none w-slider-arrow-right">
            <div className="w-icon-slider-right"></div>
          </div>
          <div className="st-slider-dots w-slider-nav w-round"></div>
        </div>
      </div>

      {/* <FoundationLocalNav /> */}

      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h2>S.E.A.F Foundation</h2>
                <p>
                  Raising young people to be self-reliant by reducing
                  unemployment in Nigeria and providing start-ups access to the
                  credit facility
                </p>
              </div>
              <a
                href="#seaf-foundation-projects"
                target="_blank"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Projects</p>
              </a>
            </div>
          </div>

          <div className="_2-col-st-grid is-txt--centered is-mt-medium ">
            <div className="our-mission">
              <div className="c-rich-text">
                <h4>VISION</h4>
                <p className="">
                  To be the leading foundation in Nigeria, empowering fresh
                  graduates, youths, and young entrepreneurs with other sources
                  of wealth creation
                </p>
              </div>
            </div>
            <div className="our-mission">
              <div className="c-rich-text">
                <h4> MISSION</h4>
                <p className="">
                  Raising young people to be self-reliant by reducing
                  unemployment in Nigeria and providing start-ups access to the
                  credit facility
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-4 wf-section">
        <div className="container">
          <div
            data-w-id="6a9211fd-a56f-6e64-f62f-db76a713afed"
            // style="opacity:0"
            className="_2_1-col-grid--masonry st-b-radius"
          >
            <div
              id="w-node-ced23dbd-0bd8-5d1f-f766-204de8d22310-224bd367"
              className="grid-cc-large grid-cc-large--600"
            >
              <div className="wrrpr">
                <img
                  src="/images/content/seaf-foundation/dr-linus-okorie-keynote-speach-730x800.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 82vw, (max-width: 767px) 56vw, (max-width: 991px) 57vw, (max-width: 1919px) 53vw, 100vw"
                  // srcSet="images/speaking2x-p-500.png 500w, images/speaking2x-p-800.png 800w, images/speaking2x-p-1080.png 1080w, images/speaking2x-p-1600.png 1600w, images/speaking2x.png 1920w"
                  alt=""
                  className="img-100-fit"
                />
                <div className="overlay--dark"></div>
                <div className="div-block-83">
                  <div className="st-r-mb">
                    <PlayerModalPlayBtn
                      props={{
                        handleClick: openPlayer,
                        playerId: 'p:id-mtceos-keynote',
                        playerSkin: 'light',
                      }}
                    />
                  </div>
                  <h3 className="heading-4">
                    MTCEOs '21 - Keynote speach{' '}
                    <span className="glue-quote__author">
                      {' '}
                      - Dr. Linus Okorie
                    </span>
                  </h3>
                  <p>
                    For anyone who wants to be a successful entrepreneur, the
                    only thing that will make you thrive is value…
                  </p>
                  <Link
                    to="/seaf-foundation/the-ceos-summit-2021"
                    className="alt-btn iswhite w-inline-block"
                  >
                    <p>Read more</p>
                    <span className="is-icon"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="grid-cc-min">
              <div className="wrrpr">
                <img
                  src="/images/content/seaf-foundation/dr-elvis-abuyere-730x800.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 82vw, (max-width: 767px) 32vw, (max-width: 991px) 33vw, (max-width: 1919px) 30vw, 100vw"
                  // srcSet="images/pexels-rfstudio-38107552x-p-500.png 500w, images/pexels-rfstudio-38107552x-p-800.png 800w, images/pexels-rfstudio-38107552x-p-1080.png 1080w, images/pexels-rfstudio-38107552x-p-1600.png 1600w, images/pexels-rfstudio-38107552x.png 1920w"
                  alt=""
                  className="img-100-fit"
                />
                <div className="overlay--dark"></div>
                <div className="div-block-83">
                  <h4>
                    If innovation and sustainability are going to be a priority,
                    we need to create a culture and environment that supports
                    re-education and the acquisition of problems solving skills.
                  </h4>
                  {/* <a href="#" className="alt-btn iswhite w-inline-block">
                    <p>Learn more</p>
                    <p className="is-icon"></p>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="grid-cc-min">
              <div className="wrrpr">
                <img
                  src="images/WhatsApp-Image-2021-08-18-at-8.18.06-PM-2.jpeg"
                  loading="lazy"
                  alt=""
                  className="img-100-fit"
                />
                <div className="overlay--dark"></div>
                <div className="div-block-83">
                  <h4>S.E.A.F Impact</h4>
                  <p>
                    How we answer life’s most persistent and urgent question
                  </p>
                  <Link
                    to="/seaf-foundation/community-engagements"
                    className="alt-btn iswhite w-inline-block"
                  >
                    <p>Learn more</p>
                    <p className="is-icon"></p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoPlayerModal
        playerData={{
          playerId: 'playerID-0012',
          videoUrl: 'https://vimeo.com/734981169',
          closePlayer,
        }}
      />

      <VideoPlayerModal
        playerData={{
          playerId: 'p:id-mtceos-keynote',
          videoUrl: 'https://vimeo.com/735380123',
          closePlayer,
        }}
      />
    </div>
  );
};

export default FoundationPage;
