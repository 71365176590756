import React from 'react';

const TextArea = ({ name, label, placeholder, inputIcon, error, ...rest }) => {
  return (
    <div>
      <div className="s-form__input">
        <div className="s-form__input__con">
          <label className="s-form__input-label" htmlFor={name}>
            {label}
          </label>
          <div className="is-icon s-form__icon -mt-[50%]">
            <p>{inputIcon}</p>
          </div>
        </div>
        <textarea
          className="s-form__input__input w-input "
          {...rest}
          name={name}
          id={name}
          placeholder={placeholder}
          rows={5}
          cols={15}
        ></textarea>
      </div>
      {error && (
        <div className="s-form-alert s-form-alert--is-error">{error}</div>
      )}
    </div>
  );
};

export default TextArea;
