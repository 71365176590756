import React from 'react';
import ClientNavItem from './clientNavItem';

const ClientNav = (props) => {
  const { navProps } = props;
  const { updateDashboardLocation } = props;

  return (
    <div className="user-credit-dash__nav">
      <div className="user-credit-dash__nav-cont _100-w">
        <ul
          role="list"
          className="user-credit-dash__nav__nav is-flex w-list-unstyled"
        >
          {navProps.map((prop) => {
            return (
              <ClientNavItem
                key={prop}
                navProp={prop}
                updateDashboard={updateDashboardLocation}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ClientNav;
