import React from 'react';

const QuoteModuleFS = (props) => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="glue-quote glue-quote--fs">
              <div className="glue-quote__quote">
                <figure>
                  <blockquote cite="https://www.huxley.net/bnw/four.html">
                    <p className="glue-quote__quote__text">
                      When it comes to investing, nothing will pay off more than
                      educating yourself. Do the necessary research and analysis
                      before making any investment decisions.
                    </p>
                  </blockquote>
                  <figcaption className="glue-quote__quote__author">
                    —— Benjamin Franklin, <cite>Quote</cite>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        className="glue-quote__quote-image"
        src="/images/content/finance/loan-department.jpg"
      />
    </div>
  );
};

export default QuoteModuleFS;
