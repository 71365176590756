export const openPlayer = (playerId) => {
  //   setModalPlayerState(true);
  //   localStorage.setItem(playerId, true);

  document.getElementById(playerId).classList.add('player--open');

  setTimeout(() => {
    document
      .getElementById(playerId + '__modal-overlay')
      .classList.add('modal-overlay--active');
    setTimeout(() => {
      document
        .getElementById(playerId + '__modal-content')
        .classList.add('modal-content-active');
    }, 300);
  }, 1);
};

export const closePlayer = (playerId, setModalPlayerState) => {
  //   e.stopPropagation();
  setTimeout(() => {
    document
      .getElementById(playerId + '__modal-overlay')
      .classList.remove('modal-overlay--active');
  }, 300);

  document
    .getElementById(playerId + '__modal-content')
    .classList.remove('modal-content-active');

  setTimeout(() => {
    document.getElementById(playerId).classList.remove('player--open');
    //     localStorage.setItem(playerId, null);
  }, 1000);

  // setModalPlayerState(false);
};

export default {
  closePlayer,
  openPlayer,
};
