import React from 'react';

const LocalMenuToggle = (props) => {
  const menuProps = props.onToggleMenu;

  return (
    <button
      className={`user-dash__local-menu-toggle  ${menuProps.menuState}`}
      onClick={() => {
        props.onToggleMenu.updateMenuState(
          menuProps.menuState == 'closed' ? 'opened' : 'closed'
        );
      }}
    >
      <div className={`user-dash__local-menu-toggle__container`}>
        <div
          className={`user-dash__local-menu-toggle__icon  ${menuProps.menuState}`}
        >
          <span className="is-icon">+</span>
        </div>
        <div className="user-dash__local-menu-toggle__menu-label">
          <p className="">Menu</p>
        </div>
      </div>
    </button>
  );
};

export default LocalMenuToggle;
