import React, { useEffect, useMemo, useState } from 'react';
import restartWebflowScript from '../common/restartWebflowScript';
import NavBar from '../common/navbar/navbar';

import Footer from '../common/footer/footer';
import ChatAndNavHelperOpen from '../common/chatAndNavHelperOpen';
import PageExitCTA from '../common/pageExitCTA';
import Trends from '../common/trends/trendsBase';
import Car from './carCardMain';
import FeaturedCars from './carsFeatured';

import VehiclesShoppingCentreHeader from './vehiclesShoppingCentreHeader';
import ShopingCenterNav from './shopingCenterNav';
import { getCar, getCars } from '../../services/carService';
import AutoSideNav from './automobileSideNavLinks';

import carImageCoverIcon from '../../images/user-engagement/car-listing-cover.svg';
import { Image } from '@adobe/react-spectrum';
import { Helmet } from 'react-helmet-async';
import Skeleton from 'react-loading-skeleton';
import { ToastQueue } from '@react-spectrum/toast';

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

const VehiclesShoppingCentre = () => {
  // useEffect(() => {
  //   var htmlEl = document.getElementsByTagName("html")[0];
  //   htmlEl.setAttribute("data-wf-page", "61c2e2d14d436da4d3382185");
  // });

  const [listedCars, setListedCars] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const cachedCarData = useMemo(() => listedCars, [listedCars]);

  const query = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    (async () => {
      try {
        if (!listedCars) {
          const { data: cars } = (
            await getCars({
              // limit: 5,
              // condition: 'New',
              sort: -1,
            })
          ).data.data;

          setListedCars(cars);
          setIsReady(true);
        }
      } catch (e) {
        // console.log(e);
        ToastQueue.negative(
          `${e.message}! We couldn't get the cars for you. Let's give it another shot`,
          {
            actionLabel: 'Reload',
            onAction: () => window.location.reload(),
            shouldCloseOnAction: true,
          }
        );
      }
    })();
  });

  restartWebflowScript();

  return (
    <>
      <Helmet>
        <title>
          Skyewise Autos showroom | Abuja | Lagos | Supercharge your driving
          experience
        </title>
        <meta name="description" content="Explore Skyewise Autos showroom" />
        <link
          rel="canonical"
          href="/about-us/people-and-leadership/management"
        />
      </Helmet>
      <div>
        <NavBar />
        <VehiclesShoppingCentreHeader />
        <div className="car-listings__grid-wrapper section wf-section">
          <div className="container ">
            <div className="car-listings__grid-wrapper">
              <div className="car-listings__cars-nav-grod">
                {/* <div className="car-listings__block-header flex_order--02">
                <div></div>
              </div>
              <div className="car-listings__block-header flex_order--01">
                <h2>All Cars</h2>
              </div> */}
                <div className="sticky_ top-[120px] h-[210px]">
                  <h4>Sort Cars</h4>
                  {/* <ShopingCenterNav /> */}

                  <AutoSideNav
                    data={[
                      {
                        linkTxt: 'All cars',
                        linkIcon: '',
                        linkAddress: '/automobile/cars',
                      },
                      {
                        linkTxt: 'New',
                        linkIcon: '',
                        linkAddress: '/automobile/cars?condition=New',
                      },
                      {
                        linkTxt: 'Used',
                        linkIcon: '',
                        linkAddress: '/automobile/cars?condition=Used',
                      },
                    ]}
                  />
                </div>
                <div className="car-listings__block flex_order--04">
                  {!isReady && (
                    <h2>
                      <Skeleton width={200} />
                    </h2>
                  )}
                  <div className="car-listings__block-header flex_order--01">
                    {isReady && cachedCarData.length > 0 && <h2>All cars </h2>}
                  </div>
                  <div className="_3-col-st-grid">
                    {isReady &&
                      cachedCarData.length > 0 &&
                      cachedCarData.map((car, i) => (
                        <Car carData={car} key={i} />
                      ))}

                    {!isReady &&
                      Array(9)
                        .fill(0)
                        .map(() => (
                          <div className="">
                            <div className="mb-2">
                              <Skeleton height={150} />
                            </div>
                            <Skeleton count={2} />
                            <div className="flex gap-6 mt-4">
                              <Skeleton
                                borderRadius={50}
                                width={90}
                                height={30}
                              />
                              <Skeleton
                                borderRadius={50}
                                width={110}
                                height={30}
                              />
                            </div>
                          </div>
                        ))}

                    {isReady && cachedCarData.length == 0 && (
                      <div className="  text-center c-rich-text">
                        <p>No cars available</p>
                        <img src={carImageCoverIcon} alt="No cars available" />
                        <div></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* GLoable Trends Here */}
        <FeaturedCars />
        <Trends />
        <PageExitCTA />
        <Footer />
      </div>
    </>
  );
};

export default VehiclesShoppingCentre;
