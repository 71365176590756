import React, { useContext } from 'react';

import UserContext from '../../context/userContext';
import { Link } from 'react-router-dom';
import configData from '../../config.json';

const storageUrl = configData.storageUrl;

const AboutUser = ({ ...props }) => {
  const currentUser = useContext(UserContext);

  return (
    <div className="user__profile">
      <div className="user__photo">
        {currentUser && (
          <img
            src={
              currentUser.profilePhoto !== 'default.jpg'
                ? storageUrl + currentUser.profilePhoto
                : '/images/co/gnrl/user.svg'
            }
            loading="lazy"
            alt=""
            className="user__profile__photo__img"
          />
        )}
      </div>
      <div className="user__note">
        <div className="div-block-42 c-rich-text">
          {currentUser && currentUser.isVerified === false && (
            <p className="user__verified">
              <Link
                className="user__verified--info"
                to="/account/kyc-verification"
              >
                <span className="is-icon is--pending"></span>{' '}
                <span className="link--underline  link--italics is-fw-400">
                  Unverified
                  <br />
                </span>
              </Link>
              <span className="is-caption-xm ">Complete your KYC </span>
            </p>
          )}

          {currentUser && currentUser.isVerified === true && (
            <p className="user__verified">
              <span className="is-icon  is-blue sm-rt-margin"></span>
              <span className="is-fw-600">Verified</span>
              <br />
              <span className="is-caption-xm ">The Cooperative</span>
            </p>
          )}

          <div className="hr-line hr-line--dark space-min"></div>

          <h3 className="user__name">
            <span className="thin-text">Hi, </span>
            {currentUser ? currentUser.name.split(' ')[0] : ''}
          </h3>
          <p className="user__welcome-note">
            Welcome to Skyewise Group. <br />
            This is your personal dashboard. Here you will find details about
            all your credit history with Skyewise. Thank you for choosing us
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUser;
