import React, { useEffect } from 'react';
import truncateString from '../../../utils/stringTruncationV2';
import stripHTMLChar from '../../../utils/stringStripHTMLCharacters';
import { useState } from 'react';

const BlogUpdateCard = (props) => {
  const { postData } = props;

  return (
    <div className="sw-blog-update__card">
      <a
        href={postData.link}
        target="_blank"
        className="st-link w-inline-block"
      >
        <div className="sw-blog-update__card__fimg">
          <div className="st-img__wrp">
            <img
              loading="lazy"
              alt={postData.title.rendered}
              className="st__img"
              src={`${postData.featured_media_url}`}
            />

            <div className="st-img__overlay overlay--dark"></div>
          </div>
        </div>
        <div className="sw-blog-update__card__excerpt ">
          <div className="sw-blog-update__card__title--wrap">
            <h4 className="sw-blog-update__card__title">
              {truncateString(postData.title.rendered, 49)}
            </h4>
          </div>
          <p>
            {`${stripHTMLChar(truncateString(postData.excerpt.rendered, 95), [
              '<p>',
              '</p>',
              '<strong>',
              '</strong>',
            ])}`}
          </p>
        </div>
      </a>
      <div className="sw-blog-update__card__metadata">
        <ul>
          <li className="metadata-tag--cat">
            <span>News</span>
          </li>
          <li>{new Date(postData.date).toDateString()}</li>
        </ul>
      </div>
    </div>
  );
};

export default BlogUpdateCard;
