import http from './httpService';
import configData from '../config.json';

const { blogUpdateUrl } = configData;
const postsEndpoint = blogUpdateUrl + '/posts';

function investmentUrl(query) {
  return `${postsEndpoint}/${query}`;
}

export function createInvestment(newInvestment) {
  return http.post(postsEndpoint, {
    investment: newInvestment.investment,
    amount: newInvestment.amount,
  });
}

export function getInvestments() {
  return http.get(postsEndpoint);
}

export function getActiveInvestment(investmentId) {
  return http.get(investmentUrl(investmentId));
}

export function getInvestment(investmentId) {
  return http.get(investmentUrl(investmentId));
}

export function getInvestmentQuery(query) {
  let queryStr = '';

  if (query) {
    Object.keys(query).map((prop, k) => {
      queryStr +=
        k == 0
          ? '?' + prop + '=' + query[prop]
          : '&' + prop + '=' + query[prop];
    });

    return http.get(postsEndpoint + queryStr);
  }
}

export function saveInvestment(investment) {
  if (investment._id) {
    const body = { ...investment };
    delete body._id;
    return http.put(investmentUrl(investment._id), body);
  }

  return http.post(postsEndpoint, investment);
}

export function updateInvestment(investmentId, body) {
  return http.patch(investmentUrl(investmentId), body);
}

export function deleteInvestment(investmentId) {
  return http.delete(investmentUrl(investmentId));
}

export default {
  getActiveInvestment,
  createInvestment,
  getInvestmentQuery,
};
