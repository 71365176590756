import React from 'react';
import Director from './boardOfDirctorsDirector';
import ManagementTeamMember from './managementTeamMember';

const Directors = () => {
  return (
    <div>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="internal-nav w-nav"
      >
        {/* <div className="container container--internal-nav w-container">
          <nav role="navigation" className="w-nav-menu">
            <a
              href="#"
              aria-current="page"
              className="internal-nav--item w-nav-link w--current"
            >
              BOARD OF DIRECTORS
            </a>
            <a href="#" className="internal-nav--item w-nav-link">
              EXECUTIVE OFFICERS
            </a>
            <a href="#" className="internal-nav--item w-nav-link">
              MANAGEMENT COMMITTEE
            </a>
          </nav>
        </div>
         */}
      </div>

      <section className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>Management Team</h3>
              </div>
            </div>
          </div>

          <div className="s-row">
            <div className="_4-col-st-grid st-grid--management">
              <div className="management">
                <ManagementTeamMember
                  manager={{
                    name: 'Dr. Elvis L. Abuyere',
                    photo: 'dr_elvis_abuyere.webp',
                    position: 'MD/CEO',
                    location: 'Management',
                    profileUrl: '#',
                  }}
                />
              </div>

              <div className="management">
                <ManagementTeamMember
                  manager={{
                    name: 'Senkoya Oluwole Babatunde',
                    photo: 'senkoya-oluwole-babatunde.webp',
                    position: 'Chief Operating Officier',
                    location: '(Head Office)',
                    profileUrl: '#',
                  }}
                />
              </div>

              <div className="management">
                <ManagementTeamMember
                  manager={{
                    name: 'Oluwaseun F. Odidi ',
                    photo: 'oluwaseun-f-odidi.webp',
                    position: 'Operations Manager',
                    location: '(Garki Branch)',
                    profileUrl: '#',
                  }}
                />
              </div>

              <div className="maanagement">
                <ManagementTeamMember
                  manager={{
                    name: 'Emmanuel Ameh',
                    photo: 'emmanuel-ameh.jpg',
                    position: 'Branch Service Manager',
                    location: '(Head Office)',
                    profileUrl: '#',
                  }}
                />
              </div>

              <div className="maanagement">
                <ManagementTeamMember
                  manager={{
                    name: 'Ifejola Opara',
                    photo: 'ifejola-opara.webp',
                    position: 'Head, Human Resource Management',
                    location: '',

                    profileUrl: '#',
                  }}
                />
              </div>

              <div className="maanagement">
                <ManagementTeamMember
                  manager={{
                    name: 'Agu-obere Clara',
                    photo: 'agu-clara.webp',
                    position: 'Customer Service Officer',
                    location: '(Head Office)',
                    profileUrl: '#',
                  }}
                />
              </div>

              <div className="maanagement">
                <ManagementTeamMember
                  manager={{
                    name: 'Adeola O. Adegoke',
                    photo: 'adeola-adegoke.webp',
                    position: 'Customer Care Representative',
                    location: '(Garki Branch)',
                    profileUrl: '#',
                  }}
                />
              </div>

              <div className="maanagement">
                <ManagementTeamMember
                  manager={{
                    name: 'Pelumi Olajengbesi ',
                    photo: 'pelumi-olajengbesi.webp',
                    position: 'Skyewise Legal Representative',
                    location: '',
                    profileUrl: '#',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Directors;
