import React, { useLayoutEffect } from "react";

const restartWebflowScriptClassComponents = () => {
  window.Webflow && window.Webflow.destroy();
  window.Webflow && window.Webflow.ready();
  window.Webflow && window.Webflow.require("ix2").init();
  window.Webflow && window.Webflow.redraw.up();
  document.dispatchEvent(new Event("readystatechange"));

  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export default restartWebflowScriptClassComponents;
