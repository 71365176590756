import React, { useEffect, useLayoutEffect } from 'react';
import { render } from 'react-dom';
import Navbar from '../common/navbar/navbar';
import HeroSlider from './heroSlider';
import DiscoverSkyewise from './discoverSkyewise';
import SkyewiseBrief from './skyewiseBrief';
import TheCooperatives from './theCooperatives';
import restartWebflowScript from '../common/restartWebflowScript';
import ChatAndNavHelperBtn from '../common/chatAndNavHelperOpen';
import SiteDirection from './siteDirectionSection';
import BlogExcerptSection from '../common/blogExcerptSection';
import TrendBase from '../common/trends/trendsBase';
import PageExitCTA from '../common/pageExitCTA';
import Footer from '../common/footer/footer';
// import Testimonials from '../common/tesnimonialSlider';
import HomepageSiteDirection from './siteDirectionSectionRow';
import { Helmet } from 'react-helmet-async';
import Testimonials from '../common/testimonialSlider/tesnimonialSlider';
import PlayerModalPlayBtn from '../common/videoPlayerModalPlayBtn';
import { openPlayer, closePlayer } from '../../utils/videoPlayerModalControls';
import VideoPlayerModal from '../common/videoPlayerModal';
import BlogUpdate from '../common/blogUpdate/blogUpdateBase';
import HomeCarPreview from './carPreviewBase';

function Home(user) {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.setAttribute('data-wf-page', '61e539b9a949f460034bd35f');

    // Check for blog post state

    // const checkBlogUpdateLocalState = (storeRef, checkIntervalMS) => {
    //   setTimeout(() => {
    //     if (JSON.parse(localStorage.getItem(storeRef)) == null) {
    //       setTimeout(() => {
    //         console.log(JSON.parse(localStorage.getItem(storeRef)));
    //         checkBlogUpdateLocalState(storeRef);
    //       }, checkIntervalMS + 1000);
    //     } else {
    //       console.log('Set', JSON.parse(localStorage.getItem(storeRef)));
    //       const blogUpdateLocal = JSON.parse(localStorage.getItem(storeRef));
    //       return blogUpdateLocal;
    //     }
    //   }, checkIntervalMS);
    // };

    // console.log('State', checkBlogUpdateLocalState('recentBlogPosts', 1500));

    // setTimeout(() => {
    //   if (JSON.parse(localStorage.getItem('recentBlogPosts')) == null) {
    //     setTimeout(() => {
    //       console.log(JSON.parse(localStorage.getItem('recentBlogPosts')));

    //       if (
    //         console.log(JSON.parse(localStorage.getItem('recentBlogPosts'))) ==
    //         null
    //       ) {
    //         setTimeout(() => {
    //           console.log(JSON.parse(localStorage.getItem('recentBlogPosts')));
    //         }, 3000);
    //       }
    //     }, 2000);
    //   }
    // }, 1000);
  });
  restartWebflowScript();

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Skyewisegroup.com - Building sustainable investment platform -
          Skyewise Group
        </title>
        <meta
          name="description"
          content="Skyewise Group is a diversified integrated conglomerate providing world-class investment opportunities and services for individuals and corporate citizens of all nations."
        />

        <link rel="canonical" href="/" />
      </Helmet>

      <Navbar user={user} />
      {/* <ChatAndNavHelperBtn /> */}
      <HeroSlider />
      <DiscoverSkyewise />
      {/* <SkyewiseBrief /> */}
      <TheCooperatives />
      {/* <SiteDirection /> */}
      <HomepageSiteDirection />

      {/* <BlogExcerptSection /> */}
      <HomeCarPreview />
      <TrendBase />
      <BlogUpdate />
      <Testimonials />
      <PageExitCTA />
      <VideoPlayerModal
        playerData={{
          playerId: 'playerID-0012',
          videoUrl: 'https://youtu.be/pcLgNBjkroU',
          closePlayer,
        }}
      />
      <Footer />
    </React.Fragment>
  );
}

export default Home;
