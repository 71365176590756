import React, { useEffect } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import NavBar from "../common/navbar/navbar";

import Footer from "../common/footer/footer";
import ChatAndNavHelperOpen from "../common/chatAndNavHelperOpen";
import PageExitCTA from "../common/pageExitCTA";

import FoundationPage from "./foundationPage";
import ProjectsParallaxSlide from "./foundationProjectsSlider";

const Foundation = () => {
  useEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f4a3224bd367");
  });

  restartWebflowScript();

  return (
    <div>
      <NavBar />
      {/* <ChatAndNavHelperOpen /> */}

      <FoundationPage />

      <ProjectsParallaxSlide />
      <PageExitCTA />
      <Footer />
    </div>
  );
};

export default Foundation;
