import React, { Component } from "react";
// import Input from "./input";
// import Select from "./select";
import ProgressSuccess from "./progressSuccess";
import ProgressLoading from "./progressLoading";
import ProgressError from "./progressError";

class Progress extends Component {
  state = {
    data: {},
    errors: {},
  };

  handleChange = ({ currentTarget: input }) => {};

  renderLoading(name, message, size, transparency) {
    return (
      <ProgressLoading
        name={name}
        message={message}
        size={size}
        transparency={transparency}
      />
    );
  }

  renderSuccess(name, message, size, transparency) {
    return (
      <ProgressSuccess
        name={name}
        message={message}
        size={size}
        transparency={transparency}
      />
    );
  }

  renderError(name, message, size, transparency) {
    return (
      <ProgressError
        name={name}
        message={message}
        size={size}
        transparency={transparency}
      />
    );
  }
}

export default Progress;
