import React, { Fragment, useLayoutEffect } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import Navbar from "../common/navbar/navbar";
import Footer from "../common/footer/footer";

import ProductsAndServicesHeader from "./productsAndServicesHeader";
import WhatWeDoOverviewIntro from "./whatWeDoIntro";
import WhatWeDoProductsAndServices from "./whatWeDoProductsAndServices";
import WWDIT from "./whatWeDoIntoText";

const ProductsAndServices = (props) => {
  useLayoutEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f460034bd35f");
  });
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />
      <ProductsAndServicesHeader />
      <WWDIT />
      <WhatWeDoProductsAndServices />
      <Footer />
    </Fragment>
  );
};

export default ProductsAndServices;
