import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css";

import App from './App';
import registerServiceWorker from './registerServiceWorker';
// import ServerError from './components/tmp/serverError';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <App />
      {/* <ServerError /> */}
    </HelmetProvider>
  </BrowserRouter>
);

registerServiceWorker();
