import { useState, useEffect, useRef, useMemo } from 'react';
import HrDevider from '../common/hrDevider';
import SubsectionTitleWIthFlip from '../common/UiLayoutsAndComponents/subsectionTItleWithFlip';
import { useParams } from 'react-router-dom';
import { getCars } from '../../services/carService';
import Skeleton from 'react-loading-skeleton';
import Car from '../automobile/carCardMain';
import Link from '@spectrum-icons/workflow/Link';
import { Button } from '@adobe/react-spectrum';

const carListingsConfig = {
  maxCars: 4,
};

const HomeCarPreview = (props) => {
  // Innitialize post counter limit

  const [listedCars, setListedCars] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const cachedCarData = useMemo(() => listedCars, [listedCars]);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    (async () => {
      try {
        if (!listedCars) {
          const { data: cars } = (
            await getCars({
              sort: 1,
              // sort_field: 'createdAt',
              limit: carListingsConfig.maxCars,
              // condition: 'New',
            })
          ).data.data;

          setListedCars(cars);
          setIsReady(true);
        }
      } catch (e) {
        // console.log(e);
        ToastQueue.negative(
          `${e.message}! We couldn't get the cars for you. Let's give it another shot`,
          {
            actionLabel: 'Reload',
            onAction: () => window.location.reload(),
            shouldCloseOnAction: true,
          }
        );
      }
    })();
  });

  return (
    <div className="section wf-section">
      <div className="container">
        <SubsectionTitleWIthFlip
          props={{
            title: 'Cars',
            text: 'Recent car listings from Skyewise Autos',
            link: '/automobile/cars',
            linkText: 'See more cars',
          }}
        />
        <div className="sw-blog-update _4-col-st-grid">
          {isReady &&
            cachedCarData.length > 0 &&
            cachedCarData.map((car, i) => <Car carData={car} key={i} />)}

          {!isReady &&
            Array(carListingsConfig.maxCars)
              .fill(0)
              .map(() => (
                <div className="">
                  <div className="mb-2">
                    <Skeleton height={150} />
                  </div>
                  <Skeleton count={2} />
                  <div className="flex gap-6 mt-4">
                    <Skeleton borderRadius={50} width={90} height={30} />
                    <Skeleton borderRadius={50} width={110} height={30} />
                  </div>
                </div>
              ))}

          {isReady && cachedCarData.length == 0 && (
            <div className="  text-center c-rich-text">
              <p>No cars available</p>
              <img src={carImageCoverIcon} alt="No cars available" />
              <div></div>
            </div>
          )}
          {/* </div> */}
        </div>

        <div className="mt-6 opacity-40">
          <HrDevider />
        </div>

        <div className="mt-4">
          <div>
            <p class="">
              Haven't seen your choice?{' '}
              <a
                class="st-link text-hover-highlight text-hover-highlight--blue is-blue st-link--bold"
                href="/cars"
              >
                See all cars
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCarPreview;
