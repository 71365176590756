import React, { Fragment, Component } from "react";
import AppointmentScheduleForm from "./appointmentScheduleForm";

const AppointmentScheduleFormSection = (props) => {
  return (
    <Fragment>
      <section className="section">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h3>Schedule a Appointment</h3>
                  <p>Please provide your details</p>
                </div>
                <a
                  href="tel:+2348094653000"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p className="is-icon"></p>
                  <p> &nbsp; Give us a call</p>
                </a>

                <AppointmentScheduleForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-bg--gray-02">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h3>Find Location</h3>
                  <p>Find our location closest to you on Google Map</p>
                </div>
                <a
                  href="https://goo.gl/maps/XysMzvHLiBPcTEPD6"
                  target="_blank"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>View On Map</p>
                  <p className="is-icon"></p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AppointmentScheduleFormSection;
