import React from "react";

const ContactHeader = () => {
  return (
    <header className="header-static header-static--contact wf-section">
      <div className="overlay--dark"></div>
      <div className="container">
        <div className="header-statoc--flex">
          <div className="header-static__container">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ContactHeader;
