import React from "react";
import { Link } from "react-router-dom";

const SustainabilityPractices = () => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>What We Do</h2>
              <p>
                Our purpose is to advance sustainable economic growth and
                financial opportunity for our community. <br />
              </p>
            </div>
            <Link
              to="/what-we-do/products-and-services"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>All our products anad services</p>
              <p className="is-icon"></p>
            </Link>
          </div>

          <div className="_1-col-c-center__c-narrow st-r-mg">
            <div className="c-rich-text">
              <h2>Products and Services</h2>
              <p>
                Over the years of business, we have evolved around the people we
                serve. Across our divisions, platforms, and services, our
                clients and customers have access to reliable products and
                services.
              </p>
            </div>
            <a href="#" className="alt-btn btn-link--is-blue w-inline-block">
              <p>Investment</p>
            </a>
            <a href="#" className="alt-btn btn-link--is-blue w-inline-block">
              <p>Loans</p>
            </a>
            <a href="#" className="alt-btn btn-link--is-blue w-inline-block">
              <p>Automobile</p>
            </a>
            <a href="#" className="alt-btn btn-link--is-blue w-inline-block">
              <p>Travel and Tours</p>
            </a>
            <a href="#" className="alt-btn btn-link--is-blue w-inline-block">
              <p> Real Estate</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainabilityPractices;
