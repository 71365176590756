import React, { Component } from 'react';

import progressHandler from '../../../utils/progressHandler';
import uploadPlaceholder from '../../../images/co/prcses/l/user-square.svg';
import loanService from '../../../services/loanService';
import { initProgressLotties } from '../../../utils/initProgressLottie';
import Form from '../../common/formElements/form';
import {
  updateMe,
  updateProfilePhoto,
  updateKYC,
} from '../../../services/userService';
import auth from '../../../services/authService';
import ProgressRender from '../../progressRender';
import validateFileExt from '../../../utils/validateFileExt';
import errorFactory from '../../../utils/errorFactory';

class userProfilePhoto extends Form {
  state = {
    selectedFile: null,
    user: null,
  };

  componentDidMount() {
    const currentUser = auth.getUVDALocal()._id;
    this.setState({ loan: currentUser });
  }

  fileSelecteSingledHandler = (event) => {
    const uploadFIle = event.target.files[0];

    try {
      // Check for valid file upload
      if (
        !validateFileExt(uploadFIle, [
          'image/jpeg',
          'image/png',
          'image/gif',
          'image/webp',
          'image/heic',
        ])
      ) {
        // Throw upload error
        const uploadError = new errorFactory(
          'Invalid Upload',
          'Not a valid image format, please upload a valid image formt  i.e: jpg, png, gif, webp, heic'
        );
        // var e = new errorFactory("NotImplementedError message");
        throw uploadError;
        // alert('The file selected is not an image. Please upload a valid image format i.e: jpg, png, gif, webp, heic');
      }
    } catch (e) {
      // Handle file upload error
      progressHandler.showProgress('p-photo', 'error', e.message);

      setTimeout(() => {
        progressHandler.hideProgress('p-photo', 'error');
      }, 3500);

      return;
    }

    const imagePreview = window.URL.createObjectURL(uploadFIle);

    event.target.parentNode.childNodes[1].children[0].src = imagePreview;

    this.setState({
      selectedFile: event.target.files,
    });
  };

  fileUploadHandler = async (field) => {
    // Initialize Lottie
    initProgressLotties();

    // Show UI Loading
    progressHandler.showProgress('p-photo', 'loading');

    try {
      // Create form Data Object
      const fd = new FormData();

      this.setState({ field: fd });

      // Single File Select
      // fd.append(field, this.state.selectedFile, this.state.selectedFile.name);

      // Multi files select
      Object.keys(this.state.selectedFile).map((key) => {
        fd.append(
          field,
          this.state.selectedFile[key],
          this.state.selectedFile.name
        );
      });

      await updateProfilePhoto(fd);

      await updateKYC({ verificationState: 'kyc-id' });

      await auth.setUVDALocal();

      // Hide UI loadin, show success
      progressHandler.hideProgress('p-photo', 'loading');
      progressHandler.showProgress('p-photo', 'success');

      setTimeout(() => {
        window.location.reload(false);
      }, 1500);
    } catch (ex) {
      if (ex.response) {
        let msg = '';

        if (typeof ex.response.data === 'string') {
          msg += msg + ex.response.data;
        }

        if (typeof ex.response.data === 'object') {
          msg += msg + ex.response.data.message;
        }

        console.error('error');
        // hide UI Loading, show Error
        progressHandler.hideProgress('p-photo', 'loading');
        progressHandler.showProgress('p-photo', 'error', msg);
      } else {
        progressHandler.hideProgress('p-photo', 'loading');
        progressHandler.showProgress(
          'loan',
          'error',
          'An error occurred, please try again later. This is not from you.'
        );
      }

      setTimeout(() => {
        progressHandler.hideProgress('p-photo', 'error');
      }, 3500);
    }
  };

  render() {
    return (
      <div className="c-file-input__container">
        <div className="c-file-input" onClick={() => this.fileInput.click()}>
          <input
            className="c-file-input__input-default"
            type="file"
            onChange={this.fileSelecteSingledHandler}
            ref={(fileInput) => (this.fileInput = fileInput)}
            single
          />

          <div className="c-file-input__input-preview input-preview--square">
            <img
              id="upload-preview"
              src={uploadPlaceholder}
              className="c-file-input__input-preview__img"
            />

            <ProgressRender
              elData={{
                loading: {
                  name: 'p-photo-progress__loading',
                  size: 'small',
                  // transparency: true,
                },
                success: {
                  name: 'p-photo-progress__success',
                  size: 'small',
                  transparency: true,
                },
                error: {
                  name: 'p-photo-progress__error',
                  size: 'small',
                  transparency: true,
                },
              }}
            />

            <div className="file-upload">
              <div className="file-upload--container">
                <div className="file-upload--file">
                  <span className="is-icon"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-file-input__cta input-cta--center ">
          <button
            onClick={() => {
              this.fileUploadHandler('photo');
            }}
            className="s-btn s-btn--blue w-button "
            disabled={this.state.selectedFile ? false : true}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default userProfilePhoto;
