import React, { useEffect } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import NavBar from "../common/navbar/navbar";
import TheCooperativeHeader from "./theCooperativeHeader";
import TheCooperativeLocalNav from "./theCooperativeLocalNav";
import TheCooperativeMain from "./theCooperativeMain";
import TheCooperativeIntroText from "./theCooperativeIntroText";
import TheCooperativeHighlight from "./theCooperativeHighlight";

import Footer from "../common/footer/footer";
import ChatAndNavHelperOpen from "../common/chatAndNavHelperOpen";
import PageExitCTA from "../common/pageExitCTA";
import TheCooperativeBenefits from "./theCooperativeBenefits";
import TheCooperative from "./theCooperativeUserDirection";
import CooperativeProducts from "./theCooperativeProducts";
import CooperativeTCJ from "./theCooperativeJoin";
import TCBA from "./theCooperativeBenefitsAlt";
import TheCooperativeMissionVision from "./theCoopeartiveMissionVision";
import QuoteModuleFS from "../common/UIModules/quoteModuleFS";

const TheCooperativeBase = () => {
  useEffect(() => {
    var htmlEl = document.getElementsByTagName("html")[0];
    htmlEl.setAttribute("data-wf-page", "61e539b9a949f4a1ef4bd375");
  });
  restartWebflowScript();

  return (
    <div>
      <NavBar />
      {/* <ChatAndNavHelperOpen /> */}
      <TheCooperativeHeader />
      <TheCooperativeLocalNav />
      <TheCooperativeIntroText />
      {/* <TheCooperativeHighlight /> */}

      <CooperativeProducts />
      {/* <TheCooperativeBenefits /> */}
      <TCBA />

      {/* <TheCooperative /> */}
      {/* <TheCooperativeMain /> */}

      <QuoteModuleFS />
      <CooperativeTCJ />
      <TheCooperativeMissionVision />

      <PageExitCTA />
      <Footer />
    </div>
  );
};

export default TheCooperativeBase;
