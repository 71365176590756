import React from "react";
import { Link } from "react-router-dom";

const OverviewBodyContent = () => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h3>Skyewise Group</h3>
              <p className="p-medium">
                The Skyewise Group is a leading global financial institution
                that delivers a broad range of financial services across
                investment, Credit Management, Real Estate, Automobiles,
                Logistics, Oil and Gas, Packaging, Contracts financing, and
                partnerships to a large and diversified client base that
                includes corporations, financial institutions, governments, and
                individuals.
              </p>
              <p className="p-medium">
                Founded in 2015, the company is headquartered in Abuja and
                maintains offices in major cities in Nigeria
              </p>
            </div>
          </div>

          {/* <div className="_1-col-c-center__c-narrow ">
            <div className="c-rich-text">
              <h3>Our Purpose and Values</h3>
              <p className="p-small">
                Skyewise Groups’ mission is to advance sustainable economic
                growth and financial opportunity across the globe. Working with
                leading businesses, entrepreneurs, and institutions, we mobilize
                our people and resources to advance the success of our clients,
                broaden individual prosperity and accelerate economic progreßss
                for all.
              </p>
            </div>
            <Link
              to="/about-us/purpose-and-values"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Learn More</p>
            </Link>
          </div> */}

          <div className="_1-col-c-center__c-narrow st-r-mg">
            <div className="c-rich-text">
              <div className="div-block-95 st-b-radius">
                <div
                  data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66b2"
                  className="st-img__wrp"
                >
                  <img
                    src="/images/content/ntve/community-1266x768.jpeg"
                    loading="lazy"
                    // sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                    // srcSet="/images/06-p-500.jpeg 500w, images/06-p-1080.jpeg 1080w, /images/06-p-1600.jpeg 1600w, /images/06.jpg 1920w"
                    alt=""
                    className="st__img"
                  />
                  <div className="st-img__overlay overlay--dark"></div>
                </div>
              </div>
              <h3 style={{ marginTop: 2 + "em" }}>People and Leadership</h3>
              <p className="p-small">
                Our people are our greatest asset – we say it often and with
                good reason. It is only with the determination and dedication of
                our people that we can serve our clients, generate long-term
                value for our shareholders and contribute to the broader public.
              </p>
            </div>
            <Link
              to="/about-us/our-people"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Our People</p>
            </Link>
            <Link
              to="/about-us/people-and-leadership"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Leadership</p>
            </Link>{" "}
          </div>

          <div className="_1-col-c-center__c-narrow st-r-mg">
            <div className="c-rich-text">
              <h3>What We Do</h3>
              <p className="p-small">
                Skyewise Group now owns and manages over 5 subsidiaries and
                affiliates, operating in three major states in Nigeria and one
                African country and agent office in US and Canada. Skyewise
                Global Investment Services, a fully owned subsidiary in charge
                of all our investment products is listed on the Nigerian Stock
                Exchange as a full corporate investment advisor in the Exchange
                in view.
              </p>
            </div>
            <Link
              to="/the-cooperative"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Skyewise Cooperative</p>
            </Link>
            <Link
              to="/automobile"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Skyewise Automobile</p>
            </Link>
            <Link
              to="/travel-and-tourism"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Skyewise Travels and Tours</p>
            </Link>
            <Link
              to="/real-estate"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Skyewise Real Estate</p>
            </Link>
            <Link
              to="/seaf-foundation"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Skyewise Foundation</p>
            </Link>
          </div>

          <div className="_1-col-c-center__c-narrow st-r-mg">
            <div className="c-rich-text">
              <h3>Partnerships</h3>
              <p className="p-small">Excerpt on partnerships.</p>
            </div>
            <a
              href="#"
              target="_blank"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Partners</p>
            </a>
          </div>

          <div className="_1-col-c-center__c-narrow st-r-mg">
            <div className="c-rich-text">
              <h3>Locations</h3>
            </div>
            <Link
              to="/contact/locations"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Find our offices </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewBodyContent;
