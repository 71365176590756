import React from 'react';
import { openPlayer } from '../../../utils/videoPlayerModalControls';
import PlayerModalPlayBtn from '../../common/videoPlayerModalPlayBtn';

const MTCEOsWatchStream = (props) => {
  return (
    <div className="_1-col-c-center wf-section is-bg--gray-03">
      <div className="container">
        <div className="keynote-play">
          <div className="keynote-play__img">
            <img
              className="glue-quote__quote-image"
              src="/images/content/seaf-foundation/dr-linus-okorie-keynote-speach-2552x913.jpg"
            />
          </div>
          <div className="keynote-play__play">
            <PlayerModalPlayBtn
              props={{
                handleClick: openPlayer,
                playerId: 'p:id-mtceos-keynote',
                playerSkin: 'light',
              }}
            />
          </div>
        </div>

        <div className="keynote-play__on-demand">
          <div className="">
            <h3>Watch the entire live stream</h3>
            <p>Re-watch your favorite sessions of the event on-demand</p>
          </div>

          <div className=" keynote-play__on-demand__btn">
            <a
              data-w-id="3c147205-aa54-b63f-fe34-d3ad94d3672f"
              target="_blank"
              className="s-btn  w-inline-block"
              href="https://youtu.be/iWz_d8AQi_E"
            >
              <p>Watch now</p>
              <img
                className="on-demand__btn__icon"
                alt="YouTube logo"
                src="/images/icons/youtube-logo-2431.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MTCEOsWatchStream;
