import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
  return (
    <footer id="footer" className="footer wf-section">
      <div className="container">
        <div className="footer__flx">
          <div className="footer__flx__blk">
            <h5 className="footer__heading">Quick Links</h5>
            <ul role="list" className="footer__items w-list-unstyled">
              <li className="footer__items__item">
                <a href="/automobile" className="footer__item__link">
                  Automobiles
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/services/investment" className="footer__item__link">
                  Investment
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/services/loan" className="footer__item__link">
                  Loan
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/real-estate" className="footer__item__link">
                  Real Estate
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/travel-and-tourism" className="footer__item__link">
                  Travel & Tour
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/seaf-foundation" className="footer__item__link">
                  Skyewise Foundation
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__flx__blk">
            <h5 className="footer__heading">Customer Services</h5>
            <ul role="list" className="footer__items w-list-unstyled">
              <li className="footer__items__item">
                <a
                  href="/what-we-do/products-and-services"
                  className="footer__item__link"
                >
                  Services
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/contact" className="footer__item__link">
                  Contact
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/user-dashboard" className="footer__item__link">
                  Account
                </a>
              </li>
              <li className="footer__items__item">
                <a href="/about-us/faq" className="footer__item__link">
                  FAQ
                </a>
              </li>
              <li className="footer__items__item">
                <a href="#" className="footer__item__link">
                  Cooperative Membership
                </a>
              </li>
              <li className="footer__items__item">
                <a href="#" className="footer__item__link">
                  Skyewise Group
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__flx__blk">
            <h2 className="footer__heading">Privacy Policy</h2>
            <ul role="list" className="w-list-unstyled">
              <li className="footer__items__item">
                <a href="#" className="footer__item__link">
                  Cookie Policy
                </a>
              </li>
              <li className="footer__items__item">
                <a href="#" className="footer__item__link">
                  Legal notice
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__flx__blk">
            <ul role="list" className="footer__items w-list-unstyled">
              <li className="footer__items__item">
                <div className="social__links">
                  <a
                    href="https://www.instagram.com/skyewisegroup/"
                    target="_blank"
                    className="social-links__footer w-inline-block"
                  >
                    <p className="is-icon--brand"></p>
                  </a>
                  <a
                    href="https://twitter.com/skyewisegroup"
                    target="_blank"
                    className="social-links__footer w-inline-block"
                  >
                    <p className="is-icon--brand"></p>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/skyewisegroup/"
                    target="_blank"
                    className="social-links__footer w-inline-block"
                  >
                    <p className="is-icon--brand"></p>
                  </a>
                  <a
                    href="https://web.facebook.com/people/Skyewise-Group/100063811904842/?_rdc=1&_rdr"
                    target="_blank"
                    className="social-links__footer no-mr w-inline-block"
                  >
                    <p className="is-icon--brand"></p>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__disclaimer__blk">
          <div className="site__copy">
            © Skyewise Group {new Date().getFullYear()}
          </div>
          <div className="hr-line hr-line--white"></div>
          <div className="footer__disclaimer c-rich-text">
            <p>
              Our Platform uses cookies. Cookies are text files containing
              information to identify repeat visitors solely for the duration of
              their visit to our internet sites. Cookies are saved on the hard
              disk or any other permanent memory of your terminal device. The
              cookies on our web pages do not contain any personal data
              whatsoever about you.
              <br />
              <br />
              Cookies save you from having to enter data multiple times, make it
              easier for you to transmit certain content and help us identify
              especially popular areas of our online offering. This lets us
              tailor the content of our Platform more precisely to your needs.
              If you wish, you can disable the cookies at any time via your
              browser settings. Please use your browser’s help function to find
              out how to change these settings.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
