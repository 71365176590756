import React from 'react';

const ClientNavItem = (props) => {
  const { navProp } = props;
  const { updateDashboard } = props;

  return (
    <li
      onClick={(e) => {
        if (navProp.location) {
          e.preventDefault();
          updateDashboard(navProp.location);
        }
      }}
      className=" user-credit-dash__nav__item hover--scale credit-dash__nav--current "
    >
      <a href={navProp.navLink} className="car-sort__nav__link w-inline-block">
        <div className="is-icon side-nav__nav__link__icon">
          <p>{navProp.navIcon}</p>
          {console.log(navProp.navLabel)}
        </div>
        <div>
          <p>{navProp.navLinkTxt}</p>
        </div>
      </a>
    </li>
  );
};

export default ClientNavItem;
