import React from "react";
import UserLoansRecords from "../creditSummary/userAllLoansRecords";

const AllLoansRecords = (props) => {
  return (
    <main className="credit-dashbord">
      <div className="credit-dashbord__heading">
        <h3 className="heading-8">All Loan Records</h3>
      </div>
      <div className="credit-dashbord__details">
        <UserLoansRecords />

        <button
          className="alt-btn btn-link--is-blue w-inline-block"
          onClick={() => {
            props.onDashbordChange("credit-summary");
          }}
        >
          <p>Back</p>
        </button>
      </div>
    </main>
  );
};

export default AllLoansRecords;
