import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import DashboardLocalFooter from '../../userDashboardLocalFooter';

const PendingLoan = (props) => {
  return (
    <div>
      <div className=" is-2_5em--white-wrap is-1_27-grid  is-txt--centered">
        <div>
          <div className="">
            <img
              src="/images/user-guide/confirmation-pending-loan_1366x575.png"
              alt="Investment pending confirmation"
            ></img>
          </div>
          <div className="c-rich-text">
            <h3>Pending Approval</h3>
          </div>
        </div>
        <div className="is-flex flex-c">
          <div className="c-rich-text is-flex__80-jc-c">
            <p>
              We are reviewing your
              <span className="credit-sum__state pending--solid glue-c-capcel">
                pending
              </span>
              loan request. If the requirements are met, we will reach out to
              you before your loan is
              <span className="credit-sum__state disbursed--solid glue-c-capcel">
                Disbursed
              </span>
            </p>

            <p>
              This process is usually completed within 24 hours. Feel free to
              reach out to us if you have any concerns.
            </p>

            <p>Thank you for choosing Skyewise</p>
          </div>
        </div>
      </div>
      <DashboardLocalFooter />
    </div>
  );
};

export default PendingLoan;
