const Brands = [
  {
    name: "Audi",
    logo: "audi-logo-mini.png",
  },
  {
    name: "BMW",
    logo: "bmw-logo-mini.png",
  },
  {
    name: "Buick",
    logo: "buick-logo-mini.png",
  },
  {
    name: "Cadillac",
    logo: "cadillac-logo-mini.png",
  },
  {
    name: "Audi",
    logo: "chevrolet-logo-mini.png",
  },
  {
    name: "Chrysler",
    logo: "chrysler-logo-mini.png",
  },
  {
    name: "Dodge",
    logo: "dodge-logo-mini.png",
  },
  {
    name: "Ford",
    logo: "ford-logo-mini.png",
  },
  {
    name: "GMC",
    logo: "gmc-logo-mini.png",
  },
  {
    name: "Honda",
    logo: "honda-logo-mini.png",
  },
  {
    name: "Hyundai",
    logo: "hyundai-logo-mini.png",
  },
  {
    name: "Infiniti",
    logo: "infiniti-logo-mini.png",
  },
  {
    name: "Jaguar",
    logo: "jaguar-logo-mini.png",
  },
  {
    name: "Jeep",
    logo: "jeep-logo-mini.png",
  },
  {
    name: "Audi",
    logo: "kia-logo-mini.png",
  },
  {
    name: "Audi",
    logo: "land-rover-logo-mini.png",
  },
  {
    name: "Lincoln",
    logo: "lincoln-logo-mini.png",
  },
  {
    name: "Mazda",
    logo: "mazda-logo-mini.png",
  },
  {
    name: "Mercedes-Benz",
    logo: "mercedes-benz-logo-mini.png",
  },
  {
    name: "Mini",
    logo: "mini-logo-mini.png",
  },
  {
    name: "Mitsubishi",
    logo: "mitsubishi-logo-mini.png",
  },
  {
    name: "Nissan",
    logo: "nissan-logo-mini.png",
  },
  {
    name: "Porsche",
    logo: "porsche-logo-mini.png",
  },
  {
    name: "Subaru",
    logo: "subaru-logo-mini.png",
  },
  {
    name: "Toyota",
    logo: "toyota-logo-mini.png",
  },
  {
    name: "Volkswagen",
    logo: "volkswagen-logo-mini.png",
  },
  {
    name: "Volvo",
    logo: "volvo-logo-mini.png",
  },
];

function getBrands() {
  return Brands;
}
export default getBrands;
