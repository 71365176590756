import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AutoButtonGridCta from '../automobile/autoButtonGridCta';
import SiteDirectionSectionRowCta from './siteDirectionSectionRowCta';

function homepageSiteDirection(props) {
  return (
    // container--no-space-top
    <div className="_1-col-c-center gray-bg-01 wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>Our Services</h2>
              <p>
                We put an unwavering focus on long-term sustainability and
                ensure it's embedded across our entire business.
              </p>
            </div>

            <SiteDirectionSectionRowCta />

            <div className="_1-col-c-center__wrp is-mt-medium">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h2>Explore Skyewise</h2>
                  <p>Discover investment opportunities with Skyewise Group</p>
                </div>
                <Link
                  data-w-id="d107a84e-3cc9-4aa5-15ee-9fef2c6e78cb"
                  to="/what-we-do/products-and-services"
                  className="s-btn is-btn--black w-inline-block"
                >
                  <p>All products and services</p>
                  <p className="is-icon"></p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default homepageSiteDirection;
