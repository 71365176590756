import React from 'react';
import CarFeatured from './carCardFeatured';
import CarFeaturedCardSkeleton from './carCardFeaturedSkeleton';

const FeaturedCars = (props) => {
  return (
    <div className="section wf-section is-bg--gray-02">
      <div className="container">
        <div className="_5-col-st-grid">
          <CarFeaturedCardSkeleton />
          <CarFeaturedCardSkeleton />
          <CarFeaturedCardSkeleton />
          <CarFeaturedCardSkeleton />
          <CarFeaturedCardSkeleton />
          {/* <CarFeatured />
          <CarFeatured />
          <CarFeatured />
          <CarFeatured /> */}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCars;
