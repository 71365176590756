import React, { Component, Fragment } from 'react';
import auth from '../services/authService';
import { Link } from 'react-router-dom';
import Table from './common/table';
import Like from './common/like';
import configData from '../config.json';

const storageUrl = configData.storageUrl;

class LoansTable extends Component {
  columns = [
    {
      path: 'user.name',
      label: 'Loaner',
      content: (loan) => (
        <Fragment>
          {loan.user && (
            <Link to={`/admin/clients/${loan.user._id}`}>
              <div className="investment--investor">
                <span className="investment--investor--photo">
                  <div className="nav__user-photo">
                    {loan.user && (
                      <img
                        src={
                          loan.user.photo !== 'default.jpg'
                            ? storageUrl + loan.user.photo
                            : '/images/thumb_15951118880user.png'
                        }
                        loading="lazy"
                        alt=""
                        className="user__profile__photo__img"
                      />
                    )}
                  </div>
                </span>
                <div>
                  {loan.user && (
                    <span className="is-fw-600">{loan.user.name}</span>
                  )}
                  {!loan.user && <span className="">No user found</span>}
                </div>
              </div>
            </Link>
          )}
        </Fragment>
      ),
    },
    {
      path: 'user.name',
      label: 'Loan Date',
      content: (loan) => (
        <span>{`${new Date(loan.createdAt).toLocaleString()}`}</span>
      ),
    },

    {
      path: 'status',
      label: 'Status',
      content: (loan) => (
        <p className={`credit-status credit-status--${loan.status}`}>
          {loan.status}
        </p>
      ),
    },

    {
      path: 'amount',
      label: 'Amount',
      content: (loan) => <span className="is-fw-600">₦ {loan.amount}</span>,
    },
    {
      path: 'details',
      label: 'Details',
      key: 'details',
      content: (loan) => (
        // <Like
        //   liked={loan.liked}
        //   onClick={() => this.props.onLike(loan)}
        // />

        <Link
          className="alt-btn btn-link--is-blue w-inline-block alt-bt--inline"
          to={`/admin/loans/${loan._id}`}
        >
          View
        </Link>
      ),
    },
    // {
    //   path: "like",
    //   label: "Like",
    //   key: "like",
    //   content: (loan) => (
    //     <Like
    //       liked={loan.liked}
    //       onClick={() => this.props.onLike(loan)}
    //     />
    //   ),
    // },
  ];

  deleteColumn = {
    key: 'delete',
    content: (loan) => (
      <button
        onClick={() => this.props.onDelete(loan)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    this.setState(user);
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { loans, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={loans}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default LoansTable;
