import React, { Component } from "react";

const ProgressLoading = ({ name, message, size, transparency }) => {
  return (
    <div
      id={name}
      className={
        transparency ? "pwi__progress__flex transparent" : "pwi__progress__flex"
      }
    >
      <div className="pwi__progress__flex__item">
        <div className="pwi__progress">
          <div
            id="pwi__progress__loading"
            className={"pwi__progress__svg " + size}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressLoading;
