import React from 'react';
import SubsectionTitleWIthFlip from '../../common/UiLayoutsAndComponents/subsectionTItleWithFlip';

const MeetTheCEOsContacts = (props) => {
  return (
    <section className="section">
      <div className="container gray-bg-02">
        <div className="_3-col-grid">
          <div className="">
            <div className="">
              <SubsectionTitleWIthFlip
                props={{
                  title: 'Venue',
                  text: 'Abuja',
                }}
              />
              <div className="contact-info__details">
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item">
                    Lagos/Osun Hall, Transcorp Hilton, Abuja.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
              <SubsectionTitleWIthFlip
                props={{
                  title: 'Date & Time',
                  text: 'Event',
                }}
              />
              <div className="contact-info__details">
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item ">
                    Date: 3rd December, 2022.
                  </p>
                </div>
              </div>

              <div className="contact-info__details">
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <p className="contact-info__details__item ">Time: 10:00am</p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
              <SubsectionTitleWIthFlip
                props={{
                  title: 'For enquiry',
                  text: 'Contact',
                }}
              />
              <div className="contact-info__details">
                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <a
                    href="tel:+2348166652054"
                    className="contact-info__details__item is--link link--blue w-inline-block"
                  >
                    <p>Deola: +234 816 665 2054</p>
                  </a>
                </div>

                <div className="glue__flex-icon">
                  <span className="is-fa-icon--300"></span>
                  <a
                    href="tel:+2348073363449"
                    className="contact-info__details__item is--link link--blue w-inline-block"
                  >
                    <p>Emmanuel: +234 807 336 3449</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetTheCEOsContacts;
