import React from 'react';

const StaticHeader = (props) => {
  const classNames = 'header-static  wf-section';
  return (
    <div
      className={
        classNames + ' ' + 'header-static--' + props.pageData.headerLocalClass
      }
    >
      <div className="overlay--dark"></div>
      <div className="container">
        <div className="header-statoc--flex">
          <div className="header-static__container">
            <h2>{props.pageData.titleText}</h2>
            {props.pageData.altTitle && <p> {props.pageData.altTitle}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticHeader;
