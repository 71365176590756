import React from 'react';
import configData from '../../config.json';
const storageUrl = configData.storageUrl;

const ShoppingCentreCarPageHeader = (props) => {
  const { car } = props;

  return (
    <>
      {car && (
        <section className="cl-hero wf-section">
          <div className="cl-hero__image-wrap">
            <div
              data-delay="7000"
              data-animation="fade"
              className="cl-hero__slider w-slider"
              data-autoplay={true}
              data-easing="ease"
              data-hide-arrows="false"
              data-disable-swipe="false"
              data-autoplay-limit="0"
              data-nav-spacing="3"
              data-duration="500"
              data-infinite="true"
            >
              <div className="w-slider-mask">
                <div
                  data-w-id="91b65c63-f4e4-4e28-0269-100f8d912c9d"
                  className=" w-slide text-white"
                >
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    src={`${storageUrl}${car.imageCover}`}
                    alt={`${car.mae} ${car.model} ${car.year} images`}
                  />
                  <div className="overlay--dark"></div>
                  <div className="container cl-hero__container z-1">
                    <div className="slide__content">
                      <div className="c-rich-text">
                        <h1 className="slide__content__title">
                          {car.model}
                          {/* <span className=" fw-100">
                            {' '}
                            | {car.exteriorColour}
                          </span> */}
                        </h1>
                        {/* <span className="pt-2 pl-2 h6">{car.year}</span> */}

                        <p>
                          {car.make} <span className="px-2">|</span>{' '}
                          {car.bodyType} <span className="px-2">|</span>{' '}
                          {car.exteriorColour}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden--el w-slider-arrow-left">
                <div className="w-icon-slider-left"></div>
              </div>
              <div className="hidden--el w-slider-arrow-right">
                <div className="w-icon-slider-right"></div>
              </div>
              <div className="hidden--el w-slider-nav w-round"></div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ShoppingCentreCarPageHeader;
