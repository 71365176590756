import React from "react";

const WWDIT = (props) => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container is--narrow">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              {/* <h3>Our Products</h3> */}
              <p>
                Over the years of business, we have evolved around the people we
                serve. <br />
                Across our divisions, platforms, and services, our clients and
                customers have access to reliable products and services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WWDIT;
