import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import useAuth from '../services/authService';

// const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         if (!auth.getCurrentUser())
//           return (
//             <Navigate
//               to={{
//                 pathname: "/login",
//                 state: { from: props.location },
//               }}
//             />
//           );
//         return Component ? <Component {...props} /> : render(props);
//       }}
//     />
//   );
// };

const ProtectedRoute = ({ children }) => {
  const auth = useAuth.getCurrentUser();
  return auth ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
