import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../../context/userContext';
import { logout } from '../../../services/authService';
// import { logout } from "../services/authService";
import configData from '../../../config.json';

function Navbar({ props }) {
  // Get Current User
  const currentUser = useContext(UserContext);

  return (
    <div className="nav-section">
      <header className="nav-section__wrp wf-section">
        <div
          data-animation="default"
          className="nav w-nav"
          data-easing2="ease"
          data-easing="ease"
          data-collapse="medium"
          role="banner"
          data-no-scroll="1"
          data-duration="500"
          data-doc-height="1"
        >
          <div className="container no-t-b-padding menu-container w-container">
            <div className="nav___wrp">
              <div className="nav__container">
                <Link
                  to="/"
                  aria-current="page"
                  className="nav__logo w-nav-brand w--current"
                >
                  <img
                    src="/images/sw-logo--dark.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 55px, (max-width: 767px) 9vw, (max-width: 991px) 50px, (max-width: 1919px) 6vw, 100vw"
                    srcSet="/images/sw-logo--dark-p-500.png 500w, /images/sw-logo--dark.png 813w"
                    alt="Skyewise Group"
                    className="nav__logo__img"
                  />
                </Link>
                <nav role="navigation" className="nav__menu w-nav-menu">
                  <div className="nav__menu--left">
                    <div
                      data-hover="false"
                      data-delay="50"
                      data-w-id="a1cbd76f-c190-559a-ac83-eb70908150d2"
                      className="nav__menu__item nav-item--drop-down w-dropdown"
                    >
                      <div className="nav__menu__item is--dropdown w-dropdown-toggle">
                        <div className="nav__menu__link">About Us</div>{' '}
                        <div className="is-icon side-nav__nav__link__icon nav__menu__link__arrow">
                          <p className="nav__menu__link__arrow--icon"></p>
                        </div>
                      </div>
                      <nav
                        data-w-id="a1cbd76f-c190-559a-ac83-eb70908150d6"
                        className="nav__dropdown w-dropdown-list"
                      >
                        <div className="nav__blur-overlay"></div>
                        <section className="nav__dropdown__c-wrp">
                          <div className="container container--no-top-buttom">
                            <div className="nav__dropdown__flx">
                              <div className="nav__dropdown__col-1">
                                <div className="nav__dropdown__col-1__container">
                                  <Link
                                    to="/about-us/overview"
                                    className="nav__dropdown__item is-border-b w-dropdown-link"
                                  >
                                    Overview
                                  </Link>
                                  <Link
                                    to="/about-us/purpose-and-values"
                                    className="nav__dropdown__item is-border-b w-dropdown-link"
                                  >
                                    Our Purpose and Values
                                  </Link>
                                  <div
                                    data-hover="false"
                                    data-delay="0"
                                    className="dropdown__lv-2 w-dropdown"
                                  >
                                    <div
                                      data-w-id="b63cb87b-4dcb-5a83-212d-85cabb1621d2"
                                      className="nav__dropdown__item is-border-b w-dropdown-toggle"
                                    >
                                      <div className="nav-icon w-icon-dropdown-toggle"></div>
                                      <div>People and Leadership</div>
                                    </div>
                                    <nav className="dropdown__lv-2__items w-dropdown-list">
                                      <Link
                                        to="/about-us/our-people"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Our People
                                      </Link>
                                      <Link
                                        to="/about-us/people-and-leadership"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Board of Directors
                                      </Link>

                                      <Link
                                        to="/about-us/people-and-leadership/management"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Management Team
                                      </Link>
                                      {/* <Link
                                        to="/about-us/people-and-leadership/awards-and-recorgnitions"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Awards and Recognition
                                      </Link> */}
                                      {/* <Link
                                        to="/seaf-foundation/community-engagements"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Community Engagement
                                      </Link> */}
                                      {/* <Link
                                        to="/sustainability"
                                        className="dropdown__item w-dropdown-link"
                                      >
                                        Sustainability
                                      </Link> */}
                                    </nav>
                                  </div>
                                  <div
                                    data-hover="false"
                                    data-delay="0"
                                    className="dropdown__lv-2 w-dropdown"
                                  >
                                    <div
                                      data-w-id="81a14e12-c06b-6a96-cdc6-469bee7ff279"
                                      className="nav__dropdown__item is-border-b w-dropdown-toggle"
                                    >
                                      <div className="nav-icon w-icon-dropdown-toggle"></div>
                                      <div>What We Do</div>
                                    </div>
                                    <nav className="dropdown__lv-2__items w-dropdown-list">
                                      <Link
                                        to="/what-we-do/overview"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Overview
                                      </Link>
                                      <Link
                                        to="/what-we-do/products-and-services"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Our Products and Services
                                      </Link>
                                      <Link
                                        to="/what-we-do/products-and-services/investment"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Investment
                                      </Link>
                                      <Link
                                        to="/what-we-do/products-and-services/loan"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Loans
                                      </Link>
                                      <Link
                                        to="/automobile"
                                        className="dropdown__item w-dropdown-link"
                                      >
                                        Automobile
                                      </Link>

                                      <Link
                                        to="/travel-and-tourism"
                                        className="dropdown__item w-dropdown-link"
                                      >
                                        Travel and Tours
                                      </Link>

                                      <Link
                                        to="/real-estate"
                                        className="dropdown__item w-dropdown-link"
                                      >
                                        Real Estate
                                      </Link>
                                    </nav>
                                  </div>
                                  {/* <Link
                                    to="/about-us/culture-and-innovation"
                                    className="nav__dropdown__item is-border-b w-dropdown-link"
                                  >
                                    Culture And Innovation
                                  </Link> */}
                                  <Link
                                    to="/contact/locations"
                                    className="nav__dropdown__item is-border-b w-dropdown-link"
                                  >
                                    Locations
                                  </Link>
                                  <Link
                                    to="/about-us/faq"
                                    className="nav__dropdown__item is-border-b w-dropdown-link"
                                  >
                                    FAQs
                                  </Link>
                                </div>
                              </div>
                              <div className="nav__dropdown__col-2">
                                <div className="div-block-123">
                                  <h4>Overview</h4>
                                  <p className="paragraph-7">
                                    The Skyewise Group is a leading global
                                    financial institution that delivers a broad
                                    range of financial services – Here you will
                                    find all information about-us the company
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </nav>
                    </div>

                    {/* Mega Menu Ends */}
                    <div
                      data-hover="false"
                      data-delay="50"
                      data-w-id="a1cbd76f-c190-559a-ac83-eb70908150d2"
                      className="nav__menu__item nav-item--drop-down w-dropdown"
                    >
                      <div className="nav__menu__item m-icon is--dropdown w-dropdown-toggle">
                        <div className="nav__menu__link">
                          Products and Services
                          <div className="is-icon side-nav__nav__link__icon nav__menu__link__arrow">
                            <p className="nav__menu__link__arrow--icon"></p>
                          </div>
                        </div>
                      </div>
                      <nav
                        data-w-id="a1cbd76f-c190-559a-ac83-eb70908150d6"
                        className="nav__dropdown w-dropdown-list"
                      >
                        <div className="nav__blur-overlay"></div>
                        <section className="nav__dropdown__c-wrp">
                          <div className="container container--no-top-buttom">
                            <div className="nav__dropdown__flx">
                              <div className="nav__dropdown__col-1">
                                <div className="nav__dropdown__col-1__container">
                                  <Link
                                    to="#"
                                    className="nav__dropdown__item is-border-b w-dropdown-link"
                                  >
                                    Overview
                                  </Link>

                                  <Link
                                    to="/what-we-do/products-and-services"
                                    className="dropdown__item is-border-b w-dropdown-link"
                                  >
                                    All Products and Services
                                  </Link>

                                  <div
                                    data-hover="false"
                                    data-delay="0"
                                    className="dropdown__lv-2 w-dropdown"
                                  >
                                    <div
                                      data-w-id="b63cb87b-4dcb-5a83-212d-85cabb1621d2"
                                      className="nav__dropdown__item is-border-b w-dropdown-toggle"
                                    >
                                      <div className="nav-icon w-icon-dropdown-toggle"></div>
                                      <div>Automobiles</div>
                                    </div>
                                    <nav className="dropdown__lv-2__items w-dropdown-list">
                                      <Link
                                        to="/automobile"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Overview
                                      </Link>
                                      <Link
                                        to="/automobile/cars"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Car Centre
                                      </Link>
                                      <Link
                                        to="/contact/appointment"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Schedule Appointment
                                      </Link>

                                      <Link
                                        to="#"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Industry Updates
                                      </Link>
                                    </nav>
                                  </div>
                                  <div
                                    data-hover="false"
                                    data-delay="0"
                                    className="dropdown__lv-2 w-dropdown"
                                  >
                                    <div
                                      data-w-id="81a14e12-c06b-6a96-cdc6-469bee7ff279"
                                      className="nav__dropdown__item is-border-b w-dropdown-toggle"
                                    >
                                      <div className="nav-icon w-icon-dropdown-toggle"></div>
                                      <div>Investment</div>
                                    </div>
                                    <nav className="dropdown__lv-2__items w-dropdown-list">
                                      <Link
                                        to="/services/investment"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Overview
                                      </Link>
                                      <Link
                                        to="#"
                                        // to="/services/investment/why-invest"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Why Invest With Us?
                                      </Link>
                                      <Link
                                        to="#"
                                        // to="/services/investment/benefits"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Benefits
                                      </Link>
                                      <Link
                                        to="/user-dashboard"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Invest Today
                                      </Link>
                                    </nav>
                                  </div>

                                  <div
                                    data-hover="false"
                                    data-delay="0"
                                    className="dropdown__lv-2 w-dropdown"
                                  >
                                    <div
                                      data-w-id="81a14e12-c06b-6a96-cdc6-469bee7ff279"
                                      className="nav__dropdown__item is-border-b w-dropdown-toggle"
                                    >
                                      <div className="nav-icon w-icon-dropdown-toggle"></div>
                                      <div>Loan</div>
                                    </div>
                                    <nav className="dropdown__lv-2__items w-dropdown-list">
                                      <Link
                                        to="/services/loan"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Overview
                                      </Link>
                                      <Link
                                        to="/services/loan/personal-loan/requirements"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Personal Loan Requirments
                                      </Link>
                                      <Link
                                        to="/services/loan/business-loan/requirements"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Business Loan Requirments
                                      </Link>
                                      <Link
                                        to="/user-dashboard"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Request Loan
                                      </Link>

                                      <Link
                                        to="#"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Contact Us
                                      </Link>
                                    </nav>
                                  </div>

                                  <div
                                    data-hover="false"
                                    data-delay="0"
                                    className="dropdown__lv-2 w-dropdown"
                                  >
                                    <div
                                      data-w-id="81a14e12-c06b-6a96-cdc6-469bee7ff279"
                                      className="nav__dropdown__item is-border-b w-dropdown-toggle"
                                    >
                                      <div className="nav-icon w-icon-dropdown-toggle"></div>
                                      <div>Travel and Tours</div>
                                    </div>
                                    <nav className="dropdown__lv-2__items w-dropdown-list">
                                      <Link
                                        to="/travel-and-tourism"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Overview
                                      </Link>
                                      <Link
                                        to="#"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Available Tours
                                      </Link>
                                      <Link
                                        to="#"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Flight Booking and Ticketing Services
                                      </Link>
                                      <Link
                                        to="#"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Book Ticket
                                      </Link>
                                    </nav>
                                  </div>

                                  <div
                                    data-hover="false"
                                    data-delay="0"
                                    className="dropdown__lv-2 w-dropdown"
                                  >
                                    <div
                                      data-w-id="81a14e12-c06b-6a96-cdc6-469bee7ff279"
                                      className="nav__dropdown__item is-border-b w-dropdown-toggle"
                                    >
                                      <div className="nav-icon w-icon-dropdown-toggle"></div>
                                      <div>Real Estate</div>
                                    </div>
                                    <nav className="dropdown__lv-2__items w-dropdown-list">
                                      <Link
                                        to="/real-estate"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Overview
                                      </Link>
                                      <Link
                                        to="#"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Real Estate Investment Opportunities
                                      </Link>

                                      <Link
                                        to="/real-estate"
                                        className="dropdown__item is-border-b w-dropdown-link"
                                      >
                                        Locations
                                      </Link>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                              <div className="nav__dropdown__col-2">
                                {/* <div className="excerpts">
                                  <div className="excerpts__card__content--img">
                                    <div className="grid-col--left">
                                      <div
                                        data-poster-url="/content/videos/geely-short-16-9_poster.jpeg"
                                        data-video-urls="/content/videos/geely-short-16-9.mp4"
                                        className="c-video-sreen"
                                      >
                                        <video
                                          poster="/content/videos/geely-short-16-9_poster.jpeg"
                                          className="c-video-sreen__video"
                                          autoPlay={true}
                                          loop={true}
                                          muted={true}
                                          playsInline=""
                                          data-wf-ignore="true"
                                          data-object-fit="cover"
                                        >
                                          <source
                                            src="/content/videos/geely-short-16-9.mp4"
                                            data-wf-ignore="true"
                                          />
                                          <source
                                            // src="videos/the-cooperative-hero-vid-mod-transcode.webm"
                                            data-wf-ignore="true"
                                          />
                                        </video>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="excerpts__card__content--txt">
                                    <div className="txt-icon-caption is-txt-blue">
                                      <p>Proud Partner of Geely Motors </p>
                                    </div>
                                    <a
                                      href="#"
                                      className="st-link w-inline-block"
                                    >
                                      <h4 className="is--txt-small">
                                        We are making it easier for Nigerians to
                                        own brand-new automobiles through our
                                        partnership with Geely & Mikano
                                      </h4>
                                    </a>
                                  </div>
                                </div> */}

                                <div className="div-block-123">
                                  <h4>Products & Services</h4>
                                  <p className="paragraph-7">
                                    Get access to reliable products and
                                    services. From Finance, Travel/Tourism,
                                    Automobile... you have products you can rely
                                    on.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </nav>
                    </div>

                    {/* Mega Menu Ends */}
                    <Link
                      to="/seaf-foundation"
                      className="nav__menu__item w-nav-link"
                    >
                      SEAF Foundation
                    </Link>
                    <Link
                      to="/the-cooperative"
                      className="nav__menu__item w-nav-link"
                    >
                      The Cooperative
                    </Link>

                    <a
                      href="https://blog.skyewisegroup.com/"
                      target="_blank"
                      className="nav__menu__item w-nav-link"
                    >
                      <span className="nav__menu__item--has-update">News</span>
                    </a>

                    <Link to="/contact" className="nav__menu__item w-nav-link">
                      Contact
                    </Link>
                  </div>
                  <div className="nav__menu--right">
                    {!currentUser && (
                      <Link
                        to="/login"
                        className="nav__menu__item nav-item--last w-inline-block nav-i--is-no-mr "
                      >
                        <div className="nav__menu__user">
                          <div className="nav__user-name is-dark-blue nav-i--is-no-mr">
                            <div className="is-flex nav__user-name__details ">
                              <span className="nav__user-name--name">
                                Client Login
                              </span>
                              <div className="nav__user-name--icon">
                                <span className="is-icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )}

                    {currentUser && (
                      <span className="nav__menu__item nav-item--last w-inline-block">
                        <div className="nav__menu__user">
                          <div className="nav__user-name--wrapper">
                            <div className="nav__user-name is-dark-blue">
                              <Link to="/user-dashboard">
                                {' '}
                                {currentUser.name.split(' ')[0]}
                              </Link>
                            </div>
                            {/* <span
                              onClick={() => {
                                logout();
                              }}
                              className="nav__user-logout"
                            >
                              Logout
                            </span> */}
                          </div>

                          <Link to="/user-dashboard">
                            <div className="nav__user-photo">
                              <img
                                src={
                                  currentUser.profilePhoto !== 'default.jpg'
                                    ? configData.storageUrl +
                                      currentUser.profilePhoto
                                    : '/images/co/gnrl/user.svg'
                                }
                                loading="lazy"
                                alt=""
                                className="user__profile__photo__img"
                              />
                            </div>
                          </Link>
                        </div>
                      </span>
                    )}
                  </div>
                </nav>
              </div>
              <div className="nav__menu__toggle w-nav-button">
                <p className="is-icon"></p>
              </div>
            </div>
          </div>
        </div>
        {/* <div  className="nav--open__bg-blur"></div>
        <div  className="nav--open__bg-underlay"></div> */}
      </header>
    </div>
  );
}

export default Navbar;
