import React from 'react';

const vehiclesShoppingCentreHeader = () => {
  return (
    <section className="cl-hero wf-section">
      <div className="cl-hero__image-wrap">
        <div
          data-delay="7000"
          data-animation="fade"
          className="cl-hero__slider w-slider"
          data-autoplay={true}
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="3"
          data-duration="500"
          data-infinite="true"
        >
          <div className="w-slider-mask">
            <div
              data-w-id="91b65c63-f4e4-4e28-0269-100f8d912c9d"
              className="cl-hero__slider__slide cl-hero__slider__slide--01 cl-hero__slider__slide--auto-01 w-slide"
            >
              <div className="overlay--dark"></div>
              <div className="container cl-hero__container">
                <div className="slide__content">
                  <div className="c-rich-text">
                    <h1
                      // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                      className="slide__content__title"
                    >
                      <span className="fw-100">Find cars at </span>
                      Skyewise Autos
                    </h1>
                    <p
                      // style="-webkit-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 10px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                      className="slide__content__desc"
                    >
                      Explore Skyewise Autos showroom{' '}
                      <span className="px-2">|</span> Abuja{' '}
                      <span className="px-2">|</span> Lagos
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden--el w-slider-arrow-left">
            <div className="w-icon-slider-left"></div>
          </div>
          <div className="hidden--el w-slider-arrow-right">
            <div className="w-icon-slider-right"></div>
          </div>
          <div className="hidden--el w-slider-nav w-round"></div>
        </div>
      </div>
    </section>
  );
};

export default vehiclesShoppingCentreHeader;
