import React from "react";
import Director from "./boardOfDirctorsDirector";

const Directors = () => {
  return (
    <div>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="internal-nav w-nav"
      >
        {/* <div className="container container--internal-nav w-container">
          <nav role="navigation" className="w-nav-menu">
            <a
              href="#"
              aria-current="page"
              className="internal-nav--item w-nav-link w--current"
            >
              BOARD OF DIRECTORS
            </a>
            <a href="#" className="internal-nav--item w-nav-link">
              EXECUTIVE OFFICERS
            </a>
            <a href="#" className="internal-nav--item w-nav-link">
              MANAGEMENT COMMITTEE
            </a>
          </nav>
        </div>
         */}
      </div>

      <section className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>Board of Directors</h3>
              </div>
            </div>
          </div>

          <div className="s-row">
            <div className="_2-col-st-grid">
              <div className="div-block-96 grid-devider--right">
                <Director
                  director={{
                    name: "Ambassador Usman Sarki",
                    photo: "ambassador_usman_sarki.webp",
                    position: "Chairman",
                    profileUrl:
                      "about-us/people-and-leadership/directors/ambassador-usman-sarki",
                  }}
                />
              </div>

              <Director
                director={{
                  name: "Dr. Elvis L. Abuyere",
                  photo: "dr_elvis_abuyere.webp",
                  position: "MD/CEO",
                  profileUrl:
                    "about-us/people-and-leadership/directors/dr-elvis-abuyere",
                }}
              />
            </div>
          </div>
          <div className="s-row__devider"> </div>

          <div className="s-row">
            <div className="_2-col-st-grid">
              <div className="div-block-96 grid-devider--right">
                <Director
                  director={{
                    name: "Dr. Nosiru U Gbadamosi",
                    photo: "dr_nosiru_gbadamosi.webp",
                    position: "Director",
                    profileUrl:
                      "about-us/people-and-leadership/directors/dr-nosiru-gbadamosi",
                  }}
                />
              </div>

              <Director
                director={{
                  name: "Engr. Chinyere Nnenna Igwegbe",
                  photo: "engr_chinyere_nnenna_igwegbe.webp",
                  position: "Director",
                  profileUrl:
                    "about-us/people-and-leadership/directors/engr-chinyere-nnenna-igwegbe",
                }}
              />
            </div>
          </div>
          <div className="s-row__devider"> </div>

          <div className="s-row">
            <div className="_2-col-st-grid">
              <div className="div-block-96 grid-devider--right">
                <Director
                  director={{
                    name: "Aifuwa Igbinoba",
                    photo: "aifuwa_igbinoba.webp",
                    position: "Director",
                    profileUrl:
                      "about-us/people-and-leadership/directors/aifuwa-igbinoba",
                  }}
                />
              </div>

              <Director
                director={{
                  name: "Barr Obiageli Onyekwuluje",
                  photo: "barr_obiageli_onyekwuluje.webp",
                  position: "Director",
                  profileUrl:
                    "about-us/people-and-leadership/directors/barr-obiageli-onyekwuluje",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Directors;
