import React from "react";
import { Link } from "react-router-dom";

const AuthPageBrand = (prop) => {
  return (
    <div className="auth-page__brand">
      <div className="auth-page__brand__container">
        <Link to="/" className="w-inline-block">
          <img
            src="/images/skyewise-group_1skyewise-group.png"
            loading="lazy"
            alt=""
            className="auth-page__brand__logo"
          />
        </Link>

        <div className="auth-page__brand__pipe"> </div>

        <div className="auth-page__brand__slogan">
          <Link to="/about-us/overview">
            <p className="auth-page__brand__slogan--text">
              Building Sustainable Investment Platform
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthPageBrand;
