import React from "react";

const TicketBookingPageHeader = (props) => {
  return (
    <div className="header-static  wf-section">
      <div className="full-height-hero__image-container full-height-hero__image-container--width">
        <picture
          className="responsive-image full-height-hero__picture lazy-loader lazy-loader--loaded full-height-hero__picture--width"
          data-load-module="LazyLoader"
          data-module-loaded="true"
          data-lazyloader-id="_kzk1cwouq281w"
        >
          <source
            media="(max-width: 979px)"
            // /Users/skyewise.it/Desktop/on-dev/skyewise-frnt-nd/public/images/temp/mb/HKW_AMG_GT_4DR_COUPE_MR.jpeg
            srcSet="/images/temp/mb/HKW_AMG_GT_4DR_COUPE_MR.jpeg"
            data-lazy-src="/images/temp/mb/HKW_AMG_GT_4DR_COUPE_MR.jpeg"
            className="lazy-loader__asset"
          />
          <img
            // /Users/skyewise.it/Desktop/on-dev/skyewise-frnt-nd/public/images/temp/mb/HKW_AMG_GT_4DR_COUPE_DR.jpeg
            src="/images/temp/mb/HKW_AMG_GT_4DR_COUPE_DR.jpeg"
            alt=""
            className="full-height-hero__image lazy-loader__asset lazy-loader__asset--loaded full-height-hero__image--width"
            data-lazy-src="/images/temp/mb/HKW_AMG_GT_4DR_COUPE_DR.jpeg"
            role="presentation"
          />
        </picture>
      </div>
      <div className="overlay--dark"></div>
      <div className="container">
        <div className="header-statoc--flex">
          <div className="header-static__container">
            <h1>Book Ticket</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketBookingPageHeader;
