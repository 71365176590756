import React, { Fragment } from "react";
import restartWebflowScript from "../common/restartWebflowScript";
import Navbar from "../common/navbar/navbar";
import OverviewHeader from "./aboutUsOverviewHeader";
import OverviewBodyContent from "./aboutUsOverviewBodyContent";
import Footer from "../common/footer/footer";
import ChatAndNavHelperBtn from "../common/chatAndNavHelperOpen";
import StaticHeaderOne from "../common/UiLayoutsAndComponents/staticHeaderOne";
import { Helmet } from "react-helmet-async";

const Overview = () => {
  restartWebflowScript();
  return (
    <Fragment>
      <Navbar />
      <Helmet>
        <title>About us - Overview - Skyewise Group</title>
        <meta
          name="description"
          content="The Skyewise Group is a leading global financial institution that delivers a broad range of financial services across investment, Credit Management, Real Estate, Automobiles, Logistics, Oil and Gas, Packaging, Contracts financing, and partnerships to a large and diversified client base that includes corporations, financial institutions, governments, and individuals."
        />
        <link rel="canonical" href="/about-us/overview" />
      </Helmet>
      <StaticHeaderOne
        pageData={{
          titleText: "Overview",
          headerLocalClass: "about-us",
        }}
      />
      <OverviewBodyContent />
      <Footer />
    </Fragment>
  );
};

export default Overview;
