import React, { useEffect, useMemo, useState } from 'react';
import restartWebflowScript from '../common/restartWebflowScript';
import NavBar from '../common/navbar/navbar';

import Footer from '../common/footer/footer';
import ChatAndNavHelperOpen from '../common/chatAndNavHelperOpen';
import PageExitCTA from '../common/pageExitCTA';
import Trends from '../common/trends/trendsBase';

import FeaturedCars from './carsFeatured';

import ShopingCenterNav from './shopingCenterNav';
import { getCar, getCars } from '../../services/carService';
import ShoppingCentreCarPageHeader from './shoppingCentreCarPageHeader';
import { useParams } from 'react-router-dom';
import ImageLightbox from '../common/imageLightbox';
import CarFeatureCapcel from './automobileFeatureCapcel';
import CarNote from './carNote';
import CarContact from './carContact';
import AutoSideNav from './automobileSideNavLinks';
import Skeleton from 'react-loading-skeleton';
import { ToastQueue } from '@react-spectrum/toast';
import ShoppingCentreCarPageHeaderSkeleton from './shoppingCentreCarPageHeaderSkeleton';

const ShoppingCentreCarPage = () => {
  // useEffect(() => {
  //   var htmlEl = document.getElementsByTagName("html")[0];
  //   htmlEl.setAttribute("data-wf-page", "61c2e2d14d436da4d3382185");
  // });

  const [car, setCar] = useState(null);

  const carCached = useMemo(() => car, [car]);

  const { id: carId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        if (!car) {
          const { data } = (await getCar(carId)).data.data;

          setCar(data);
        }
      } catch (e) {
        ToastQueue.negative(
          `${e.message}! We couldn't get the cars for you. Let's give it another shot`,
          {
            actionLabel: 'Reload',
            onAction: () => window.location.reload(),
            shouldCloseOnAction: true,
          }
        );
      }
    })();
  });

  restartWebflowScript();

  return (
    <>
      {carCached && (
        <div>
          <NavBar />
          <ShoppingCentreCarPageHeader car={carCached} />
          <div className="car-listings__grid-wrapper section wf-section">
            <div className="container ">
              <div className="car-listings__grid-wrapper">
                <div className="car-listings__cars-nav-grod">
                  {/* <div className="car-listings__block-header flex_order--02">
                <div></div>
              </div>
              <div className="car-listings__block-header flex_order--01">
                <h2>All Cars</h2>
              </div> */}
                  <div className="sticky_ top-[120px] h-[210px]">
                    <h4>Sort Cars</h4>
                    {/* <ShopingCenterNav /> */}
                    <AutoSideNav
                      data={[
                        {
                          linkTxt: 'All cars',
                          linkIcon: '',
                          linkAddress: '/automobile/cars',
                        },
                        {
                          linkTxt: 'Contact us',
                          linkIcon: '',
                          linkAddress: '#get-car-contact',
                        },
                      ]}
                    />
                  </div>
                  <div className="car-listings__block flex_order--04">
                    <div className="car-listings__block-header flex_order--01">
                      <div className="mt-2">
                        <h2>{`${carCached.make} ${carCached.model} ${carCached.year}`}</h2>
                        <p className="mt-2">
                          A listing from Skyewise Automobile
                        </p>
                      </div>
                    </div>

                    <div className="mt-8">
                      <h3>Features</h3>

                      <div className="_5-col-st-grid pt-4 mb-8">
                        {car.year && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.year,
                              title: 'Year',
                            }}
                          />
                        )}

                        {car.condition && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.condition,
                              title: 'Condition',
                            }}
                          />
                        )}

                        {/* {car.mileage && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.mileage,
                              suffix: 'Miles',
                            }}
                          />
                        )} */}

                        {car.transmissionSystem && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.transmissionSystem,
                              title: 'Transmission',
                            }}
                          />
                        )}

                        {car.bodyType && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.bodyType,
                              title: 'Body type',
                            }}
                          />
                        )}

                        {car.exteriorColour && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.exteriorColour,
                              exteriorColourCode: car.exteriorColourCode,
                              title: 'Exterior colour',
                            }}
                          />
                        )}

                        {car.cylinder && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.cylinder,
                              prefix: 'V-',
                              title: 'Cylinders',
                            }}
                          />
                        )}

                        {car.engineCapacity && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.engineCapacity,
                              suffix: 'Litre engine',
                              title: 'Condition',
                            }}
                          />
                        )}
                        {car.securityFeature && (
                          <CarFeatureCapcel
                            car={{
                              icon: '',
                              feature: car.securityFeature,
                              title: 'Condition',
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="c-rich-text">
                      <h3>Gallery</h3>
                      {carCached.images && (
                        <ImageLightbox
                          images={[carCached.imageCover, ...carCached.images]}
                        />
                      )}
                    </div>

                    {/* Description */}
                    {car.description && (
                      <div className="c-rich-text mt-8">
                        <h3>Description</h3>
                        <p>{car.description}</p>
                      </div>
                    )}

                    {/* Note */}

                    <CarNote />

                    <CarContact />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* GLoable Trends Here */}
          <FeaturedCars />

          <PageExitCTA />
          <Footer />
        </div>
      )}

      {!carCached && (
        <div>
          <NavBar />
          <ShoppingCentreCarPageHeaderSkeleton />
          <div className="car-listings__grid-wrapper section wf-section">
            <div className="container ">
              <div className="car-listings__grid-wrapper">
                <div className="car-listings__cars-nav-grod">
                  {/* <div className="car-listings__block-header flex_order--02">
                <div></div>
              </div>
              <div className="car-listings__block-header flex_order--01">
                <h2>All Cars</h2>
              </div> */}
                  <div className="sticky_ top-[120px] h-[210px]">
                    <h4>Sort Cars</h4>
                    {/* <ShopingCenterNav /> */}
                    <AutoSideNav
                      data={[
                        {
                          linkTxt: 'All cars',
                          linkIcon: '',
                          linkAddress: '/automobile/cars',
                        },
                        {
                          linkTxt: 'Contact us',
                          linkIcon: '',
                          linkAddress: '#get-car-contact',
                        },
                      ]}
                    />
                  </div>
                  <div className="car-listings__block flex_order--04">
                    <div className="car-listings__block-header flex_order--01">
                      <div className="mt-2 ">
                        <h2>
                          <Skeleton width={340} />
                        </h2>
                        <p className="mt-2">
                          <Skeleton width={230} />
                        </p>
                      </div>
                    </div>

                    <div className="mt-8">
                      <h3>
                        <Skeleton width={140} />
                      </h3>

                      <div className="_5-col-st-grid pt-4 mb-8">
                        {Array(8)
                          .fill(0)
                          .map((e, i) => (
                            <div>
                              <Skeleton key={i} height={52} />
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="c-rich-text">
                      <h3>
                        <Skeleton width={140} />
                      </h3>

                      <div className="_3-col-st-grid pt-4 mb-8">
                        {Array(6)
                          .fill(0)
                          .map((e, i) => (
                            <Skeleton key={i} height={150} />
                          ))}
                      </div>
                    </div>

                    {/* Description */}

                    <div className="c-rich-text mt-8">
                      <h3>
                        <Skeleton width={140} />
                      </h3>
                      <p>
                        <Skeleton count={2} />
                      </p>
                    </div>

                    {/* Note */}

                    <div className="c-rich-text mt-8">
                      <h3>
                        <Skeleton width={140} />
                      </h3>
                      <p>
                        <Skeleton count={2} />
                        <div className="ml-6 mt-2">
                          <Skeleton count={3} width={300} />
                        </div>
                      </p>
                    </div>

                    {/* Contact section Skeleton */}
                    <div className="c-rich-text mt-8">
                      <h3>
                        <Skeleton width={140} />
                      </h3>
                      <div className="mb-1">
                        <Skeleton height={38} />
                      </div>
                      <div className="mb-1">
                        <Skeleton height={38} />
                      </div>
                      <div className="mb-1">
                        <Skeleton height={38} width={450} />
                      </div>

                      <div className="mt-6">
                        <Skeleton borderRadius={100} width={100} height={35} />
                      </div>

                      <div className="contact-info__details _5-col-st-grid mt-4">
                        <Skeleton height={25} />
                        <Skeleton height={25} />
                        <Skeleton height={25} />
                      </div>

                      <div className="mt-4">
                        <Skeleton width={240} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* GLoable Trends Here */}
          <FeaturedCars />
          <PageExitCTA />
          <Footer />
        </div>
      )}
    </>
  );
};

export default ShoppingCentreCarPage;
