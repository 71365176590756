import React from 'react';
import { Link } from 'react-router-dom';

const AppointmentScheduleVisitorsTips = (props) => {
  return (
    <section className="section wf-section  is-bg--gray-01">
      <div className="container">
        <div className="_2-col-st-grid">
          <div>
            <div className="div-block-124">
              <div className="c-rich-text w-richtext">
                <h2>We're always here for you</h2>
                <p>
                  Want to pay us a visit, talk to someone, or test a car? Feel
                  free to schedule an appointment with us. We are always
                  delighted to have you.
                </p>

                <p>
                  Please provide your details below and we will reach out and
                  offer guidance. Find our locations easily using the Location
                  Finder feature.
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <img src="/images/user-guide/appointment-booking-dashed.png" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppointmentScheduleVisitorsTips;
