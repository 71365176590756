import React, { Fragment } from 'react';
import LeadershipHeader from './leadershipHeader';
import TeamGrid from './managementTeam';
import ManagementTeamGrd from './managementTeamGrid';
import Navbar from '../common/navbar/navbar';
import Footer from '../common/footer/footer';
import restartWebflowScript from '../common/restartWebflowScript';
import { Helmet } from 'react-helmet-async';

const ManagementTeam = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Helmet>
        <title>Mangement team</title>
        <meta
          name="description"
          content="Skyewise Group diverse but complementary management team"
        />
        <link
          rel="canonical"
          href="/about-us/people-and-leadership/management"
        />
      </Helmet>

      <Navbar />
      <LeadershipHeader />
      <TeamGrid />
      {/* <ManagementTeamGrd /> */}
      <Footer />
    </Fragment>
  );
};

export default ManagementTeam;
