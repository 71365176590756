import React from 'react';

const Testimony = (slideDetial) => {
  const { data: slide } = slideDetial;
  return (
    <div
      data-w-id="9aeeaf58-576e-2776-caa5-d5662fe27dcf"
      className="c-active-slider__slider__slide w-slide"
    >
      <div className="c-active-slider__slider__container">
        <div className="c-active-slider__slider__flex is--row-reverse">
          <div className="c-active-slider__slider__flex__col-1">
            <img
              src={slide.img}
              loading="lazy"
              //   sizes="(max-width: 479px) 74vw, (max-width: 767px) 25vw, (max-width: 991px) 22vw, (max-width: 1919px) 21vw, 100vw"
              //   srcSet="/images/michael-mims-ISczHsDwK1M-unsplash2x-p-500.png 500w, images/michael-mims-ISczHsDwK1M-unsplash2x-p-800.png 800w, images/michael-mims-ISczHsDwK1M-unsplash2x-p-1080.png 1080w, images/michael-mims-ISczHsDwK1M-unsplash2x-p-1600.png 1600w, images/michael-mims-ISczHsDwK1M-unsplash2x.png 1920w"
              alt={slide.title}
              className="img-100-fit"
            />
          </div>
          {console.log(slideDetial)}
          <div className="c-active-slider__slider__flex__col-2">
            <div className="c-rich-text">
              <h4>{slide.title}</h4>
              <p>{slide.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimony;
