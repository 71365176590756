import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const SkyewiseProduct = (product) => {
  return (
    <div className="_4-col-st-grid__item">
      <div className="grid__item">
        <div className="s-dual-rw-card">
          <div className="s-dual-rol-card__img-rw">
            <img
              src={product.details.image}
              // src={`/images/products/${product.image}`}
              loading="lazy"
              // sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 28vw, (max-width: 1919px) 26vw, 21vw"
              // srcSet="/images/1L3A0405-p-500.png 500w, /images/1L3A0405-p-800.png 800w, /images/1L3A0405-p-1080.png 1080w, /images/1L3A0405-p-1600.png 1600w, /images/1L3A0405.png 1920w"
              alt=""
              className="img-100-fit"
            />
          </div>
          <div className="s-dual-rol-card__content-rw">
            <h3 className="is--txt-light">{product.details.name}</h3>
            <p className="content-rw__p">{product.details.description}</p>
            <div className="content-rw__cta">
              <Link
                to={`/${product.details.url}`}
                className="st-link st-link--is-blue"
              >
                Learn more
              </Link>

              {product.details.altUrl && (
                <Fragment>
                  {" "}
                  <span className="content-rw__cta-devider"></span>
                  <Link
                    to={`/${product.details.altUrl}`}
                    className="st-link st-link--is-blue"
                  >
                    Login
                  </Link>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkyewiseProduct;
