export function tabNavigator(tabGroupClass, activeTab, nexTabClass) {
  const tabGroup = document.querySelectorAll('.' + tabGroupClass);
  const tabGroupImages = document.querySelectorAll(
    '.' + tabGroupClass + '__img'
  );

  //   Reset Tab Highlight
  tabGroup.forEach((tab) => {
    if (tab.classList.contains(activeTab)) {
      tab.classList.remove(activeTab);
    }
  });

  //   Reset Active Tab Image
  tabGroupImages.forEach((tab) => {
    if (tab.classList.contains(activeTab)) {
      tab.classList.remove(activeTab);
    }
  });

  document
    .getElementById(nexTabClass.id + '__img')
    .classList.add('tab__content--active');
  console.log(nexTabClass.id + '__img');
  nexTabClass.classList.add('tab__content--active');
}

export default tabNavigator;
