import React, { Fragment } from "react";

const CIBPG = (props) => {
  return (
    <Fragment>
      <section
        id="investment-benefits"
        className="section site-intro wf-section"
      >
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>
                  Being a member of the Cooperative, you are entitled to the
                  following benefits
                </h3>
                <p>Exclusive for Members</p>
              </div>
            </div>
          </div>
          <div className="_2-col-st-grid _2-col-st-grid--make-1-col">
            <div className="grid-col--left">
              <div className="c-rich-text">
                <ul className="i-benefits">
                  <li className="i-benefit">
                    <p className="fa-icon is-fa-icon--100 fa-icon--x-large">
                      
                    </p>
                    <h4>Monthly interest </h4>
                    <p>
                      You are entitled to monthly interest on your redeemable
                      contribution/special deposit.
                    </p>
                  </li>
                  <li className="i-benefit">
                    <h4>Loan</h4>
                    <p>You have access to Cooperative loans on request.</p>
                  </li>
                  <li className="i-benefit">
                    <h4>Management Meetings </h4>
                    <p>
                      Annual general meeting and can be among the management
                      team
                    </p>
                  </li>
                  <li className="i-benefit">
                    <h4>Security for New Members </h4>
                    <p>
                      You can use your contribution as security for new loaning
                      members
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid-col--right">
              <div className="c-rich-text">
                <ul className="i-benefits">
                  <li className="i-benefit">
                    <h4>Buy Car With Your Membership</h4>
                    <p>
                      You can get a car of your choice from Skyewise Autos and
                      monthly repayment through our partner Wema Bank.
                    </p>
                  </li>
                  <li className="i-benefit">
                    <h4>Own a House</h4>
                    <p>
                      You can use your contribution to own a house at your
                      location of choice depending on your contributed
                    </p>
                  </li>
                  <li className="i-benefit">
                    <h4>Weekly Training &amp; Empowerment Programs</h4>
                    <p>
                      You have access to the Cooperative weekly training and
                      empowerment programs
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <div className="_1-col-c-center wf-section">
        <div className="container">
          <div className="_1-col-c-center__wrp">
            <div className="_1-col-c-center__c-narrow">
              <div className="c-rich-text">
                <h3>Talk to someone?</h3>
                <p>Give us a call if you wish to speak with us in person</p>
              </div>
              <a
                href="/contact"
                className="alt-btn btn-link--is-blue w-inline-block"
              >
                <p>Contact</p>
                <p className="is-icon"></p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CIBPG;
