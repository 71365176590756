import React, { Fragment } from 'react';
import Navbar from '../common/navbar/navbar';
import Footer from '../common/footer/footer';
import restartWebflowScript from '../common/restartWebflowScript';
import StaticHeaderOne from '../common/UiLayoutsAndComponents/staticHeaderOne';
import Offices from './skyewiseOffices';
import { Helmet } from 'react-helmet-async';

const OfficeLocations = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Helmet>
        <title>Office locations - Contact us - Skyewise Group </title>
        <meta
          name="description"
          content="Find Skyewise Group branches and offices near you"
        />
        <link rel="canonical" href="/contact/locations" />
      </Helmet>

      <Navbar />
      <StaticHeaderOne
        pageData={{
          titleText: 'Locations',
          headerLocalClass: 'office-locations',
          overlay: true,
        }}
      />
      <Offices />
      <Footer />
    </Fragment>
  );
};

export default OfficeLocations;
