import React from "react";
import { Link } from "react-router-dom";

const Director = (props) => {
  const directorsBaseImgUrl = "/images/content/our-people/directors/";

  return (
    <div className="div-block-96 ">
      <div className="div-block-97">
        <img
          src={directorsBaseImgUrl + props.director.photo}
          loading="lazy"
          alt={props.director.name}
        />
      </div>
      <div className="div-block-97">
        <Link
          to={"/" + props.director.profileUrl}
          className="st-link w-inline-block"
        >
          <h4 className="c-h4">{props.director.name}</h4>
        </Link>
        <p>{props.director.position} </p>
      </div>
    </div>
  );
};

export default Director;
