import React from 'react';
import { Link } from 'react-router-dom';

const SWTravelAndTour = (props) => {
  return (
    <div
      data-w-id="da700eed-4ca2-9a0f-1f5f-910d3f1d96b2"
      className="hero-slider__slide w-slide hero-slider__slide--sw-travel-and-tour"
    >
      <div className="overlay--dark overlay--dark--65deg"></div>
      <div className="container hero-slider__container">
        <div className="slide__content">
          <h1 className="slide__content__title">
            See the world,
            <span style={{ fontWeight: 100 }}> Discover yourself.</span>
          </h1>
          <p className="slide__content__desc">
            Travel with Skyewise Travel and Tourism
          </p>
          <div className="slide__cta">
            <Link
              to="/travel-and-tourism/ticket-booking"
              className="s-btn is-btn--light w-inline-block"
            >
              <p>Ticketing</p>
            </Link>
            <Link
              data-w-id="787c375c-d2ee-73cb-a78e-260ff68890ef"
              to="/travel-and-tourism/"
              className="alt-btn is-btn--alt w-inline-block"
            >
              <div className="alt-btn__wrp">
                <p>Available tours</p>
              </div>
              <div className="alt-btn__underline is-white__underline"></div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SWTravelAndTour;
