import React from 'react';
import { Link } from 'react-router-dom';

const CommunityEngagements = () => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <h2>S.E.A.F Impact</h2>
              <p>
                Our answer to life’s most persistent and urgent question is
                service. Service to the community, service to others. <br />
              </p>
            </div>
            <a
              href="#seaf-projects"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>SEAF Projects</p>
              {/* <p className="is-icon"></p> */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityEngagements;
