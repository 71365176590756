import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import auth from '../../../services/authService';
import { updateInvestment } from '../../../services/investmentService';
import { updateMe } from '../../../services/userService';

import copyText from '../../../utils/copyText';

const FundInvestment = (props) => {
  return (
    <div className=" is-2_5em--white-wrap is-txt--centered">
      <h3>Bank deposite</h3>
      <div className="">
        <img
          src="/images/user-guide/fund-transfer-2.png"
          alt="Fund your investment"
        ></img>
      </div>
      <div className="c-rich-text">
        <h4>Fund your investment </h4>
        <span className="is-flex flex-c">
          <span className="is-flex__80-jc-c">
            <p>
              Your investment becomes active as soon as the payment has been
              confirmed. We will notify you when the process is completed
            </p>
          </span>
        </span>
      </div>
      <div className="hr-line hr-line--dark"></div>

      <div className="credit-dashbord__sum fund-channels">
        <div className="fund-channel">
          <div className="fund-channel__bank">
            <div className="fund-channel__bank__thumb">
              <img
                src="/images/user-guide/partnerships/zenith-bank_2.png"
                alt="Zenith Bank Account"
              />
            </div>
            <div className="fund-channel__bank__name">
              <p>ZENITH BANK</p>
              <p className="fund-channel__bank__acc-name">
                Skyewise Cooperative
              </p>
            </div>
          </div>
          <div className="fund-channel__bank__acc-no">
            <button
              onClick={(e) => {
                copyText(e);
              }}
              className="fund-channel__bank__acc-no__copy"
            >
              1016275763
            </button>
          </div>
        </div>

        <div className="fund-channel">
          <div className="fund-channel__bank">
            <div className="fund-channel__bank__thumb">
              <img
                src="/images/user-guide/partnerships/gt-bank_2.png"
                alt="GT Bank Account your investment"
              />
            </div>
            <div className="fund-channel__bank__name">
              <p>GT Bank</p>
              <p className="fund-channel__bank__acc-name">
                Skyewise Cooperative
              </p>
            </div>
          </div>
          <div className="fund-channel__bank__acc-no">
            <button
              onClick={(e) => {
                copyText(e);
              }}
              className="fund-channel__bank__acc-no__copy"
            >
              0431335570
            </button>
          </div>
        </div>
      </div>
      <div className="hr-line hr-line--dark"></div>

      <div className="is-cta-align--center">
        <button
          onClick={async () => {
            const { data } = await (
              await updateMe({ investmentState: 'funded' })
            ).data;

            const investmentId = data.user.currentInvestment;

            await updateInvestment(investmentId, { funded: true });

            await auth.setUVDALocal();

            window.location.reload();
          }}
          className="s-btn is-btn--black w-inline-block"
        >
          <p>Done</p>
          <p className="is-icon"></p>
        </button>
      </div>
      <div className="hr-line hr-line--dark"></div>
      <p>Skyewie Cooperative</p>
    </div>
  );
};

export default FundInvestment;
