import React from "react";
import Step from "./stepIndicatorStep";

const StepsIndicator = (steps) => {
  return (
    <div className="c-steps-indicator">
      <div className="c-steps-indicator__container">
        {steps.CurrentSteps.map((desc, i) => (
          <Step
            stepData={{ step: desc, count: i + 1 }}
            stepType={steps.stepType}
            key={i}
          />
        ))}
      </div>
      <div className="c-steps-indicator__connector"></div>
    </div>
  );
};

export default StepsIndicator;
