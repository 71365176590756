import React, { Fragment } from "react";
import Navbar from "../../common/navbar/navbar";
import Footer from "../../common/footer/footer";
import { Link } from "react-router-dom";
import restartWebflowScript from "../../common/restartWebflowScript";

const DirectorAifuwaIgbinoba = (props) => {
  restartWebflowScript();

  return (
    <Fragment>
      <Navbar />

      <div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="internal-nav w-nav"
        >
          <div className="header-static static-header--leadership wf-section">
            <div className="container">
              <div className="header-statoc--flex">
                <div className="header-static__container">
                  <h1>Aifuwa Igbinoba</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow is--left-align">
                <div className="c-rich-text">
                  <div className="div-block-96 ">
                    <div className="div-block-97">
                      <img
                        src="/images/content/our-people/directors/aifuwa_igbinoba.webp"
                        loading="lazy"
                        alt="Aifuwa Igbinoba"
                      />
                    </div>
                    <div className="div-block-97">
                      {/* <a className="st-link w-inline-block" href="/"> */}
                      <h4 className="c-h4">Aifuwa Igbinoba</h4>
                      {/* </a> */}
                      <p>Director </p>
                      <p className="p-medium">
                        Partner at Lex Luminar Attorneys in Abuja, Nigeria.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="_1-col-c-center__c-narrow is--left-align st-r-mg ">
                <div className="c-rich-text">
                  <p className="p-small">
                    Aifuwa Igbinoba is a partner at Lex Luminar Attorneys in
                    Abuja, Nigeria. He is a professional with over 15 years of
                    experience as a legal and financial advisor. He has
                    extensive knowledge of Nigeria's financial markets and
                    policies as he has over a decade of experience with one of
                    the biggest financial institutions in Nigeria, FirstBank of
                    Nigeria Ltd.
                  </p>

                  <p className="p-small">
                    He provides expert strategic support and direction for the
                    firm in the areas of Energy, Agribusiness, International
                    trade, Infrastructure projects, technology, and media.
                  </p>

                  <p className="p-small">
                    He is presently a consultant to the Edo State Government on
                    Social Mobilization under the State Universal Basic
                    Education Board (SUBEB).
                  </p>

                  <p className="p-small">
                    He obtained his Bachelor of Law (LLB) from the University of
                    Benin, Edo State, Nigeria, and obtained his Master of Law
                    (LLM) in Energy, Environment and Natural Resources Law from
                    the University of Houston, Texas, the USA in 2018.
                  </p>

                  <p className="p-small">
                    He has a certificate in Negotiation and Leadership from
                    Harvard Law School, a Certificate in Sustainability
                    Principles in Banking from the Lagos Business School Pan
                    African University.
                  </p>
                </div>
              </div>

              <div className="_1-col-c-center__c-narrow  st-r-mg">
                <div className="c-rich-text">
                  <h3>Directors</h3>
                </div>
                <Link
                  to="/about-us/people-and-leadership/directors"
                  className="alt-btn btn-link--is-blue w-inline-block"
                >
                  <p>Go back to all directors</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default DirectorAifuwaIgbinoba;
