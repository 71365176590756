import React from "react";
import { Link } from "react-router-dom";

const PurposeAndValuesBodyContent = () => {
  return (
    <div className="_1-col-c-center wf-section">
      <div className="container">
        <div className="_1-col-c-center__wrp">
          <div className="_1-col-c-center__c-narrow">
            <div className="c-rich-text">
              <p className="p-medium">
                At every step of our employees’ careers we invest in them, and
                ensure their interests remain focused on the long term and
                closely aligned with those of our clients and shareholders.
              </p>
              <p>
                Our goals are to maximize individual potential, increase
                commercial effectiveness, reinforce the firm’s culture, expand
                our people’s professional opportunities, and help them
                contribute positively to their greater communities.
              </p>
            </div>
            <div className=" st-b-radius">
              <div
                data-w-id="11218e7e-ce50-0e27-442e-d0a0d59a66b2"
                className="st-img__wrp"
              >
                <img
                  src="/content/images/community/board-meeting-dec-16x6.jpeg"
                  loading="lazy"
                  // sizes="(max-width: 479px) 82vw, (max-width: 767px) 42vw, (max-width: 991px) 44vw, (max-width: 1919px) 19vw, 15vw"
                  // srcSet="/images/06-p-500.jpeg 500w, images/06-p-1080.jpeg 1080w, /images/06-p-1600.jpeg 1600w, /images/06.jpg 1920w"
                  alt=""
                  className="st__img"
                />
                <div className="st-img__overlay overlay--dark"></div>
              </div>
            </div>
          </div>
          <div className="_1-col-c-center__c-narrow is-mt-medium ">
            <div className="c-rich-text">
              <h3>Our People</h3>
              <p className="p-small">
                Diversity is essential to our mission as a firm: it lets us
                remain at the edge of innovation by sharing idaeas, respond to
                the needs of our clients, and ensure that our people can work at
                their maximum potential.
              </p>

              <p className="p-small">
                Teamwork is our culture. For our groups to excel, all members
                must feel that they are operating in an inclusive environment
                that welcomes and supports differences, and that encourages
                input from all perspectives.
              </p>
            </div>
          </div>

          <div className="_1-col-c-center__c-narrow is-mt-medium ">
            <div className="c-rich-text">
              <h3>Leadership</h3>

              <p className="p-small">
                We believe that companies with diverse boards of directors are
                better positioned for stronger financial performance and
                improved governance. Through our leadership, we are positioned
                to better serve our clients, generating long-term value for our
                shareholders, and contributing to the broader public.
              </p>
            </div>
            <Link
              to="/about-us/people-and-leadership"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Board of Directors</p>
            </Link>
            <Link
              to="/about-us/people-and-leadership/management"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Management Team</p>
            </Link>
          </div>

          <div className="_1-col-c-center__c-narrow is-mt-medium ">
            <div className="c-rich-text">
              <h3>The Cooperative Community</h3>

              <p className="p-small">
                Building Network Through Partnerships. <br />
                The Cooperative develops educational programs, networking
                forums, leadership conferences, business-focused events, and
                client events to empower members of our community. The
                Cooperative Community is open to all professionals, business
                people, employees, and individuals
              </p>
            </div>

            <Link
              to="/the-cooperative"
              className="alt-btn btn-link--is-blue w-inline-block"
            >
              <p>Learn More About Skyewise Cooperative Society</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurposeAndValuesBodyContent;
