import React, { Fragment, Component } from 'react';
import ContactForm from './contactFormGeneral';
import { Link } from 'react-router-dom';

const TicketBookingFormSection = (props) => {
  return (
    <Fragment>
      <section className="section">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h3>Contact Form</h3>
                  <p>You may contact us by filling the form below</p>
                </div>

                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-bg--gray-02">
        <div className="_1-col-c-center wf-section">
          <div className="container">
            <div className="_1-col-c-center__wrp">
              <div className="_1-col-c-center__c-narrow">
                <div className="c-rich-text">
                  <h3>Find location</h3>
                  <p>Get precise direction to our offices with Google maps.</p>
                </div>
                <a
                  href="https://goo.gl/maps/XysMzvHLiBPcTEPD6"
                  className="alt-btn btn-link--is-blue w-inline-block"
                  target="_blank"
                >
                  <p>Map</p>
                  <p className="is-icon"></p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default TicketBookingFormSection;
