import React, { Component } from "react";

const RealEstateBenefitsSection = (props) => {
  return (
    <div className="highlights is-textlight wf-section">
      <div className="container is--narrow">
        <div className="_2-col-st-grid">
          <div className="grid__item">
            <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p>
            <div className="c-rich-text">
              <h4>Vision</h4>
              <p>
                To uphold a high level of professionalism in the real estate
                sector and to render value to our clients always
              </p>
            </div>
          </div>

          <div className="grid__item">
            <p className="is-icon is-icon--no-m-rt is-icon--icon-2x"></p>
            <div className="c-rich-text">
              <h4>Mission</h4>
              <p>
                To be the best in providing affordable smart homes with
                excellent modern facilities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateBenefitsSection;
