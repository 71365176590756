import React from 'react';
import { formatCurrency } from '../../../utils/formatCurrency';
import Investments from '../../investments';
import stringLimitAndTruncation from '../../../utils/stringLimitAndTruncation';

const UserInvestmentHistory = (record) => {
  return (
    <div
      // data-w-id="494c59a3-cfee-f9d8-2af4-a9ac00b49cb7"
      className="credit__history__history"
    >
      <div className="credit__history__item">
        <h5 className="credit__history__date">
          {new Date(record.historyData.createdAt).toLocaleDateString()}
        </h5>
        <p className="credit__history__date__desc  txt--x-small">
          {stringLimitAndTruncation(record.historyData.investment, 45)}
        </p>
      </div>
      <div className="credit__history__status">
        {/* <p  className="is-txt__small">No record found </p> */}
      </div>
      <div className="credit__history__item">
        <p className={'credit-sum__state ' + record.historyData.status}>
          <span
            className={`credit-sum__state--indicator ${record.historyData.status}`}
          ></span>
          <span> {record.historyData.status} </span>
        </p>
      </div>
      <div className="credit__history__item">
        <p className="credit__history__amount">
          N {formatCurrency(Number(record.historyData.amount))}
        </p>
      </div>
    </div>
  );
};

export default UserInvestmentHistory;
